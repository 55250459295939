import {IconButton} from "@mui/material";
import Modal from "react-responsive-modal";
import React, {useState} from "react";
import Product from "../product/Product";
import RepairIcon from "../images/repair-icon.png";
import {v4} from "uuid";
import Layer from "../layer/Layer";
// import "../style/work-force-displayer.css";


export default function WorkForceDisplayer({operations, background, provisionalWork, jobRepair, jobProblem, ibatApi, readOnly}){
    const [workForce, setWorkForce] = useState(false)
    const poi = operations?.filter(op=>op?.metadata)?.map(op=>op?.metadata?.points)?.flat(2)     //.map(p=>p.selectedProducts))?.flat(2)


    return(
        /*<div className={"workForceButton"}>
            {prods.length>0 && <IconButton onClick={()=>setWorkForce(true)}><img alt={""} src={RepairIcon}/></IconButton>}
            <Modal open={workForce} onClose={()=>setWorkForce(false)}>
                <ul>
                    {prods.map(d=><li key={v4()}>
                        <Product {...d}/>
                    </li>)}
                </ul>
            </Modal>
        </div>*/
        <div className={"workforce-btn"}>
            {poi.length>0 && <IconButton onClick={()=>setWorkForce(true)}><img alt={""} src={RepairIcon}/></IconButton>}
            <Modal open={workForce} onClose={()=>setWorkForce(false)}>
                <Layer points={poi}
                       background={background}
                       provisionalWork={provisionalWork}
                       jobRepair={jobRepair}
                       jobProblem={jobProblem}
                       read0nly={readOnly}
                       ibatApi={ibatApi}
                />
            </Modal>
        </div>

    )
}