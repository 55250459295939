import {IconButton} from "@mui/material";
import {useEffect, useState} from "react";
import Select from "react-select";
import IbatApi from "../../../utils/IbatApi";

export default function PreciserFamilyTab({preciserFamilies, families, updateList, jtList}){

    const mappedFamilies = families.map(f => {
        return {id: f.id, name: f.name, value: f.id, label: f.name}
    })

    const [selectedFamily, setSelectFamily] = useState()
    const [jobTypes, setJobTypes] = useState()
    const [newFamilyObject, setNewFamilyObject] = useState({})
    const api = new IbatApi()

    useEffect(()=> {
    }, [newFamilyObject, selectedFamily, preciserFamilies])

    function handleSelectFamily(d){
        setNewFamilyObject({...newFamilyObject, family: d.value, jobTypes:[]})
        api.getFamilyProduct(d.id)
            .then(r => {
                setJobTypes(jtList.filter(jt => r.jobTypes.includes(jt.id)))
            })
            .catch(e => console.log(e))
    }
    function getJobTypesConcerned(preciserFamily){
        api.getFamilyProduct(preciserFamily.family)
            .then(r => {
                setJobTypes(jtList.filter(jt => r.jobTypes.includes(jt.id)))
            })
            .catch(e => console.log(e))
    }

    function handleJobTypeNewFamily(d){
        setNewFamilyObject({...newFamilyObject, jobTypes: d.map(jt => jt.value)})
    }
    function handleName(e) {
        setNewFamilyObject({...newFamilyObject, name: e.target.value})
    }
    function newPreciserFamily() {
        console.log(newFamilyObject)
        api.newCatalogPreciserFamily({...newFamilyObject, id: newFamilyObject.id ?? null, reference: newFamilyObject.reference ?? '-1'})
            .then(() => {updateList()})
            .catch(() => {
                console.log("une erreur est survenue")
            })
    }
    /*function addCaracToFamily(){
        let currentCarac = newFamilyObject?.caracteristics ?? {}
        currentCarac = {...currentCarac, [newCarac.caracName]:newCarac.caracType}
        setNewFamilyObject({...newFamilyObject, caracteristics: currentCarac})
    }
    function removeCarac(k){
        delete newFamilyObject?.caracteristics[k]
        setNewFamilyObject({...newFamilyObject})
    }*/

    function newFamily(){
        return (
            <div className={"catalog-content flex-column"}>
                <div className={"flex-row"}>
                    <div>
                        <label>Nom de la sous-famille</label><br />
                        <input type={"text"} placeholder={"Tuiles"} value={newFamilyObject?.name ?? ""} onChange={e=>handleName(e)}/>
                    </div>
                    <div>
                        <label>Membre de la famille</label>< br />
                        <Select
                            placeholder={"Toiture, Électricité, ..."}
                            value = {mappedFamilies.filter(f=> f.value === newFamilyObject?.family)[0]  ?? null}
                            onChange = {d => handleSelectFamily(d)}
                            options={mappedFamilies}/>
                    </div>
                    {newFamilyObject.family &&
                        <div>
                            <label>Corps de métier concerné</label>< br />
                            <Select
                                isMulti
                                placeholder={"Couverture, Plomberie, ..."}
                                value = {jobTypes?.filter(jt => newFamilyObject?.jobTypes?.includes(jt.id))}
                                onChange = {d => handleJobTypeNewFamily(d)}
                                options={jobTypes}/>
                        </div>
                    }
                </div>
                {/*Caractéristiques de la sous-famille
                <ul>
                    {newFamilyObject?.caracteristics && Object.keys(newFamilyObject?.caracteristics)?.map((k, idx) =>
                        <li>
                            <IconButton className={"action-btn cancel"} onClick={()=>removeCarac(k)}>X</IconButton> {k} ({newFamilyObject?.caracteristics[k]})
                        </li>
                    )}
                <div className={"flex-row"}>
                    <input type={"text"} name={"caracteristic"} placeholder={"Longueur"} value={newCarac?.caracName ?? ""} onChange={event => setNewCarac({...newCarac, caracName: event.target.value})}/>
                    <Select
                        className={"large"}
                        placeholder={"Centimètre, millimètre..."}
                        onChange = {d => setNewCarac({...newCarac, caracType: d.value})}
                        options={typeOfValue}/>
                    <IconButton className={"action-btn validation"} onClick={addCaracToFamily}>Ajouter</IconButton>
                </div>
                </ul>*/}
                <div className={"flex-row"}>
                    <IconButton className={"action-btn cancel"} onClick={()=>setNewFamilyObject({})}>Annuler</IconButton>
                    <IconButton className={"action-btn validation"} onClick={newPreciserFamily}>Ajouter</IconButton>
                </div>
            </div>
        )
    }
    function editFamily(){
        return (
            <div className={"catalog-content flex-column"}>
                <div className={"flex-row"}>
                    <div>
                        <label>Nom de la sous-famille</label><br />
                        <b>{newFamilyObject?.name}</b>
                    </div>
                    <div>
                        <label>Membre de la famille</label>< br />
                        <Select
                            placeholder={"Toiture, Électricité, ..."}
                            value = {mappedFamilies.filter(f=> f.value === newFamilyObject?.family)[0]  ?? null}
                            onChange = {d => handleSelectFamily(d)}
                            options={mappedFamilies}/>
                    </div>
                    {newFamilyObject.family &&
                        <div>
                            <label>Corps de métier concerné</label>< br />
                            <Select
                                isMulti
                                placeholder={"Couverture, Plomberie, ..."}
                                value = {jobTypes?.filter(jt => newFamilyObject?.jobTypes?.includes(jt.id))}
                                onChange = {d => handleJobTypeNewFamily(d)}
                                options={jobTypes}/>
                        </div>
                    }
                </div>
                {/*Caractéristiques de la sous-famille
                <ul>
                    {newFamilyObject?.caracteristics && Object.keys(newFamilyObject?.caracteristics)?.map((k, idx) =>
                        <li>
                            <IconButton className={"action-btn cancel"} onClick={()=>removeCarac(k)}>X</IconButton> {k} ({newFamilyObject?.caracteristics[k]})
                        </li>
                    )}
                    <div className={"flex-row"}>
                        <input type={"text"} name={"caracteristic"} placeholder={"Longueur"} value={newCarac?.caracName ?? ""} onChange={event => setNewCarac({...newCarac, caracName: event.target.value})}/>
                        <Select
                            className={"large"}
                            placeholder={"Centimètre, millimètre..."}
                            onChange = {d => setNewCarac({...newCarac, caracType: d.value})}
                            options={typeOfValue}/>
                        <IconButton className={"action-btn validation"} onClick={addCaracToFamily}>Ajouter</IconButton>
                    </div>
                </ul>*/}
                <div className={"flex-row"}>
                    <IconButton className={"action-btn cancel"} onClick={()=>setNewFamilyObject({})}>Annuler</IconButton>
                    <IconButton className={"action-btn validation"} onClick={newPreciserFamily}>Sauvegarder</IconButton>
                </div>
            </div>
        )
    }

    return (
        <div className={"family-tab br05 flex-row"}>
            <ul>
                <li>
                    <IconButton className={"action-btn validation"} onClick = {() => {
                        setNewFamilyObject({})
                        setSelectFamily()
                    }}>Nouvelle sous-famille</IconButton>
                </li>
                {preciserFamilies?.sort((a, b) => a.name > b.name ? 1 : -1)?.map(i =>
                    <li>
                        <div className={"as-link"} onClick={()=>{
                            setSelectFamily(i)
                            setNewFamilyObject(i)
                            getJobTypesConcerned(i)
                        }}>{i.name}</div>
                    </li>
                )}
            </ul>
            {
                !selectedFamily && newFamily()
            }
            {
                selectedFamily && editFamily()
            }
        </div>
    )
}