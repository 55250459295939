import React, {Fragment, useEffect, useState} from "react";
import IbatApi from "../utils/IbatApi";
import {constants} from "../components/util/constants";
import {v4} from "uuid";

export default function RiskSelector({onSelect, selected, emergency = false}){
    const api=new IbatApi();
    const[RAL,setRAL]=useState()
    const optionRA=[
        {label:constants.RA_NONE_LABEL,value:constants.RA_NONE},
        {label:constants.RA_PARTIAL_LABEL,value:constants.RA_PARTIAL},
        {label:constants.RA_TOTAL_LABEL,value:constants.RA_TOTAL}
    ]
    useEffect(()=>{
        if(selected) {
            setRAL(selected)
        }
        else {
            api.getCompany().then(myCompany=>{
                let level = emergency ? myCompany.defaultRiskAnalysisInEmergencyLevel : myCompany.defaultRiskAnalysisLevel
                setRAL(level)
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[selected, emergency])

    return <select className={"large w100"} value={RAL} onChange={e=> {
                setRAL(e.target.value)
                onSelect(e.target.value, emergency)
            }}>
             {optionRA?.map(opt=>
                 <option key={v4()} value={opt.value}>{opt.label}</option>
             )}
            </select>
}
