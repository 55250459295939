import React, {Fragment, useEffect, useState} from "react"
import {IconButton, Tooltip} from "@mui/material";
import OpenFormIcon from "../../images/new-interv.png"
import IbatApi from "../../utils/IbatApi";
import CreateDevis from "./CreateDevis";
import Modal from "react-responsive-modal";
import constants from "../util/constants";
import {useSnackbar} from "notistack";
import { text } from "../util/constants";
import {isSpecialIntervention} from "../../helpers/interventionManager";

export default function InterventionForm({onSaveSucceed, site, className, client, quotationNumber, supply, material, amount, quotationId, supplierMode}) {
    const default_state= {
        devis: {clientIdBCommande: ""},
        intervention:{},
        siteId: site?.id,
        site: {...site},
    }
    const { enqueueSnackbar} = useSnackbar();

    const [state, setState] = useState(default_state)
    const [show, setShow] = useState(false)
    const [isSavable, setIsSavable] = useState(false)
    const api = new IbatApi()

    function handleSave() {
        let intervention= {
            etat: state.intervention !== 0 && state.intervention.team && state.intervention.team.name !== "" ? constants.SCHEDULED : constants.RECEIVED,// todo: ajouter environement variable
            idSecteur: state.intervention.secteur??null,
            idTypeIntervention: state.intervention.type,
            teamId: state.intervention.team?state.intervention.team.id:null,
            teamSupplyId: state.intervention.teamSupply?state.intervention.teamSupply.id:null,
            dateIntervention: state.intervention.intervention?new Date(state.intervention.intervention).getTime():0,
            jobTypeId: state.intervention.jobTypeId,
            estimateDuration:state.intervention.estimateDuration?state.intervention.estimateDuration:constants.DEFAULT_DURATION,
            dateEndIntervention: new Date(new Date(state.intervention.intervention).getTime()+state.intervention.dateEndIntervention).getTime(),
            underType:state.intervention.underType,
            riskAnalysisLevel:state.intervention.riskAnalysisLevel,
            availability: state.intervention.availability ? new Date(state.intervention.availability).getTime():null,
            supplyAlert : state.supplyMachinery.length > 0 || state.supplyDocument.filter(sd => sd.type === "2").length > 0
        }
        let mappedSAD = state.supplyAdminDocument?.map(sad => {
            return {
                ...sad,
                documents: sad?.documents?.map(file => {
                    if (typeof file === "string") {
                        return {name: file}
                    }
                    return file
                }),
                type: sad.type.id ?? sad.type
            }
        })
        let myUserId = api.getMyUserId();
        let devisApi = {
            ...state.devis,
            acompte: state.acompte,
            clientIdBCommande: state.clientIdBCommande,
            montant: state.montant,
            clientId: state.clientId,
            insuranceId: state.insuranceId,
            myUserId: myUserId ,
            comments: state.comments,
            dateReception: new Date(state.reception).getTime(),
            documents: state.documents?state.documents:[],
            devisData:state.material.map(d => {return {...d, name: d.type}})??[],
            supplyMachinery:state.supplyMachinery?state.supplyMachinery:[],
            supplyDocument:state.supplyDocument?state.supplyDocument:[],
            supplyAdminDocument: mappedSAD??[],
            intervention: {...intervention},
            originQuotationId: quotationId,
        }

        api.creatIntervention(state.site.id, devisApi)
            .then(resp => {
                setState(default_state);
                setIsSavable(false)
                onSaveSucceed()
                window.open(`/devis/${resp.id}`, "_blank")
                setShow(false)
            })
            .then(r => enqueueSnackbar(text.SNACKBAR_SAVED,{variant:"success"}))
            .catch(e=>{
                enqueueSnackbar(text.SNACKBAR_ERROR,{variant:"error"})
            })
    }

    /*function handleCancel() {
        setState(default_state)
        setShow(false)
    }*/
    function handleSavable(data) {
        if(!isSpecialIntervention(data.intervention.type)){
            setIsSavable(data.reception && data.clientId &&  data.siteId && data.intervention.type && data?.intervention?.secteur)
            return
        }
        if (+data.intervention.type === 8){
          setIsSavable(
            data.siteId
            && data.intervention.type
          )
        }
        else {
          setIsSavable(
            data.siteId
            && data.intervention.type
            && data.intervention.secteur
            && data.intervention.team
            && data.intervention.intervention
            && data.intervention.estimateDuration!==null
          )
        }

    }

    function handleData(data) {
        setState(data)
        handleSavable(data)
    }

    return (
        <Fragment>
            {
                !supplierMode &&
                <Tooltip title={text.TOOLTIP_CREATE_COMMAND}>
                    <IconButton className={className} disabled={!api.hasRole(constants.PERMISSION_AM) } onClick={() => {setShow(true)}}>
                        <img alt={""} src={OpenFormIcon}/>
                    </IconButton>
                </Tooltip>
            }
            {
                supplierMode &&
                <IconButton className={"action-btn neutral"} onClick={() => setShow(true)}>
                    Créer une nouvelle intervention chez ce fournisseur
                </IconButton>
            }

            <Modal open={api.hasRole(constants.PERMISSION_AM) && show} onClose={()=>setShow(false)}>
                <div className={"commande-form primary-section flex-column"}>
                    <h2>{text.H2_CREATION_COMMAND}</h2>
                    <CreateDevis amount={amount} onChange={handleData} site={{...site}} client={{...client}} quotationNumber={quotationNumber} supply={supply} material={material} supplierMode={supplierMode}/>
                    {!isSavable && <p className={"error"}>{text.INCOMPLETE_FORM}</p>}
                    <IconButton onClick={isSavable?() => {handleSave();setShow(false)}:() => {setShow(false)}}
                                className={`action-btn ${isSavable? "validation" : "cancel"}`}>
                        {isSavable ? text.CREATE : text.CANCEL}
                    </IconButton>

                </div>
            </Modal>
        </Fragment>
    )
}
