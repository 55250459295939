import React, {useEffect, useState} from "react";
import SearchCompany from "../../forms/SearchCompany";
import {Checkbox, IconButton} from "@mui/material";
import {text} from "../../util/constants";
import IbatApi from "../../../utils/IbatApi";


const api = new IbatApi()

export default function CompanyCrud(){
    const [company, setCompany] = useState()
    const [logo, setLogo] = useState()
    const [countIntervention, setCountIntervention] = useState(0)
    const selectCompany = (c) => {
        api.getCompanyById(c.id)
            .then(comp => {
                setCompany(comp)
                api.getFile(comp?.logo).then(resp => setLogo(resp.url)).catch(e=>setLogo())
            })
        api.getInterventionCountByCompany(c.id).then(resp => {
            setCountIntervention((resp))
        })
    }
    function handleSave(){
        api.updateCompany(company)
    }
    function handleChange(ev){
        setCompany({...company, [ev.target.id]:ev.target.value})
    }
    function handleBillable(ev){
        setCompany({...company, billable:ev.target.checked})
    }
    useEffect(()=>{}, [company])
    return(
        <div>
            <SearchCompany onSave={selectCompany}/>
            {company &&
                <div className={"grid company-crud"}>

                    <span>Company :</span>
                    Nombre d'intervention démarrée depuis l'appli mobile : {countIntervention} - soit  {countIntervention} * 5 = {countIntervention * 5}€
                    <div className={"flex-column"}>
                        {logo && <img alt={"logo"} src={logo??""}/>}
                        <span>id: <b>{company?.id}</b></span>
                        <span>Nom: <input className={"large"} type={"text"} value={company?.name} id={"name"} onChange={handleChange}/></span>
                        <span>Siren: <input className={"large"} type={"text"} value={company?.siren} id={"siren"} onChange={handleChange}/></span>
                        <span>Adresse: <input className={"large"} type={"text"} value={company?.address} id={"address"} onChange={handleChange}/></span>
                        <span>Code Postal: <input className={"large"} type={"text"} value={company?.cp} id={"cp"} onChange={handleChange}/></span>
                        <span>Ville: <input className={"large"} type={"text"} value={company?.ville} id={"ville"} onChange={handleChange}/></span>
                        <span>Mail: <input className={"large"} type={"text"} value={company?.mail} id={"mail"} onChange={handleChange}/></span>
                        <span>Tel: <input className={"large"} type={"text"} value={company?.phone} id={"phone"} onChange={handleChange}/></span>
                        <span>Facturable : <Checkbox className={"large"} checked={company?.billable ?? false} id={"billable"} onChange={handleBillable}/></span> <br />
                        <span> Style sélectionné: <input className={"large"} type={"text"} defaultValue={company?.frontStyle} id={"frontStyle"} onChange={handleChange}/></span>

                        <IconButton className={"action-btn validation"} onClick={handleSave}> {text.SAVE}</IconButton>
                    </div>
                </div>
            }
        </div>
    )
}