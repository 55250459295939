import React, {useEffect, useState} from "react";
import IbatApi from "../../utils/IbatApi";
import SiteIcon from "../../images/site-add.png"
import Table from "../../table/Table";
import {IconButton} from "@mui/material";
import Modal from "react-responsive-modal";
import CreateSite from "../forms/CreateSite";
import {useSnackbar} from "notistack";
import {text} from "../util/constants";
import {v4} from "uuid";


export default function Sites({showSupplierIntervention}) {
    const {enqueueSnackbar}=useSnackbar()


    const columns = [
        {id: "id", label: text.ID, type: showSupplierIntervention ? "text" : "linkText", isChecked: true, isFiltrable: true},
        {id: "name", label: text.NAME, type: "text", isChecked: true, isFiltrable: true},
        {id: "address", label: text.ADDRESS, type: "text", isChecked: true, isFiltrable: true},
        {id: "ville", label: text.TOWN, type: "text", isChecked: true, isFiltrable: true},
        {id: "cp", label: text.ZIPCODE, type: "text", isChecked: true, isFiltrable: true},
        {id: "pays", label: text.COUNTRY, type: "text", isChecked: true, isFiltrable: true, hideOnPhone: true},
        {id: "contactName", label: text.CONTACT_NAME, type: "text", isChecked: true, isFiltrable: false, hideOnPhone: true},
        {id: "contactStatus", label: text.CONTACT_STATUS, type: "text", isChecked: true, isFiltrable: false, hideOnPhone: true},
    ]

    const [siteForm,setSiteForm]=useState(false)
    const [state, setState] = useState({
        columns: columns,
        siteList: [],
        count: 0,
        pageNumber: 0,
        pageSize: 20,
        filters: {
            cp: "",
            externalId: "",
            pays: "",
            adresse: "",
            name: "",
            id: "",

        }
    })
    const [typing,setTyping]=useState(false)

    let api = new IbatApi();
    useEffect(() => {
        getSites()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [typing,state.pageNumber,state.pageSize])

    const handleFilter = (e) => {
        let value = e.target.value
        let name = e.target.name
        setState(state => ({...state, filters: {...state.filters, [name]: value}, lastQueryAt: new Date()}))
    }

    const handleChangePage = (newPage) => {
        setState(state => ({...state, pageNumber: newPage}))
    };

    const handleChangeRowsPerPage = (event) => {
        setState(state => ({...state, pageSize: event.target.value, pageNumber: 0}))
    };


    const handleColumns = (event) => {
        let columns = state.columns
        let filter = {...state.filters}
        let find = columns.find(col => col.id === event.target.name);
        find.isChecked = event.target.checked
        if (find.isFiltrable) {
            filter[find.id] = ""
        }
        setState(state => ({...state, columns: columns, filters: filter, mapping: false}))
    }


    function getSites() {
        var sendedAt = new Date()
        api.getSiteFilterd({
            ...state.filters,
            pageNumber: state.pageNumber,
            pageSize: state.pageSize
        })
            .then(res => {
                if(!state.lastQueryAt || sendedAt >= state.lastQueryAt){
                    setState(state => ({...state, siteList: res.content ,count:res.totalElements}))
                }
            })
            .catch(e=>enqueueSnackbar(e.message,{variant:"error"}))
    }

    function handleSiteForm(){
        setSiteForm(!siteForm)
    }

    return (
        <div className={"page list-page"}>
            <Table list={state.siteList}
                   filter={state.filters}
                   columns={state.columns}
                   rowCount={state.count}
                   pageSize={state.pageSize}
                   pageNumber={state.pageNumber}

                   onEndTyping={setTyping}
                   onGetData={getSites}
                   onHandleFilter={handleFilter}
                   onHandleColumns={handleColumns}
                   onHandleChangePage={handleChangePage}
                   onHandleChangeRowsPerPage={handleChangeRowsPerPage}
                   tabName={"site"}
                   showSupplierIntervention={showSupplierIntervention}

            >
                <IconButton onClick={handleSiteForm} ><img alt={""} src={SiteIcon}/></IconButton>
                <Modal open={siteForm} onClose={handleSiteForm}>
                    <CreateSite onSave={handleSiteForm} name={""} onCancel={handleSiteForm}/>
                </Modal>
            </Table>
        </div>
    )
}