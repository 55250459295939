import React, {useEffect, useState} from "react";
import IbatApi from "../../utils/IbatApi";
import {IconButton} from "@mui/material";
import OperationMandatoireIcon from "../../images/mandatory-icon.png"
import Select from "react-select";
import constants, {text} from "../util/constants";
import {useSnackbar} from "notistack";
import ExpandableDiv from "../util/ExpandableDiv";

export default function OperationForm({idIntervention, onCreate,mandatories, isMobile}) {
    const { enqueueSnackbar} = useSnackbar();
    let api = new IbatApi();
    const [operation, setOperation] = useState([])
    const [operationList, setOperationsList] = useState([])

    //todo ajouter un moyen de récupérer les jobType
    useEffect(()=>{
        api.getMyOperationType("roofer").then(ots=>{
            setOperationsList(ots.map(ot=>({label:ot.name,value:ot.id})))
            var oldOperationTypeId = mapOldOperationTypeId()
            const otMaps=ots.filter(ot=> mandatories?.includes(ot.id) || oldOperationTypeId?.includes(ot.name)).map(ot=>({label:ot.name,value:ot.id}))
            setOperation(otMaps)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[mandatories])

    function createOperation() {
        api.createOperation(idIntervention, operation.map(op=>op.value)).then(r => {
            onCreate();
            setOperation(r.addedOperation)
        }).then(r => enqueueSnackbar(text.SNACKBAR_SAVED,{variant:"success"}))
            .catch(e=>enqueueSnackbar(text.SNACKBAR_ERROR,{variant:"error"}))
    }
    function mapOldOperationTypeId() {
        return mandatories?.map(m => {
            switch (m) {
                case "1":
                    return "Général"
                case "2":
                    return "Nettoyage"
                case "3":
                    return "Plain carré"
                case "4":
                    return "Chéneaux"
                case "5":
                    return "Naissances"
                case "6":
                    return "Relevés"
                case "7":
                    return "Couvertines"
                case "8":
                    return "Lanterneaux"
                case "9":
                    return "Sorties de toit"
                case "10":
                    return "Bardage"
                case "11":
                    return "Abergement"
                case "12":
                    return "Charpente"
                case "13":
                    return "Cheminées"
                case "14":
                    return "Descentes"
                case "15":
                    return "Dômes"
                case "16":
                    return "Faîtage"
                case "17":
                    return "Gouttières"
                case "18":
                    return "Revêtement bacs acier"
                case "19":
                    return "Revêtement étanchéité"
                case "20":
                    return "Solins"
                case "21":
                    return "Sorties de climatisation"
                case "22":
                    return "Tôles"
                case "23":
                    return "Velux"
                case "24":
                    return "VMC"
                case "25":
                    return "Autres"
                case "26":
                    return "Tuiles"
                case "27":
                    return "Ardoises"
                case "28":
                    return "Chapeaux de cheminée"
                case "29":
                    return "Rives"
                case "30":
                    return "Noues"
                case "31":
                    return "Verrières"
                case "32":
                    return "Lucarnes"
                case "33":
                    return "Anomalies"
                case "34":
                    return "Mise hors d'eau"
                default :
                    return null
            }
        })

    }
    function handleOperationType(ops) {
        setOperation(ops)
    }

    return (
        <div hidden={!api.hasRole(constants.PERMISSION_AM)} className={"added-operation flex-column primary-section"}>
            <h2 className={"flex-row"}>{text.MANDATORY_OPERATIONS}</h2>
            {
                isMobile ?
                    <ExpandableDiv className={"flex-column w100"}>
                        <Select
                            isMulti
                            onChange={handleOperationType}
                            placeholder={text.OPERATIONS_TO_SUBMIT}
                            options={operationList.sort((a, b) => a.label > b.label ? 1 : -1)}
                            value={operation}
                        />
                        <IconButton className={"action-btn neutral save"}
                                    onClick={createOperation}>{text.SAVE}</IconButton>
                    </ExpandableDiv>
                    :
                    <div className={"secondary-section flex-column w100"}>
                        <Select
                            isMulti
                            onChange={handleOperationType}
                            placeholder={text.OPERATIONS_TO_SUBMIT}
                            options={operationList.sort((a, b) => a.label > b.label ? 1 : -1)}
                            className={"mandatorySelect"}
                            value={operation}
                        />
                        <IconButton className={"action-btn neutral save"}
                                    onClick={createOperation}>{text.SAVE}</IconButton>
                    </div>

            }
        </div>

    )
}
