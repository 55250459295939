import React, {useEffect, useState} from "react";
import IbatApi from "../../utils/IbatApi";
import {useSnackbar} from "notistack";
import {IconButton} from "@mui/material";
import constants, {text} from "../util/constants";
import InteractionClient from "./InteractionClient";
import Modal from "react-responsive-modal";
import ValidateIcon from "../../images/validate-icon.png";
import FinishIcon from "../../images/finish-icon.png";
import {v4} from "uuid";

export default function Notifications() {

    const [state, setState] = useState()
    const [showInteractionClient, setShowInteractionClient] = useState(false);

    const { enqueueSnackbar } = useSnackbar();
    let api = new IbatApi();

    useEffect(() => {
        if (!state){
            getData()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function getData(){
        api.getNotifications()
            .then(resp => setState(resp))
            .catch(e => {
                console.log(e)
                enqueueSnackbar(e.statusText ?? "No response",{variant:"error"})
            })
    }

    function handleHover(notifId){
        let targetNotif = state.unseen.filter(n=> n.id === notifId)
        if (targetNotif.length === 0 || (targetNotif.length > 0 && targetNotif[0].seen)) {
            return
        }
        api.readNotification(notifId)
        setState({...state, unseen: state.unseen.map(n => n.id === notifId ? {...n, seen: true} : n)})
    }

    function openTarget(target){
        if (target.includes("interactionClient")){
            let vars = {}
            let filter = target.split("?")[1].split("&")
            for (const filterKey in filter) {
                let values = filter[filterKey].split("=")
                if (!values[0].includes("interventionId")){
                    vars[values[0].replace("intervention", "id")] = values[1]
                }
                else {
                    vars["id"] = values[1]
                }
            }
            setShowInteractionClient(vars)
        }
        else {
            window.open(`/${target}`)
        }
    }
    function closeNotif(id){
        api.closeNotif(id)
            .then(resp => {
                enqueueSnackbar(text.SNACKBAR_SAVED,{variant:"success"})
                // getData()
                setState({...state, seen: state.seen.map(n => n.id === id ? {...n, status: "CLOSED"} : n), unseen: state.unseen.map(n => n.id === id ? {...n, status: "CLOSED"} : n)})
            })
            .catch(e => enqueueSnackbar(e?.data?.error ?? "No response",{variant:"error"}))
    }
    function showNotif(notif, seen){
        return (
            <div key={v4()} className={`alert secondary-section flex-row ${notif.seen ? "seen" : seen}`} onMouseEnter={()=>handleHover(notif.id)}>
                <div className={"pointer w100"} onClick={()=>openTarget(notif.link)}>
                    <div className={"date"}>
                        {new Date(notif.emissionDate).toLocaleString()}
                    </div>
                    <div className={"notif"}>
                        <div className={"from"}>
                            De : {notif.from?.name}
                        </div>
                        <div className={"as-link"}>
                            => {notif.message}
                        </div>
                    </div>
                </div>

                <IconButton className={"updateNotifStatus"} disabled={!api.hasRole(constants.PERMISSION_AM)} checked={notif.status === "CLOSED"}  onClick={()=>closeNotif(notif.id)}>
                    <img alt={"desk-status"} src={notif.status==="CLOSED"?ValidateIcon:FinishIcon}/>
                </IconButton>

            </div>
        )
    }

    return (
        <div className={"page"}>
            <div className={"notification-bean flex-row"}>
                <div className={"col flex-column primary-section"}>
                    Notifications lues
                    {
                        state?.seen?.sort((n1, n2)=> n1.emissionDate > n2.emissionDate ? -1 : 1).map(notif=> showNotif(notif, "seen"))
                    }
                </div>

                <div className={"col flex-column primary-section"}>
                    Notifications non lues
                    {
                        state?.unseen?.sort((n1, n2)=> n1.emissionDate > n2.emissionDate ? 1 : -1).map(notif=> showNotif(notif, "unseen"))
                    }
                </div>
                <div className={"col flex-column primary-section"}>
                    À traiter
                    {
                        state?.todo?.sort((n1, n2)=> n1.emissionDate > n2.emissionDate ? 1 : -1).map(notif=> showNotif(notif, "seen"))
                    }
                </div>
                <Modal open={showInteractionClient} onClose={()=>setShowInteractionClient(false)}>
                    <InteractionClient {...showInteractionClient}/>
                </Modal>
            </div>
        </div>
    )
}