import {IconButton} from "@mui/material";
import constants from "../util/constants";
import SaveIcon from "../../images/button-create.png";
import React, {Fragment, useEffect, useState} from "react";
import IbatApi from "../../utils/IbatApi";
import Loading from "../util/Loading";
import {useSnackbar} from "notistack";
import {getIcon} from "../../helpers/assetManager";

export default function PdfBean({id,onUpload, type}){

    const api = new IbatApi();
    const [pdfDocument,setPdfDocument]=useState()
    const { enqueueSnackbar}=useSnackbar();

    useEffect(()=>{

        id && api.getIntervention(id)
            .then(i=>{
                if (type === "security"){
                  handleOpenSecurityPDF(i)
                }
                else {
                  handleOpenInterventionPDF(i)
                }
            })
    }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        ,[id, type])

    function draw(ctx,p,bkg) {
        return new Promise(resolve=> {
            const icon = new Image()
            let img = dataIdToImg(p.dataId);
            icon.src = img
            console.log(icon.src)
            icon.onload = () => {
                ctx.fillStyle = p.name === "Repair" ? '#00f' : "#ff0000";
                ctx.beginPath()
                ctx.arc(Math.abs(1 - p.y) * bkg.width, Math.abs(p.x) * bkg.height, 5, 0, 2 * Math.PI)
                ctx.fill()
                ctx.drawImage(icon, Math.abs(1 - p.y) * bkg.width - icon.width / 2, Math.abs(p.x) * bkg.height - icon.height / 2)
                resolve("img "+img)
            }
        })
    }

    function dataIdToImg(id){
        return getIcon(id)
        // switch (id){
        //     case 30034:
        //         return IconMastic;
        //     case 30035:
        //         return IconSilicon;
        //     case 30036 :
        //         return IconBituband;
        //     case 30037 :
        //         return IconResine;
        //     case 30038 :
        //         return IconBitume;
        //     case 30039 :
        //         return IconFixation;
        //     case 30207 :
        //         return IconRemplacement;
        //     case 30206 :
        //         return IconRemise;
        //     case 30041 :
        //         return IconLongTerme;
        //     case 30042 :
        //         return IconUrgent;
        //     case 30043 :
        //         return IconImportant;
        //     case 30044 :
        //         return IconUrgenceMoyenne;
        //     case 30045 :
        //         return IconImportanceMoyenne;
        //     default:
        //         return IconFuite;
        // }
    }

    function handleLayer(points,image){
        const canvas=document.createElement('canvas');
        let background = handleBackground(image.b64,points,canvas);
        return background

    }
    function handleBackground(image,points,canvas){
        return new Promise(resolve => {
            let bkGround = new Image();
            bkGround.src = image;
            const ctx = canvas.getContext('2d');
            bkGround.onload = function () {
                canvas.height = bkGround.height
                canvas.width = bkGround.width
                ctx.drawImage(bkGround,0,0, bkGround.width, bkGround.height);
                Promise.all(points.map(p => draw(ctx, p, bkGround)))
                    .then(d=>{
                        return resolve(canvas.toDataURL())})
                    .catch (e => console.log(e))
                // return resolve(canvas.toDataURL())
            }
        })
    }
    function addrepairWork(operation){
        let reducer=(acc,object)=>{
            let findIndex = acc.findIndex(obj=>obj.dataId===object.dataId);
            if (findIndex===-1){
                acc.push(object)
            }
            return acc
        }
        let work =operation.metadata?.points.filter(poi=>poi.name===constants.WORK).reduce(reducer,[])
        let repair =operation.metadata?.points.filter(poi=>poi.name===constants.REPAIR).reduce(reducer,[])
        operation.repair=repair
        operation.work=work
        return operation

    }

    async function handleOpenInterventionPDF(state) {
      state.operations=state.operations.map(operation=>addrepairWork(operation))
      api.getFile(state.devis.site.imageUrl)
        .then(img => {
          const pdfContent = state
          pdfContent.operations = pdfContent.operations.filter(op => op.visible)
          const layers = pdfContent.operations
            .filter(op => op.metadata && op.metadata.points.length > 0)
            .map(async op => ([op.typeId, await handleLayer(op.metadata.points, img)]));


          let fullpoints = pdfContent.operations
            .filter(op => op.metadata && op.metadata.points.length > 0)
            .flatMap(op => op.metadata.points);
          layers.push(new Promise(async resolve => resolve([-1, await handleLayer(fullpoints, img)])))


          Promise.all(layers)
              .then(d => api.genInterventionPdf({
                company: state.devis.company,
                intervention: pdfContent,
                layers: d
              },false))
              .then(data1 => {
                const blobUrl = URL.createObjectURL(data1);
                setPdfDocument(blobUrl)
              })
              .catch(e=>enqueueSnackbar(e.message,{variant:"error"}))
        })
        .catch(e=>enqueueSnackbar(e.message,{variant:"error"}))
    }
    async function handleOpenSecurityPDF(state) {
      const pdfContent = {
        feedBack: state.feedBack,
        resume: state.resume,
        object: state.object,
        team: state.team.name,
        dateIntervention: state.dateIntervention,
        endIntervention: state.endIntervention,
        riskAnalysisLevel: state.riskAnalysisLevel,
        devis: state.devis,
        secteur: state.secteur.name,
        riskAnalysis: state.riskAnalysis
      }
        api.getFile(state.devis.site.imageUrl)
          .then(img => {
            api.genSecurityPdf(pdfContent)
              .then(data1 => {
                const blobUrl = URL.createObjectURL(data1);
                setPdfDocument(blobUrl)
              })
              .catch(e=>enqueueSnackbar(e.message,{variant:"error"}))
        })
          .catch(e=>enqueueSnackbar("ImgSite "+e.message,{variant:"error"}))
    }

    function uploadRapport(){
        api.hasRole(constants.PERMISSION_CRE) && api.addReport(id,pdfDocument).then(onUpload())
    }
    return(
    <Fragment>
        {pdfDocument?
            <div>
                <embed src={pdfDocument} height={700} width={1000}/>
                <IconButton disabled={!api.hasRole(constants.PERMISSION_CRE)} onClick={uploadRapport}><img alt={""} src={SaveIcon}/> </IconButton>
            </div>:
            <Loading/>}

    </Fragment>)

}
