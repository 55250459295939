import IbatApi from "../../utils/IbatApi";
import React, {useEffect, useState} from "react";
import {Navigate, useLocation} from 'react-router-dom'
import Modal from "react-responsive-modal";
import AuthPage from "./AuthPage";

/*export default function PrivateRoute({component, properties,...rest }){

    let api = new IbatApi();
    const isLoggedIn = api.getToken()!==""

    return (
        <Route {...rest}
            render={props =>

                isLoggedIn ? (React.createElement(component, {...properties})) : (<Navigate to={{ pathname: '/login', state: { from: props.location } }} />
                )
            }
        />
    )
}*/

export default function PrivateRoute({ children }) {

    const [showLogin, setShowLogin] = useState(false);
    let api = new IbatApi();
    const isLoggedIn = api.getToken()!==""
    let location = useLocation();

    useEffect(() => {
        // Écoutez l'événement d'erreur 401 global
        const handleUnauthorizedError = () => {
            setShowLogin(window.location.pathname !== "/login" && window.location.pathname !== "/subscribe");
        };

        window.addEventListener('unauthorizedError', handleUnauthorizedError);

        return () => {
            // Nettoyez l'écouteur d'événement lors de la suppression du composant
            window.removeEventListener('unauthorizedError', handleUnauthorizedError);
        };
    }, []);

    return isLoggedIn || showLogin ?
        <>
            {children}
            <Modal key={Math.random()} open={showLogin} onClose={()=>setShowLogin(!showLogin)} >
                <AuthPage modal={true} closeModal={()=>setShowLogin(!showLogin)}/>
            </Modal>
        </> :
        <Navigate to={{ pathname: '/login', state: { from: location } }} />;
}