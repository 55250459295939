import React, {useEffect, useState} from "react";
import IbatApi from "../../utils/IbatApi";
import Select from "react-select";
import {useSnackbar} from "notistack";
import {text} from "../util/constants";

export default function SearchInsurance({onSave}) {
    const [insuranceList, setInsuranceList] = useState(false)
    const [insurance, setInsurance] = useState()
    let api = new IbatApi();

    const { enqueueSnackbar} = useSnackbar();

    useEffect(()=>{
        if(!insuranceList){
            api.getAllInsurance()
                .then(r=> setInsuranceList(r.map(s => ({label: s.name, name: s.name, id: s.id,value:s.id}))))
                .catch(e=> enqueueSnackbar(text.SNACKBAR_ERROR,{variant:"error"}))
        }
    })

    let loadOptions=(inptv)=>{
        console.log(inptv)
        return api.findInsurance(inptv).then(resp => {
            console.log(resp)
            console.log(resp)
            let map = resp.map(s => ({label: s.name, name: s.name, id: s.id,value:s.id}))
            setInsuranceList(map)
            return map
        })
    }

    function handleInputChange(d){
        loadOptions(d)
    }

    function handleChange(d){
        if(d!==null){
            onSave({id:d.value,name:d.label})
            setInsurance({value:d.value,label:d.label})
        }
    }
    return (
        <div className={"w100"}>
            <Select
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                menuPortalTarget={document.body}
                classNamePrefix={"search"}
                placeholder={text.INSURANCE_SEARCH}
                isClearable
                onChange={handleChange}
                onInputChange={handleInputChange}
                value={insurance}
                noOptionsMessage={()=>text.NO_ENTRY_SELECTED}
                options={insuranceList}
            />
        </div>
    )
}
