import {IconButton} from "@mui/material";
import React, {useState} from "react";
import Modal from "react-responsive-modal";
import IbatApi from "../../../utils/IbatApi";
import {useSnackbar} from "notistack";


export default function NomenclatureElementType({families, preciserFamilies, productTypes, updateList, elementTypeList}) {
    const [selectedEt, setSelectedEt] = useState()
    const [addNomenclatureElement, setAddNomenclatureElement] = useState(false)
    const {enqueueSnackbar}=useSnackbar()

    const api = new IbatApi()

    /*const nomenclatureType = [
        {value: "install", label: "Pose"},
        {value: "remove", label: "Dépose"},
        {value: "substitution", label: "Remplacement"},
        {value: "repair", label: "Réparation"},
        {value: "paint", label: "Peinture"},
        {value: "clean", label: "Nettoyage"},
        {value: "check", label: "Contrôle"},
        {value: "maintenance", label: "Maintenance"},
    ]
    const nomenclatureTypeMapping = {
        install: "Pose",
        remove: "Dépose",
        substitution: "Remplacement",
        repair: "Réparation",
        paint: "Peinture",
        clean: "Nettoyage",
        check: "Contrôle",
        maintenance: "Maintenance",
    }*/

    function mapReference(ref) {
        let splittedRef = ref.match(/.{1,4}/g)
        let a = families.find(f => f.reference === splittedRef[0])?.name
        let b = preciserFamilies.find(f => f.reference === splittedRef[1])?.name
        let c = productTypes.find(f => f.reference === splittedRef[2])?.name
        return `${a ?? "?"} > ${b ?? "?"} > ${c ?? "?"}`
    }

    function removeNomenclatureElement(value, type){
        let currentNomenclature = selectedEt?.nomenclature
        currentNomenclature[type] = currentNomenclature[type].filter( n => n !== value)
        setSelectedEt({...selectedEt, nomenclature: currentNomenclature})
    }

    /*const addNomenclatureToEt = () => {
        if (!addType){
            return
        }
        selectInputRef.current.select.setValue()
        let nomenclature = {...selectedEt.nomenclature, [addType.value]: []}
        setSelectedEt({...selectedEt, nomenclature: nomenclature})
        setAddType(false)
    }*/
    const addElementToNomenclature = () => {
        let currentNomenclature = selectedEt.nomenclature ?? {}
        let ref = `${addNomenclatureElement.family.ref}${addNomenclatureElement.preciserFamily.ref}${addNomenclatureElement.productType.ref}`
        if (!currentNomenclature[addNomenclatureElement.type]) {
            currentNomenclature[addNomenclatureElement.type] = []
        }
        if (currentNomenclature[addNomenclatureElement.type]?.length > 0 && currentNomenclature[addNomenclatureElement.type].includes(ref)){
            enqueueSnackbar('Cet élément est déjà présent', {variant: "error"})
            return
        }
        currentNomenclature[addNomenclatureElement.type].push(ref)
        setSelectedEt({...selectedEt, nomenclature: currentNomenclature})
        setAddNomenclatureElement(false)
    }

    const saveNomenclature = () => {
        api.saveEt(selectedEt)
            .then(() => enqueueSnackbar('Enregistré', {variant: "success"}))
            .catch(e => {
                console.log(e)
                enqueueSnackbar('Une erreur est survenue', {variant: "error"})
            })
    }

    function editNomenclature() {
        return (
                <div className={"catalog-content flex-column"}>
                    <h2>{selectedEt.name}</h2>
                    <div className={"flex-row"}>
                        <div className={"secondary-section flex-column"}>
                            <h3>Element obligatoire</h3>
                            <ul>
                                {
                                    selectedEt?.nomenclature?.mandatory?.map(k=>
                                        <li>
                                            <div>
                                                <IconButton className={"action-btn cancel"} onClick={()=>removeNomenclatureElement(k, "mandatory")}>
                                                    X
                                                </IconButton> {mapReference(k)}
                                            </div>
                                        </li>
                                    )
                                }
                                <li>

                                    <IconButton className={"action-btn validation"} onClick={()=> setAddNomenclatureElement({type: "mandatory"})}>
                                        +
                                    </IconButton>
                                </li>
                            </ul>
                        </div>
                        <div className={"secondary-section flex-column"}>
                            <h3>Element optionnels</h3>
                            <ul>
                                {
                                    selectedEt?.nomenclature?.optional?.map(k=>
                                        <li>
                                            <div>
                                                <IconButton className={"action-btn cancel"} onClick={()=>removeNomenclatureElement(k, "optional")}>
                                                    X
                                                </IconButton> {mapReference(k)}
                                            </div>
                                        </li>
                                    )
                                }
                                <li>

                                    <IconButton className={"action-btn validation"} onClick={()=> setAddNomenclatureElement({type: "optional"})}>
                                        +
                                    </IconButton>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className={"flex-row"}>
                        <IconButton className={"action-btn cancel"} onClick={()=>setSelectedEt()}>Annuler</IconButton>
                        <IconButton className={"action-btn validation"} onClick={saveNomenclature}>Enregistrer</IconButton>
                    </div>
                </div>
            )
    }
    return (
        <div className={"family-tab br05 flex-row"}>
            <ul>
                {elementTypeList?.sort((a, b) => a.name > b.name ? 1 : -1)?.map(et =>
                    <li>
                        <div className={"as-link"} onClick={()=>{
                            setSelectedEt(et)
                            // setNewFamilyObject(et)
                        }}>{et.name}</div>
                    </li>
                )}
            </ul>
            {
                selectedEt && editNomenclature()
            }

            <Modal open={addNomenclatureElement} onClose={()=> setAddNomenclatureElement(false)}>
                <div className={"flex-column"}>
                    <h1>{`${addNomenclatureElement?.family?.name ?? "Famille"} > ${addNomenclatureElement?.preciserFamily?.name ?? "Sous-famille"} > ${addNomenclatureElement?.productType?.name ?? "Type de produit"}`}</h1>
                    <div className={"flex-row primary-section"}>
                        <ul style={{maxHeight: '75vh', overflow: "auto"}}>
                            {
                                !addNomenclatureElement?.family?.name &&
                                families.map(f =>
                                    <li  onClick={()=> setAddNomenclatureElement({...addNomenclatureElement, family: {id: f.id, ref: f.reference, name: f.name}, preciserFamily: null, productType: null})}>
                                        <div className={"as-link"} style={{textDecoration: addNomenclatureElement.family?.id===f.id ? "underline 0.15em":""}}>{f.name}</div>
                                    </li>
                                )
                            }
                        </ul>

                        {
                            !addNomenclatureElement?.preciserFamily?.name && addNomenclatureElement.family &&
                            <ul style={{maxHeight: '75vh', overflow: "auto"}}>
                                {
                                    preciserFamilies?.filter(pf => pf.family === addNomenclatureElement.family.id)?.map(pf =>
                                        <li onClick={()=> setAddNomenclatureElement({...addNomenclatureElement, preciserFamily: {id: pf.id, ref: pf.reference, name: pf.name}, productType: null})}>
                                            <div className={"as-link"} style={{textDecoration: addNomenclatureElement.preciserFamily?.id===pf.id ? "underline 0.15em":""}}>{pf.name}</div>
                                        </li>
                                    )
                                }
                            </ul>
                        }

                        {
                            addNomenclatureElement.preciserFamily &&
                            <ul style={{maxHeight: '75vh', overflow: "auto"}}>
                                {
                                    productTypes?.filter(pt => pt.preciserFamily === addNomenclatureElement.preciserFamily.id)?.map(pt =>
                                        <li onClick={()=> setAddNomenclatureElement({...addNomenclatureElement, productType: {id: pt.id, ref: pt.reference, name: pt.name}})}>
                                            <div className={"as-link"} style={{textDecoration: addNomenclatureElement.productType?.id===pt.id ? "underline 0.15em":""}}>{pt.name}</div>
                                        </li>
                                    )
                                }
                            </ul>
                        }
                    </div>
                    <div className={"flex-row"}>
                        <IconButton className={"action-btn cancel"} onClick={()=>setAddNomenclatureElement({})}>Annuler</IconButton>
                        {
                            (addNomenclatureElement?.family && addNomenclatureElement?.preciserFamily && addNomenclatureElement?.productType) &&
                            <IconButton className={"action-btn validation"} onClick={addElementToNomenclature}>Ajouter</IconButton>
                        }
                    </div>
                </div>
            </Modal>
        </div>
    )
}