import IbatApi from "../../utils/IbatApi";
import {Fragment, useEffect, useState} from "react";
import ReactLoading from "react-loading";
import {enqueueSnackbar, useSnackbar} from "notistack";
// import {ReactComponent} from "*.svg";

export default function Download(){

  const api = new IbatApi()
  const [file, setFile] = useState()
  const [downloaded, setDownloaded] = useState(false)
  const {enqueueSnackbar}=useSnackbar()
  const apk = api.getTokenData()['appVersion']

  useEffect(()=>{
    api.getAPK(apk)
        .then(resp => setFile(resp.url))
        .catch(e => enqueueSnackbar('Erreur', {variant: "error"}))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={"flex-column"}>
      {(!file && !downloaded) &&
          <>
            <h1>
              Nous préparons votre téléchargement,<br />
              Merci de patienter.
            </h1>
            <ReactLoading type={"bubbles"} color="#6a1418" />
          </>
      }
      {(file && !downloaded) && <a download={apk} href={file} onClick={()=>setDownloaded(true)}><h1>Lancer le téléchargement d'eBat !</h1></a> }
      {(file && downloaded) && <h1>Merci d'avoir télécharger eBat !</h1>}
    </div>
  )
}
