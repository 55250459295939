import React, {useEffect, useState,} from "react";
import {useParams} from "react-router-dom"
import IbatApi from "../../utils/IbatApi";
import Logo from "../../images/ebat-logo.png"
import CaseIcon from "../../images/case2.png";
import DocumentIcon from "../../images/file.png";
import CustomImage from "../util/CustomImage";
import Modal from "react-responsive-modal";
import {DocumentLoaderList} from "../util/DocumentLoader";
import constants, {text} from "../util/constants";
import {EventStreamContentType, fetchEventSource} from "@microsoft/fetch-event-source";
import {Buffer} from 'buffer';
import Table from "../../table/Table";
import FamilyPicture from "../../operation/FamilyPicture";
import Caroussel from "../util/Caroussel";
import Layer from "../../layer/Layer";


export default function TrackFile() {

    const {token} = useParams()
    const api = new IbatApi(token ?? null);
    const [state, setState] = useState({})
    const [caroussel, setCaroussel] = useState(false)
    const [imgIsActive, setImgIsActive] = useState(false)
    const [pictureList, setPictureList] = useState()
    const [object, setObject] = useState("")
    const [operations, setOperations] = useState([])
    const [poi, setPoi] = useState([])

    const [provisionalWork, setProvisionalWork] = useState([])
    const [jobRepair, setJobRepair] = useState([])
    const [jobProblem, setJobProblem] = useState([])


    useEffect(() => {
        if (!token) {
            return
        }
        getIntervention()
        /*.then(r => {
            api.getStatus().then(s=>{
                setStatus(s)
            })
            api.getPoiTypes("jobRepair")
                .then(r => setJobRepair(r))
                .catch(e => enqueueSnackbar(text.SNACKBAR_ERROR,{variant:"error"}))
            api.getPoiTypes("jobProblem")
                .then(r => setJobProblem(r))
                .catch(e => enqueueSnackbar(text.SNACKBAR_ERROR,{variant:"error"}))
            api.getPoiTypes("provisionalWork")
                .then(r => setProvisionalWork(r))
                .catch(e => enqueueSnackbar(text.SNACKBAR_ERROR,{variant:"error"}))
        })*/
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        // const start = new Date()
        //
        //4Min 36Sec ------ 4Min 17Sec
        //
        /*const operationsPictures = operations.flatMap(op => op.pictures)
        const duplicateOperations = [...operations]
        api.getFileList(operationsPictures.map(pic => pic.filePath)).then(r => {
            const end = new Date()
            const ms = end.getTime() - start.getTime()
            var min = Math.floor(ms / 60000);
            var sec = ((ms % 60000) / 1000).toFixed(0);
            console.log(min + ':' + sec)
            for (const idx in operations) {
                const operation = duplicateOperations[idx];
                for (const [key, value] of Object.entries(r)) {
                    const index = operation.pictures.findIndex((pic) => pic.filePath === key);
                    if (index !== -1) {
                        let updatedPictureList = operation.pictures
                        updatedPictureList = updatedPictureList.map((pic, i) => {
                            if (i === index) {
                                return { ...pic, url: "data:image/png;base64,"+value };
                            }
                            return pic;
                        });
                        duplicateOperations[idx].pictures = updatedPictureList
                    }
                }
            }
            setOperations(duplicateOperations)
        })*/

        //
        //5Min 13Sec ------ 5Min 8Sec
        fetchDataAndUpdatePictureList()
        /*for (let i = 0; i < operationsPictures.length; i += chunkSize) {
            // fetchDataAndUpdatePictureList(operationsPictures.slice(i, i + chunkSize))
        }*/
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [operations?.length])

    async function fetchDataAndUpdatePictureList() {
        const operationsPictures = operations?.flatMap(op => op.pictures)
        // const operationsPictures = list

        if (!operations || operationsPictures?.length === 0) {
            return
        }
        const url = "https://backend.ebat.tegeo.fr/core/utils/streamImages"// + queryString;

        const start = new Date()
        let headers = {}
        if (token) {
            headers["Access-Token"] = token
        } else {
            headers["Authorization"] = api.getAuthorizationHeader()
        }
        fetchEventSource(url, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(operations?.flatMap(op => op.pictures).map(pic => pic?.filePath)),
            onmessage(ev) {
                // Convert the Base64 string back to binary data (byte array)
                const binaryImageData = Buffer.from(ev.data, 'base64')

                // Convert the binary data to a Blob
                const blobImage = new Blob([new Uint8Array(binaryImageData)], {type: 'image/png'});
                const url = URL.createObjectURL(blobImage);

                // Find the index of the existing image in the pictureList array based on the filename
                for (const idx in operations) {
                    const operation = operations[idx];
                    const index = operation.pictures.findIndex((pic) => pic.filePath === ev.event);
                    if (index !== -1) {
                        let updatedPictureList = operation.pictures
                        updatedPictureList = updatedPictureList.map((pic, i) => {
                            if (i === index) {
                                return {...pic, url: url};
                            }
                            return pic;
                        });
                        operation.pictures = updatedPictureList
                        const updatedOperationsList = operations.filter(o => o.id !== operation.id)
                        updatedOperationsList.push(operation)
                        setOperations(updatedOperationsList)
                    }
                    // setPictureList(updatedPictureList)
                }
                if (ev.event === 'FatalError') {
                    throw new Error(ev.data);
                }
            },
            async onopen(response) {
                if (response.ok && response.headers.get('content-type') === EventStreamContentType) {
                } else {
                    throw new Error();
                }
            },
            onerror(err) {
                if (err instanceof Error) {
                    throw err; // rethrow to stop the operation
                } else {
                    // do nothing to automatically retry. You can also
                    // return a specific retry interval here.
                }
            },
            onclose(e) {
                const end = new Date()
                const ms = end.getTime() - start.getTime()
                var min = Math.floor(ms / 60000);
                var sec = ((ms % 60000) / 1000).toFixed(0);
            }
        })
    }

    function getIntervention() {
        return api.getIntervention().then(i => {
            setState({...state, ...i})
            setObject(i.object)
            setOperations(i.operations)
            setPoi(i.operations?.filter(op=>op?.metadata)?.map(op=>op?.metadata?.points)?.flat(2))
        })
    }


    function handleZoom() {
        setImgIsActive(v => !v)
    }

    function handlePicture(listPictures) {
        if (!listPictures) {
            return []
        }
        let mothers = listPictures.filter(pic => !pic.motherId)
        let families = []
        for (let mother of mothers) {
            let fam = [...listPictures.filter(childPic => childPic.motherId === mother.id)]
            fam.push(mother)
            families.push(fam)
        }
        return families.filter(fam => fam.length > 0)
    }


    return (
        <div className={"page flex scrollable"}>
            <div className={"grid trackFile-header header-section br20"}>
                <div className={"flex-row"}>
                    <img src={Logo} alt={"logo"}/>
                </div>
                <div className={"flex-row"}>
                    <img src={CaseIcon} alt={"case"}/>
                    <h2>Suivi de dossier</h2>
                </div> </div>
            <div className={"track-file primary-section"}>
                {state.status &&
                    <div className={"flex-column"}>
                        <h2>{state?.devis?.insurance?.name ?? ""}</h2>
                        <div className={"intervState br10"}> <p>État de l'intervention : <b>{text.STATUS_TRADUCTION[state?.status ?? ""]}</b></p>
                        </div> </div>
                }
                <div className={`grid intervention flex-column`}>

                    <div className={"infos widfit"}>
                        <div className={"secondary-section"}>
                            <div className={"flex-column"}>
                                <span>{state?.devis?.site?.name}</span>
                                <span>{`${state?.devis?.site?.address}, ${state?.devis?.site?.cp}, ${state?.devis?.site?.ville}`}</span>
                                <span>{state?.devis?.site?.contactPhone.length > 0 ? `${state?.devis?.site?.contactPhone.join(", ")}` : "Aucun numéro communiqué"}</span>
                                <span>{`${state?.devis?.site?.contactMail ?? "Aucune adresse mail communiquée"}`}</span>
                            </div>
                        </div>
                        {
                            state?.generatedReport?.length > 0 &&
                                <div className={"documents flex-column"}>
                                    <div className={"secondary-section"}>
                                        <DocumentLoaderList name={state.generatedReport} noHeader={true}/>
                                    </div>
                                </div>
                        }
                    </div>

                    {
                        state?.devis?.site?.imageUrl &&
                        <div className={"plan flex-column"}>
                            {/*                        <CustomImage ibatApi={api} name={state?.devis?.site?.imageUrl ?? null} onClick={() => null}/>*/}
                            <Layer points={poi}
                                   background={state?.devis?.site?.imageUrl}
                                   provisionalWork={provisionalWork}
                                   jobRepair={jobRepair}
                                   jobProblem={jobProblem}
                                   readOnly={true}
                                   ibatApi={api}
                            />
                        </div>
                    }
                    <div className={"rapport"}>
                        <div className={"primary-section"}>
                            <div className={"operation-table"}>
                                {
                                    operations
                                        ?.sort((o1, o2) => o1.defaultPosition > o2.defaultPosition ? 1 : -1)
                                        ?.map((o) => {
                                            let reducer = (acc, object) => {
                                                let findIndex = acc.findIndex(obj => obj.dataId === object.dataId);
                                                    if (findIndex === -1) {
                                                        acc.push(object)
                                                    }
                                                    return acc
                                                }
                                                let works = o?.metadata?.points?.filter(poi => poi.name === constants.WORK).reduce(reducer, [])
                                                let pictureList = handlePicture(o?.pictures?.map(p => {
                                                    return {...p, url: p.url ?? "url"}
                                                })).sort((pF1, pF2) => pF1[0].date - pF2[0].date)
                                                return <div className={"secondary-section"}>
                                                    {
                                                        o.metadata && (o.metadata.suggestion || works) &&
                                                        <div>
                                                            <div className={"section"}>
                                                                <ul className={"flex suggestion"}>
                                                                    {
                                                                        o.states &&
                                                                        o.states.split("\\n")
                                                                            .map(s => s.split("\n"))
                                                                            .flat(2)
                                                                            .map((s, i) => s ? <li key={i}>{s}</li> : s)
                                                                    }
                                                                    {
                                                                        o.metadata &&
                                                                        o.metadata.suggestion.split("\\n")
                                                                            .map(s => s.split("\n"))
                                                                            .flat(2)
                                                                            .map((s, i) => s ? <li key={i}>{s}</li> : s)
                                                                    }
                                                                </ul>

                                                                <ul className={"preconisation"}>
                                                                    {works?.map((s, i) => s ?
                                                                        <li key={i}>{s.dataName}</li> : s)}
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    }
                                                    <div className={"flex-row auto-line"}>
                                                        {
                                                            pictureList
                                                                .map((pF, idx) =>
                                                                    <FamilyPicture index={idx + 1} pictures={pF}
                                                                                   onChangePictureVisible={() => null}
                                                                                   onCaroussel={e => {
                                                                                       setCaroussel(e)
                                                                                       setPictureList(pictureList)
                                                                                   }}
                                                                    />
                                                                )
                                                        }
                                                        {/*<Modal
                                                    classNames={{modal: `${imgIsActive ? 'overflow-visible' : ""}`}}
                                                    open={caroussel}
                                                    onClose={() => setCaroussel(false)}>
                                                    <Caroussel listOperation={otherOperations}
                                                               handleZoom={handleZoom}
                                                               ibatApi={ibatApi}
                                                               list={handlePicture(pictureList).sort((pF1, pF2) => pF1[0].date - pF2[0].date).map((pF, idx) => ({
                                                                   index: idx,
                                                                   family: pF
                                                               }))}
                                                        // findComplementData={getPicture}
                                                               firstFile={caroussel}
                                                               onExport={refreshOperation}/>
                                                </Modal>*/}
                                                    </div>
                                                </div>
                                            }
                                        )
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <Modal classNames={{modal: `${imgIsActive ? 'overflow-visible' : ""}`}} open={caroussel && pictureList}
                       onClose={() => setCaroussel(false)}>
                    <Caroussel handleZoom={handleZoom}
                               ibatApi={api}
                               readOnly={true}
                               list={pictureList?.map((pF, idx) => ({
                                   index: idx,
                                   family: pF
                               }))}
                        // findComplementData={getPicture}
                               firstFile={caroussel}/>
                </Modal>
            </div>
        </div>
    )

}
