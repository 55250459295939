import React, {Fragment, useEffect, useRef, useState} from "react";
import IbatApi from "../utils/IbatApi";
import CustomImage from "../components/util/CustomImage";
import Modal from "react-responsive-modal";
import {text} from "../components/util/constants";
// import "../style/layer.css"
import {getIcon} from "../helpers/assetManager";
import {IconButton} from "@mui/material";
import {v4} from "uuid";
import {enqueueSnackbar} from "notistack";
import ExtensibleComponent from "../components/util/ExtensibleComponent";
import {isMobile} from "react-device-detect";
import {useMediaQuery} from "react-responsive";
import Caroussel from "../components/util/Caroussel";

export default function Layer({points, background, provisionalWork, jobRepair, jobProblem, quotationMode, close, onSave, addMode, lineGroupId, ibatApi, readOnly}){
    const [state,setState]=useState({})
    const [selectedPoint,setSelectedPoint]=useState(false)
    const [pointType,setPointType]=useState(jobRepair ?? provisionalWork ?? jobProblem ?? [])
    const [filterPicture,setFilterPicture]=useState(false)
    const [pointTypeName,setPointTypeName]=useState("")
    const [current,setCurrent]=useState(0)
    const [deletedPoint,setDeletedPoint]=useState([])
    const [newPoint,setNewPoint]=useState(false)
    const [img, setImg]=useState()
    const [updateCanvas, setUpdateCanvas]=useState(false)
    const canvasRef =useRef(null)
    const filter = useRef(null)
    const api= ibatApi ?? new IbatApi()
    const isMobile = useMediaQuery({ query: '(max-width: 915px)' })

    const scale = 1

    useEffect(() => {
        api.getFile(background)
            .then(img=>{
                setImg(img.b64)
                handleBackground(img.b64)
            })
            .catch(e => enqueueSnackbar(e.message, {variant: "error"}))

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(()=>{
        handleBackground()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[img, deletedPoint, newPoint.x, pointTypeName])

    useEffect(()=>{
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[updateCanvas])

    function handleBackground(image){
        let ctx = canvasRef.current.getContext('2d');
        let bkGround = new Image();
        bkGround.src = image ?? img;
        bkGround.onload = (e)=>{
            setState({width:bkGround.width,height:bkGround.height})
            ctx.drawImage(bkGround,0,0, bkGround.width, bkGround.height);
            points
                ?.filter(p => readOnly || p?.dataId?.includes(pointTypeName) || p?.name.includes(pointTypeName))
                ?.forEach(p=>draw(ctx, p,bkGround))
            if (newPoint) {
                draw(ctx, newPoint, bkGround)
            }
            setUpdateCanvas(v => !v)
        }
    }
    function draw(ctx, p,bkg) {
        const icon=new Image()
        icon.src = dataIdToImg(deletedPoint.includes(p.id) ? null : p.dataId)
        icon.onload=()=> {
            ctx.fillStyle = p.name === "Repair" ? '#00f' : "#ff0000";
            ctx.beginPath()
            ctx.arc(Math.abs(1 - p.y) * bkg.width*scale, Math.abs(p.x) * bkg.height*scale, 5, 0, 2 * Math.PI)
            ctx.fill()
            ctx.drawImage(icon, Math.abs(1 - p.y) * bkg.width*scale - icon.width / 2, Math.abs(p.x) * bkg.height*scale - icon.height / 2)
        }
    }

    function dataIdToImg(id){
        return getIcon(id)
    }


    function handlePicture(selectedPoint) {
        setSelectedPoint(selectedPoint)
    }

    function getPoints(x,y,area,width,height){
        return points.map(p=>({...p,x:Math.abs(1-p.y)*height,y:(p.x)*width,picture:p.picture}))
            .filter(p => readOnly || p.dataId === pointTypeName || p.name === pointTypeName || pointTypeName === "")
            .filter(p=>Math.abs(p.x-x)<= area && Math.abs(p.y-y)<=area)
            .forEach(p=>handlePicture(p))
    }

    function handlePoint(e) {
        const rect=canvasRef.current.getBoundingClientRect()
        const height=rect.right-rect.left
        const width=rect.bottom-rect.top
        const x = e.clientX - rect.left
        const y = e.clientY - rect.top
        if (addMode && newPoint) {
            setNewPoint({x: y / width, y: 1-x / height, name: "Work", dataId: "miscellaneous", dataName: "miscellaneous", lineGroupId: lineGroupId, id: v4()})
            return
        }
        getPoints(x,y,5,width,height)
    }
    function showSelectedProduct(p) {
        return (
            <div key={v4()} className={"secondary-section flex-column selected-product w100"} style={p.deleted === true ? {textDecoration: "line-through"} : {}}>
                <h2>{text[p.actionType] ? `(${text[p.actionType]})` : ""} {p?.product?.name} {p.variantValue ? `(${p.variantValue})` : ""} : </h2>
                {p.qty && <div className={"w100"}>Qte : {p.qty}</div>}
                {p.mandatoriesFilter?.map(f =>
                <div className={"w100"} key={v4()}>
                    {f.userValue ? <div className={"as-link"} onClick={()=>setFilterPicture(f)}>{f.name} : {f.userValue}</div> : `${f.name} : Non renseigné`}
                </div>
                )}
            </div>
        )
    }
    function handleFamilyPicture(listPictures) {
        let mothers = listPictures?.filter(pic => !pic.motherId)
        let families = []
        for (let mother of mothers) {
            let fam = [...listPictures.filter(childPic => childPic.motherId === mother.id)]
            fam.push(mother)
            families.push(fam)
        }
        return families.filter(fam => fam.length > 0)
    }


    function next(){
        setCurrent(Math.min((current+1),filterPicture.userPicture.length-1))
    }
    function previous(){
        setCurrent(Math.max(current-1,0))
    }

    return (
        <div className={`layer${readOnly ? " read-only" : ""}`}>
            <div className={`flex-${isMobile ? "column": "row"} w100`}>
                <div>
                    { !readOnly &&
                        <div>
                            <ExtensibleComponent className={"flex-column"} content={"Filtrer"} ref={filter}>
                                <div className={"flex-row"}>
                                    <ul className={"flex-column"}>
                                        {
                                            jobRepair &&
                                            <li key={v4()}>
                                                <div className={"as-link"} style={{textDecoration: pointTypeName === "Repair" ? "underline" : "none"}} onMouseEnter={() => {
                                                    setPointType(jobRepair)
                                                    setPointTypeName("Repair")
                                                }}>Réparation effectuée
                                                </div>
                                            </li>
                                        }
                                        {
                                            provisionalWork &&
                                            <li key={v4()}>
                                                <div className={"as-link"}
                                                     style={{textDecoration: pointTypeName === "Work" ? "underline" : "none"}}
                                                     onMouseEnter={() => {
                                                         setPointType(provisionalWork)
                                                         setPointTypeName("Work")
                                                }}>Travaux à prévoir</div>
                                            </li>
                                        }
                                        {
                                            jobProblem &&
                                            <li key={v4()}>
                                                <div className={"as-link"} style={{textDecoration: pointTypeName === "Problem" ? "underline" : "none"}} onMouseEnter={() => {
                                                    setPointType(jobProblem)
                                                    setPointTypeName("Problem")
                                                }}>Pointage autres</div>
                                            </li>
                                        }
                                    </ul>
                                    <ul className={"flex-column"}>
                                        { pointType.length > 0 &&
                                            pointType.map(p =>
                                                <li key={v4()}>
                                                    <div className={"as-link"}
                                                         style={{textDecoration: pointTypeName === p.id ? "underline" : "none"}}
                                                         onClick={() => setPointTypeName(p.id)}>{p.name}</div>
                                                </li>
                                            )
                                        }
                                    </ul>
                                </div>
                                {
                                    isMobile &&
                                    <IconButton className={"action-btn validation"} onClick={()=>filter.current.onEndTimeOut()}>Filtrer</IconButton>
                                }
                            </ExtensibleComponent>
                        </div>
                    }
                    <div className={"flex-column"}>
                        <canvas id={"layer"} width={state.width} height={state.height} onClick={handlePoint}
                                ref={canvasRef}/>
                        {
                            selectedPoint && selectedPoint.reportComment &&
                            <div className={"secondary-section flex-column"}>
                                <div className={"flex-row"}>
                                    <h2>Commentaire : </h2>
                                    <p>
                                        {selectedPoint.reportComment}
                                    </p>
                                </div>
                                {
                                    selectedPoint && selectedPoint.reportRecommendation &&
                                    <div className={"flex-row"}>
                                        <h2>Recommandation : </h2>
                                        <p>
                                            {selectedPoint.reportRecommendation}
                                        </p>
                                    </div>
                                }
                            </div>
                        }
                    </div>

                    {
                        quotationMode &&
                        <div className={`flex-${isMobile ? "column" : "row"} auto-line`}>
                        <IconButton className={`action-btn cancel`} onClick={close}>Annuler</IconButton>
                            <IconButton className={`action-btn validation`} onClick={()=>onSave(deletedPoint)}>Générer le devis</IconButton>
                        </div>
                    }
                </div>
                {
                    selectedPoint && !readOnly &&
                    <div className={"handle-point flex-row"}>
                        <div className={"flex-column"}>
                            <h1>{selectedPoint.dataName}</h1>
                            { selectedPoint.picture?.filePath &&
                                <CustomImage ibatApi={ibatApi} width={500} className={"layer-img"} name={selectedPoint.picture?.filePath}/>
                            }
                            {/*{console.log(handleFamilyPicture(selectedPoint?.pictureList))}*/}
                            {/*{console.log(selectedPoint.pictureList[0].filePath)}*/}
                            { selectedPoint.pictureList?.length > 0 &&
                                <Caroussel
                                    ibatApi={ibatApi}
                                    list={handleFamilyPicture(selectedPoint?.pictureList).sort((pF1, pF2) => pF1[0].date - pF2[0].date).map((pF, idx) => ({
                                    index: idx,
                                    family: pF
                                }))}/>
                            }

                        </div>

                        {
                            ((selectedPoint.selectedProducts && selectedPoint.selectedProducts.length > 0) || selectedPoint.comment) &&
                            <div className={"flex-column w100"}>
                                {
                                    quotationMode &&
                                    <IconButton
                                        className={`action-btn ${deletedPoint.includes(selectedPoint.id) ? "validation" : "cancel"}`}
                                        onClick={() => {
                                            if (deletedPoint.includes(selectedPoint.id)) {
                                                setDeletedPoint(deletedPoint.filter(p => p !== selectedPoint.id))
                                            }
                                            else {
                                                setDeletedPoint([...deletedPoint, selectedPoint.id])
                                            }
                                        }}>
                                        {deletedPoint.includes(selectedPoint.id) ? "Ajouter" : "Retirer"} ce point du devis
                                    </IconButton>
                                }

                                <div className={"primary-section"}>
                                    <h1>Fournitures sélectionnées</h1>
                                    {selectedPoint?.selectedProducts?.map(p => showSelectedProduct(p))}

                                    <div className={"secondary-section"}>
                                        <h2>Commentaire : </h2>
                                        <p>
                                            {selectedPoint.comment}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                }
                {
                    selectedPoint && readOnly &&
                    <Modal onClose={() => setSelectedPoint(null)} open={selectedPoint}>
                        <CustomImage
                            ibatApi={ibatApi}
                            width={500}
                            className={"layer-img"}
                            name={selectedPoint.picture.filePath}/>
                    </Modal>
                }
            </div>

            {
                !readOnly && addMode &&
                <div className={"flex-row"}>
                    <IconButton
                        className={`action-btn ${!newPoint ? "save" : "cancel"}`}
                        onClick={() => {
                            if (newPoint) {
                                setNewPoint(false)
                            }
                            else {
                                setNewPoint({})
                            }
                        }}>
                        {!newPoint ? "Localiser cette section" : "Annuler"}
                    </IconButton>

                    {
                        newPoint &&
                        <IconButton
                            className={`action-btn validation`}
                            onClick={() => {
                                onSave && onSave(newPoint)
                                setNewPoint(false)
                            }}>
                            Valider
                        </IconButton>
                    }
                </div>
            }

            {
                filterPicture?.userPicture?.length > 0 &&
                <Modal open={filterPicture} onClose={()=> {
                    setFilterPicture(false)
                    setCurrent(0)
                }}>
                    <div>
                        <h1>{filterPicture.name} : {filterPicture.userValue}</h1>
                        <div className={"caroussel-container primary-section"}>
                            <div className={"caroussel flex-row rounded"}>
                                <div className={"indexNumber rounded"}>{current+1}/{filterPicture.userPicture.length}</div>
                                <IconButton onClick={previous}>‹</IconButton>

                                <div className={"keep-space"}>
                                    <CustomImage className={"rounded"} imgClassName={"w100"} name={filterPicture.userPicture[current]}/>
                                </div>
                                <IconButton onClick={next}>›</IconButton>

                            </div>
                        </div>
                    </div>
                </Modal>
            }

        </div>
    )

}