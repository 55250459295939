import React, {useEffect, useState} from "react"
import ContactForm from "./ContactForm";
import IbatApi from "../../utils/IbatApi";
import MapPointer from "../util/MapPointer";
import {IconButton} from "@mui/material";
import {useSnackbar} from "notistack";
import {text} from "../util/constants";

export default function CreateSite({onCancel,onSave,name, dataSite}) {
    const { enqueueSnackbar} = useSnackbar();
    const [state, setState] = useState({
        cp: "",
        longitude: "",
        latitude: "",
        adresse: "",
        ville: "",
        imageUrl: "",
        name: dataSite?.name??"",
        siret:"",

        contactName: dataSite?.name??"",
        contactPhone: dataSite?.contactPhone[0],
        contactPhone2: dataSite?.contactPhone[1]
    })
    useEffect(() => {
        setState({...state, name: name ? name : ""})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    let api = new IbatApi();




    function handleImage(data){
        const site=data.site
        setState({...state,
            latitude: data.latitude,
            longitude: data.longitude,
            cp: site?.data?.cp,
            ville: site?.data?.city,
            adresse: site?.data?.adresse,
            imageUrl:data.imageUrl })

    }

    function handleSite(site){
        setState({
            ...state,
            latitude: site.data.latitude[1],
            longitude: site.data.longitude[0],
            cp: site.data.cp,
            ville: site.data.city,
            adresse: site.data.adresse,
        })
    }

    const setLongLat = (e) => {
        const pos = {latitude: e.latitude, longitude: e.longitude, id: "+"}
        setState(state => ({...state, longitude: pos.longitude, latitude: pos.latitude,radius:e.radius}))

    }

    function handleSave() {
        let formatedState = {
            name: state.name?state.name:state.adresse,
            ville: state.ville,
            cp: state.cp,
            adresse: state.adresse,
            imageUrl: state.imageUrl,
            longitude: state.longitude,
            latitude: state.latitude,
            radius: state.radius,
            contactName: state.contactName,
            contactPhone: [state.contactPhone,state.contactPhone2].filter(Boolean).join(';').split(";"),
            contactStatus: state.contactStatus,
            siret:state.siret
        }

        console.log(formatedState)

        api.createSite(formatedState)
            .then(id => {
                onSave && onSave({id: id, name: state.name})
            })
            .then(r => enqueueSnackbar(text.SNACKBAR_SAVED,{variant:"success"}))
            .catch(e=>enqueueSnackbar(text.SNACKBAR_ERROR,{variant:"error"}))
    }

    function handleCancel() {
        onCancel && onCancel()
    }

    const handleContact = (contact) => {
        setState({...state, contactName: contact.name, contactPhone: contact.phone, contactPhone2: contact.phone2,contactStatus: contact.status})
    };

    function isSavable() {
        return state?.longitude !== "" &&
            state.adresse !== "" &&
            state?.latitude !== "" &&
            state.cp !== "" &&
            state.adresse !== "" &&
            state.imageUrl !== "" &&
            state.contact !== null;
    }

    return (
        <div className={"flex-column"}>
            <h2>{text.SITE_CREATION}</h2>
        <div className={"siteForm flex"}>

            <div className={"container"}>
                {<MapPointer creation={true} onImageClick={handleImage} onLongLat={setLongLat} onSiteSelected={handleSite} voie={dataSite ? (dataSite.address??"") + " " + (dataSite.cp??"") + " " + (dataSite.city??"") : name} />}
            </div>
            <div className={"totalContactForm"}>
        <div className={"siteName"}>
                        <input type={'text'}
                               value={state.name}
                               placeholder={text.SITE_NAME}
                               onChange={(e) => {
                                   setState({...state, name: e.target.value})
                               }}
                        />
                    </div>
                    <div className={"siretNumber"}>
                        <input type={'text'}
                               value={state.siret}
                               placeholder={text.SITE_SIRET}
                               onChange={(e) => {
                                   setState({...state, siret: e.target.value})
                               }}
                        /></div>

                    <ContactForm onChange={handleContact} dataSite={dataSite} />
        {isSavable() ?
                        <IconButton onClick={handleSave} className={"action-btn validation"}>{text.CREATE}</IconButton> :
                        <IconButton className={"action-btn cancel"} onClick={handleCancel}>{text.CANCEL}</IconButton>
                    }
                    </div>

        </div>
        </div>
    )

}