import {IconButton} from "@mui/material";
import React, {useState} from "react";
import Select from "react-select";
import IbatApi from "../../../utils/IbatApi";

export default function ProductTypeTab({preciserFamilies, updateList, productTypes, jtList}){

    const mappedPreciserFamilies = preciserFamilies?.map(p => {
        return {value: p.id, label: p.name}
    })

    const [selectedProductType, setSelectProductType] = useState()
    const [newProductType, setNewProductType] = useState({})
    const [jobTypes, setJobTypes] = useState()

    const typeOfValue = [
        {label:"Centimètre", value:"cm", type:"Float", marginErrorExist: true},
        {label:"Mètre", value:"m", type:"Float", marginErrorExist: true},
        {label:"Millimètre", value:"mm", type:"Float", marginErrorExist: true},
        {label:"Litre", value:"L", type:"Float", marginErrorExist: true},
        {label:"Mètre carré", value:"m²", type:"Float", marginErrorExist: true},
        {label:"Mètre cube", value:"m3", type:"Float", marginErrorExist: true},
        {label:"Mètre linéaire", value:"ml", type:"Float", marginErrorExist: true},
        {label:"Nombre", value:"", type:"Integer"},
        {label:"Liste", value:[], type:"Array"},
        {label:"Sans Unité", value:"", type:"String"},
    ]
    const imgRefs = [
        {label:"Longueur", value:"length", type:"Float", marginErrorExist: true},
        {label:"Largeur", value:"width", type:"Float", marginErrorExist: true},
        {label:"Fabricant", value:"maker", type:"Float", marginErrorExist: true},
    ]
    const [newCarac, setNewCarac] = useState({})
    const [newElement, setNewElement] = useState("")

    const api = new IbatApi()

    function handleName(e) {
        setNewProductType({...newProductType, name: e.target.value})
    }
    function handleSelectFamily(d){
        console.log(preciserFamilies.filter(f=> f.id === d.value)[0].jobTypes)
        setNewProductType({...newProductType, preciserFamily: d.value, jobTypes:[]})
        setJobTypes(jtList.filter(jt => preciserFamilies.filter(f=> f.id === d.value)[0].jobTypes.includes(jt.id)))
        // setJobTypes(preciserFamilies.filter(f=> f.id === d.value)[0].jobTypes?.map(jt => { return {id: jt.id, name: jt.name, label: jt.name, value: jt.id}}))
    }

    function editSelectFamily(d){
        setSelectProductType({...selectedProductType, preciserFamily: d.value, jobTypes:[]})
        let targetPreciserFamilies = preciserFamilies.filter(f=> f.id === d.value)[0]
        setJobTypes(jtList?.filter(jt => targetPreciserFamilies.jobTypes?.includes(jt.id)))
        // setJobTypes(targetPreciserFamilies.jobTypes?.map(jt => { return {id: jt.id, name: jt.name, label: jt.name, value: jt.id}}))
    }

    function handleJobTypeNewFamily(d){
        setNewProductType({...newProductType, jobTypes: d.map(jt => jt.value)})
    }
    function handleJobTypeEditFamily(d){
        setSelectProductType({...selectedProductType, jobTypes: d.map(jt => jt.value)})
    }

    function handleNewCaracObject(){
        let newCaracObject = {name: newCarac.name, valueType: newCarac.valueType, value: newCarac.value ?? ""}
        if (newCarac.imgRef) {
            newCaracObject = {...newCaracObject, imgRef: newCarac.imgRef}
        }
        if (newCarac.mandatory) {
            newCaracObject = {...newCaracObject, mandatory: newCarac.mandatory}
        }
        if (newCarac.marginError) {
            newCaracObject = {...newCaracObject, marginError: newCarac.marginError}
        }
        return newCaracObject
    }

    function addCarac(){
        let currentCarac = newProductType?.caracteristics ?? []
        let newCaracObject = handleNewCaracObject()
        currentCarac.push(newCaracObject)
        console.log(currentCarac)
        setNewProductType({...newProductType, caracteristics: currentCarac})
        setNewCarac({})
    }
    function editAddCarac(){
        let currentCarac = selectedProductType?.caracteristics ?? []
        let newCaracObject = handleNewCaracObject()
        currentCarac.push(newCaracObject)
        setSelectProductType({...selectedProductType, caracteristics: currentCarac})
        setNewCarac({})
    }

    function createProductType() {
        api.newProductType({
            ...newProductType,
            id: newProductType.id ?? null,
            reference: newProductType.reference ?? '-1'
        })
            .then(() => {
                updateList()
                setSelectProductType({})
                setNewProductType({})
            })
    }
    function updateProductType() {
        api.newProductType({...selectedProductType, id: selectedProductType.id ?? null, reference: selectedProductType.reference ?? '-1'})
            .then(() => {updateList()})
            .catch(() => {
                console.log("une erreur est survenue")
            })
    }

    function newProductTypeUI(){
        return (
            <div className={"catalog-content flex-column"}>
                <div className={"flex-row"}>
                    <div>
                        <label>Nom du type de produit</label><br />
                        <input type={"text"} placeholder={"Tuiles"} value={newProductType?.name ?? ""} onChange={e=>handleName(e)}/>
                    </div>
                    <div>
                        <label>Membre de la sous-famille</label>< br />
                        <Select
                            placeholder={"Toiture, Électricité, ..."}
                            value = {mappedPreciserFamilies.filter(f=> f.value === newProductType?.preciserFamily)[0]  ?? null}
                            onChange = {d => handleSelectFamily(d)}
                            options={mappedPreciserFamilies}/>
                    </div>
                    {newProductType.preciserFamily &&
                        <div>
                            <label>Corps de métier concerné</label>< br />
                            <Select
                                isMulti
                                placeholder={"Couverture, Plomberie, ..."}
                                value = {jobTypes?.filter(jt => newProductType?.jobTypes.includes(jt.id))}
                                onChange = {d => handleJobTypeNewFamily(d)}
                                options={jobTypes}/>
                        </div>
                    }
                </div>
                Caractéristiques du type de produit
                <ul>
                    {newProductType?.caracteristics && newProductType?.caracteristics?.map((k) =>
                        <li>
                            <IconButton className={"action-btn cancel"} onClick={()=>setNewProductType({...newProductType, caracteristics: newProductType?.caracteristics?.filter(c => c.name !== k.name)})}>
                                X
                            </IconButton> {k.name}
                            {
                                typeof k.value === "string"
                                    ?
                                    k.value ? ` (${k.value})` : ""
                                    :
                                    k.value?.map(e => (
                                        <li>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{e}
                                        </li>
                                    ))
                            }
                        </li>
                    )}
                    <div className={"flex-row"}>
                        <input type={"text"} name={"caracteristic"} placeholder={"Longueur"} value={newCarac?.name ?? ""} onChange={event => setNewCarac({...newCarac, name: event.target.value})}/>
                        <Select
                            className={"large w100"}
                            placeholder={"Centimètre, millimètre..."}
                            onChange = {d => setNewCarac({...newCarac, valueType: d.type, imgRef: d.imgRef, marginErrorExist: d.marginErrorExist, value: d.value})}
                            options={typeOfValue}/>
                    </div>
                    {
                        newCarac.valueType === "Array" &&
                        <div>
                            {newCarac.value?.map(e => <li>
                                <IconButton className={"action-btn cancel"} onClick={()=>{
                                    setNewCarac({...newCarac, value: newCarac.value.filter(v => e !== v)})
                                }}>X</IconButton>
                                {e}
                            </li>)}
                            <div className={"flex-row"}>
                                <input type={"text"} name={"caracteristic"} value={newElement} onChange={event => setNewElement(event.target.value)}/>
                                <IconButton className={"action-btn validation"} onClick={() => {
                                    newCarac.value.push(newElement)
                                    setNewCarac({...newCarac, value: newCarac.value})
                                    setNewElement("")
                                }}>+</IconButton>
                            </div>
                        </div>
                    }
                    <div className={"flex-row"}>
                        <div>
                            <input type={"checkbox"} name={"mandatory"} checked={newCarac?.mandatory ?? false} onChange={event => setNewCarac({...newCarac, mandatory: event.target.checked})}/> Filtre obligatoire
                        </div>
                        {
                            newCarac.marginErrorExist &&
                            <input type={"number"} name={"marginError"} placeholder={"0.7"} value={newCarac?.marginError ?? ""} onChange={event => setNewCarac({...newCarac, marginError: event.target.value})}/>
                        }
                    </div>
                    <Select
                        className={"large w100"}
                        placeholder={"Illustration du filtre"}
                        onChange = {d => setNewCarac({...newCarac, imgRef: d.value})}
                        options={imgRefs}/>
                    <IconButton className={"action-btn validation"} onClick={addCarac}>Ajouter</IconButton>
                </ul>
                <div className={"flex-row"}>
                    <IconButton className={"action-btn cancel"} onClick={()=>setNewProductType({})}>Annuler</IconButton>
                    <IconButton className={"action-btn validation"} onClick={createProductType}>Ajouter</IconButton>
                </div>
            </div>
        )
    }
    function editProductType(){
        return (
            <div className={"catalog-content flex-column w100"}>
                <h1>{selectedProductType?.name}</h1>
                <span>{selectedProductType?.id}</span>
                <div className={"flex-row"}>
                    <div>
                        <label>Membre de la sous-famille</label>< br />
                        <Select
                            placeholder={"Toiture, Électricité, ..."}
                            value = {mappedPreciserFamilies.filter(f=> f.value === selectedProductType?.preciserFamily)[0]  ?? null}
                            onChange = {d => editSelectFamily(d)}
                            options={mappedPreciserFamilies}/>
                    </div>
                    {selectedProductType.preciserFamily &&
                        <div>
                            <label>Corps de métier concerné</label>< br />
                            <Select
                                isMulti
                                placeholder={"Couverture, Plomberie, ..."}
                                value = {jobTypes?.filter(jt => selectedProductType?.jobTypes.includes(jt.id))}
                                onChange = {d => handleJobTypeEditFamily(d)}
                                options={jobTypes}/>
                        </div>
                    }
                </div>
                Caractéristiques du type de produit
                <ul className={"w100"}>
                    {selectedProductType?.caracteristics && selectedProductType?.caracteristics?.map((k) =>
                        <li className={"secondary-section flex-column"}>
                            <IconButton className={"action-btn cancel tr-corner"} onClick={()=>setSelectProductType({...selectedProductType, caracteristics: selectedProductType?.caracteristics?.filter(c => c.name !== k.name)})}>
                                X
                            </IconButton>
                            <h2>{`${k.name}${k.value && typeof k.value === "string" ? " (" + k.value + ")" : ""}`}</h2>
                            {
                                typeof k.value !== "string" &&
                                    <ul>
                                        {
                                            k.value?.map(e => (
                                                <li>
                                                    {e}
                                                </li>
                                            ))
                                        }
                                    </ul>
                            }

                            {k.mandatory && <span>Photo et mesures obligatoires</span>}
                            {k.marginError && <span>Marge d'erreur : {k.marginError}</span>}
                            {k.imgRef && <span>Illustration : {k.imgRef}</span>}
                        </li>
                    )}
                </ul>
                <div className={"secondary-section add-carac"}>

                    <input type={"text"} name={"caracteristic"}
                           placeholder={"Nom de la carateristique"}
                           className={"large w100"}
                           value={newCarac?.name ?? ""} onChange={event => setNewCarac({
                        ...newCarac,
                        name: event.target.value
                    })}/>
                    <Select
                        className={"large w100"}
                        placeholder={"Unité de mesure"}
                        onChange={d => setNewCarac({
                            ...newCarac,
                            valueType: d.type,
                            imgRef: d.imgRef,
                            marginErrorExist: d.marginErrorExist,
                            value: d.value
                        })}
                        options={typeOfValue}/>
                    {
                        newCarac.valueType === "Array" &&
                        <ul className={"secondary-section flex-column"}>
                            <h2>{newCarac?.name ?? ""}</h2>
                            {newCarac.value?.map(e => <li>
                                <IconButton className={"action-btn cancel"} onClick={() => {
                                    setNewCarac({
                                        ...newCarac,
                                        value: newCarac.value.filter(v => e !== v)
                                    })
                                }}>X</IconButton>
                                {e}
                            </li>)}
                            <div className={"flex-row"}>
                                <input type={"text"} name={"caracteristic"} value={newElement}
                                       onChange={event => setNewElement(event.target.value)}/>
                                <IconButton className={"action-btn validation"} onClick={() => {
                                    newCarac.value.push(newElement)
                                    setNewCarac({...newCarac, value: newCarac.value})
                                    setNewElement("")
                                }}>
                                    +
                                </IconButton>
                            </div>
                        </ul>
                    }
                    <div className={"flex-row"}>
                        <input type={"checkbox"} name={"mandatory"}
                               checked={newCarac?.mandatory ?? false}
                               onChange={event => setNewCarac({
                                   ...newCarac,
                                   mandatory: event.target.checked
                               })}/> Filtre obligatoire
                    </div>
                    {
                        newCarac.marginErrorExist &&
                        <input type={"number"} name={"marginError"} placeholder={"Marge d'erreur"}
                               className={"large w100"}
                               value={newCarac?.marginError ?? ""}
                               onChange={event => setNewCarac({
                                   ...newCarac,
                                   marginError: event.target.value
                               })}/>
                    }
                    <Select
                        className={"large w100"}
                        placeholder={"Illustration du filtre"}
                        onChange={d => setNewCarac({...newCarac, imgRef: d.value})}
                        options={imgRefs}/>
                </div>
                <IconButton className={"action-btn validation"} onClick={editAddCarac}>Ajouter</IconButton>
                <div className={"flex-row"}>
                    <IconButton className={"action-btn cancel"}
                                onClick={() => setNewProductType({})}>Annuler</IconButton>
                    <IconButton className={"action-btn validation"}
                                onClick={updateProductType}>Sauvegarder</IconButton>
                </div>
            </div>
        )
    }

    return (
        <div className={"family-tab br05 flex-row w100"}>
            <ul>
                <li>
                    <IconButton className={"action-btn validation"} onClick={() => {
                        // setProduct({})
                        setSelectProductType()
                    }}>Nouveau</IconButton>
                </li>
                {productTypes?.sort((a, b) => a.name > b.name ? 1 : -1)?.map(i =>
                    <li>
                        <div className={"as-link"} onClick={() => {
                            setSelectProductType(i)
                            setJobTypes(jtList.filter(jt => i.jobTypes?.includes(jt.id)))
                            // setProduct(i)
                        }}>{i.name}</div>
                    </li>
                )}
            </ul>
            {
                !selectedProductType && newProductTypeUI()
            }
            {
                selectedProductType && editProductType()
            }
        </div>
    )
}