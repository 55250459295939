import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import IbatApi from "../../utils/IbatApi";
import UpdateSiteImage from "../forms/UpdateSiteImage";
import {text} from "../util/constants";
import InterventionForm from "../forms/InterventionForm";
import {v4} from "uuid";
import Table from "../../table/Table";


export default function Site() {

    const {id} = useParams()

    let api = new IbatApi();
    const [state, setState] = useState({})
    const [interventions, setinterventions] = useState([])
    const [developCriteriaBar, setDevelopCriteriaBar] = useState(false)
    // const [operation, setOperation] = useState([])
    const [loadingInterventionState, setLoadingInterventionState] = useState("Loading")

    const siteColumns =  [
        {id: "id", isChecked: true, label: text.ID, type: "linkText"},
        {id: "type", isChecked: true, label: text.FILE_TYPE, type: "object"},
        {id: "status", isChecked: true, label: text.STATUS, type: "text"},
        {id: "reception", isChecked: true, label: text.RECEPTION_DATE, type: "text"},
        {id: "intervention", isChecked: true, label: text.INTERVENTION_START, type: "object"},
        {id: "secteur", isChecked: true, label: text.ZONE, type: "object"},
        {id: "team", isChecked: true, label: text.TEAM, type: "object"},
        {id: "gestionnaire", isChecked: true, label: text.TH_ADMINISTRATOR, type: "object"},
        {id: "client", isChecked: true, label: text.CLIENT, type: "objectLink"},
    ]

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])
    function getData(){
        api.getSite(id).then(data => setState({...state, ...data}))
        api.getSiteIntervention(id).then(i => {
            setinterventions(i
                .sort((a, b) => {
                if (a.dateIntervention === null && b.dateIntervention !== null) {
                    return -1;
                }
                if (a.dateIntervention !== null && b.dateIntervention === null) {
                    return 1;
                }

                if (a.dateIntervention === null && b.dateIntervention === null) {
                    return a.dateReception < b.dateReception ? 1 : -1
                }
                return a.dateIntervention < b.dateIntervention ? 1 : -1
            })
                .map(i => {
                    return api.normalize(i)
                })
            )
            setLoadingInterventionState("Done")
        }).catch(e => {
            console.log(e)
            setLoadingInterventionState("Error")
        })
        /*api.getSiteOperation(id).then(ops=>setOperation(ops))*/
    }

    /*function getTwinOperation(opList,operation){
        return opList.filter(op=>op.visible).find(op=>op.typeId===operation.operationId)
    }
    function updateIntervention(){
        let interventionCopy=interventions.filter(i=>!isModifiable(i)).map(i=>{
            return api.getInterventionOperation(i.id)
                .then(ops=>operation
                    .sort((o1,o2)=>o1.operationId.localeCompare(o2.operationId))
                    .map(op=>getTwinOperation(ops,op))
                ).then(ops=>({...i,operations:ops}))
        })
        Promise.all(interventionCopy).then(intvs=>setinterventions(intvs))

    }*/
    return (
        <div className={"w100 page scrollable"}>

            <aside className={`flex-column criteria-bar${developCriteriaBar ? " developped" : ""}`}>
                <div className={`margin-content`}>
                    <InterventionForm className={"site-newIntervention"} onSaveSucceed={getData} site={{...state}}/>
                    <div className={`bord1 only-for-small-screen${developCriteriaBar ? " developped" : ""}`} onClick={()=>setDevelopCriteriaBar(!developCriteriaBar)}>
                        >
                    </div>
                </div>
            </aside>
            <div className={"flex-column"}>
                <div className={"site-header"}>
                    <h1 className={"flex-row"}>{text.SITE} {id}</h1>
                    <div className={"flex-column primary-section"}>
                        <UpdateSiteImage voie={encodeURI(state.address+", "+state.cp+", "+state.ville)} img={state.imageUrl?state.imageUrl:null} siteId={id} latitude={state.latitude} longitude={state.longitude} update={getData}/>
                        <div className={"secondary-section flex-column"}>
                            <h2>{state.name??text.NO_NAME}</h2>
                            <p>{state.address}, {state.cp},  {state.ville} <br/> {state.status}<br/>{state.contactName}<br/></p>
                            <ul>{state.contactPhone?.map(ph=><li key={v4()}>{ph}</li>)}</ul>
                        </div>
                    </div>
                </div>
                {
                    loadingInterventionState === "Loading" ?
                        <div>
                            Chargement en cours ...
                        </div>
                        :
                        (
                            loadingInterventionState === "Error" ?
                                <div>
                                    Une erreur est survenue lors du chargement des sites du client ...
                                </div>
                                :
                                <div className={"interventions w100"}>
                                    <div className={"secondary-section"}><h3>
                                        {text.INTERVENTION_HISTORY} ({interventions.length>0?interventions.length:0})<br />
                                    </h3>
                                    </div>
                                    <div className={"of-auto"}>
                                    <div className={"wmax"}>
                                        <Table
                                        tabName={"interventions"}
                                        hideFilter={true}
                                        columns={siteColumns}
                                        list={interventions ?? []}
                                        />
                                    </div>
                                    </div>
                                </div>
                        )
                }
            </div>


        </div>
    )
}
