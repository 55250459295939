import CreateIntervention from "./CreateIntervention";
import React, {Fragment, useEffect, useState} from "react";
import {IconButton, Tooltip} from "@mui/material";
import CreateDevisIcon from "../../images/add-devis.png"
import {Modal} from "react-responsive-modal";
import SearchClient from "./SearchClient";
import DevisForm from "./DevisForm";
import {text} from "../util/constants";
import SearchInsurance from "./SearchInsurance";
import moment from "moment";
import {getUID} from "../../utils/Utils";

export default function CreateDevis({onChange, site, client, quotationNumber, supply, material, amount, supplierMode}){

    const default_state={
        acompte:0,
        comments:"",
        dateReception:0,
        montant: amount ?? 0,
        clientIdBCommande: quotationNumber ?? "",
        material:material ?? [],
        supplyDocument:[],
        supplyAdminDocument: supply?.elements?.length > 0 ? supply.elements.filter(f => f.dataType === "AdminDocument").map(e => {
            return {
                // eslint-disable-next-line no-useless-computed-key
                ["@type"]: e["@type"],
                isReady: e.isReady,
                issueDate: e.issueDate,
                ready: e.isReady,
                receptionDate: e.receptionDate,
                type: {id: e.type.id},
                documents: e.documents,
                id: getUID(),
            }
        }): [],
        supplyMachinery:[],
        myUserId:0,
        interventionApi:{},

        client:{},
        reception: moment(new Date()).format('YYYY-MM-DDTHH:mm'),
        companyId:1,
        site: site ?? {},
        siteId: site?.id,
    }

    const [temp,setTemp]=useState(default_state)
    const [state, setState] = useState({...default_state, intervention: 0, clientId: 0})
    const [devis,setDevis]=useState(false)

    useEffect(() => {
        if (supplierMode) {
            const newState = {...state, intervention: {type: 8}, site: site, siteId: site.id}
            setState(newState)
            onChange(newState)
        }

    }, []);

    function handleDevis(data) {
        setDevis(false)
        setState({...state, ...data})
        onChange({...state,  ...data})
    }

    function handleSite(data) {
        setState({...state, site: data,siteId:data.id})
        onChange({...state,site: data, siteId: data.id})

    }

    function handleInterventionData(data){
        setState({...state,intervention:data})
        onChange({...state,intervention:data})
    }

    function handleReception(e) {
        setState({...state, reception: e.target.value})
        onChange({...state, reception: e.target.value})
    }

    function handleClient(data) {
        setState({...state, client: data,clientId: data.id})
        onChange({...state, clientId: data.id})
    }
    function handleInsurance(data) {
        setState({...state, insurance: data,insuranceId: data.id})
        onChange({...state, insuranceId: data.id})
    }

    function handleDevisFormSave(devis,metadata,comments,documents){
        let tempData={...temp,
            clientIdBCommande : devis.clientIdBCommande,
            documents : documents,
            acompte:devis.acompte,
            montant:devis.montant,
            supplyDocument: devis.supplyDocument,
            supplyAdminDocument: devis.supplyAdminDocument,
            supplyMachinery: devis.supplyMachinery,
            material: devis.material,
            comments:comments,
            intervention: state.intervention
        }
        setTemp(tempData)
    }


    return(
        <div className={"grid commande-data"}>
            <CreateIntervention onValid={handleInterventionData} onSiteChange={handleSite} site={{...site}} supplierMode={supplierMode}>

                <Fragment>
                    <div className={"flex secondary-section"} title={text.BILL_CMD}>
                        <div className={"flex-row w100"}>
                            <input className={"large w100"} type={"text"} value={state.clientIdBCommande} placeholder={text.REF_NUM} onChange={(e) => {
                                setState({...state, clientIdBCommande: e.target.value})
                                setTemp({...temp, clientIdBCommande: e.target.value})
                            }}/>
                            <Tooltip title={text.ADD_DOCS}>
                                <IconButton className={"no-padding"} onClick={() => setDevis(true)}>
                                    <img className={"add-supply"} alt={""} src={CreateDevisIcon}/>
                                </IconButton>
                            </Tooltip>
                        </div>
                    </div>

                    <Modal open={devis} onClose={() => setDevis(false)}>
                        <DevisForm temp={temp} onSave={handleDevisFormSave} trigger={handleDevis} amount={amount}/>
                    </Modal>


                    <div className={"secondary-section"} title={"Date"}>
                        <input className={"large w100"} type={"datetime-local"} value={state.reception} onChange={handleReception}/>
                    </div>

                    <div title={"Client"} className={"secondary-section"}>
                        <SearchClient onSave={handleClient} preSelectionnedClient={{...client}}/>
                    </div>

                    {state.intervention.type === '2' &&
                        <div title={"Assurance"} className={"secondary-section"}>
                        <SearchInsurance onSave={handleInsurance}/>
                    </div>}

                </Fragment>

            </CreateIntervention>
        </div>
    )
}