import constants from "../components/util/constants";

export function isSpecialIntervention(type){
    return (+type===5 || +type===6 ||+type===7 || +type===8 || +type===129956 || type==null)
}

export function isClassic(id){
    return id!==5 && id!==6 && id!==7 && id!==8 && id!==129956
}

export function isModifiable(pin) {
    return pin.status===constants.STATUS_RECEIVED || pin.status===constants.STATUS_SCHEDULED || pin.status===constants.STATUS_TOSCHEDULED|| pin.status===constants.STATUS_PENDING;
}
export function getShape(status) {
    switch (status){
        case constants.STATUS_SCHEDULED: return "square"
        case constants.STATUS_PROCESSING: return "triangle"
        default : return ""
    }
}
export function getPinColor(id, status){
    if(status===constants.STATUS_DONE || status===constants.STATUS_WRITING || status===constants.STATUS_FINISHED){
        return "rgba(0,0,0,1)"
    }
    switch (id){
        case constants.INTERVENTION_URGENCE:return "rgba(255,4,4,1)";
        case constants.INTERVENTION_SAV:return"rgba(255,100,0,.7)";
        case constants.INTERVENTION_ENTRETIEN:return "rgba(0,88,253,.7)";
        case constants.INTERVENTION_CHANTIER:return "rgba(106,0,255,.7)";
        case constants.INTERVENTION_DECHETTERIE:return "rgba(42,176,42,0.7)";
        default :return "rgba(140,138,138,0.7)";
    }
}

export function getColor(lvl){
    let color={backgroundColor:"#999"}
    if(lvl===null){
        return color;
    }

    if(lvl===4) {
        color={backgroundColor:"#000"}
    }
    if(lvl===3) {
        color={backgroundColor:"#f00"}
    }
    if(lvl===2) {
        color={backgroundColor:"#f80"}
    }
    if(lvl===1) {
        color={backgroundColor:"#ff0"}
    }
    if(lvl===0) {
        color={backgroundColor:"#0f0"}
    }

    return color
}

