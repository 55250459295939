import React, {useCallback, useEffect, useRef, useState} from "react";
import {IconButton} from "@mui/material";
import SearchTeam from "../components/forms/SearchTeam";
import Calendar from "./Calendar";
import PlanningMap from "../planning-map/PlanningMap";
import {text} from "../components/util/constants";
import moment from "moment";
import {useMediaQuery} from "react-responsive";

export function Planning({team, date = moment(new Date()).isoWeekYear() + "-W" + (moment(new Date()).isoWeek() < 10 ? "0" + moment(new Date()).isoWeek() : moment(new Date()).isoWeek())}){
    const [loading]=useState(false)
    const [state,setState]=useState(team?{team:team}: {})
    const [hovered,setHovered]=useState()
    const calendarRef=useRef()
    const mapRef=useRef()
    const [path,setPath]=useState([])
    const [headerHeight, setHeaderHeight]=useState(0)
    const headerRef = useRef(null)
    const planificatorRef = useRef(null)
    const isMobile = useMediaQuery({ query: '(max-width: 915px)' })
    useEffect(()=>{
        !state.begin && dateParser(date)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[state.team])

    useEffect(()=> {
        if (mapRef.current){
            mapRef.current.setGeodesicPath(path)
        }
    }, [path])

    useEffect(() => {
        setHeaderHeight(headerRef?.current?.offsetHeight ?? 0)
    }, [headerRef?.current])

    function getNeededIntervention(teamId,begin,end){
        calendarRef.current.getEvents(teamId,begin,end, setPath)
        mapRef.current.getinterventions(teamId,begin,end)
    }

    function addEvent(event){
        // api.getEvent(event.intervention.event.id)
        //     .then(headEvent=>{
        //         if(headEvent.start>=event.start){
        //             event.id=headEvent.id
        //         }
        //         return event
        //     },error=>event).then(event=>{
        calendarRef.current.addEvent(event)
        // })
    }

    function handleDate({target}) {
        let value = target.value;
        dateParser(value)
    }

    function dateParser(dateStr){
        let year=parseInt(dateStr.split('-')[0])
        let week=(parseInt(dateStr.slice(-2)))
        let firsDayOftheYearNumber = new Date(year,0,0).getDay()?new Date(year,0,0).getDay():7;
        let begin=new Date(year,0,7*(week-1)+(8-firsDayOftheYearNumber)).getTime()
        let end=new Date(year,0,7*(week-1)+(14-firsDayOftheYearNumber)).getTime()
        setState({...state,begin:begin,end:end})
    }

    function handleTeam(team) {
        setState({...state,team:team})
    }

    function saveIntervention(){
        calendarRef.current.save()
    }

    function importIntervention() {
        if (!state.begin || !state.end){
            alert(text.ALERT_SELECT_DATE)
            return
        }
        if (!state.team){
            alert(text.SELECT_TEAM)
            return
        }
        getNeededIntervention(state.team.id,state.begin,state.end)
    }

    function handleTravelTime() {
        calendarRef.current.travelTime()
        // updateTravelTime(plannedIntervention.sort((i1,i2)=>i1.dateIntervention-i2.dateIntervention))
        //
        //     .then(()=>enqueueSnackbar(text.SNACKBAR_SUCCESS, {variant: "success"}))
        //     .catch(e=>{
        //         enqueueSnackbar(text.TRAVEL_TIME_ERROR, {variant: "error"})
        //         setLoading(false)
        //     })
    }

    const handleHovered = useCallback((e, id) => {

        let timeout = setTimeout(() => {
            setHovered(prev => id)
        }, id === null ? 0 : 100);

        if (id !== null){
            const handleMouseLeave = () => {
                clearTimeout(timeout);
            };
            e?.target?.addEventListener('mouseleave', handleMouseLeave);
        }

    }, [])

    function switchTeam(team) {
        setState({...state,team:team})
        getNeededIntervention(team.id,state.begin,state.end)
    }

    const handleHorizontalScroll = (step) => {
        planificatorRef.current.scrollTo({top: 0, left: +planificatorRef.current.scrollLeft + step, behavior: 'smooth'});
    }

    return(
        <div className={"planificator"} ref={planificatorRef}>
            <div className={"planningHead flex-row"} ref={headerRef}>
                <input className={"large w100"} type={"week"} id={"week"} name={"week"} defaultValue={date} onChange={handleDate}/>
                <div className={"w100"}>
                    <SearchTeam defaultValue={{value:state.team?.id,label:state.team?.name}} onSave={handleTeam}/>
                </div>
                <div className={"flex-row"}>
                    <IconButton className={"action-btn neutral refresh"} onClick={importIntervention}/>
                    <IconButton className={"action-btn validation save"} onClick={saveIntervention}><div className={"not-on-small-screen"}>{text.SAVE}</div></IconButton>
                </div>
            </div>

            <div className={`${isMobile ? "flex-row" : "grid"} planning`}>
                <Calendar
                    begin={state.begin}
                    end={state.end}
                    team={state.team}
                    events={[]}
                    // maps={maps}
                    // map={map}
                    hovered={hovered}
                    onHover={handleHovered}
                    handleAddPin={addEvent}
                    ref={calendarRef}
                />
                <PlanningMap
                    hovered={hovered}
                    onHover={handleHovered}
                    handleAddPin={addEvent}
                    switchTeam={switchTeam}
                    // onApiLoaded={handleApiLoaded}
                    ref={mapRef}
                    headerHeight={headerHeight}/>
                {
                    isMobile &&
                    <div className={`planning-switcher flex-row`}>
                        <IconButton className={"action-btn neutral"} onClick={()=>{handleHorizontalScroll(5000)}}>></IconButton>
                        <IconButton className={"action-btn neutral"} onClick={()=>{handleHorizontalScroll(-5000)}}>{"<"}</IconButton>
                    </div>
                }
            </div>
        </div>

    )
}