import {useParams} from "react-router-dom";
import React, {Fragment, useEffect, useState} from "react";
import IbatApi from "../../utils/IbatApi";
import {IconButton, Tooltip} from "@mui/material";
import CreateIntervention from "../forms/CreateIntervention";
import OpenFormIcon from "../../images/new-interv.png";
import PdfIcon from "../../images/pdf-icon.png";
import CommentIcon from "../../images/comment.png"
import ContactIllustIcon from "../../images/icon-contact.png";
import ContactIcon from "../../images/contact-illus.png";
import ApproIcon from "../../images/supply-icon.png";
import link from "../../images/link.png";
import EditSupply from "../forms/EditSupply";
import Modal from "react-responsive-modal";
import InteractionClient from "./InteractionClient";
import EditInterventionTeam from "../forms/EditInterventionTeam";
import EditInterventionDate from "../forms/EditInterventionDate";
import DocumentLoader from "../util/DocumentLoader";
import constants, {text} from "../util/constants";
import {EditText, EditTextarea} from "react-edit-text";
import {useSnackbar} from "notistack";
import ClientForm from "../forms/ClientForm";
import SearchClient from "../forms/SearchClient";
import EditBillForm from "../forms/EditBillForm";
import {useMediaQuery} from "react-responsive";
import {getStatusIcon, getTypeIcon} from "../../utils/Utils";

export default function Devis(){
    const {id} = useParams()
    const[state,setState]=useState({})
    const[clientIdBCommande,setClientIdBCommande]=useState("")
    const[interventions,setInterventions]=useState([])
    const[interventionForm,setInterventionForm]=useState({})
    const[show,setShow]=useState(false)
    const[isSavable,setIsSavable]=useState(false)
    const [approModal, setApproModal] = useState(false)
    const [contacts, setContacts] = useState(false)
    // const [report, setReport] = useState(false)
    const [billModal, setBillModal] = useState(false)
    const [loading, setLoading] = useState(false)
    const [searchEntity, setSearchEntity] = useState(false)
    const [billEntity, setBillEntity] = useState(false)
    const [editBill, setEditBill] = useState(false)
    const [updateClient, setUpdateClient] = useState(false)
    const [developCriteriaBar, setDevelopCriteriaBar] = useState(false)
    const [openTeam, setOpenTeam] = useState(false)
    const [openDate, setOpenDate] = useState(false)
    const { enqueueSnackbar} = useSnackbar();
    const isMobile = useMediaQuery({ query: '(max-width: 915px)' })

    /*const gridColumn = [
        {id: "id", isChecked: true, label: text.NUMBER, type: "linkText"},
        {id: "status", isChecked: true, label: text.STATUS, type: "text"},
        {id: "type", isChecked: true, label: text.FILE_TYPE, type: "object"},
        {id: "interactions", isChecked: true, label: text.INTERACTIONS, icon: IconEye, type: "popup", popup: InteractionClient},
        {id: "team", isChecked: true, label: text.TEAM, type: "popup", popup: EditInterventionTeam},
        {id: "intervention", label: text.INTERVENTION, isChecked: true, type: "popup", popup: EditInterventionDate},
        {id: "endIntervention", isChecked: true, label: text.INTERVENTION_END, type: "dateText"},
        {id: "object", isChecked: true, label: text.OBJECT, type: "text"},
        {id: "bill", isChecked: true, label: text.BILL, type: "popup", popup: EditBillForm},
    ]*/

    const api =new IbatApi()
    useEffect(()=> {
        getDevis()
        id && getDevisIntervention()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[id])


    function getDevis() {
        api.getDevis(id).then(devis => {
            setState(devis);
            !devis.site?.imageUrl && alert("Une image est requise pour cette commande")
            setClientIdBCommande((devis?.clientIdBCommande && devis.clientIdBCommande.trim().length > 1) ? devis.clientIdBCommande : constants.NULL)
        })
    }

    function getDevisIntervention() {
        api.getDevisIntervention(id).then(i =>{
            /*setInterventions(i.map(i=>{
                i.intervention = {
                    id: id,
                    value: i.dateIntervention,
                    name: i.dateIntervention ?
                        new Date(i.dateIntervention).toLocaleDateString('fr-FR').toString() :
                        (
                            i.headEvent ?
                                new Date(i.headEvent).toLocaleDateString('fr-FR').toString() :
                                "-"
                        ),
                }

                i.bill =  {name: i.bill}
                i.status=text.STATUS_TRADUCTION[i.status]
                return i
            }))*/
            setInterventions(i)
        })
    }


    function handleSave() {
        if(!isSavable){
            setShow(false)
            return
        }
        let intervention= {
            etat:  interventionForm.team?.name && interventionForm.intervention ? constants.SCHEDULED : constants.TOSCHEDULED,
            idSecteur: interventionForm.secteur,
            idTypeIntervention: interventionForm.type,
            teamId: interventionForm.team?interventionForm.team.id:null,
            dateIntervention: interventionForm.intervention?new Date(interventionForm.intervention).getTime():0,
            jobTypeId: interventionForm.jobTypeId,
            estimateDuration:3600,
        }
        api.addIntervention(state.id, intervention).then(() => {
            setInterventionForm({});
            setShow(false)
            setIsSavable(false)
            getDevisIntervention()

        })
    }

    function handleSavable(data) {
        setIsSavable( data.type)
    }

    function handleData(data) {
        setInterventionForm(data)
        handleSavable(data)
    }

    function handleClientIdBCommande(e) {
        setClientIdBCommande(e.target.value)
    }

    function saveClientIdBCommande()  {
        api.setDevisClientIdBoncommande(id,encodeURIComponent(clientIdBCommande)).then(()=>getDevis())
    }

    function handleCommentaire() {
        api.setClientConcern(id,encodeURIComponent(state.comments))
    }

    function handleCommentaireChange(e) {
        if(e.length>constants.MAX_LENGTH){
            return
        }
        setState({...state,comments:e.target.value})
    }
    function handleSupply(){
        return api.getDevisSupply(id)
    }

    function handleContacts(id) {
        setContacts(id)
    }

    /*function handleReport() {
        setReport(!report)
    }*/

    function handleBill() {
        if(!state.client.accountantAccount){
            alert("Le client n'a pas de compte comptable. Merci de lui en renseigner un et d'actualiser la page.")
            return
        }
        setLoading(true)
        api.createBillFromQuotation(state.originQuotation.id, billModal.interventionId, billModal.bpi, billEntity.id)
            .then((r) => {
                enqueueSnackbar(text.SNACKBAR_SUCCESS_BILLED,{variant:"success"})
                setLoading(false)

                //update one element of the list intervention
                let newIntervention = [...interventions]
                newIntervention.forEach((i) => {
                    if(i.id === billModal.interventionId){
                        i.bill = r
                    }
                })
                setInterventions(newIntervention)


                setBillModal(false)
                // window.location.reload()
            })
            .catch(() => {
                setLoading(false)
                setBillModal(false)
                enqueueSnackbar(text.SNACKBAR_ERROR,{variant:"error"})
            })


    }
    function handlePayment() {
        api.setPaid(id)
    }

    function handleChangeMontant(e) {
        setState({...state,montant:e.target.value})
    }
    function saveMontant() {
        api.setMontant(id,encodeURIComponent(state.montant))
    }

    function handleChangeAcompte(e) {
        setState({...state,acompte:e.target.value})
    }

    function saveAcompte() {
        api.setAcompte(id,encodeURIComponent(state.acompte))
    }

    const handleBillEntity = (d) => {
        api.getClientById(d.id)
            .then(r => {
                setSearchEntity(false)
                setBillEntity(r)
            })
            .catch (() => enqueueSnackbar('Une erreur est survenue', {variant: "error"}))
    }

    function handleSaveClient(d){
        setUpdateClient(false)
        setBillEntity(d)
        return api.updateClient(d)
    }

    return(
        <div className={"page flex scrollable"}>
            <aside className={`flex-column criteria-bar${developCriteriaBar ? " developped" : ""}`}>
                {/*<img alt={""} className={"illus"} src={Icon}/>*/}
                <div className={"margin-content"}>
                    {/*<IconButton disabled><img alt={""} src={BonCommande}/></IconButton>*/}
                    <Tooltip title={text.TOOLTIP_ADD_INTERV + " " + id}>
                        <IconButton onClick={() => {
                            setShow(true)
                        }}>
                            <img alt={""} src={OpenFormIcon}/>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={text.TOOLTIP_CLIENT_CHAT}>
                        <IconButton onClick={() => handleContacts(id)}><img alt={""}
                                                                            src={ContactIllustIcon}/></IconButton>
                    </Tooltip>
                    <Tooltip title={text.TOOLTIP_SUPPLY}>
                        <IconButton onClick={() => setApproModal(!approModal)}><img alt={""}
                                                                                    src={ApproIcon}/></IconButton>
                    </Tooltip>
                </div>
                <div
                    className={`bord1 only-for-small-screen${developCriteriaBar ? " developped" : ""}`}
                    onClick={() => setDevelopCriteriaBar(!developCriteriaBar)}>
                    >
                </div>

                <Modal open={show} onClose={() => setShow(false)}>
                    <div className={"commande-form"}>
                        <div className={"commande-head"}>
                            <h3>{text.CREATE_INTERV}</h3>
                        </div>

                        <div className={"grid commande-data"}>
                            <CreateIntervention onValid={handleData}/>
                        </div>
                        <IconButton className={`action-btn ${isSavable ? "validation" : "cancel"}`}
                                    onClick={() => {
                                        handleSave();
                                        setShow(false)
                                    }}>
                            {isSavable ? text.CREATE : text.CANCEL}
                            {/*<img alt={""} src={isSavable?ValidateFormIcon:OpenFormIcon}/>*/}
                        </IconButton>
                    </div>
                </Modal>

            </aside>
            <div className={"flex-column devis-bean"}>

                <div className={"references flex-column"}>
                    <h1>{text.COMMAND_NUM} {id}</h1>
                    <Tooltip title={text.TOOLTIP_CMD_REF}>
                    <div>
                            <EditText className={"ref-edit bord1 br20"} onChange={handleClientIdBCommande} value={clientIdBCommande} onBlur={saveClientIdBCommande}/>
                        </div>
                    </Tooltip>
                </div>
                <div className={"documents flex-row"}>
                    {state?.pdfMission?.includes("https://") ?
                        <a rel={"noreferrer"} target={"_blank"} href={state.pdfMission}><img alt={"pdf de mission"} src={PdfIcon}/></a>
                        :
                        (
                            state?.pdfMission &&
                            <div className={"flex-column"}>
                                <DocumentLoader name={state?.pdfMission} />
                                <p>pdf de mission</p>
                            </div>
                        )
                    }
                    {
                        state?.originQuotation?.odooId &&
                        <div className={"flex-column"}>
                            <DocumentLoader name={state?.originQuotation?.odooId} type={"quotation"} />
                            <p>Devis</p>
                        </div>
                    }
                    {
                        interventions &&
                        interventions?.map(i => i?.bills?.length > 0 && i?.bills?.map((b, idx) =>
                            <div className={"flex-column"}>
                                <DocumentLoader name={b.odooId} type={"bill"} />
                                <p>Facture intervention<br />{i.id} ({idx + 1})</p>
                            </div>)
                        )
                    }
                </div>
                <div className={"flex infos secondary-section"}>

                        <div className={"justLeft flex-row bord1"}>
                            <div className={"tabcell"}>
                                {text.AMOUNT} :
                            </div>
                            <div className={"tabcell"}>
                                <EditText className={"justLeft w50 bord1 tabinput"} type={"number"} value={""+state.montant} name={"montant"} inline={true} onChange={handleChangeMontant} onBlur={saveMontant}/>€
                            </div>
                        </div>
                        <div className={"justLeft flex-row bord1"}>
                            <div className={"tabcell"}>
                                {text.DEPOSIT} :
                            </div>
                            <div className={"tabcell"}>
                                <EditText className={"justLeft w50 bord1 tabinput"} type={"number"} value={""+state.acompte} name={"acompte"} inline={true} onChange={handleChangeAcompte} onBlur={saveAcompte}/>€
                            </div>
                        </div>

                        <div className={"justLeft flex-row bord1"}>
                                <div className={"tabcell"}>
                                    {text.CLIENT_2} :
                                </div>
                                {
                                    state.client &&
                                    <a className={"tabcell"} href={`/client/${state.client.id}`}>
                                        {state.client.name}
                                    </a>
                                }
                            </div>
                        <div className={"justLeft flex-row bord1"}>
                            <div className={"tabcell"}>
                                {text.SITE_2} :
                            </div>
                            {
                                state.site &&
                                <a className={"tabcell"} href={`/site/${state.site.id}`}>
                                    {state.site.name}
                                </a>
                            }
                        </div>

                        <div className={"grid bord1 tabrow"}>
                            <div className={"tabcell"}>
                                {text.RECEPTION_DATE} :
                            </div>
                            <div className={"tabcell"}>
                                {
                                    state.dateReception &&
                                    new Date(state.dateReception).toLocaleDateString()
                                }
                            </div>
                        </div>
                    {/*<IconButton className={"action-btn validation notAvailable"} onClick={handlePayment}>{text.PAID}</IconButton>*/}
                </div>
                <div className={"details primary-section"}>
                    <h2>{text.INTERVENTION_LINKED}</h2>


                    {
                        isMobile ?
                            <div className={"flex-row auto-line"}>
                                {interventions.length > 0 && interventions.map(i => {
                                    i.dateIntervention = (i.dateIntervention ? i.dateIntervention : i.headEvent ? i.headEvent.start : 0);
                                    return i
                                })
                                    .map(i =>
                                        <div className={"secondary-section br05 grid card-section"}>
                                            <div className={"secondary-section card-intervention flex-row"}>
                                                <img src={link}/><a href={`/interventions/${i.id}`}> {i.id}</a>
                                            </div>
                                            <div className={"card-status flex-column"}><img src={getStatusIcon(text.STATUS_TRADUCTION[i.status])}/>{text.STATUS_TRADUCTION[i.status]}</div>
                                            <div className={"card-type flex-column"}><img src={getTypeIcon(i.type.id)}/>{i.type.name}</div>
                                            <div className={"w100 card-line-2 flex-column secondary-section"}>
                                                <div className={"alignSelfend"}>
                                                <Tooltip title={text.TOOLTIP_LIST}>
                                                    <IconButton
                                                        onClick={() => handleContacts(i.id)}><img
                                                        alt={""}
                                                        src={ContactIcon} height="19"/></IconButton>
                                                </Tooltip>
                                            </div>

                                            <div className={"bord1 w100"} onClick={() => setOpenTeam(i.id)}>Equipe : {i.team.name}</div>
                                            <div className={"bord1 w100"} onClick={() => setOpenDate(i.id)}>Prévue le : {i.headEventDate > 0 ? new Date(i.headEventDate).toLocaleDateString() : "-"}</div>
                                            <div className={"bord1 w100"}>Finit le : {i.endIntervention > 0 ? new Date(i.endIntervention).toLocaleDateString() : "-"}</div>
                                            <div className={"bord1 w100"}>Objet : {i.object ? i.object : text.TO_DEFINE}</div>
                                            <div className={"primary-section"}>
                                                {
                                                    (i?.bill && i?.bill?.includes("VE/")) ?
                                                        <div
                                                            onClick={() => setEditBill(i)}>{i.bill}</div> :
                                                        (
                                                            state.originQuotation ?
                                                                <IconButton style={{padding: "0 1rem"}}
                                                                            className={"action-btn validation"}
                                                                            onClick={() => {
                                                                                setBillModal({
                                                                                    interventionId: i.id,
                                                                                    bpi: false,
                                                                                    originQuotation: state.originQuotation
                                                                                })
                                                                                setBillEntity({...state.client})
                                                                            }}>{text.TO_BILL}</IconButton>
                                                                :
                                                                <p><IconButton style={{
                                                                    padding: "0 1rem",
                                                                    fontSize: "1rem"
                                                                }}
                                                                               disabled
                                                                               className={"action-btn validation"}
                                                                               onClick={() => setBillModal({
                                                                                   interventionId: i.id,
                                                                                   bpi: false,
                                                                                   originQuotation: false
                                                                               })}>{text.TO_BILL_NO_QUOTATION}</IconButton>
                                                                </p>
                                                        )
                                                }
                                            </div>
                                            </div>
                                            <Modal open={openTeam === i.id} onClose={() => setOpenTeam(false)}>
                                                <EditInterventionTeam id={openTeam} update={() => {
                                                    getDevisIntervention();
                                                    setOpenTeam(false)
                                                }}/>
                                            </Modal>
                                            <Modal open={openDate === i.id} onClose={() => setOpenDate(false)}>
                                            <EditInterventionDate id={openDate}
                                                                  day={i.dateIntervention}
                                                                  team={i.team}
                                                                  update={() => {
                                                                      getDevisIntervention();
                                                                      setOpenDate(false)
                                                                  }}/>
                                        </Modal>

                                    </div>)
                                }
                            </div>
                            :
                            <table className={"table"}>
                                <thead className={"w100"}>
                                <tr className={"w100 table-row grid header"}>
                                    <th className={"w100 filter-input br05 flex-column"}>{text.INTERVENTION_NUMBER}</th>
                                    <th className={"w100 filter-input br05 flex-column"}>{text.STATUS}</th>
                                    <th className={"w100 filter-input br05 flex-column"}>{text.FILE_TYPE}</th>
                                    <th className={"w100 filter-input br05 flex-column"}>{text.CONTACTS}</th>
                                    <th className={"w100 filter-input br05 flex-column"}>{text.TEAM}</th>
                                    <th className={"w100 filter-input br05 flex-column"}>{text.INTERVENTION_START}</th>
                                    <th className={"w100 filter-input br05 flex-column"}>{text.INTERVENTION_END}</th>
                                    <th className={"w100 filter-input br05 flex-column"}>{text.OBJECT}</th>
                                    <th className={"w100 filter-input br05 flex-column"}>{text.BILL}</th>
                                </tr>
                                </thead>
                                <tbody className={"w100"}>
                                {interventions.length > 0 && interventions.map(i => {
                                    // i.dateIntervention = (i.dateIntervention ? i.dateIntervention : i.headEvent ? i.headEvent.start : 0);
                                    i.dateIntervention = (i.dateIntervention ?? i.headEventDate);
                                    return i
                                })
                                    .map(i => <tr className={"table-row grid w100"}>
                                        <td className={"cell w100 flex-column"}><a
                                            href={`/interventions/${i.id}`}> {i.id}</a></td>
                                        <td className={"cell w100 flex-row"}><img src={getStatusIcon(text.STATUS_TRADUCTION[i.status])}/>{text.STATUS_TRADUCTION[i.status]}</td>
                                        <td className={"cell w100 flex-row"}><img src={getTypeIcon(i.type.id)}/>{i.type.name}</td>
                                        <td className={"cell w100 flex-column"}>
                                            <Tooltip title={text.TOOLTIP_LIST}>
                                                <IconButton
                                                    onClick={() => handleContacts(i.id)}><img
                                                    alt={""}
                                                    src={ContactIcon} height="19"/></IconButton>
                                            </Tooltip>
                                        </td>
                                        <td className={"cell w100 flex-column"}
                                            onClick={() => setOpenTeam(i.id)}>{i.team.name}</td>
                                        <td className={"cell w100 flex-column"}
                                            onClick={() => setOpenDate(i.id)}>{i.dateIntervention > 0 ? new Date(i.dateIntervention).toLocaleDateString() : "-"}</td>
                                        <td className={"cell w100 flex-column"}>{i.endIntervention > 0 ? new Date(i.endIntervention).toLocaleDateString() : "-"}</td>
                                        <td className={"cell w100 flex-column"}>{i.object ? i.object : text.TO_DEFINE}</td>
                                        <td className={"cell w100 flex-column"}>
                                            {
                                                (i?.bill && i?.bill?.includes("VE/")) ?
                                                    <div
                                                        onClick={() => setEditBill(i)}>{i.bill}</div> :
                                                    (
                                                        state.originQuotation ?
                                                            <IconButton style={{padding: "0 1rem"}}
                                                                        className={"action-btn validation"}
                                                                        onClick={() => {
                                                                            setBillModal({
                                                                                interventionId: i.id,
                                                                                bpi: false,
                                                                                originQuotation: state.originQuotation
                                                                            })
                                                                            setBillEntity({...state.client})
                                                                        }}>{text.TO_BILL}</IconButton>
                                                            :
                                                            <p><IconButton style={{
                                                                padding: "0 1rem",
                                                                fontSize: "1rem"
                                                            }}
                                                                           disabled
                                                                           className={"action-btn validation"}
                                                                           onClick={() => setBillModal({
                                                                               interventionId: i.id,
                                                                               bpi: false,
                                                                               originQuotation: false
                                                                           })}>{text.TO_BILL_NO_QUOTATION}</IconButton>
                                                            </p>
                                                    )
                                            }
                                        </td>
                                        <Modal open={openTeam === i.id}
                                               onClose={() => setOpenTeam(false)}>
                                            <EditInterventionTeam id={openTeam} update={() => {
                                                getDevisIntervention();
                                                setOpenTeam(false)
                                            }}/>
                                        </Modal>
                                        <Modal open={openDate === i.id}
                                               onClose={() => setOpenDate(false)}>
                                            <EditInterventionDate id={openDate}
                                                                  day={i.dateIntervention}
                                                                  team={i.team}
                                                                  update={() => {
                                                                      getDevisIntervention();
                                                                      setOpenDate(false)
                                                                  }}/>
                                        </Modal>

                                    </tr>)}
                                </tbody>
                            </table>

                    }
                </div>
                <div className={"comments primary-section"}>
                    <div className={"commande-comments-title flex-row"}><img alt={""}
                                                                             src={CommentIcon}/>{text.NOTES_AND_COMMENTS}
                    </div>
                    <EditTextarea className={"w100"} inputClassName={"textedit large w100"}
                                  value={state?.comments ?? ""}
                                  onChange={handleCommentaireChange} rows={6}
                                  onBlur={handleCommentaire}> </EditTextarea>
                </div>

                <Modal open={approModal} onClose={() => setApproModal(!approModal)}>
                    <EditSupply id={id} handleSupply={handleSupply}
                                title={`${state?.clientIdBCommande} - ${state?.site?.name}`}/>
                </Modal>


                <Modal open={contacts} onClose={() => setContacts(false)}>
                    <InteractionClient id={contacts} devis={contacts === id} site={state?.site}/>
                </Modal>
                <Modal open={billModal} onClose={() => setBillModal(false)}>
                    <div className={"primary-section"}>
                        <h1>Facturation</h1>

                        {
                            billModal.originQuotation ?
                                <Fragment>
                                    {
                                        (billEntity?.type === "PRIVATE_PERSON" || !billEntity?.type) ?
                                            <h2>
                                                <div className={"as-link"}
                                                     onClick={() => setUpdateClient(billEntity)}>{billEntity.name}</div>
                                                est un particulier</h2>
                                            :
                                            <h2>
                                                <div className={"as-link"}
                                                     onClick={() => setUpdateClient(billEntity)}>{billEntity.name}</div>
                                                n'est pas un particulier</h2>
                                    }
                                    {
                                        !billEntity?.accountantAccount &&
                                        <h2>Aucun compte client n'est renseigné</h2>
                                    }
                                    <p>
                                        <input
                                            type={"checkbox"}
                                            checked={billModal?.bpi}
                                            onChange={() => setBillModal({
                                                ...billModal,
                                                bpi: !billModal?.bpi
                                            })}
                                        />
                                        <label>Facturation BPI</label>
                                    </p>
                                </Fragment> :
                                <Fragment>
                                    <h2>Le devis n'a pas d'origine</h2>
                                    <p>La facturation ne peut pas être générée pour le moment</p>
                                </Fragment>
                        }
                        <div className={"flex-row"}>
                            <IconButton className={"action-btn validation"} disabled={loading}
                                        onClick={() => setSearchEntity(true)}>{text.FACTURATION_ENTITY_CHANGE}</IconButton>
                            <IconButton className={"action-btn validation"} disabled={loading}
                                        onClick={() => handleBill()}>{text.TO_BILL}</IconButton>
                        </div>
                    </div>
                </Modal>
                <Modal open={updateClient} onClose={() => setUpdateClient(false)}>
                    <ClientForm {...updateClient} onSave={handleSaveClient}
                                onCancel={() => setUpdateClient(false)}/>
                </Modal>
                <Modal open={searchEntity} onClose={() => setSearchEntity(false)}>
                    <div className={"flex-column"}>
                        <h2>Sélectionnez une entité de facturation</h2>
                        <SearchClient onSave={(d) => handleBillEntity(d)}/><br/>

                        <IconButton className={"action-btn cancel br10"}
                                    onClick={() => setSearchEntity(false)}>{text.CANCEL}</IconButton>
                    </div>
                </Modal>
                <Modal open={editBill} onClose={() => setEditBill(false)}>
                    <EditBillForm id={editBill.id} content={editBill.bill} bills={editBill.bills}
                                  update={getDevisIntervention}/>
                </Modal>

                {/*<div className={"space"}/>*/}


            </div>
        </div>
    )
}
