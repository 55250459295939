import React, {useEffect, useState} from "react";
import {Button, Input} from "react-chat-elements";
import {text} from "../components/util/constants";
import SearchImg from "../images/search-icon.png";

export default function ChatWindow({api, myName, chat, updateUnReadMessage, closeChatWindow, lastNotification, updateChatField, needAttention, newChat}){

    const [conversation, setConversation] = useState()
    const [users,setUsers] = useState()
    const [filteredUsers,setFilteredUsers]=useState([])

    useEffect(()=>{
        update()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chat])
    useEffect(()=>{
        if (lastNotification){
            if(lastNotification?.target?.replace("msg/", "") === chat.id){
                update()
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lastNotification])


    function update(){
        api.getMessageFromChat(chat.id)
            .then(resp => {
                setConversation({messages: resp.content})
            })
    }

    function getAllUser(){
        api.getAllUser().then(data=> {
            setUsers(data)
            setFilteredUsers(data)
        })
    }
    function filterUser(e) {
        const name = e.target.value;
        setFilteredUsers(users.filter(user => user.username.includes(name)))
    }
    function handleUserSelected(chat, user){
        api.getChatByParticipants(user.username)
            .then(resp => {
                resp.opened = true
                newChat(chat.id, resp)
            })
    }

    function updateConversationField(field, value){
        setConversation({...conversation, [field]:value})
    }
    /*function updateChatObject(chatId, chat){
        /!*if (openedChat.filter(c=>c.id === chat.id).length===0){
            addOpenedChat({...chat, title: chat.name, opened: false}, true)
            return
        }
        setOpenedChat(openedChat.map(c => c.id === chatId ? {...chat, opened: c.opened, title: c.title, name: c.title} : c))*!/
    }*/

    function sendMessage(){
        if(!conversation.textToSend || conversation.textToSend.trim() === ""){
            return
        }
        api.sendChatMessage(chat.id, chat.name, conversation.textToSend)
            .then(resp => {
                if(chat.messages && chat.messages.length === 0){
                    closeChatWindow(chat.id)
                    newChat(resp.id, {...resp, opened: true})
                }
                else{
                    setConversation({...conversation, messages: resp.messages.sort((m1, m2) => new Date(m1.emissionDate).getTime()>new Date(m2.emissionDate).getTime() ? -1 : 1)})
                }
            })
        updateConversationField("textToSend", "")
        document.getElementsByClassName("input_" + chat.id)[0].getElementsByClassName("rce-input")[0].value = ""
        document.getElementsByClassName("input_" + chat.id)[0].getElementsByClassName("rce-input")[0].style = "height: 36px"
    }
    function showMessage(message){
        return (
            <div className={`message-date  ${message.emitter.name === myName ? "right" : ""}`}>
                <div className={`date`}>
                    {new Date(message?.emissionDate).toLocaleString()}
                </div>
                <div className={`bord1 message`}>

                    {message.content.split("\n").map(str => <div>{str}</div>)}
                </div>
            </div>
        )
    }

    const handleBackLine = (ev, chat) => {
        updateUnReadMessage(chat)
        let value = ev.target.value
        if (value.slice(-1) === '\n'){
            value = value.trim()
        }
        ev.target.value = value
        updateConversationField("textToSend", encodeURIComponent(ev.target.value))
    }
    function handleInput(value, backLine){
        document.getElementsByClassName("input_" + chat.id)[0].getElementsByClassName("rce-input")[0].value = backLine ? value : value.trim()
        updateConversationField("textToSend", encodeURIComponent(value))
    }

    return (
        <div key={chat.id} className={`chatContainer ${!chat.opened ? "minimized" : ""}`} onClick={()=>updateUnReadMessage(chat)}>
            {/*<div className={`chatWindowHeader ${!chat.opened ? "minimized" : ""}  ${chatList.filter(c=> c.id === chat.id && c.needAttention).length>0 ? "needAttention" : ""}`}>*/}
            <div className={`chatWindowHeader ${!chat.opened ? "minimized" : ""} ${needAttention ? "needAttention" : ""}`}>
                <div className={`close`} onClick={()=>closeChatWindow(chat.id)}/>
                {chat.title}
                <div className={`fold`} onClick={()=>updateChatField(chat.id, "opened", !chat.opened)}/>
            </div>
            {chat.opened &&
                (chat.id !== "new" ?
                        <div className={"chatThread"}>
                            <div className={"messageList"}>
                                {
                                    conversation?.messages?.map(message=>showMessage(message))
                                }
                            </div>
                            <div>
                                <div className={"divider"}/>
                                <Input
                                    autoHeight={false}
                                    onChange={(ev)=>handleBackLine(ev, chat)}
                                    placeholder="Votre message ici..."
                                    multiline={true}
                                    className={"chatInput input_"+chat.id}
                                    onKeyDown={(e)=> {
                                        if (!e.ctrlKey && e.key === "Enter"){
                                            sendMessage(chat)
                                        }
                                        else if (e.ctrlKey && e.key === "Enter"){
                                            handleInput(e.target.value+"\n", true)
                                        }
                                    }}
                                    rightButtons={<Button onClick={()=>sendMessage(chat)} color="white" backgroundColor="black" text="➤" />}
                                />
                            </div>
                        </div>
                        :
                        <div className={"newMessageContainer"}>
                            {!users && getAllUser()}
                            <div className={"searchBar"}>
                                <input type={"text"} placeholder={text.ENTER_USERNAME} defaultValue={null} onChange={filterUser}/>
                                <img alt={""} src={SearchImg} className={"searchIcon"}/>
                            </div>
                            <ul>
                                {filteredUsers.map(u=><li onClick={()=> handleUserSelected(chat, u)}>{u.username}</li>)}
                            </ul>


                            <div>
                                <div className={"divider"}/>
                                <Input
                                    onChange={(ev)=>updateConversationField("textToSend", ev.target.value)}
                                    placeholder="Votre message ici..."
                                    multiline={true}
                                    className={"chatInput  input_"+chat.id}
                                    rightButtons={<Button onClick={()=>sendMessage(chat)} color="white" backgroundColor="black" text="➤" />}
                                />
                            </div>
                        </div>
                )
            }
        </div>
    )
}