import {text} from "../components/util/constants";
import {enqueueSnackbar} from "notistack";

import maintenance from "../images/maintenance.png";
import emergency from "../images/emergency.png";
import sav from "../images/sav.png";
import chantier from "../images/chantier.png";
import dechetterie from "../images/dechetterie.png";
import depot from "../images/depot.png";
import atelier from "../images/atelier.png";
import fournisseur from "../images/fournisseur.png";
import metrage from "../images/metrage.png";
import expertise from "../images/expertise.png";
import appro from "../images/appro.png";
import canceled from "../images/canceled.png";
import received from "../images/received.png";
import toSchedule from "../images/to-schedule.png";
import scheduled from "../images/scheduled.png";
import processing from "../images/processing.png";
import done from "../images/done.png";
import writing from "../images/writing.png";
import finished from "../images/finished.png";
import supplyNotReady from "../images/not-ready.png";
import supplyReady from "../images/ready.png";

export const getUID = () => {
    return 'xxxxxxxxxxxxxxxxxxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        var r = (Math.random() * 16) | 0,
            v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    })
}

export const validateEmail = (email) => {
    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    const emailIsValid = regex.test(email);
    if (!emailIsValid) {
        enqueueSnackbar(text.INVALID_EMAIL, {variant: "error"})
    }
    return emailIsValid
}
export const validateSiren = (siren) => {
    console.log(siren.length !== 9)
    let isValid = false;
    if ( (siren.length !== 9) || (isNaN(siren)) ) {
        enqueueSnackbar(text.INVALID_SIREN, {variant: "error"})
        return isValid
    }
    // Donc le SIREN est un numérique à 9 chiffres
    let sum = 0;
    let tmp;
    for (let cpt = 0; cpt<siren.length; cpt++) {
        if ((cpt % 2) === 1) { // Les positions paires : 2ème, 4ème, 6ème et 8ème chiffre
            tmp = siren.charAt(cpt) * 2; // On le multiplie par 2
            if (tmp > 9)
                tmp -= 9;	// Si le résultat est supérieur à 9, on lui soustrait 9
        }
        else
            tmp = siren.charAt(cpt);
        sum += parseInt(tmp);
    }
    isValid = (sum % 10) === 0;
    if (!isValid) {
        enqueueSnackbar(text.INVALID_SIREN, {variant: "error"})
    }
    return isValid;
}

export const getTypeIcon = (status) => {
    switch (status) {
        case 1 :
            return maintenance;
        case 2 :
            return emergency;
        case 3 :
            return sav;
        case 4 :
            return chantier;
        case 5 :
            return dechetterie;
        case 6 :
            return depot;
        case 7 :
            return atelier;
        case 8 :
            return fournisseur;
        case 9 :
            return metrage;
        case 10 :
            return expertise;
        case 129956 :
            return appro;
        default:
            return

    }

}


export const getStatusIcon = (status) => {
    switch (status) {
        case "Annulée" :
            return canceled;
        case "Reçue" :
            return received;
        case "À planifier" :
            return toSchedule;
        case "Planifiée" :
            return scheduled;
        case "En cours" :
            return processing;
        case "Fait" :
            return done;
        case "Rédaction" :
            return writing;
        case "Fini" :
            return finished;
        default:
            return

    }

}

export const getSupplyStatusIcon = (status) => {
    switch (status) {
        case "Prêt" :
            return supplyReady;
        case "Incomplet" :
            return supplyNotReady;
        default:
            return

    }

}