import {useEffect, useState} from "react";
import IbatApi from "../../../utils/IbatApi";
import NomenclatureElementType from "./NomenclatureElementType";
import NomenclatureOperationType from "./NomenclatureOperationType";


export default function NomenclatureEdit({jobTypes}) {
    const [tab, setTab] = useState("Family")

    const [families, setFamilies] = useState([])
    const [preciserFamilies, setPreciserFamilies] = useState([])
    const [productTypes, setProductTypes] = useState([])

    const [elementTypeList, setElementTypeList] = useState([]);
    const [operationTypeList, setOperationTypeList] = useState([]);

    const api = new IbatApi()

    useEffect(()=> {
        if (!families || families.length === 0) {
            getFamilies()
        }
        if (!preciserFamilies || preciserFamilies.length === 0) {
            getPreciserFamilies()
        }
        if (!productTypes || productTypes.length === 0) {
            getProductTypes()
        }
        if (!elementTypeList || elementTypeList.length === 0) {
            refreshElementType()
        }
        if (!operationTypeList || operationTypeList.length === 0) {
            refreshOperationType()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    function refreshElementType(){
        api.getElementType().then(type => setElementTypeList(type))
    }
    function refreshOperationType(){
        api.getOperationType().then(otList => setOperationTypeList(otList.sort((a, b)=> a.emergency_order > b.emergency_order ? 1 : -1)))
    }

    function getFamilies(){
        api.getCatalogFamilies()
            .then(resp => {
                setFamilies(resp)
            })
            .catch(e => console.log(e))
    }
    function getPreciserFamilies(){
        api.getCatalogPreciserFamilies()
            .then(resp => {
                setPreciserFamilies(resp)
            })
            .catch(e => console.log(e))
    }
    function getProductTypes(){
        api.getProductTypes()
            .then(resp => {
                setProductTypes(resp)
            })
            .catch(e => console.log(e))
    }


    function getTab(){
        switch(tab) {
            case "elementType" :
                return <NomenclatureElementType families={families} preciserFamilies={preciserFamilies} productTypes={productTypes} updateList={refreshElementType} elementTypeList={elementTypeList} />
            case "operationType" :
                return <NomenclatureOperationType families={families} preciserFamilies={preciserFamilies} productTypes={productTypes} updateList={refreshOperationType} elementTypeList={elementTypeList} operationTypeList={operationTypeList} />
            default :
                return <NomenclatureElementType families={families} preciserFamilies={preciserFamilies} productTypes={productTypes} updateList={refreshElementType} elementTypeList={elementTypeList} />
        }
    }

    return (
        <div className={"primary-section flex-column catalog-admin"}>
            <div className={"flex-row"}>
                <div className={"as-link"} onClick={()=>setTab("elementType")}  style={{textDecoration: tab==="elementType" ? "underline 0.15em":""}}>Nomenclature de supports (ElementType)</div>
                <div className={"as-link"} onClick={()=>setTab("operationType")}  style={{textDecoration: tab==="operationType" ? "underline 0.15em":""}}>Nomenclature des éléments (OperationType)</div>
            </div>
            {getTab()}
        </div>
    )
}