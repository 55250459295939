import React, {useState} from "react";
import IbatApi from "../../utils/IbatApi";
import Select from "react-select";
import {text} from "../util/constants";


export default function SearchCompany({onSave}) {
  const [companyList, setCompanyList] = useState([])
  const [timer, setTimer] = useState()
  let api = new IbatApi();

  function loadOptions(inptv){
    return api.findCompany(inptv).then(resp => {
      let map = resp.map(s => ({
        label: s.name + " : " + s.address+ " " + s.cp + " " + s.ville,
        id: s.id,
        value:s.id,
        name: s.name + " : " + s.address + " " + s.cp + " " + s.ville
      }))
      setCompanyList(map)
      return map
    })
  }

  function handleInputChange(d){
    if(d){
      timer && clearTimeout(timer)
      setTimer(setTimeout(()=>loadOptions(d),500))
    }
  }


  function handleChange(d){
    if(d!==null){
      onSave(d)
    }
  }
  return (
    <div className={"createSite"}>
      <Select
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        menuPortalTarget={document.body}
        classNamePrefix={"search"}
        isClearable
        placeholder={text.COMPANY_NAME}
        onChange={handleChange}
        onInputChange={handleInputChange}
        noOptionsMessage={()=>text.NO_ENTRY_SELECTED}
        options={companyList}
        filterOption={e=>true}
      />
    </div>
  )
}
