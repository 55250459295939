import React, {Fragment} from "react";
import {Tooltip} from "@mui/material";
import {text} from "./constants";

export default function FileUploader({files,onUpload,single,id, accept}){

    function fileEncoder(dfiles){
        for(let i=0;i<dfiles.length;i++){
            const fileReader = new FileReader();
            let file=dfiles[i]
            fileReader.readAsDataURL(file);
            fileReader.onload=()=>{
                let data=fileReader.result
                data=data.replace(/^data:.+;base64,/, '')
                onUpload([...files, {type: file.name.split(".").pop(), data: data}])
            }
        }
    }

    function handleFile(e) {
        const downloadfiles=e.target.files
        fileEncoder(downloadfiles)
    }

    return(
        <Fragment>
            <Tooltip title={text.TOOLTIP_ADD_FILE}>
                <label className={"bord1 fileUploader br05"} htmlFor={"fileUploader"+id}/>
            </Tooltip>
            <input accept={accept ?? "*"} type={"file"} id={"fileUploader"+id} name={"documents"} multiple={!single} onChange={handleFile}/>
        </Fragment>
    )
}