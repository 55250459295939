import {useEffect, useState} from "react";
import IbatApi from "../../../utils/IbatApi";
import FamilyTab from "./FamilyTab";
import PreciserFamilyTab from "./PreciserFamilyTab";
import ProductTab from "./ProductTab";
import ProductTypeTab from "./ProductTypeTab";


export default function CatalogEdit({jobTypes}) {
    const [tab, setTab] = useState("Family")

    const [families, setFamilies] = useState([])
    const [preciserFamilies, setPreciserFamilies] = useState([])
    const [productTypes, setProductTypes] = useState([])
    const [products, setProducts] = useState([])

    const api = new IbatApi()

    useEffect(()=> {
        if (!families || families.length === 0) {
            getFamilies()
        }
        if (!preciserFamilies || preciserFamilies.length === 0) {
            getPreciserFamilies()
        }
        if (!products || products.length === 0) {
            getProducts()
        }
        if (!productTypes || productTypes.length === 0) {
            getProductTypes()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function getFamilies(){
        api.getCatalogFamilies()
            .then(resp => {
                setFamilies(resp)
            })
            .catch(e => console.log(e))
    }
    function getPreciserFamilies(){
        api.getCatalogPreciserFamilies()
            .then(resp => {
                setPreciserFamilies(resp)
            })
            .catch(e => console.log(e))
    }
    function getProductTypes(){
        api.getProductTypes()
            .then(resp => {
                setProductTypes(resp)
            })
            .catch(e => console.log(e))
    }
    function getProducts(){
        api.getProducts()
            .then(resp => {
                setProducts(resp)
            })
            .catch(e => console.log(e))
    }


    function getTab(){
        switch(tab) {
            case "Family" :
                return <FamilyTab families={families} jobTypes={jobTypes} updateList={getFamilies}/>
            case "PreciserFamily" :
                return <PreciserFamilyTab preciserFamilies={preciserFamilies} families={families} jtList={jobTypes} updateList={getPreciserFamilies}  />
            case "ProductType" :
                return <ProductTypeTab preciserFamilies={preciserFamilies} jtList={jobTypes} updateList={getProductTypes} productTypes={productTypes} />
            case "Product" :
                return <ProductTab preciserFamilies={preciserFamilies} jtList={jobTypes} updateList={getProducts} products={products} />
            default :
                return <FamilyTab families={families}/>
        }
    }


    return (
        <div className={"primary-section flex-column catalog-admin"}>
            <div className={"flex-row"}>
                <div className={"as-link"} onClick={()=>setTab("Family")} style={{textDecoration: tab==="Family" ? "underline 0.15em":""}}>Editer les familles</div>
                <div className={"as-link"} onClick={()=>setTab("PreciserFamily")}  style={{textDecoration: tab==="PreciserFamily" ? "underline 0.15em":""}}>Editer les sous familles</div>
                <div className={"as-link"} onClick={()=>setTab("ProductType")}  style={{textDecoration: tab==="ProductType" ? "underline 0.15em":""}}>Editer les types de produits</div>
                <div className={"as-link"} onClick={()=>setTab("Product")}  style={{textDecoration: tab==="Product" ? "underline 0.15em":""}}>Editer les produits</div>
            </div>
            {getTab()}
        </div>
    )
}