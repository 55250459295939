import "react-chat-elements/dist/main.css";
import "../style/Messenger.css";
import {ChatList} from "react-chat-elements";
import React, {useEffect, useState} from "react";
import IbatApi from "../utils/IbatApi";
import ChatWindow from "./ChatWindow";

export default function Messenger({lastNotification}){
    const api=new IbatApi()
    const [chatList, setChatList] = useState([])
    const [chatListOpen, setChatListOpen] = useState(false)
    const [openedChat, setOpenedChat] = useState([])
    const myName = api.getTokenData()?.username

    useEffect(()=>{
        getChatList()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lastNotification])

    useEffect(()=>{
        if (lastNotification && lastNotification?.from?.name !== myName){
            let targetChat = chatList.filter(c => c.id === lastNotification.target.replace("msg/", ""))
            if (targetChat.length > 0){
                addOpenedChat(targetChat[0], true)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chatList])

    function getChatList(){
        api.getAllChats()
            .then(resp=>{
                if (!resp) {
                    return
                }
                let newChatList = resp?.map(chat => {
                    return {
                        ...chat,
                        title: chat.name,
                        subtitle: chat.message,
                        date: new Date(chat.emissionDate),
                        unread: chat.unread,
                        needAttention: chat.unread>0
                    }
                })
                setChatList(newChatList)
            })
            .catch(err => console.log(err.message))
    }


    function updateUnReadMessage(chat){
        let targetChat = chatList.filter(c => c.id === chat.id)[0]
        if (targetChat?.unread === 0){
            return
        }
        api.updateUnReadMessage(chat.id)
            .then(resp =>
                setChatList(chatList.map(c =>
                    c.id === chat.id ?
                        {...chat,
                            title: chat.name,
                            subtitle: c.message,
                            date: new Date(chat.emissionDate),
                            unread: 0,
                            needAttention: false
                        } :
                        c
                ))
            )
    }

    function addOpenedChat(chat, auto){
        let targetChat = openedChat.filter(c=>c.id === chat?.id)
        if (!auto){
            updateUnReadMessage(chat)
        }
        if (targetChat.length>0 && auto){
            updateChatField(chat.id, "opened", targetChat[0].opened?? false)
            return
        }
        if (targetChat.length>0 && !auto){
            updateChatField(chat.id, "opened", true)
            return
        }
        setOpenedChat(openedChat=> [...openedChat.filter(c=>c.id !== "new"), {...chat, opened: chat?.opened ?? false, name: chat.title}])
    }
    function closeChatWindow(chatId){
        setOpenedChat(openedChat.filter(c=>c.id !== chatId))
    }
    function updateChatField(chatId, field, value){
        if (openedChat.filter(c=>c.id === chatId).length === 0){
            return
        }
        setOpenedChat(openedChat.filter(c=>c.id !== "new").map(c => c.id === chatId ? {...c, [field]:value} : c))
    }

    function newChat(chatId, chat){
        addOpenedChat({...chat, title: chat.name, opened: chat.opened ?? false}, true)
    }

    return(
        <div className={"chatWidget"}>
            <div className={`chatListContainer ${!chatListOpen ? "minimized" : ""}`}>
                <div className={`chatListHeader ${!chatListOpen ? "minimized" : ""} ${chatList?.filter(chat => chat.needAttention).length > 0 ? "needAttention" : ""}`} onClick={()=>setChatListOpen(!chatListOpen)}>
                    💬
                    <div className={`fold`}/>
                </div>
                {chatListOpen &&
                    <div>
                        <div className={"newMessageBtn"} onClick={()=>addOpenedChat({id: "new", opened: true, title: "Nouveau Message"})}>+</div>
                        <ChatList
                        className='chat-list'
                        dataSource=
                            {
                                chatList
                            }
                        onClick={(chat)=>addOpenedChat({...chat, opened: true})}/>
                    </div>
                }
            </div>

            <div className={"openedChat"}>
                {openedChat.map(chat => <ChatWindow newChat={newChat} needAttention={chatList.filter(c => c.id === chat.id)[0]?.needAttention} updateChatField={updateChatField} api={api} lastNotification={lastNotification} chat={chat} closeChatWindow={closeChatWindow} myName={myName} updateUnReadMessage={updateUnReadMessage}/>)}
            </div>
        </div>
    )
}

