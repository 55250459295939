import React, {Fragment, useEffect, useState} from "react";
import IbatApi from "../utils/IbatApi";
import {constants} from "../components/util/constants";
import {v4} from "uuid";

export default function StyleSelector({onSelect}){
  const api=new IbatApi();
  const[style]=useState(api.getStyleCookie())
  const optionRA=[
    {label:constants.default_style_label,value:constants.default_style},
    {label:constants.light_style_label,value:constants.light_style},
    {label:constants.dark_style_label,value:constants.dark_style}
  ]
  useEffect(()=>{
  },[])

  return <Fragment>
      <select disabled={style?.includes("custom")} value={style} onChange={e=>onSelect(e.target.value)}>
          {optionRA?.map(opt=>
              <option key={v4()} value={opt.value}>{opt.label}</option>
          )}
      </select>
  </Fragment>
}


