import React, {useState} from "react";
import {useSnackbar} from "notistack";
import IbatApi from "../../../utils/IbatApi";
import Modal from "react-responsive-modal";
import {IconButton} from "@mui/material";

export default function NomenclatureOperationType({families, preciserFamilies, productTypes, updateList, elementTypeList, operationTypeList}) {
    const [selectedOt, setSelectedOt] = useState()
    const [addNomenclatureElement, setAddNomenclatureElement] = useState(false)
    const [importNomenclatureElement, setImportNomenclatureElement] = useState(false)
    const {enqueueSnackbar}=useSnackbar()

    const api = new IbatApi()


    function mapReference(ref) {
        if (ref?.includes("complements_")){
            let splittedRef = ref.split("_")[1]
            let newRef = elementTypeList.find(et => et.id === splittedRef)
            let imported = ""
            if (!newRef.nomenclature){
                return 'Import vide'
            }
            for (const ref in newRef.nomenclature) {
                imported += `\t${mapReference(newRef.nomenclature[ref])}\n`
            }
            return `Importé de l'élément "${newRef.name}" :\n${imported}`
        }
        let splittedRef = ref.match(/.{1,4}/g)
        let a = families.find(f => f.reference === splittedRef[0])?.name
        let b = preciserFamilies.find(f => f.reference === splittedRef[1])?.name
        let c = productTypes.find(f => f.reference === splittedRef[2])?.name

        return `${a ?? "?"} > ${b ?? "?"} > ${c ?? "?"}`
    }

    function removeNomenclatureElement(value, type){
        let currentNomenclature = selectedOt?.nomenclature
        currentNomenclature[type] = currentNomenclature[type].filter( n => n !== value)
        setSelectedOt({...selectedOt, nomenclature: currentNomenclature})

    }

    const addElementToNomenclature = () => {
        let currentNomenclature = selectedOt.nomenclature
        if (!currentNomenclature[addNomenclatureElement.type]) {
            currentNomenclature[addNomenclatureElement.type] = []
        }
        let ref = `${addNomenclatureElement.family.ref}${addNomenclatureElement.preciserFamily.ref}${addNomenclatureElement.productType.ref}`
        if (currentNomenclature[addNomenclatureElement.type].includes(ref)){
            enqueueSnackbar('Cet élément est déjà présent', {variant: "error"})
            return
        }
        currentNomenclature[addNomenclatureElement.type].push(ref)
        setSelectedOt({...selectedOt, nomenclature: currentNomenclature})
        setAddNomenclatureElement(false)
    }

    const handleChange = (e) => {
        let currentNomenclature = selectedOt.nomenclature
        currentNomenclature[e.target.name] = e.target.checked
        setSelectedOt({...selectedOt, nomenclature: currentNomenclature})
    }

    const importNomenclature = () => {
        let toImport = importNomenclatureElement.imported.map(i => `complements_${i.id}`)
        let currentNomenclature = selectedOt.nomenclature ?? []
        for (const element in toImport) {
            if (currentNomenclature.includes(toImport[element])){
                enqueueSnackbar(`La nomenclature de "${element}" est déjà présente`, {variant: "error"})
            }
            else {
                currentNomenclature.push(toImport[element])
            }
        }
        setSelectedOt({...selectedOt, nomenclature: currentNomenclature})
        setImportNomenclatureElement(false)
    }

    const saveNomenclature = () => {
        api.saveOt(selectedOt)
            .then(() => enqueueSnackbar('Enregistré', {variant: "success"}))
            .catch(e => {
                console.log(e)
                enqueueSnackbar('Une erreur est survenue', {variant: "error"})
            })
    }

    function editNomenclature() {
        return (
            <div className={"catalog-content flex-column"}>
                <h2>{selectedOt.name}</h2>
                <div className={"flex-row"}>
                    <div className={"secondary-section flex-column"}>
                        <h3>Element obligatoire</h3>
                        <ul>
                            {
                                selectedOt?.nomenclature?.mandatory?.map(k =>
                                    <li>
                                        <div>
                                            <IconButton className={"action-btn cancel"}
                                                        onClick={() => removeNomenclatureElement(k, "mandatory")}>
                                                X
                                            </IconButton> {mapReference(k)}
                                        </div>
                                    </li>
                                )
                            }
                            <li>

                                <IconButton className={"action-btn validation"}
                                            onClick={() => setAddNomenclatureElement({type: "mandatory"})}>
                                    +
                                </IconButton>
                            </li>
                        </ul>
                    </div>
                    <div className={"secondary-section flex-column"}>
                        <h3>Element optionnels</h3>
                        <ul>
                            {
                                selectedOt?.nomenclature?.optional?.map(k =>
                                    <li>
                                        <div>
                                            <IconButton className={"action-btn cancel"}
                                                        onClick={() => removeNomenclatureElement(k, "optional")}>
                                                X
                                            </IconButton> {mapReference(k)}
                                        </div>
                                    </li>
                                )
                            }
                            <li>

                                <IconButton className={"action-btn validation"}
                                            onClick={() => setAddNomenclatureElement({type: "optional"})}>
                                    +
                                </IconButton>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className={"flex-row"}>
                    <input checked={selectedOt?.nomenclature?.extendElementType ?? false} type={"checkbox"} name={"extendElementType"} onChange={handleChange}/>
                    Cette opération hérite de la nomenclature du type d'élément
                </div>

                <div className={"flex-row"}>
                    <IconButton className={"action-btn cancel"}
                                onClick={() => setSelectedOt()}>Annuler</IconButton>
                    <IconButton className={"action-btn validation"}
                                onClick={saveNomenclature}>Enregistrer</IconButton>
                </div>
            </div>
        )
    }

    return (
        <div className={"family-tab br05 flex-row"} style={{whiteSpace: "pre-wrap"}}>
            <ul>
                {operationTypeList?.sort((a, b) => a.name > b.name ? 1 : -1)?.map(ot =>
                    <li>
                        <div className={"as-link"} onClick={() => {
                            setSelectedOt(ot)
                            // setNewFamilyObject(et)
                        }}>{ot.name}</div>
                    </li>
                )}
            </ul>
            {
                selectedOt && editNomenclature()
            }

            <Modal open={addNomenclatureElement} onClose={() => setAddNomenclatureElement(false)}>
                <div className={"flex-column"}>
                    <h1>{`${addNomenclatureElement?.family?.name ?? "Famille"} > ${addNomenclatureElement?.preciserFamily?.name ?? "Sous-famille"} > ${addNomenclatureElement?.productType?.name ?? "Type de produit"}`}</h1>
                    <div className={"primary-section flex-row"}>
                        <ul style={{maxHeight: '75vh', overflow: "auto"}}>
                            {
                                !addNomenclatureElement?.family?.name &&
                                families.map(f =>
                                    <li onClick={() => setAddNomenclatureElement({
                                        ...addNomenclatureElement,
                                        family: {id: f.id, ref: f.reference, name: f.name},
                                        preciserFamily: null,
                                        productType: null
                                    })}>
                                        <div className={"as-link"}
                                             style={{textDecoration: addNomenclatureElement.family?.id === f.id ? "underline 0.15em" : ""}}>{f.name}</div>
                                    </li>
                                )
                            }
                        </ul>

                        {
                            !addNomenclatureElement?.preciserFamily?.name && addNomenclatureElement.family &&
                            <ul style={{maxHeight: '75vh', overflow: "auto"}}>
                                {
                                    preciserFamilies?.filter(pf => pf.family === addNomenclatureElement.family.id)?.map(pf =>
                                        <li onClick={() => setAddNomenclatureElement({
                                            ...addNomenclatureElement,
                                            preciserFamily: {
                                                id: pf.id,
                                                ref: pf.reference,
                                                name: pf.name
                                            },
                                            productType: null
                                        })}>
                                            <div className={"as-link"}
                                                 style={{textDecoration: addNomenclatureElement.preciserFamily?.id === pf.id ? "underline 0.15em":""}}>{pf.name}</div>
                                        </li>
                                    )
                                }
                            </ul>
                        }

                        {
                            addNomenclatureElement.preciserFamily &&
                            <ul style={{maxHeight: '75vh', overflow: "auto"}}>
                                {
                                    productTypes?.filter(pt => pt.preciserFamily === addNomenclatureElement.preciserFamily.id)?.map(pt =>
                                        <li onClick={()=> setAddNomenclatureElement({...addNomenclatureElement, productType: {id: pt.id, ref: pt.reference, name: pt.name}})}>
                                            <div className={"as-link"} style={{textDecoration: addNomenclatureElement.productType?.id===pt.id ? "underline 0.15em":""}}>{pt.name}</div>
                                        </li>
                                    )
                                }
                            </ul>
                        }
                    </div>
                    <div className={"flex-row"}>
                        <IconButton className={"action-btn cancel"} onClick={()=>setAddNomenclatureElement({})}>Annuler</IconButton>
                        {
                            (addNomenclatureElement?.family && addNomenclatureElement?.preciserFamily && addNomenclatureElement?.productType) &&
                            <IconButton className={"action-btn validation"} onClick={addElementToNomenclature}>Ajouter</IconButton>
                        }
                    </div>
                </div>
            </Modal>

            <Modal open={importNomenclatureElement} onClose={()=> setImportNomenclatureElement(false)}>
                <div className={"flex-row"}  style={{whiteSpace: "pre-wrap"}}>
                    <ul>
                        {elementTypeList?.map(et =>
                            <li>
                                <div className={"as-link"} onClick={()=>{
                                    let imported =  importNomenclatureElement.imported ?? []
                                    if (imported.filter(i => i.name === et.name).length === 0) {
                                        imported.push({name: et.name, id: et.id, nomenclature: et.nomenclature ?? []})
                                        setImportNomenclatureElement({...importNomenclatureElement, imported: imported})
                                    }
                                }}>{et.name}</div>
                            </li>
                        )}
                    </ul>
                    {
                        importNomenclatureElement.imported &&
                        <ul>
                            {importNomenclatureElement.imported.map(i =>
                                <li>
                                    <b>{i.name}</b>
                                    {i.nomenclature.map(n =>
                                        `\n${mapReference(n)}`
                                    )}
                                </li>
                            )}
                        </ul>
                    }
                </div>
                <div className={"flex-row"}>
                    <IconButton className={"action-btn cancel"} onClick={()=>setAddNomenclatureElement(false)}>Annuler</IconButton>
                    <IconButton className={"action-btn validation"} onClick={importNomenclature}>Enregistrer</IconButton>
                </div>
            </Modal>
        </div>
    )
}