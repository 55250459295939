import TimeEstimationIcon from "../../images/est-time.png";
import {text} from "./constants";
import {IconButton, Tooltip} from "@mui/material";
import React, {useState} from "react";


export default function TimeSelector ({onSave}) {

    const [time,setTime] =useState({month:0,week:0,day:0,hour:0,minute:0});

    function handleTime(e) {
        let value = parseInt(e.target.value);
        let name = e.target.name;
        setTime({...time,[name]:value});
    }

    function handleSave() {
        let days = (time.month * 20) + (time.week * 5)
        let hour = time.hour + ((days + time.day) * 7)
        let minutes = hour*60 + time.minute
        let effTime = minutes * 60
        onSave(effTime*1000)
    }
    return (
        <div className={"editIntervention flex-column"}>
            <img alt={""} src={TimeEstimationIcon}/>
            <div className={"primary-section"}>
                <h2>{text.DEFINE_ESTIMATED_TIME}</h2>
                <div className={"flex-column"}>
                    <div className={"secondary-section"}>
                        <Tooltip title={text.TOOLTIP_TWENTYDAYS_ISAMONTH}>
                            <label htmlFor={"month"}>{text.MONTH}</label>
                        </Tooltip>
                        <input type={"number"} name={"month"} id={"month"} placeholder={"Mois"} value={time.month}
                               onChange={handleTime}/>
                    </div>
                    <div className={"secondary-section"}>
                        <Tooltip title={text.TOOLTIP_ONEWEEK_ISFIVEDAYS}>
                            <label htmlFor={"week"}>{text.WEEKS}</label>
                        </Tooltip>
                        <input type={"number"} name={"week"} id={"week"} placeholder={text.WEEKS} value={time.week}
                               onChange={handleTime}/>
                    </div>
                    <div className={"secondary-section"}>
                        <Tooltip title={text.TOOLTIP_ONEDAY_ISSEVENHOURS}>
                            <label htmlFor={"day"}>{text.DAYS}</label>
                        </Tooltip>
                        <input type={"number"} name={"day"}
                               id={"day"} placeholder={text.DAYS} value={time.day} onChange={handleTime}/>
                    </div>
                    <div className={"secondary-section"}>
                        <label htmlFor={"hour"}>{text.HOURS}</label>
                        <input type={"number"} name={"hour"} id={"hour"} placeholder={text.HOURS} value={time.hour}
                               onChange={handleTime}/>
                    </div>
                    <div className={"secondary-section"}>
                        <label htmlFor={"minute"}>{text.MINUTES}</label>
                        <input type={"number"} name={"minute"} id={"minute"} placeholder={text.MINUTES}
                               value={time.minute} onChange={handleTime}/>
                    </div>
                </div>
            </div>
            <IconButton className={"action-btn validation"} onClick={handleSave}>{text.SAVE}</IconButton>
        </div>
    )
}