import React, {useState} from "react";
import IbatApi from "../utils/IbatApi";

const api =new IbatApi()
export default function AudioComponents({filename}){
    const [audio,setAudio]=useState()
    api.getAudio(filename).then(a=>setAudio(a))
    return(
        <audio controls src={audio} >
        </audio>
    )
}