import React, {Fragment, useEffect, useRef} from "react";
import {useDrop} from "react-dnd";
import StepFlat2 from "./StepFlat";
import constants from "../components/util/constants";
import {timeToDate} from "../helpers/timeToDate";


function Container({date,containerSize,onRemove,time,moveStep,onHover,onTimeChange,isScrollActive,eventList, hovered, handleAddPin, resetEvent, removeEventFromList}){

    function isMoment(event) {
        return event.start>=date
            && event.start<(date+15*constants.M1);
    }

    function intersect(sp){
        return eventList?.filter(intv=>(sp.start>=intv.start && sp.endEvent<=intv.endEvent) || (sp.start<=intv.start && sp.endEvent>=intv.endEvent))
    }


    function handleDrop(data, date) {
            moveStep(data.id, date)
    }

    function dragElement(id){
        return eventList
    }

    const myref=useRef(null)
    const [{isOver}, drop] = useDrop(() => (
        {
            accept:["event"],
            drop: (d,monitor) => (handleDrop(d,date)),
            collect: monitor => ({isOver:monitor.isOver()})
        }),[eventList])

    useEffect(()=>{
        if(time===6*constants.H1 && isScrollActive) {
            myref.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }
    },[isScrollActive, time])
    return (
        <div ref={myref} className={`agenda-line${isScrollActive ? " show-time" : ""}`}>
            <div
                className={`${isOver?"hoverStepFlat":""}${isScrollActive ? " show-time" : ""}`}
                ref={drop}
                name={timeToDate(time)}
                style={{height:containerSize}}>
                {eventList
                    ?.filter(intv=>isMoment(intv))
                    .map((sp, idx)=><StepFlat2 key={sp.id}
                                       onTimeChange={onTimeChange}
                                       containerSize={containerSize}
                                       event={sp}
                                       time={date}
                                       resetEvent={resetEvent}
                                       pin={sp.intervention}
                                       onRemove={onRemove}
                                       handleAddPin={handleAddPin}
                                       onHover={onHover}
                                       hovered={hovered}
                                       left={intersect(sp).indexOf(sp)*(100/intersect(sp)?.length)}
                                       width={100/intersect(sp)?.length}
                                       removeEventFromList={removeEventFromList}
                                       onDrag={e=>dragElement(e)}/>)}
            </div>
        </div>
    )

}


export default function StepContainer({eventList,onTimeChange,isScrollActive,active,onRemove,beginDate,onClick,onHover,moveStep, hovered, handleAddPin, resetEvent, removeEventFromList}) {
    return (
        <>
                <div
                    className={`step-container${active?" selected":""}`}
                    onClick={onClick}>

                    {Array.from({length:96},(v,k)=>k*15*constants.M1)
                        .map((k)=><Container key={k}
                                           isScrollActive={isScrollActive}
                                           onRemove={onRemove}
                                           containerSize={10}
                                           onTimeChange={onTimeChange}
                                           eventList={eventList}
                                           onHover={onHover}
                                           resetEvent={resetEvent}
                                           handleAddPin={handleAddPin}
                                           hovered={hovered}
                                           removeEventFromList={removeEventFromList}
                                           date={parseInt(beginDate)+k}
                                           time={k} moveStep={moveStep}/>
                            )}

                </div>
        </>
    )

}

