import SearchTeam from "./SearchTeam";
import React, {Fragment, useEffect, useState} from "react";
import IbatApi from "../../utils/IbatApi";
import SearchSite from "./SearchSite";
import constants, {text} from "../util/constants";
import {isClassic, isSpecialIntervention} from "../../helpers/interventionManager";
import RiskSelector from "../../risk-selector/RiskSelector";
import {v4} from "uuid";

export default function CreateInterventionForm({onValid, children, onSiteChange, classic, site, supplierMode}) {

    const [jobTypeOfCompany, setJobTypeOfCompany] = useState()
    const [jobTypeList, setJobTypeList] = useState()

    const [types, setTypes] = useState()
    const [underTypes, setUnderTypes] = useState()
    const [secteur, setSecteur] = useState()
    const [state, setState] = useState({})


    let api = new IbatApi();

    useEffect(() => {
        if (supplierMode) {
            setState(prev => ({...prev, type: 8}))
            onValid({...state, type: 8})
            onSiteChange(site)
        }
        if (!jobTypeOfCompany) {
            api.getCompany().then(myCompany => {
                setJobTypeOfCompany(myCompany.jobType)
                if (myCompany.jobType?.length >= 1) {
                    setState(prev => ({...prev, jobTypeId: myCompany.jobType[0]}))
                    onValid(prev => ({...prev, jobTypeId: myCompany.jobType[0]}))
                }
            })
        }
        if (!jobTypeList) {
            api.getJobTypes().then(jt => {
                setJobTypeList(jt.map(jt => {
                        return {
                            label: jt.name,
                            value: jt.id
                        }
                    }
                ))
            })
        }
        api.getSecteur()
            .then(secteur =>{
                setSecteur(secteur)
                if (secteur.length >= 1){
                    setState(prev=> ({...prev, secteur: secteur[0].id}))
                    onValid({...state, secteur: secteur[0].id})
                }
            })
        api.getTypes().then(types => setTypes(types))
        api.getMyUnderTypes().then(types => {
            setUnderTypes(types?.map(ut => {
                return {label: ut.name, value: ut.id}
            }))
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function handleTeam(data) {
        setState({...state, team: data})
        onValid({...state, team: data})
    }

    function handleSupplyTeam(data) {
        setState({...state, teamSupply: data})
        onValid({...state, teamSupply: data})
    }

    function handleType(e) {
        setState({...state, type: e.target.value})
        onValid({...state, type: e.target.value})
    }

    function handleJobType(e) {
        setState({...state, jobTypeId: e.target.value})
        onValid({...state, jobTypeId: e.target.value})
    }

    function handleUnderType(e) {
        setState({...state, underType: e.target.value})
        onValid({...state, underType: e.target.value})
    }

    function handleRiskAnalysisLevel(level) {
        setState({...state, riskAnalysisLevel: level})
        onValid({...state, riskAnalysisLevel: level})
    }

    function handleSecteur(e) {
        setState({...state, secteur: e.target.value})
        onValid({...state, secteur: e.target.value})

    }

    function handleIntervention(e) {
        setState({...state, intervention: e.target.value})
        onValid({...state, intervention: e.target.value})
    }


    function handleEndIntervention(e) {
        let value = e.target.value * 3600 * 24 * 1000;
        setState({...state, dateEndIntervention: value})
        onValid({...state, dateEndIntervention: value})

    }

    function handleEstimatedDuration(e) {
        let [h, m] = e.target.value.split(":")
        let value = 3600 * h + 60 * m
        setState({...state, estimateDuration: value})
        onValid({...state, estimateDuration: value})
    }

    function handleAvailability(e) {
        setState({...state, availability: e.target.value})
        onValid({...state, availability: e.target.value})
    }

    return (
        <Fragment>
            <div className={"secondary-section"} title={text.FILE_TYPE}>
                <select value={supplierMode ? 8 : (state.type ?? "null")} required onChange={handleType}
                        className={"large w100"}>
                    <option value={"null"} disabled>{text.FILE_TYPE}</option>
                    {types && types.sort((t1, t2) => t1.name > t2.name ? 1 : -1).filter(t => !classic || isClassic(t.id)).filter(t => !supplierMode || +t.id === 8)
                        .map(t => <option key={v4()} value={t.id}>{t.name}</option>)}
                </select>
            </div>
            {!isSpecialIntervention(state.type) &&
                <div className={"secondary-section"} title={text.RISK_ANALYSIS_LEVEL}>
                    <RiskSelector onSelect={handleRiskAnalysisLevel} emergency={state.type === "2"}/>
                </div>
            }

            {(state.type === constants.INTERVENTION_CHANTIER && underTypes?.length > 0) &&
                <div className={"secondary-section"} title={text.UNDER_TYPE}>
                    <select value={state.underType ?? "null"} required onChange={handleUnderType}
                            className={"large w100"}>
                        <option value={null} disabled>{text.UNDER_TYPE}</option>
                        {/*{underTypes && underTypes?.sort((t1,t2)=>t1?.name>t2?.name?1:-1)?.filter(t=>!classic || isClassic(t?.id))*/}
                        {/*    ?.map(t => <option value={t}>{t}</option>)}*/}
                        {underTypes?.sort((a, b) => a?.label > b?.label ? 1 : -1)?.map(ut => <option key={v4()}
                                                                                                     value={ut.value}>{ut.label}</option>)}
                    </select>
                </div>}
            {(!isSpecialIntervention(state.type) && jobTypeOfCompany?.length > 1) &&
                <div className={"secondary-section"} title={"Corps de métier"}>
                    <select required onChange={handleJobType} value={state.jobTypeId ?? "null"}
                            className={"large w100"}>
                        <option key={v4()} defaultValue="" disabled value={null}>Corps de métier</option>
                        {jobTypeList.filter(jt => jobTypeOfCompany.includes(jt.value))?.sort((a, b) => a?.label > b?.label ? 1 : -1)?.map(ut =>
                            <option key={v4()} value={ut.value}>{ut.label}</option>)}
                    </select>
                </div>}


            {!isSpecialIntervention(state.type) && children}

            {!classic && onSiteChange &&
                <div title={text.SITE} className={"secondary-section commande-search"}>
                    <SearchSite onSave={onSiteChange} preSelectionnedSite={{...site}}/>
                </div>}

            {
                !isSpecialIntervention(state.type) &&
                <div className={"secondary-section"} title={text.AVAILABLE_FROM}>
                    <input className={"large w100"} type={"date"} onChange={handleAvailability}/>
                </div>
            }

            {(state.type) && secteur && secteur?.length > 1 &&
                <div className={"secondary-section"} title={text.ZONE}>
                    <select className={"large w100"} required onChange={handleSecteur} value={state.secteur ?? "null"}>
                        <option key={v4()} defaultValue="" disabled value={"null"}>{text.ZONE}</option>
                        {secteur?.map(s => <option key={v4()} value={s.id}>{s.name}</option>)}
                    </select>
                </div>}

            {
                (state.secteur || +state.type === 8) &&
                <div title={text.TEAM} className={"secondary-section"}>
                    <SearchTeam jobType={state.jobTypeId} onSave={handleTeam}
                                value={state.team ? state.team : {id: "", name: ""}}/>
                </div>
            }

            {(state.team || +state.type === 8) &&
                <div className={"secondary-section"} title={text.INTERVENTION_START}>
                    <input className={"large w100"} type={"datetime-local"} onChange={handleIntervention}/>
                </div>}


            {(state.intervention && (+state.type === 6 || +state.type === 129956)) &&
                <div className={"secondary-section"} title={text.DURATION}>
                    <input className={"large w100"} type={"time"} onChange={handleEstimatedDuration}/>
                </div>
            }

            {(state.estimateDuration) &&
                <div className={"secondary-section"} title={text.SUPPLY_FOR}>
                    <input className={"large w100"} type={"number"} onChange={handleEndIntervention}/> {text.DAYS}
                </div>}

            {
                (state.estimateDuration && +state.type === 129956) &&
                <div className={"secondary-section"} title={text.TEAM_SUPPLY}>
                    <SearchTeam jobType={state.jobTypeId} onSave={handleSupplyTeam}
                                value={state.teamSupply ? state.teamSupply : {id: "", name: ""}}/>
                </div>
            }
        </Fragment>
    )
}
