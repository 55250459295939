import {useSnackbar} from "notistack";
import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import IbatApi from "../utils/IbatApi";
import constants, {text} from "../components/util/constants";
import {EditText, EditTextarea} from "react-edit-text";
import {IconButton, Tooltip, Stepper, Step, StepLabel} from "@mui/material";
import CommentIcon from "../images/comment.png";
import CancelInterventionIcon from "../images/cancel-interv-icon.png";
import QuotationForm from "./QuotationForm";
import Modal from "react-responsive-modal";
import PenIcon from "../images/pen-edit-icon.png";
import ClientForm from "../components/forms/ClientForm";
import SearchClient from "../components/forms/SearchClient";
import {DocumentLoaderList} from "../components/util/DocumentLoader";
import ApproIcon from "../images/supply-icon.png";
import EditSupply from "../components/forms/EditSupply";
import InterventionForm from "../components/forms/InterventionForm";
import IconMap from "../images/button-map.png";
import Layer from "../layer/Layer";
import {v4} from "uuid";
import {useMediaQuery} from "react-responsive";

export default function Quotations() {

    let {id} = useParams()
    const {enqueueSnackbar}=useSnackbar()
    const [devis, setDevis] = useState({})
    const [quotation, setQuotation] = useState(false)
    const [status, setStatus] = useState([])
    const [interventionId, setInterventionId] = useState([])
    const [editBillingEntity, setEditBillingEntity] = useState(false)
    const [updateClient, setUpdateClient] = useState(false)
    const [updateSiteMail, setUpdateSiteMail] = useState(false)
    const [searchEntity, setSearchEntity] = useState(false)
    const [approModal, setApproModal] = useState(false)
    const [supply, setSupply] = useState({})
    const [provisionalWork, setProvisionalWork] = useState([])
    const [jobRepair, setJobRepair] = useState([])
    const [points, setPoints] = useState(false)
    const api = new IbatApi();
    const isMobile = useMediaQuery({ query: '(max-width: 915px)' })

    useEffect(() => {
        if(!quotation){
            getQuotation()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    function getQuotation() {
        handleSupply().then(r => setSupply(r))
        api.getQuotationStatus().then(s=>{
            setStatus(s)
        })
            .catch(() => {
                enqueueSnackbar(text.SNACKBAR_ERROR, {variant: "error"})
            })

        api.getQuotation(id)
            .then(res => {
                setDevis({...devis, ...res})
                setInterventionId(res.interventions.filter(i=> i.quotations.filter(q => q.id === +id).length > 0)[0].id)
                let q = res.interventions.flatMap(i => i.quotations).find(quotation => quotation.id === +id)
                q = {...q, billEntity: q.billEntity ?? res.client}
                setQuotation(q)
            })
            .catch(() => {
                enqueueSnackbar(text.SNACKBAR_ERROR, {variant: "error"})
            })

        api.getPoiTypes("provisionalWork")
            .then(r => setProvisionalWork(r))
            .catch(e => enqueueSnackbar(text.SNACKBAR_ERROR,{variant:"error"}))

        api.getPoiTypes("jobRepair")
            .then(r => setJobRepair(r))
            .catch(e => enqueueSnackbar(text.SNACKBAR_ERROR,{variant:"error"}))
    }

    function handleSupply(){
        return api.getQuotationSupply(id)
    }

    const processAmount = () => {
        let amount = quotation?.quotationGroup
            ?.flatMap(qg => qg.lineGroup)
            ?.flatMap(lg => lg.product)
            ?.map(p => Number.parseFloat(p.m_pvht ?? (p.pvht ?? 0))*Number.parseFloat(p.m_qty??(p.qty ?? 0)))
            ?.reduce((a, v) => a = a+v,0)
        return Number.parseFloat(amount)?.toFixed(2)
    }
    const getBeautifulAmount = (value) => {
        let val = value ?? processAmount()
        val =  new Intl.NumberFormat('fr-FR')?.format(val)
        return val
    }
    
    function handleDevisName(e) {
        setQuotation({...quotation, name: e.target.value})
    }
    function saveDevisName()  {
        api.updateQuotationName(quotation.id, encodeURIComponent(quotation.name))
            .then(() => {
                getQuotation()
                enqueueSnackbar('Enregistré', {variant: "success"})
            })
            .catch(e => {
                console.log(e)
                enqueueSnackbar('Une erreur est survenue', {variant: "error"})
            })
    }
    function saveHourlyRate()  {
        api.updateQuotationHourlyRate(quotation.id, quotation.hourlyRate ?? 0)
            .then(() => {
                getQuotation()
                enqueueSnackbar('Enregistré', {variant: "success"})
            })
            .catch(e => {
                console.log(e)
                enqueueSnackbar('Une erreur est survenue', {variant: "error"})
            })
    }

    function handleCommentaireChange(e) {
        setQuotation({...quotation,comment:e.target.value})
    }
    function handleCommentaire() {
        api.updateQuotationComment(id,encodeURIComponent(quotation.comment))
            .then(() => enqueueSnackbar('Commentaire enregistré', {variant: "success"}))
            .catch(e => {
                console.log(e)
                enqueueSnackbar('Une erreur est survenue', {variant: "error"})
            })
    }

    const handleBillEntity = (d) => {
        api.getClientById(d.id)
            .then(r => {
                setSearchEntity(false)
                setQuotation({...quotation, billEntity: {...r}})
                setEditBillingEntity(r)
            })
            .catch (() => enqueueSnackbar('Une erreur est survenue', {variant: "error"}))
    }
    const updateBillEntity = () => {
        api.updateBillEntity(quotation.id, quotation.billEntity.id)
            .then(() => {
                setSearchEntity(false)
                setEditBillingEntity(false)
                enqueueSnackbar('Entité de facturation mise à jour', {variant: "success"})
                getQuotation()
            })
            .catch (e => {
                console.log(e)
                enqueueSnackbar('Une erreur est survenue', {variant: "error"})
            })
    }

    const handleSiteMail = (d) => {
        let site = {...devis.site, contactMail: d}
        setDevis({...devis, site: site})
    }
    const updateSiteContactMail = () => {
        api.updateSiteMail(devis?.site?.id ?? quotation.deliveryEntity?.id, devis.site.contactMail)
            .then(() => {
                setUpdateSiteMail(false)
                enqueueSnackbar('Email de contact mis à jour', {variant: "success"})
            })
            .catch (e => {
                console.log(e)
                enqueueSnackbar('Une erreur est survenue', {variant: "error"})
            })
    }

    const getWorkForceTime = () => {
        return quotation.quotationGroup
            .flatMap((qg) => qg.lineGroup)
            .flatMap((lg) => lg.product)
            .flatMap((p) => +p.m_workforceTime ?? (+p.workforceTime ?? 0))
            .reduce((acc, currentValue) => acc + currentValue, 0)
    }
    const getPAHT = () => {
        return quotation.quotationGroup
            .flatMap((qg) => qg.lineGroup)
            .flatMap((lg) => lg.product)
            .flatMap((p) => +p.m_paht ?? (+p.paht ?? 0))
            .reduce((acc, currentValue) => acc + currentValue, 0)
    }

    const handleStatus = (status) => {
        api.updateQuotationStatus(quotation.id, status)
            .then(() =>{
                enqueueSnackbar("Le statut du devis a été mis à jour", {variant: "success"})
                getQuotation()
                setQuotation({...quotation, status: status})
            })
            .catch (e => {
                enqueueSnackbar('Erreur', {variant: "error"})
                console.log(e)
            })
    }

    function handleSaveClient(d){
        setUpdateClient(false)
        if (editBillingEntity) {
            setEditBillingEntity(d)
        }
        if (d.newRequest) {
            return api.createClient(d).then(r => {
                setDevis({...devis, client: r})
                setQuotation({...quotation, billEntity: r})
            })
        } else {
            setDevis({...devis, client: d})
            setQuotation({...quotation, billEntity: d})
            return api.updateClient(d)
        }
    }

    function getMaterial(){
        return quotation?.quotationGroup
            ?.flatMap(qg => qg.lineGroup)
            ?.flatMap(lg =>  lg.product.map(p => {return {...p, pointId: lg.point?.id}}))
            ?.filter(f => !f.productName.includes("Main d'oeuvre"))
            .flatMap(p => {
                return {type:`${p.productName} ${p.variantValue ?? ""}`, quantity: p.m_qty ?? (p.qty ?? 0), price: 0, unitQuantity: p.m_qty ?? (p.qty ?? 0), unit: p.m_unit ?? p.unit, isReady: 0, id: v4(), pointId: p.pointId}
            } )
    }

    return (
        <div className={"page scrollable"}>
            <aside className={"criteria-bar"}>
                <div className={"margin-content"}>
                    <Tooltip title={text.TOOLTIP_DISPLAY_QUOTATION_MAP}>
                        <IconButton onClick={()=> setPoints(
                            {list: quotation?.quotationGroup?.flatMap(qg => qg.lineGroup)?.flatMap(p => p.point).filter(p => p !== null), addMode: false}
                        )
                        }><img alt={"img"} src={IconMap }/></IconButton>
                    </Tooltip>
                    <Tooltip title={text.TOOLTIP_SUPPLY}>
                        <IconButton onClick={()=>setApproModal(!approModal)}><img alt={""} src={ApproIcon}/></IconButton>
                    </Tooltip>
                    <InterventionForm
                        onSaveSucceed={()=>handleStatus(constants.STATUS_READY)}
                        className={"site-newIntervention"}
                        amount={quotation?.amountUntaxed ?? processAmount()}
                        site={devis.site}
                        client={quotation.billEntity ?? devis.client}
                        quotationNumber={quotation.odooRef}
                        supply={supply??[]}
                        material={getMaterial()}
                        quotationId={id}/>
                    <IconButton disabled={quotation.status === constants.STATUS_CLASSIFIED} onClick={()=>handleStatus(constants.STATUS_CLASSIFIED)}>
                        <img alt={""} src={CancelInterventionIcon}/>
                    </IconButton>
                </div>
            </aside>
            <div className={"flex-column devis-bean"}>

                {
                    isMobile ?
                        <Stepper className={"stepper w100"} activeStep={0} alternativeLabel>
                            <Step>
                                <StepLabel>{text.STATUS_TRADUCTION[quotation.status]}</StepLabel>
                            </Step>
                        </Stepper>
                        :
                        <Stepper className={"stepper w100"} activeStep={status.indexOf(quotation.status)} alternativeLabel>
                            {status.map((st) => (
                                <Step key={st}>
                                    <StepLabel>{text.STATUS_TRADUCTION[st]}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                }


                <div className={"references"}>
                    <Tooltip title={"Référence du devis"}>
                        <div>
                            <h1>{quotation.odooRef}</h1>
                        </div>
                    </Tooltip>
                    <Tooltip title={"Référence interne du devis"}>
                        <div>
                            <EditText className={"bord1 ref-edit"} onChange={(e)=> handleDevisName(e)}
                                      value={quotation.name ?? constants.NULL} onBlur={saveDevisName}/>
                        </div>
                    </Tooltip>
                </div>
                <div className={"documents"}>
                    {quotation.odooId &&
                        <DocumentLoaderList name={[quotation.odooId]} noHeader={true} type={"quotation"}/>}
                </div>
                <div className={"infos seondary-section w100"}>
                    <div className={"grid bord1 tabrow"}>
                        <div className={"tabcell"}>
                            {text.AMOUNT_UNTAXED}
                        </div>
                        <div className={"tabcell"}>
                            {quotation && (getBeautifulAmount(quotation.amountUntaxed))}€
                        </div>
                        <div className={"tabcell"}>
                            {text.DEPOSIT}
                        </div>
                        <div className={"tabcell"}>
                            {quotation.amount ?? constants.NULL}€
                        </div>
                    </div>
                    <div className={"grid bord1 tabrow"}>
                        <div className={"tabcell"}>
                            {text.WORK_FORCE_TIME}
                        </div>
                        <div className={"tabcell"}>
                            {quotation && (getWorkForceTime(quotation))} H
                        </div>
                        <div className={"tabcell"}>
                            {text.HOURLY_RATE}
                        </div>
                        <div className={"tabcell"}>
                            <input className={"bord1 tabinput"}
                                   onChange={(event) => setQuotation({...quotation, hourlyRate: event.target.value})}
                                   onBlur={() => saveHourlyRate()}
                                   value={quotation.hourlyRate ?? 0}
                            />
                            <span>€</span>
                        </div>
                    </div>
                    <div className={"grid bord1 tabrow"}>
                        <div className={"tabcell"}>
                            {text.WORKFORCE_PRICE}
                        </div>
                        <div className={"tabcell"}>
                            {quotation && (getWorkForceTime(quotation) * (quotation.hourlyRate ?? 0))} <span>€</span>
                        </div>
                        <Tooltip title={text.MARGIN_PRICE_TOOLTIP}>
                            <div className={"tabcell"}>
                                {text.MARGIN_PRICE}
                            </div>
                        </Tooltip>
                        <div className={"tabcell"}>
                            {quotation && getBeautifulAmount(quotation.amountUntaxed - getPAHT() - (getWorkForceTime(quotation) * (quotation.hourlyRate ?? 0)))}
                            <span>€</span>
                        </div>
                    </div>
                    <div className={"grid bord1 tabrow"}>
                        <div className={"tabcell"}>
                            {text.FACTURATION_ENTITY}
                        </div>
                        {
                            quotation?.billEntity &&
                            <div className={`as-link tabcell ${!quotation?.billEntity?.mail ? "alert" : ""}`}
                                 onClick={() => setEditBillingEntity(quotation.billEntity)}
                            >
                                {quotation.billEntity.name}
                            </div>
                        }

                        <div className={"tabcell"}>
                            {text.DELIVERY_ENTITY}
                        </div>
                        {
                            (devis.site || quotation.deliveryEntity) &&
                            (devis?.site?.contactMail || quotation?.deliveryEntity?.contactMail) ?
                                <a rel={"noreferrer"} className={`tabcell`} target={"_blank"}
                                   href={`/site/${devis?.site?.id ?? quotation?.deliveryEntity?.id}`}> {devis?.site?.name ?? quotation?.deliveryEntity?.name}</a>
                                :
                                <div className={`as-link tabcell alert`}
                                     onClick={() => setUpdateSiteMail(true)}>
                                    {devis?.site?.name ?? quotation.deliveryEntity?.name}
                                </div>
                        }
                    </div>
                    <div className={"grid bord1 tabrow"}>
                        <div className={"tabcell"}>{text.CREATION_DATE}</div>
                        <div
                            className={"tabcell"}>{quotation.createdAt && new Date(quotation.createdAt).toLocaleDateString()}</div>
                        <div className={"tabcell"}>{text.EXPIRATION_DATE}</div>
                        <div
                            className={"tabcell"}>{quotation.validityDate && new Date(quotation.validityDate).toLocaleDateString()}</div>
                    </div>
                </div>
                <div className={"comments primary-section"}>
                    <div className={"devis-comments-title flex-row"}>
                        <img alt={""} src={CommentIcon}/>{text.NOTES_AND_COMMENTS}
                    </div>
                    <EditTextarea inputClassName={"textedit large w100"} className={"textedit w100"} value={quotation.comment ?? ""}
                                  onChange={handleCommentaireChange} rows={15}
                                  onBlur={handleCommentaire}/>
                </div>
                <div className={"details w100"}>
                    {
                        quotation &&
                        <QuotationForm
                            handleStatus={handleStatus}
                            supply={supply}
                            mQuotation={quotation}
                            devisId={devis.id}
                            interventionId={interventionId}
                            update={(qg, title) => setQuotation({...quotation, quotationGroup: qg, clientTitle: title})}
                            refresh={() => getQuotation()}
                            site={devis.site}
                            setPoints={setPoints}
                        />}
                </div>

                <Modal open={points} onClose={() => setPoints(false)}>
                    <Layer points={points.list}
                           background={devis?.site?.imageUrl}
                           provisionalWork={provisionalWork}
                           jobRepair={jobRepair}
                           addMode={points.addMode}
                           onSave={points.onSave}
                           lineGroupId={points.lineGroupId}
                    />
                </Modal>
                <Modal open={editBillingEntity} onClose={() => setEditBillingEntity(false)}>
                    <div className={"flex-column"}>
                        <h2>Entité de facturation</h2>
                        <div className={"primary-section"}>

                            <div className={"flex-row"}>
                                <a rel={"noreferrer"} href={`/client/${editBillingEntity.id}`}
                                   target="_blank">{editBillingEntity.name}</a>
                                <IconButton onClick={() => setUpdateClient(editBillingEntity)}>
                                    <img alt={""} src={PenIcon}/>
                                </IconButton>
                            </div>

                            <div className={"flex-row"}>
                                <IconButton className={"action-btn neutral save"} onClick={() => setSearchEntity(true)}>Changer
                                    l'entité de facturation</IconButton>
                                <IconButton className={"action-btn neutral save"}
                                            onClick={() => setUpdateClient(quotation?.deliveryEntity ? {
                                                ...quotation.deliveryEntity,
                                                newRequest: true
                                            } : {...devis.site, newRequest: true})}>Convertir le site en
                                    client</IconButton>
                                <IconButton className={"action-btn validation"}
                                            onClick={updateBillEntity}>{text.SAVE}</IconButton>
                            </div>
                        </div>
                    </div>
                </Modal>
                <Modal open={updateClient} onClose={() => setUpdateClient(false)}>
                    <ClientForm {...updateClient} onSave={handleSaveClient} onCancel={() => setUpdateClient(false)}/>
                </Modal>
                <Modal open={searchEntity} onClose={() => setSearchEntity(false)}>
                    <div className={"form flex-column"}>
                        <h2>Sélectionnez une entité de facturation</h2>
                        <SearchClient onSave={(d) => handleBillEntity(d)}/><br/>

                        <IconButton className={"action-btn cancel"}
                                    onClick={() => setSearchEntity(false)}>{text.CANCEL}</IconButton>
                    </div>
                </Modal>
                <Modal open={updateSiteMail} onClose={() => setUpdateSiteMail(false)}>
                    <div className={"flex-column"}>
                        <h2>Ajouter une adresse mail au site</h2>
                        <div className={"secondary-section"}>
                            <input className={"large w100"} placeholder={"adresse@mail.fr"} type={"text"}
                                   onChange={(e) => handleSiteMail(e.target.value)}/>
                        </div>
                        <IconButton className={"action-btn validation"}
                                    onClick={updateSiteContactMail}>{text.SAVE}</IconButton>

                    </div>
                </Modal>

                <Modal open={approModal} onClose={() => setApproModal(false)}>
                    <EditSupply handleSupply={handleSupply} updateParentSupply={setSupply} title={`${quotation?.odooRef} - ${devis?.site?.name ?? quotation?.deliveryEntity?.name}`}/>
                </Modal>
            </div>
        </div>
    )
}