import CustomImage from "../util/CustomImage";
import NoImageIllust from "../../images/not-required-image.png";
import React from "react";

export default function Risk({id,answer,pictures,question, ibatApi}){
    return (
        <div className={"bord1 card"}>
            <span id={"question"}><b>{question}</b></span>
            <br/>

            {answer?.map(ans=><div id={"reponse"}>-{ans}</div>)}
            {
                pictures?.length > 0?
                    pictures?.map(p => <CustomImage ibatApi={ibatApi} imgClassName={"bord1 w100"} name={p}/> ):
                    <img className={"w100 bord1"} alt={""} src={NoImageIllust} />
            }
        </div>
    )
}