import React, {useEffect, useState} from "react";
import {Checkbox, IconButton, Switch} from "@mui/material";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {text} from "../../util/constants";
import IbatApi from "../../../utils/IbatApi";

export default function JobManager({getJobTypeNameList}){

    const [elementTypeList, setElementTypeList] = useState();
    const [operationTypeList, setOperationTypeList] = useState();
    const [elementStateList, setElementStateList] = useState();
    const [elementSuggestionList, setElementSuggestionList] = useState();

    const [etCreateMode, setEtCreateMode] = useState(false)
    const [etEditMode, setEtEditMode] = useState(false)
    const [etDelMode, setEtDelMode] = useState(false)
    const [etAddMandatoryOp, setEtAddMandatoryOp] = useState(false)
    const [etAddOptionalOp, setEtAddOptionalOp] = useState(false)
    const [underTypeAddEt, setUnderTypeAddEt] = useState(false)

    const [otCreateMode, setOtCreateMode] = useState(false)
    const [otEditMode, setOtEditMode] = useState(false)
    const [otDelMode, setOtDelMode] = useState(false)
    const [otAddState, setOtAddState] = useState(false)
    const [otAddSuggestion, setOtAddSuggestion] = useState(false)

    const [esCreateMode, setEsCreateMode] = useState(false)
    const [esEditMode, setEsEditMode] = useState(false)
    const [esDelMode, setEsDelMode] = useState(false)

    const [etSelected, setEtSelected] = useState(false)
    const [otSelected, setOtSelected] = useState(false)
    const [eSSelected, setESSelected] = useState(false)
    const [esFilter, setEsFilter] = useState(false)

    const [esListToShow, setEsListToShow] = useState("state")

    const api = new IbatApi()

    useEffect(() => {
        refreshElementType()
        refreshOperationType()
        refreshElementState()
        refreshElementSuggestion()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    function refresh(stuff){
        if (stuff === "elementType"){
            refreshElementType()
        }
        else if (stuff === "operationType"){
            refreshOperationType()
        }
        else if (stuff === "elementState"){
            refreshElementState()
        }
        else if (stuff === "elementSuggestion"){
            refreshElementSuggestion()
        }
    }

    function refreshElementType(){
        api.getElementType().then(type => setElementTypeList(type))
    }
    function refreshOperationType(){
        api.getOperationType().then(otList => setOperationTypeList(otList.sort((a, b)=> a.emergency_order > b.emergency_order ? 1 : -1)))
    }
    function refreshElementState(){
        api.getElementStateSuggestion("elementState").then(estate => setElementStateList(estate))
    }
    function refreshElementSuggestion(){
        api.getElementStateSuggestion("elementSuggestion").then(es => setElementSuggestionList(es))
    }

    function setAllModeToFalse(){
        setEtCreateMode(false)
        setEtEditMode(false)
        setEtDelMode(false)
        setEtAddMandatoryOp(false)
        setEtAddOptionalOp(false)
        setOtCreateMode(false)
        setOtEditMode(false)
        setOtDelMode(false)
        setOtAddState(false)
        setOtAddSuggestion(false)
        setEsCreateMode(false)
        setEsEditMode(false)
        setEsDelMode(false)
    }

    function saveEt(){
        api.saveEt(etSelected).then(resp => {
            if (!etSelected.id){
                setEtSelected({...etSelected, id: resp.id})
            }
            refresh("elementType")
        })
    }
    function saveOt(){
        api.saveOt(otSelected).then(resp => {
            if (!otSelected.id){
                setOtSelected({...otSelected, id: resp.id})
            }
            refresh("operationType")
        })
    }
    function saveEs(){
        api.saveEs(eSSelected).then(resp => {
            setESSelected(resp)
            refresh(eSSelected?.dataType??"elementState")
        })
    }

    function removeEt(id){
        api.removeEt(id).then(resp => {
            refresh("elementType")
            if (etSelected.id === id){
                setEtSelected(null)
            }
        })
    }
    function removeOt(id){
        api.removeOt(id).then(resp => {
            refresh("operationType")
            if (otSelected.id === id){
                setOtSelected(null)
            }
        })
    }
    function removeEs(id){
        api.removeEs(id).then(resp => {
            refresh(eSSelected?.dataType)
            if (eSSelected.id === id){
                setESSelected(null)
            }
        })
    }

    const handleEtChange = (ev) => {
        setEtSelected({...etSelected, [ev.target.id]: ev.target.value})
    }
    const handleOtChange = (ev) => {
        if(ev.target.type === "checkbox"){
            setOtSelected({...otSelected, [ev.target.id]: ev.target.checked})
        }
        else if(ev.target.type === "number"){
            setOtSelected({...otSelected, [ev.target.id]: Number(ev.target.value)})
        }
        else {
            setOtSelected({...otSelected, [ev.target.id]: ev.target.value})
        }
    }
    const handleEsChange = (ev) => {
        if(ev.target.type === "checkbox"){
            setESSelected({...eSSelected, [ev.target.id]:ev.target.checked? "elementSuggestion" : "elementState"})
        }
        else if(ev.target.type === "number"){
            setESSelected({...eSSelected, [ev.target.id]: Number(ev.target.value)})
        }
        else{
            setESSelected({...eSSelected, [ev.target.id]: ev.target.value})
        }
    }

    const changeEsList = (ev) => {
        setEsListToShow(ev.target.checked? "suggestion" : "state")
    }
    const handleEsFilter = (ev) => {
        setEsFilter(ev.target.value)
    }


    const onDragEndOt = (result): void => {
        let field = result.source.droppableId
        let from = result.source?.index
        let to = result.destination?.index
        let id = result.draggableId
        /*if (!etSelected.newMandatoryOperationType) {
          let nmop = etSelected?.mandatoryOperationType?.map((ot, idx) => {
            return {id: ot, position: idx}
          })
          setEtSelected({...etSelected, newMandatoryOperationType: nmop})
        }
        if (!etSelected.newOptionalOperationType) {
          let nmop = etSelected?.optionalOperationType?.map((ot, idx) => {
            return {id: ot, position: idx}
          })
          setEtSelected({...etSelected, newOptionalOperationType: nmop})
        }*/
        if (to !== from){
            etSelected[field].filter(op=>op.id === id)[0].position = -10
        }
        if (to > from){
            etSelected[field].map((ot, idx) => {
                if (idx > from && idx <= to){
                    ot.position --
                }
                return ot
            })
            etSelected[field].filter(ot=>ot.id === id)[0].position = to
        }
        if (to < from){
            etSelected[field].map((ot, idx) => {
                if (idx >= to && idx < from){
                    ot.position ++
                }
                return ot
            })
            etSelected[field].filter(ot=>ot.id === id)[0].position = to
        }
        etSelected[field].sort((a,b) => a.position > b.position ? 1 : -1)
        setEtSelected(etSelected)
    }
    const onDragEndEs = (result): void => {
        let field = result.source.droppableId
        let from = result.source?.index
        let to = result.destination?.index
        let id = result.draggableId

        if (to !== from){
            otSelected[field].filter(es=>es.id === id)[0].position = -10
        }
        if (to > from){
            otSelected[field].map((es, idx) => {
                if (idx > from && idx <= to){
                    es.position --
                }
                return es
            })
            otSelected[field].filter(es=>es.id === id)[0].position = to
        }
        if (to < from){
            otSelected[field].map((es, idx) => {
                if (idx >= to && idx < from){
                    es.position ++
                }
                return es
            })
            otSelected[field].filter(es=>es.id === id)[0].position = to
        }
        otSelected[field].sort((a,b) => a.position > b.position ? 1 : -1)
        setOtSelected(otSelected)
    }

    function delOt(id){
        setEtSelected({...etSelected,
            newMandatoryOperationType: etSelected?.newMandatoryOperationType?.filter(mo => mo.id !== id),
            newOptionalOperationType: etSelected?.newOptionalOperationType?.filter(oo => oo.id !== id)
        })
    }
    function delEs(id){
        setOtSelected({...otSelected,
            stateList: otSelected?.stateList?.filter(es => es.id !== id),
            suggestionList: otSelected?.suggestionList?.filter(es => es.id !== id)
        })
    }
    function delEtFromUnderType(id){
        setEtSelected({...etSelected,
            elementType: etSelected?.elementType?.filter(et => et !== id)
        })
    }

    function getElementTypeById(id){
        return elementTypeList.filter(ot => ot.id === id)[0] ?? null
    }
    function getOperationTypeById(id){
        return operationTypeList.filter(ot => ot.id === id)[0] ?? null
    }
    function getEsById(id){
        return elementStateList.filter(es => es.id === id)[0] ?? elementSuggestionList.filter(es => es.id === id)[0] ?? null
    }

    function showElementTypeSelected(){
        return (
            <div>
                {!etCreateMode && !etEditMode ?
                    <div>
                        <b>{etSelected?.name}</b><br/>
                        Id : <span className={"uuid"}>{etSelected?.id}</span><br/>
                        <span>JobType : <ul>{getJobTypeNameList(etSelected?.new_jobtype)?.map(jt => <li><b>{jt}</b></li>)}</ul></span>
                        <span>{etSelected?.company ? "Spécifique à une entreprise (" + etSelected.company + ")" : ""}</span>
                        <span>
              {etSelected?.elementType &&
                  <div>
                      Éléments concernés :
                      <ul>
                          {etSelected?.elementType?.map(et => <li><b>{showItemName(getElementTypeById(et), "etSelected")}</b></li>)}
                      </ul>
                  </div>
              }
            </span>

                        <span>Operations obligatoires : <ul>{etSelected?.newMandatoryOperationType?.map(mo => <li>
                            <b>{showItemName(getOperationTypeById(mo.id))}</b></li>)}</ul></span>
                        <span>Operations optionnelles : <ul>{etSelected?.newOptionalOperationType?.map(oo => <li>
                            <b>{showItemName(getOperationTypeById(oo.id))}</b></li>)}</ul></span>
                    </div> :
                    <div>
                        Id : <span className={"uuid"}>{etSelected?.id}</span><br/>
                        <input type={"text"} id={"name"} defaultValue={etSelected?.name} placeholder={"Nom du type d'élément"} onChange={handleEtChange}/><br/>
                        <span>JobType : <ul>{etSelected?.jobtype?.map(jt => <li><b>{jt}</b></li>)}</ul></span>
                        <div>
                            <span>Si spécifique à une entreprise, préciser son id :</span>
                            <input onChange={handleEtChange} type={"number"} id={"company"} value={etSelected?.company}/>
                        </div>

                        <span>
              {etSelected?.dataType === "intervention_underType" &&
                  <div>
                      Éléments concernés :
                      <ul>
                          {etSelected?.elementType?.map(et => <li><b>{showItemName(getElementTypeById(et), "etSelected")}</b></li>)}
                      </ul>
                      <span>
                  {!underTypeAddEt &&
                      <IconButton onClick={() => {
                          setUnderTypeAddEt(true)
                          setEtAddMandatoryOp(false)
                          setEtAddOptionalOp(false)
                      }} className={"action-btn validation"}>
                          Ajouter +
                      </IconButton>
                  }
                          {underTypeAddEt &&
                              <div>
                                  <IconButton onClick={() => {
                                      setUnderTypeAddEt(false)
                                  }} className={"action-btn cancel"}>
                                      Terminer
                                  </IconButton>
                              </div>
                          }
                </span>
                  </div>
              }
            </span>

                        <span>Operations mandatoires :
              <div className={"dnd"}>
                <DragDropContext onDragEnd={onDragEndOt}>
                  <Droppable droppableId="newMandatoryOperationType">
                    {(provided) => (
                        <ul  className="questionConfigDnd questionConfig" {...provided.droppableProps} ref={provided.innerRef}>
                            {etSelected?.newMandatoryOperationType?.map(ot => showDraggableItemName(ot, "etSelected"))}
                            {provided.placeholder}
                        </ul>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            </span>
                        <span>
              {!etAddMandatoryOp &&
                  <IconButton onClick={() => {
                      setEtAddMandatoryOp(true)
                      setEtAddOptionalOp(false)
                      setUnderTypeAddEt(false)
                  }} className={"action-btn validation"}>
                      Ajouter +
                  </IconButton>
              }
                            {etAddMandatoryOp &&
                                <div>
                                    <IconButton onClick={() => {
                                        setEtAddMandatoryOp(false)
                                    }} className={"action-btn cancel"}>
                                        Terminer
                                    </IconButton>
                                </div>
                            }
            </span>

                        <span>Operations optionnelles :
              <div className={"dnd"}>
                <DragDropContext onDragEnd={onDragEndOt}>
                  <Droppable droppableId="newOptionalOperationType">
                    {(provided) => (
                        <ul  className="questionConfigDnd questionConfig" {...provided.droppableProps} ref={provided.innerRef}>
                            {etSelected?.newOptionalOperationType?.map(ot => showDraggableItemName(ot, "etSelected"))}
                            {provided.placeholder}
                        </ul>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            </span>
                        <span>
              {!etAddOptionalOp &&
                  <IconButton onClick={() => {
                      setEtAddMandatoryOp(false)
                      setUnderTypeAddEt(false)
                      setEtAddOptionalOp(true)
                  }} className={"action-btn validation"}>
                      Ajouter +
                  </IconButton>
              }
                            {etAddOptionalOp &&
                                <div>
                                    <IconButton onClick={() => {
                                        setEtAddOptionalOp(false)
                                    }} className={"action-btn cancel"}>
                                        Terminer
                                    </IconButton>
                                </div>
                            }
            </span>
                    </div>
                }
            </div>

        )
    }
    function showOperationTypeSelected(){
        return (
            <div className={"elementTypeList"}>
                {!otCreateMode && !otEditMode ?
                    <div>
                        <b>{otSelected?.name}</b><br/>
                        Id : <span className={"uuid"}>{otSelected?.id}</span><br/>
                        <div>
                            <label htmlFor={"color"}>Couleur des POI :</label>
                            <input type={"color"} id={"color"} name={"color"} disabled={true} value={otSelected?.color ?? "#ffffff"}/>
                        </div>
                        {/*<span>JobType : <ul>{otSelected?.jobtype?.map(jt => <li><b>{jt}</b></li>)}</ul></span>*/}
                        <span>JobType : <ul>{getJobTypeNameList(otSelected?.new_jobtype)?.map(jt => <li><b>{jt}</b></li>)}</ul></span>
                        <span>{otSelected?.company ? "Spécifique à une entreprise (" + otSelected.company + ")" : ""}</span>

                        <span>Liste d'états possible : <ul>{otSelected?.stateList?.map(es => <li>
                            <b>{showItemName(getEsById(es.id), "otSelected")}</b></li>)}</ul></span>
                        <span>Liste des suggestions possible : <ul>{otSelected?.suggestionList?.map(es => <li>
                            <b>{showItemName(getEsById(es.id), "otSelected")}</b></li>)}</ul></span>

                        <span>{otSelected?.emergency_order ? "Ordre d'urgence : " + otSelected.emergency_order : ""}</span>
                        <span>{otSelected?.emergency_state ? "En urgence uniquement" : ""}</span>
                    </div> :
                    <div>

                        Id : <span className={"uuid"}>{otSelected?.id}</span><br/>
                        <div>
                            <span>Nom de l'opération :</span>
                            <input onChange={handleOtChange} type={"text"} id={"name"} value={otSelected?.name}/>
                        </div>
                        <div>
                            <label htmlFor={"color"}>Couleur des POI :</label>
                            <input onChange={handleOtChange} type={"color"} id={"color"} name={"color"} disabled={false} value={otSelected?.color ?? "#ffffff"}/>
                        </div>
                        <span>JobType : <ul>{otSelected?.jobtype?.map(jt => <li><b>{jt}</b></li>)}</ul></span>

                        <span>Liste d'états possible :
              <div className={"dnd"}>
                <DragDropContext onDragEnd={onDragEndEs}>
                  <Droppable droppableId="stateList">
                    {(provided) => (
                        <ul  className="questionConfigDnd questionConfig" {...provided.droppableProps} ref={provided.innerRef}>
                            {otSelected?.stateList?.map(es => showDraggableItemName(es, "otSelected"))}
                            {provided.placeholder}
                        </ul>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            </span>
                        <span>
              {!otAddState &&
                  <IconButton onClick={() => {
                      setOtAddState(true)
                      setOtAddSuggestion(false)
                  }} className={"action-btn validation"}>
                      Ajouter +
                  </IconButton>
              }
                            {otAddState &&
                                <div>
                                    <IconButton onClick={() => {
                                        setOtAddState(false)
                                    }} className={"action-btn cancel"}>
                                        Terminer
                                    </IconButton>
                                </div>
                            }
            </span>

                        <span>Liste des suggestions possible :
              <div className={"dnd"}>
                <DragDropContext onDragEnd={onDragEndEs}>
                  <Droppable droppableId="suggestionList">
                    {(provided) => (
                        <ul  className="questionConfigDnd questionConfig" {...provided.droppableProps} ref={provided.innerRef}>
                            {otSelected?.suggestionList?.map(es => showDraggableItemName(es, "otSelected"))}
                            {provided.placeholder}
                        </ul>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            </span>
                        <span>
              {!otAddSuggestion &&
                  <IconButton onClick={() => {
                      setOtAddSuggestion(true)
                      setOtAddState(false)
                  }} className={"action-btn validation"}>
                      Ajouter +
                  </IconButton>
              }
                            {otAddSuggestion &&
                                <div>
                                    <IconButton onClick={() => {
                                        setOtAddSuggestion(false)
                                    }} className={"action-btn cancel"}>
                                        Terminer
                                    </IconButton>
                                </div>
                            }
            </span>

                        <div>
                            <span>Si spécifique à une entreprise, préciser son id :</span>
                            <input onChange={handleOtChange} type={"number"} id={"company"} value={otSelected?.company}/>
                        </div>
                        <div>
                            <span>Ordre d'apparition (intervention d'urgence) :</span>
                            <input onChange={handleOtChange} type={"number"} id={"emergency_order"} value={otSelected?.emergency_order}/>
                        </div>
                        <span>En urgence uniquement <Checkbox onChange={handleOtChange} checked={otSelected?.emergency_state ?? false} id={"emergency_state"}/> </span>
                    </div>
                }
            </div>
        )
    }
    function showESSelected(){
        return (
            <div>
                {!esCreateMode && !esEditMode ?
                    <div>
                        <b>{eSSelected?.name}</b><br/>
                        Id : <span className={"uuid"}>{eSSelected?.id}</span><br/>
                        {/*<span>JobType : <ul>{eSSelected?.jobtype?.map(jt => <li><b>{jt}</b></li>)}</ul></span>*/}
                        <span>JobType : <ul>{getJobTypeNameList(eSSelected?.new_jobtype)?.map(jt => <li><b>{jt}</b></li>)}</ul></span>
                        Position : {eSSelected?.position}<br/>
                        Concerne l'opération "{getOperationTypeById(eSSelected?.operationType)?.name ?? "fake"+eSSelected?.operationType}"
                    </div> :
                    <div>
                        {/*Id : <span className={"uuid"}>{eSSelected?.id}</span><br />*/}
                        <div>
                            <span>Id :</span>
                            <input onChange={handleEsChange} type={"text"} id={"id"} value={eSSelected?.id}/>
                        </div>
                        <div>
                            <span>Nom de l'état/suggestion :</span>
                            <input onChange={handleEsChange} type={"text"} id={"name"} value={eSSelected?.name}/>
                        </div>
                        <span>JobType : <ul>{eSSelected?.jobtype?.map(jt => <li><b>{jt}</b></li>)}</ul></span>

                        <div>
                            État<Switch className={"switch"} checked={eSSelected?.dataType === "elementSuggestion"} id={"dataType"} onChange={handleEsChange} />Suggestion<br/>
                        </div>

                        {/*Position : {eSSelected?.position}<br />*/}
                        <div>
                            <span>OPType :</span>
                            <input onChange={handleEsChange} type={"text"} id={"operationType"} value={eSSelected?.operationType}/>
                        </div>
                        <div>
                            <span>Position :</span>
                            <input onChange={handleEsChange} type={"number"} id={"position"} value={eSSelected?.position}/>
                        </div>
                    </div>
                }
            </div>
        )
    }

    function showItemName(item, from){
        return (
            <div className={item?.dataType === "intervention_underType" ? "elementTypeItem underType" : "elementTypeItem"} >
                {from === "otList" && (otDelMode)?<b onClick={()=>removeOt(item.id)} className={"delete"}>X</b>:""}
                {from === "esList" && esDelMode && <b onClick={()=>removeEs(item.id)} className={"delete"}>X</b>}
                {from === "etList" && etDelMode && <b onClick={()=>removeEt(item.id)} className={"delete"}>X</b>}
                {from === "etSelected" && underTypeAddEt && <b onClick={()=>delEtFromUnderType(item.id)} className={"delete"}>X</b>}
                <b onClick={()=>{
                    if (etAddMandatoryOp && !etSelected?.newMandatoryOperationType?.map(mo=>mo.id)?.includes(item.id) && !etSelected?.newOptionalOperationType?.map(mo=>mo.id)?.includes(item.id)){
                        if (etSelected?.newMandatoryOperationType){
                            etSelected.newMandatoryOperationType.push({id:item.id, position:etSelected.newMandatoryOperationType.length})
                            setEtSelected({...etSelected})
                        } else {
                            setEtSelected({...etSelected, newMandatoryOperationType: [{id:item.id, position:0}]})
                        }
                    }
                    else if (etAddOptionalOp && !etSelected?.newOptionalOperationType?.map(oo=>oo.id)?.includes(item.id) && !etSelected?.newMandatoryOperationType?.map(oo=>oo.id)?.includes(item.id)){
                        if (etSelected?.newOptionalOperationType){
                            etSelected.newOptionalOperationType.push({id:item.id, position:etSelected.newOptionalOperationType.length})
                            setEtSelected({...etSelected})
                        } else {
                            setEtSelected({...etSelected, newOptionalOperationType: [{id:item.id, position:0}]})
                        }
                    }
                    else if (otAddState && !otSelected?.stateList?.map(es=>es.id)?.includes(item.id) && !otSelected?.suggestionList?.map(es=>es.id)?.includes(item.id)){
                        if (otSelected?.stateList){
                            otSelected.stateList.push({id:item.id, position: otSelected?.stateList.length})
                            setOtSelected({...otSelected})
                        } else {
                            setOtSelected({...otSelected, stateList: [{id:item.id, position:0}]})
                        }
                    }
                    else if (otAddSuggestion && !otSelected?.stateList?.map(es=>es.id)?.includes(item.id) && !otSelected?.suggestionList?.map(es=>es.id)?.includes(item.id)){
                        if (otSelected?.suggestionList){
                            otSelected?.suggestionList.push({id:item.id, position:otSelected?.suggestionList.length})
                            setOtSelected({...otSelected})
                        } else {
                            setOtSelected({...otSelected, suggestionList: [{id:item.id, position:0}]})
                        }
                    }
                    else if (underTypeAddEt && !etSelected?.elementType?.includes(item.id)){
                        if (etSelected?.elementType){
                            etSelected?.elementType.push(item.id)
                            setEtSelected({...etSelected})
                        } else {
                            setEtSelected({...etSelected, elementType: [item.id]})
                        }
                    }
                    else if (from === "esList") {
                        setESSelected(item)
                        setEsCreateMode(false)
                        setEsEditMode(false)
                    }
                    else if (from === "etList"){
                        setEtCreateMode(false)
                        setEtEditMode(false)
                        setEtSelected(item)
                    }
                    else if (from === "otList"){
                        setOtSelected(item)
                        setOtCreateMode(false)
                        setOtEditMode(false)
                        setEsFilter(item.id)
                    }
                    else if(from === "otSelected"){
                        setESSelected(item)
                    }
                    else {
                        setOtSelected(item)
                        setOtCreateMode(false)
                        setOtEditMode(false)
                        setEsFilter(item.id)
                    }
                }}>{item?.name}</b>
            </div>
        )
    }
    function showDraggableItemName(idPosObj, from){
        let ot = getOperationTypeById(idPosObj.id)
        if (from === "otSelected"){
            ot = getEsById(idPosObj.id)
        }
        if (from === "otList"){
            ot = getElementTypeById(idPosObj.id)
        }
        return (
            <Draggable key={idPosObj.id} draggableId={idPosObj.id} index={idPosObj.position} isDragDisabled={(from !== "otSelected" && !etEditMode && !etCreateMode) || (from === "otSelected" && !otEditMode && !otCreateMode)}>
                {(provided) => (
                    <li className={etEditMode? "draggable" : ""} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                        <div className={"elementTypeItem"}>
                            {from === "etSelected" && (etEditMode||etCreateMode)?<b onClick={()=>delOt(idPosObj.id, from)} className={"delete"}>X</b>:""}
                            {from === "otList" && (otDelMode)?<b onClick={()=>removeOt(idPosObj.id)} className={"delete"}>X</b>:""}
                            {from === "otSelected" && (otEditMode||otCreateMode)?<b onClick={()=>delEs(idPosObj.id)} className={"delete"}>X</b>:""}
                            <b onClick={()=>{
                                if (etAddMandatoryOp && !etSelected?.mandatoryOperationType?.includes(idPosObj.id) && !etSelected?.optionalOperationType?.includes(idPosObj.id)){
                                    if (etSelected?.mandatoryOperationType){
                                        etSelected.mandatoryOperationType.push(idPosObj.id)
                                        setEtSelected({...etSelected})
                                    } else {
                                        setEtSelected({...etSelected, mandatoryOperationType: [idPosObj.id]})
                                    }
                                }
                                else if (etAddOptionalOp && !etSelected?.optionalOperationType?.includes(idPosObj.id) && !etSelected?.mandatoryOperationType?.includes(idPosObj.id)){
                                    if (etSelected?.optionalOperationType){
                                        etSelected.optionalOperationType.push(idPosObj.id)
                                        setEtSelected({...etSelected})
                                    } else {
                                        setEtSelected({...etSelected, optionalOperationType: [idPosObj.id]})
                                    }
                                }
                                else if (from !== "otSelected"){
                                    setOtSelected(ot)
                                    setOtCreateMode(false)
                                    setOtEditMode(false)
                                }
                                else if(from === "otSelected"){
                                    setESSelected(ot)
                                }
                            }}>{ot?.name}</b>
                        </div>
                    </li>
                )}
            </Draggable>
        )
    }

    function showElementTypeList(){
        return (
            <div className={"elementTypeList"}>
                {elementTypeList?.map(et => showItemName(et, "etList"))}
            </div>
        )
    }
    function showOperationTypeList(){
        return (
            <div className={"elementTypeList"}>
                {operationTypeList?.map(ot => showItemName(ot, "otList"))}
            </div>
        )
    }
    function showESList(){
        if(esListToShow === "state"){
            return elementStateList?.filter(es=>es?.operationType.includes(esFilter??""))?.map(es => showItemName(es, "esList"))
        } else {
            return elementSuggestionList?.filter(es=>es?.operationType.includes(esFilter??""))?.map(es => showItemName(es, "esList"))
        }
    }

    return (
        <div className={"grid edit-job"}>
            <div className={"secondary-section"}>
                {showElementTypeList()}
                {(!etCreateMode && !etDelMode) &&
                    <div className={"sticky-bottom flex-column"}>
                        <IconButton onClick={()=>{
                            setAllModeToFalse()
                            setEtDelMode(true)
                            setEtSelected()
                        }} className={"action-btn cancel"}>Supprimer</IconButton>
                        <IconButton onClick={()=>{
                            setAllModeToFalse()
                            setEtCreateMode(true)
                            setEtSelected()
                        }} className={"action-btn validation"}>Créer</IconButton>
                    </div>
                }
                {(etCreateMode) &&
                    <div className={"sticky-bottom flex-column"}>
                        <IconButton onClick={()=>{
                            setEtSelected()
                            setEtCreateMode(false)
                        }} className={"action-btn cancel"}>{text.CANCEL}</IconButton>
                        <IconButton onClick={()=>{
                            setEtCreateMode(false)
                            saveEt()
                        }} className={"action-btn validation"}>{text.SAVE}</IconButton>
                    </div>
                }
                {(etDelMode) &&
                    <div className={"sticky-bottom flex-column"}>
                        <IconButton onClick={()=>{
                            setEtSelected()
                            setEtDelMode(false)
                        }} className={"action-btn validation"}>Terminer</IconButton>
                    </div>
                }
            </div>
            <div className={"secondary-section"}>
                {etSelected || etCreateMode ? showElementTypeSelected() : "Aucun élément sélectionné"}
                {etSelected?.id && !etEditMode &&
                    <div className={"sticky-bottom flex-column"}>
                        <IconButton onClick={() => {
                            setAllModeToFalse()
                            setEtEditMode(true)
                        }} className={"action-btn validation"}>Éditer</IconButton>
                    </div>
                }
                {etSelected?.id && etEditMode ?
                    <div className={"sticky-bottom flex-column"}>
                        <IconButton onClick={() => {
                            setEtEditMode(false)
                            setEtSelected(getElementTypeById(etSelected.id))
                        }} className={"action-btn cancel"}>Annuler</IconButton>
                        <IconButton onClick={() => {
                            setEtEditMode(false)
                            saveEt()
                        }} className={"action-btn validation"}>
                            Enregistrer
                        </IconButton>
                    </div> : ""
                }
            </div>

            <div className={"secondary-section"}>
                {showOperationTypeList()}

                {(!otCreateMode && !otDelMode) &&
                    <div className={"sticky-bottom flex-column"}>
                        <IconButton onClick={()=>{
                            setAllModeToFalse()
                            setOtDelMode(true)
                            setOtSelected()
                        }} className={"action-btn cancel"}>Supprimer</IconButton>
                        <IconButton onClick={()=>{
                            setAllModeToFalse()
                            setOtCreateMode(true)
                            setOtSelected()
                        }} className={"action-btn validation"}>Créer</IconButton>
                    </div>
                }
                {(otCreateMode) &&
                    <div className={"sticky-bottom flex-column"}>
                        <IconButton onClick={()=>{
                            setOtSelected()
                            setOtCreateMode(false)
                        }} className={"action-btn cancel"}>{text.CANCEL}</IconButton>
                        <IconButton onClick={()=>{
                            setOtCreateMode(false)
                            saveOt()
                        }} className={"action-btn validation"}>{text.SAVE}</IconButton>
                    </div>
                }
                {(otDelMode) &&
                    <div className={"sticky-bottom flex-column"}>
                        <IconButton onClick={()=>{
                            setOtSelected()
                            setOtDelMode(false)
                        }} className={"action-btn validation"}>Terminer</IconButton>
                    </div>
                }
            </div>
            <div className={"secondary-section"}>

                {otSelected || otCreateMode ? showOperationTypeSelected() : "Aucun élement sélectionné"}
                {otSelected?.id && !otEditMode &&
                    <div className={"sticky-bottom flex-column"}>
                        <IconButton onClick={() => {
                            setAllModeToFalse()
                            setOtEditMode(true)
                        }} className={"action-btn validation"}>Éditer</IconButton>
                    </div>
                }
                {otSelected?.id && otEditMode ?
                    <div className={"sticky-bottom flex-column"}>
                        <IconButton onClick={() => {
                            setOtEditMode(false)
                            setOtSelected(getOperationTypeById(otSelected.id))
                        }} className={"action-btn cancel"}>Annuler</IconButton>
                        <IconButton onClick={() => {
                            setOtEditMode(false)
                            saveOt()
                        }} className={"action-btn validation"}>
                            Enregistrer
                        </IconButton>
                    </div> : ""
                }
            </div>

            <div className={"secondary-section grid scinded"}>
                <div>
                    {eSSelected || esCreateMode ? showESSelected() : "Aucun élement sélectionné"}
                    {eSSelected?.id && !esEditMode &&
                        <div className={"sticky-bottom flex-column"}>
                            <IconButton onClick={() => {
                                setAllModeToFalse()
                                setEsEditMode(true)
                            }} className={"action-btn validation"}>Éditer</IconButton>
                        </div>
                    }
                    {eSSelected?.id && esEditMode &&
                        <div className={"sticky-bottom flex-column"}>
                            <IconButton onClick={() => {
                                setEsEditMode(false)
                                setESSelected(getEsById(eSSelected.id))
                            }} className={"action-btn cancel"}>Annuler</IconButton>
                            <IconButton onClick={() => {
                                setEsEditMode(false)
                                saveEs()
                            }} className={"action-btn validation"}>Enregistrer</IconButton>
                        </div>
                    }
                </div>
                <div>
                    État<Switch className={"switch"} defaultChecked={false} onChange={changeEsList} />Suggestion<br/>
                    <input type={"text"} placeholder={"filter by uuid's operationType"} value={otSelected?.id} onChange={handleEsFilter}/>
                    <div className={"esList"}>
                        {showESList()}
                    </div>
                </div>
                {(!esCreateMode && !esDelMode) &&
                    <div className={"sticky-bottom flex-column"}>
                        <IconButton onClick={()=>{
                            setAllModeToFalse()
                            setEsDelMode(true)
                            setESSelected()
                        }} className={"action-btn cancel"}>Supprimer</IconButton>
                        <IconButton onClick={()=>{
                            setAllModeToFalse()
                            setEsCreateMode(true)
                            setESSelected()
                        }} className={"action-btn validation"}>Créer</IconButton>
                    </div>
                }
                {(esCreateMode) &&
                    <div className={"sticky-bottom flex-column"}>
                        <IconButton onClick={()=>{
                            setESSelected()
                            setEsCreateMode(false)
                        }} className={"action-btn cancel"}>{text.CANCEL}</IconButton>
                        <IconButton onClick={()=>{
                            setEsCreateMode(false)
                            saveEs()
                        }} className={"action-btn validation"}>{text.SAVE}</IconButton>
                    </div>
                }
                {(esDelMode) &&
                    <div className={"sticky-bottom flex-column"}>
                        <IconButton onClick={()=>{
                            setESSelected()
                            setEsDelMode(false)
                        }} className={"action-btn validation"}>Terminer</IconButton>
                    </div>
                }
            </div>
        </div>
    )
}

