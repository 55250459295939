import React, {Fragment} from "react";
import {Modal} from "react-responsive-modal"
import IbatApi from "../../utils/IbatApi";
import constants, {text} from "../../components/util/constants";
import {useSnackbar} from "notistack";
import Ready from "../../images/ready.png";
import NotReady from "../../images/not-ready.png";
import Trash from "../../images/delete.png";
import {IconButton} from "@mui/material";


export default function Row({ligne, keyRow, columns, trigger, onClick, selected, prioritize, tableName, fastMode}) {

    const [openModal, setOpenModal] = React.useState(null)
    const [content, setContent] = React.useState([])
    const {enqueueSnackbar} = useSnackbar()

    function mapSupplyStatusToText(status) {
        switch (status) {
            case 0:
                return constants.NULL;
            case 1:
                return text.AVAILABLE_PRODUCT;
            case 2:
                return text.SUPPLY_ON_SITE;
            case 3:
                return text.SUPPLIER;
            default:
                return constants.NULL;
        }
    }
    function mapSupplyStatusToOrdinal(status){
        switch (status) {
            case constants.NULL:
                return 0;
            case text.AVAILABLE_PRODUCT:
                return 1;
            case text.SUPPLY_ON_SITE:
                return 2;
            case text.SUPPLIER:
                return 3;
            default:
                return 0;
        }
    }
    function handleModal(idModal) {
        if (!fastMode){
            setOpenModal(null)
            setOpenModal(idModal)
        }
    }
    function handleUpdadte(){
        setOpenModal(null)
        trigger()
    }

    const handleSupplyChange=(e)=>{
        ligne[e.target.name] = e.target.value
        if(content[e.target.id] !== e.target.value){
            setContent({[e.target.id]: e.target.value})
        }
    }
    const updateSupplyStatus=(e)=>{
        ligne["status"] = Number(e.target.value) > 0 ? Ready : NotReady
        ligne[e.target.name] = mapSupplyStatusToText(Number(e.target.value))
        if(content[e.target.id] !== e.target.value){
            setContent({[e.target.id]: e.target.value})
        }
        new IbatApi().changeSupplyStatus(e.target.id, e.target.value).then(enqueueSnackbar(text.SNACKBAR_SAVED)).catch(e => {
            if (e.response?.status === 401) {
                enqueueSnackbar(text.PLEASE_LOGIN, {variant: "error"})
                // window.open("/login")
                return
            }
            enqueueSnackbar(text.SNACKBAR_ERROR, {variant: "error"})
        })
    }
    const saveChange=(e)=>{
        if (e.keyCode === 13){
            if (e.target.name === 'supply'){
                new IbatApi().renameSupply(e.target.id, e.target.value).then(
                    enqueueSnackbar(text.SNACKBAR_SAVED)
                ).catch(e => {
                    if (e.response?.status === 401) {
                        enqueueSnackbar(text.PLEASE_LOGIN, {variant: "error"})
                        // window.open("/login")
                        return
                    }
                    enqueueSnackbar(text.SNACKBAR_ERROR, {variant: "error"})
                })
            } else if (e.target.name === 'supplier'){
                new IbatApi().renameSupplier(e.target.id, e.target.value).then(it => {
                        enqueueSnackbar(text.SNACKBAR_SAVED)
                    }
                ).catch(e => {
                    if (e.response?.status === 401) {
                        enqueueSnackbar(text.PLEASE_LOGIN, {variant: "error"})
                        // window.open("/login")
                        return
                    }
                    enqueueSnackbar(text.SNACKBAR_ERROR, {variant: "error"})
                })
            } else if (e.target.name === 'qty'){
                new IbatApi().changeQty(e.target.id, e.target.value).then(it => {
                        enqueueSnackbar(text.SNACKBAR_SAVED)
                    }
                ).catch(e => {
                    if (e.response?.status === 401) {
                        enqueueSnackbar(text.PLEASE_LOGIN, {variant: "error"})
                        // window.open("/login")
                        return
                    }
                    enqueueSnackbar(text.SNACKBAR_ERROR, {variant: "error"})
                })
            }
        }
    }
    const deleteSupply=(e)=>{
        ligne["deletable"] = false
        switch (ligne["datatype"]){
            case "ConstructionMaterial":
                new IbatApi().deleteMaterial(ligne["supplyId"], ligne['id']).then(resp=>{
                    ligne["deletable"] = false
                    enqueueSnackbar(text.SNACKBAR_SAVED)
                }).catch(e => {
                    if (e.response?.status === 401) {
                        enqueueSnackbar(text.PLEASE_LOGIN, {variant: "error"})
                        // window.open("/login")
                        return
                    }
                    enqueueSnackbar(text.SNACKBAR_ERROR, {variant: "error"})
                })
                break
            case "ConstructionMachinery":
                new IbatApi().deleteMachinery(ligne["supplyId"], ligne['id']).then(resp => {
                    ligne["deletable"] = false
                    enqueueSnackbar(text.SNACKBAR_SAVED)
        }).catch(e => {
                    if (e.response?.status === 401) {
                        enqueueSnackbar(text.PLEASE_LOGIN, {variant: "error"})
                        // window.open("/login")
                        return
                    }
                    enqueueSnackbar(text.SNACKBAR_ERROR, {variant: "error"})
                })
                break
            case "ConstructionDocument":
                new IbatApi().deleteDocument(ligne["supplyId"], ligne['id']).then(resp => {
                    ligne["deletable"] = false
                    enqueueSnackbar(text.SNACKBAR_SAVED)
                }).catch(e => {
                    if (e.response?.status === 401) {
                        enqueueSnackbar(text.PLEASE_LOGIN, {variant: "error"})
                        // window.open("/login")
                        return
                    }
                    enqueueSnackbar(text.SNACKBAR_ERROR, {variant: "error"})
                })
                break
            case "AdminDocument":
                new IbatApi().deleteAdminDocument(ligne["supplyId"], ligne['id']).then(resp => {
                    ligne["deletable"] = false
                    enqueueSnackbar(text.SNACKBAR_SAVED)
                }).catch(e => {
                    if (e.response?.status === 401) {
                        enqueueSnackbar(text.PLEASE_LOGIN, {variant: "error"})
                        // window.open("/login")
                        return
                    }
                    enqueueSnackbar(text.SNACKBAR_ERROR, {variant: "error"})
                })
                break
            default:
                break
        }
        setContent(...content)
    }


    return (
        <div key={keyRow}
            onClick={onClick}
            className={`w100 flex table-row${selected ? " glow" : ""}${prioritize ? " prioritize" : ""}`} >
            {columns.filter(col => col.isChecked).map((col, k) => {
              if (col.type.includes("objectLink")) {
                  return (
                    <div className={`cell w100 flex-column${col.hideOnPhone ? " not-on-small-screen" : ""}`} key={k} title={ligne[col.id]["name"]}>
                        {fastMode || !ligne[col.id]["id"] ?
                            <Fragment>
                                {ligne[col.id]["id"] && ligne[col.id]["warning"] &&
                                    <span className={"alert"}/>
                                }
                                {ligne[col.id]["name"]}
                            </Fragment> :
                            <a className={col.id} href={"/" + (ligne[col.id]["target"] ?? col.id) + "/" + ligne[col.id]["id"]} target={"_blank"} rel={"noreferrer"}>
                                <Fragment>
                                    {ligne[col.id]["warning"] &&
                                        <span className={"alert"}/>
                                    }
                                    {ligne[col.id]["name"]}
                                </Fragment>
                            </a>
              }
              </div>
              )
              }
              if (col.type.includes("toUpdate")) {
                  return (
                      <Fragment key={k}>
                        <div
                            title={ligne[col.id]["name"]}
                            className={`cell w100 flex-column${col.hideOnPhone ? " not-on-small-screen" : ""}${ligne[col.id]["temporary"] ? " editable" : ""}`}
                            onClick={() => {
                                if (ligne[col.id]["temporary"]){
                                    handleModal(ligne.id + "" + col.id)
                                }
                            }}
                        >
                              <p style={!fastMode ? {color: ligne[col.id]["temporary"] ? "red" : "", "textDecoration": ligne[col.id]["temporary"] ? "underline" : ""}: {}}>{ligne[col.id]["name"]}</p>

                        </div>
                              <Modal open={openModal === ligne.id + "" + col.id}
                                     onClose={() => setOpenModal(null)}>
                                  {
                                      React.createElement(col.popup, {
                                          content: content,
                                          id: ligne.id,
                                          idDev: ligne.devis.id,
                                          team: ligne.team,
                                          day: ligne.intervention?.value??0,
                                          name: ligne[col.id]['name'],
                                          siteId: ligne.site.id,
                                          voie: ligne.address.voie,
                                          dataSite: {address: ligne.address.name, name: ligne.site.name, cp: ligne.cp, city: ligne.ville, contactPhone: ligne.site.contactPhone},
                                          addDoc: {
                                              id:ligne["id"],
                                              type: ligne["datatype"]
                                          },
                                          update: handleUpdadte
                                      })
                                  }
                              </Modal>
                      </Fragment>
                  )
                }
              else if (col.type === "object") {
                  return (
                    <div className={`cell w100 flex-column${col.hideOnPhone ? " not-on-small-screen" : ""}`} key={k} title={ligne[col.id]["name"] ?? ""}>
                      {ligne[col.id]["warning"] && <span className={"alert"}/>}
                      {ligne[col.id]["name"]}
                    </div>
                  )
                }
              else if (col.type.includes("linkText")) {
                  return (
                      <div className={`cell w100 flex-column${col.hideOnPhone ? " not-on-small-screen" : ""} ` + col.id+tableName} key={k} title={ligne[col.id]}>
                          {!fastMode ?
                            <a className={col.id} href={"/" + tableName + "/" + ligne.id} target={"_blank"} rel={"noreferrer"}>
                              {ligne[col.id]}
                            </a> :
                            <p>{ligne[col.id]}</p>
                          }
                      </div>
                  )

                }
              else if (col.type.includes("popup")) {
                  let content;
                content = col.icon ? <img alt={""} src={col.icon}/> : (col.type.includes("WithIcon") ? <div className={"flex-column"}><img src={col.function(ligne[col.id]?.name)} />{ligne[col.id]?.name}</div> : ligne[col.id]?.name);
                content = col.icon ?
                  <>
                      {(ligne[col.id]?.name && ligne[col.id]?.name?.length>0) ? `${ligne[col.id]?.name?.length.toString()}` :""}
                      <img alt={""} src={col.icon}/>
                  </>:
                  content

                  return (
                    <Fragment key={k}>
                      <div className={`cell w100 ${col.id} flex-column editable${col.hideOnPhone ? " not-on-small-screen" : ""}`}
                          title={ligne[col.id] ? ligne[col.id].name : ligne.id + "" + col.id}
                          onClick={() => handleModal(ligne.id + "" + col.id)}>
                        <p>{content}</p>
                        {ligne[col.id] && ligne[col.id]["warning"] && <span className={"alert"}/>}
                      </div>
                      <Modal open={openModal === ligne.id + "" + col.id}
                             onClose={() => handleModal(null)}>
                          {
                              React.createElement(col.popup, {
                                  content: content,
                                  id: ligne?.id,
                                  idDev: ligne?.devis?.id,
                                  team: ligne?.team,
                                  day: ligne?.intervention?.value??10,
                                  name: ligne[col.id]?.name,
                                  siteId: ligne?.site?.id,
                                  addDoc: {
                                      id:ligne["id"],
                                      type: ligne["datatype"]
                                  },
                                  voie: ligne?.address?.voie,
                                  img: ligne?.site?.imageUrl,
                                  background: ligne?.background,
                                  latitude: ligne?.site?.latitude,
                                  longitude: ligne?.site?.longitude,
                                  points: ligne?.points,
                                  update: handleUpdadte,
                                  title: `${ligne?.devis?.name} - ${ligne?.site?.name} (${ligne?.ville})`,
                              })
                          }
                      </Modal>
                    </Fragment>
                  )
                }
              else if (col.type.includes("dateText")){
                  return (
                      <div className={`cell w100 flex-column${col.hideOnPhone ? " not-on-small-screen" : ""}`} key={k}>
                        {ligne[col.id] ? new Date(ligne[col.id]).toLocaleDateString('fr-FR').toString() : "-"}
                      </div>)
                }
              else if (col.type.includes("supply")){
                  let content;
                content = ligne[col.id]["name"]!==undefined ?  ligne[col.id]["name"] : ligne[col.id];
                content = col.icon? <img alt={""} src={ligne[col.id]}/>:content
                content = ligne["deletable"] ? content : ""
                  return (<div className={`cell w100 flex-column${col.hideOnPhone ? " not-on-small-screen" : ""}`} key={k}>
                    {content}
                  </div>)
                }
              else if (col.type.includes("inputText")){
                  if (ligne["deletable"] &&
                    (ligne["datatype"] === "ConstructionMaterial" || (col.id === "supplier" && ligne["datatype"] === "ConstructionMachinery"))){
                      return (<div className={`cell w100 flex-column${col.hideOnPhone ? " not-on-small-screen" : ""}`} key={k}>
                          <input className={col.id} type="text" id={ligne["id"]} name={col.id} value={ligne[col.id]["name"]!==undefined ?  ligne[col.id]["name"] : ligne[col.id]} onChange={handleSupplyChange} onKeyDown={saveChange}/>
                      </div>)
                  }
                  else if (ligne["deletable"]) {
                      return (<div className={`cell w100 flex-column${col.hideOnPhone ? " not-on-small-screen" : ""}`} key={k}>
                          {ligne[col.id]["name"] !== undefined ? ligne[col.id]["name"] : ligne[col.id]}
                      </div>)
                  } else {
                      return (
                      <div className={`cell w100 flex-column${col.hideOnPhone ? " not-on-small-screen" : ""}`} key={k}></div>
                      )
                  }
                }
              else if (col.type.includes("selectable")){
                  if (ligne["deletable"] && (ligne["datatype"] === "ConstructionMaterial" || ligne["datatype"] === "ConstructionMachinery")){
                      return (
                          <div className={`cell w100 flex-column${col.hideOnPhone ? " not-on-small-screen" : ""}`} key={k}>
                              <select key={ligne["id"]} id={ligne["id"]} name={"isReady"} value={mapSupplyStatusToOrdinal(ligne[col.id])} onChange={updateSupplyStatus} >
                                  <option value={constants.UNKNOWN}>- </option>
                                  <option value={constants.IN_STOCK}>En Stock </option>
                                  <option value={constants.SUPPLIER}>Fournisseur </option>
                                  <option value={constants.ON_SITE}>Sur site </option>
                              </select>
                          </div>
                      )
                  }
                  else{
                      return (<div className={`cell w100 flex-column${col.hideOnPhone ? " not-on-small-screen" : ""}`} key={k}></div>)
                  }
              }
              else if (col.type.includes("remove")){
                  return (<div className={`cell w100 flex-column${col.hideOnPhone ? " not-on-small-screen" : ""}`} key={k}>
                      {ligne["deletable"] && <IconButton className="remove" onClick={deleteSupply}><img alt={""} src={Trash}/></IconButton>}
                  </div>)
              }
              else if (col.type.includes("component")){
                  return (
                      <div className={`cell w100 flex-column`} key={k}>
                        {
                            React.createElement(col.component, {
                                ...col.props,
                                id: ligne.id
                            })
                        }
                  </div>
                  )
              }
              else if (col.type.includes("textWithIcon")) {
                  return (
                      <div
                          className={`cell w100 flex-column${col.hideOnPhone ? " not-on-small-screen" : ""}`}
                          key={k}>
                          <img width={23} src={col.function(ligne[col.id])}
                               alt={""}/> {ligne[col.id]}
                      </div>
                  )
              }
              else if (col.type.includes("objectWithIcon")) {
                  return (
                      <div
                          className={`cell w100 flex-column${col.hideOnPhone ? " not-on-small-screen" : ""}`}
                          key={k}>
                          <img width={23} src={col.function(ligne[col.id]["id"])}
                               alt={""}/> {ligne[col.id]["name"]}
                      </div>
                  )
              }
              else {
                  return (
                      <div className={`cell w100 flex-column${col.hideOnPhone ? " not-on-small-screen" : ""}`} key={k}>
                        {ligne[col.id]}
                      </div>
                  )
                }
            })
            }
        </div>
    )
}
