import React, {useState} from "react";
import {useDrag} from "react-dnd";
import constants, {text} from "../components/util/constants";
import {IconButton, Popper, Tooltip} from "@mui/material";
import {isModifiable} from "../helpers/interventionManager";
import {ResizableBox} from 'react-resizable';
import Modal from "react-responsive-modal";
import EditSupply from "../components/forms/EditSupply";
import InteractionClient from "../components/bean/InteractionClient";
import EventCreator from "../event-creator/EventCreator";
import EventModifier from "../event-creator/EventModifier";
import EditInterventionTeam from "../components/forms/EditInterventionTeam";

function StepFlat ({event,pin,onHover,onRemove,onTimeChange, resetEvent,containerSize,onDrag, hovered, handleAddPin, removeEventFromList, time, width, left}) {
    const [dialog,setDialog]=useState(false)
    const [modal,setModal]=useState(null)
    /*function handleTimeChange(newTime){
        onTimeChange(event.id,newTime/1000)
        setModal(null)
    }*/

    function handleResize(e,data){
        let eventHeight = data.size.height;
        let numberOfQuarter=(eventHeight/containerSize)
        let time=numberOfQuarter*15*constants.M1
        onTimeChange(event.id,time)
    }

    function getContactedColor(){
        let color = ""

        if (pin.devis?.interactionClients?.length===0){
            color = "alert contact red"
        }
        if (pin.emergencyEventStatus) {
            if (pin.emergencyEventStatus === "NO_RESPONSE"){
                color = "alert contact orange"
            }
            else if (pin.emergencyEventStatus === "OK"){
                color = "alert contact green"
            }
            else if (pin.emergencyEventStatus === "NOK") {
                color = "alert contact purple"
            }
        }
        if (color !== ""){
            return <span className={color}></span>
        }
    }

    const [{isDragging}, drag] = useDrag(() => ({
        type:"event",
        item:event,
        end:(item,monitor)=>onDrag(pin.id),
        collect: (monitor) => ({
            isDragging: monitor.isDragging()
        })
    }))
    return(!isDragging &&
        <div className={`event`} style={{position: "absolute",width: width+"%", left: left+"%", height:(containerSize*((event.endEvent-event.start))/(15*constants.M1)), zIndex:!dialog? 1 : 2}}>
               <ResizableBox className={`${!isModifiable(pin) ? " fix" : ""}${hovered===pin.id?" hovered":""}`}
                               height={containerSize*((event.endEvent-event.start))/(15*constants.M1)}
                               draggableOpts={{grid: [containerSize, containerSize]}}
                               axis={"y"}
                               onResizeStop={handleResize}

                >
                   <Tooltip classes={{tooltip: "eventTooltip"}} placement={"right-start"} title={<div>{pin.devis.site?.name},<br />{pin.devis.site.ville} ({pin.devis.site.cp})</div>}>
                       <div className={"step-flat-container flex-column"}
                            style={{height:(containerSize*((event.endEvent-event.start))/(15*constants.M1))}}
                            onMouseEnter={(e)=>onHover(e, pin.id)}
                            onMouseLeave={(e)=>onHover(e, null)}
                       >
                           <div className={`step-flat`} ref={drag}
                                onClick={(e=>setDialog(dialog?null:e.currentTarget))}
                               // style={style}
                               //  style={{height:(containerSize*((event.endEvent-event.start))/(15*constants.M1))-10}}
                           >

                                   <div className={"site flex-row"} style={{fontSize:(event.endEvent-event.start)*constants.S1>15*constants.M1?15:containerSize}} >
                                       {getContactedColor()}
                                       {/*{pin.devis?.interactionClients?.length===0 && <span className={"warning"}>o</span>}*/}
                                       <span className={"siteName"}>{pin.supplyAlert ? <span>&#128679;</span> : ""}{pin.devis.site?.name}</span>
                                   </div>

                           </div>
                       </div>
                   </Tooltip>
                </ResizableBox>

                <Popper className={"popper secondary-section flex-column"}
                    placement="left"
                    disablePortal={true}
                    modifiers={{
                        flip: {
                            enabled: true,
                        },
                        preventOverflow: {
                            enabled: true,
                            boundariesElement: 'scrollParent',
                        },
                        arrow: {
                            enabled: true,
                            element: dialog,
                        },
                    }}
                    anchorEl={dialog}
                    open={dialog}
                    onClose={()=>setDialog(false)}
                >
                    <IconButton className={"action-btn cancel exit fs1 w100"} onClick={()=>setDialog(false)}>x</IconButton>

                    <a className={"secondary-section"} href={`/interventions/${pin.id}`} target={'blank'}> {pin.devis.site.name}</a>
                    <nav className={"secondary-section"}>
                        <Tooltip title={text.SUPPLY_DISPLAY}>
                            <span className={"appro"} onClick={()=>setModal("appro")}/>
                        </Tooltip>
                        <Tooltip title={text.CONTACTS}>
                            <span className={"contact"} onClick={()=>setModal("contact")}/>
                        </Tooltip>
                        <Tooltip title="Ajouter un créneau pour cette intervention">
                            <span className={"add"} onClick={()=>setModal("addEvent")}>+</span>
                        </Tooltip>
                        <Tooltip title="Modifier ce créneau">
                            <span className={"reset"} onClick={()=>setModal("resetEvent")}>rs</span>
                        </Tooltip>
                        <Tooltip title="Affecter tous les créneaux de cette intervention à une autre équipe">
                            <span className={"changeTeam"} onClick={()=>setModal("changeTeam")}>-></span>
                        </Tooltip>
                        <Tooltip title={text.REMOVE_STEP}>
                        <span className={"delete"} onClick={()=>onRemove(event)}/></Tooltip>
                    </nav>
                    <span className={"date-intervention secondary-section"}>
                        {new Date(event.start).toLocaleTimeString()} à {new Date(event.endEvent).toLocaleTimeString()}
                    </span>
                    <Modal
                        classNames={(modal==="resetEvent" || modal==="addEvent" || modal==="contact") ? {modal: 'onTheRight', root: 'transparent'} : {}} open={modal} onClose={()=> {
                            setModal(null)
                            setDialog(false)
                        }}>
                            {modal==="appro"&&<EditSupply id={pin.id} title={`${pin.devis.clientIdBCommande} - ${pin.devis.site.name}`} />}
                            {modal==="contact"&&<InteractionClient id={pin.id} site={pin.devis.site}/>}
                            {modal==="addEvent"&&<EventCreator intervention={{...pin}} onSave={handleAddPin} onClose={()=>setModal(null)}/>}
                            {modal==="resetEvent"&&<EventModifier event={{...event}} onSave={resetEvent}/>}
                            {modal==="changeTeam"&&<EditInterventionTeam id={pin.id} update={()=>removeEventFromList(event.id)}/>}
                        </Modal>



                </Popper>
            </div>
    )
}

export default class StepFlat2 extends React.Component {
    constructor(props) {
        super();
        this.props = props
    }


    shouldComponentUpdate(prevProps){
        return this.props.pin.id === this.props.hovered || ((this.props.pin.id === prevProps.hovered) && !this.props.hovered) || (this.props.event.intervention.emergencyEventStatus !== prevProps.event.intervention.emergencyEventStatus);
    }
    render() {
        return <StepFlat
            event={this.props.event}
            pin={this.props.pin}
            onHover={this.props.onHover}
            onRemove={this.props.onRemove}
            onTimeChange={this.props.onTimeChange}
            resetEvent={this.props.resetEvent}
            containerSize={this.props.containerSize}
            onDrag={this.props.onDrag}
            hovered={this.props.hovered}
            handleAddPin={this.props.handleAddPin}
            removeEventFromList={this.props.removeEventFromList}
            time={this.props.time}
            width={this.props.width}
            left={this.props.left}/>
    }
}

