import React, { useRef, useState} from "react"
import Row from "./row/Row";
import CriteriaIcon from "../images/button-filter.png";
import Checkbox from "../components/util/Checkbox";
import {IconButton, Tooltip} from "@mui/material";
import FirstPage from "../images/page-first.png";
import PreviousPage from "../images/page-previous.png";
import LastPage from "../images/page-last.png";
import NextPage from "../images/page-next.png";
import { text } from "../components/util/constants";
import Loading from "../loading/Loading";
import Modal from "react-responsive-modal";
import Intervention from "../components/bean/Intervention";
import LoadingSVG from "../images/LoadingSVG.svg";
import switchToList from "../images/switch-to-list.png";
import switchToCard from "../images/switch-to-card.png";

export default function Table(
    {
        list,
        loading,
        columns,
        pageNumber,
        pageSize,
        onGetData,
        onHandleColumns,
        onHandleFilter,
        onEndTyping,
        rowCount,
        onHandleChangeRowsPerPage,
        onHandleChangePage,
        tabName,
        children,
        fastMode,
        showSupplierIntervention,
        filter,
        hideFilter,
        setShowMode
    }) {

    const [visible, setVisible] = useState(false)
    const [timer, setTimer] = useState(null)
    const [typing, setTyping] = useState(false)
    const [selectedRow, setSelectedRow] = useState("")
    const [developCriteriaBar, setDevelopCriteriaBar] = useState(false)
    const [fastModeModal, setFastModeModal] = useState(null)
    // const ref=useRef(null)



    function handleChange(value) {
        clearTimeout(timer);

        onHandleFilter(value)

        let time = setTimeout(triggerChange, 600);
        setTimer(time)
    }

    function handleKeyDown(e) {
        if (e.keyCode === 13) {
            triggerChange();
        }
    }

    function triggerChange() {
        setTyping(!typing)
        onEndTyping(!typing);
    }


    function onHandleCriteria() {
        setVisible(!visible)
    }

    function nextPage() {
        let k=pageNumber+1
        if(k<Math.floor(rowCount/pageSize)) {
            onHandleChangePage(k)
        }
    }

    function lastPage() {
        let k =Math.floor(rowCount/pageSize)
        onHandleChangePage(k)
    }

    function previousPage() {
        let k=pageNumber-1
        if(k>=0) {
            onHandleChangePage(k)
        }
    }

    function firstPage() {
        onHandleChangePage(0)
    }

    function previous(){
        if (fastModeModal>0){
            setSelectedRow(list[fastModeModal-1].id)
            setFastModeModal(fastModeModal-1)
        }
    }
    function next(){
        if (fastModeModal<list.length) {
            setSelectedRow(list[fastModeModal+1].id)
            setFastModeModal(fastModeModal + 1)
        }
    }

    return (
        <>
            {
                !hideFilter &&
                <div className={`br10 criteria-list-dropdown not-on-small-screen${visible ? " visible" : ""}`} >
                <Tooltip title={text.CRITERIA_LIST}>
                    <IconButton className={"criteria-button"} onClick={onHandleCriteria} >
                        <img alt={"img"}  className={"criteria-icon"} src={CriteriaIcon} />
                    </IconButton>
                </Tooltip>
                <ul className={"bord1 criteria-list br10"}>
                    {columns.map((col, id) =>
                        <li key={id}>
                            <Checkbox className={"bord1 criteria br20"} handleCheckChieldElement={onHandleColumns} {...col} myKey={id}/>
                        </li>)
                    }
                </ul>
            </div>
            }

            {/*<div className={"scroll"} id={"right"} onMouseLeave={mouseLeave} onMouseEnter={mouseEnter}/>
                    <div className={"scroll"} id={"left"} onMouseLeave={mouseLeave} onMouseEnter={mouseEnter}/>
*/}


            {
                !hideFilter && loading &&
                <img alt={""} className={"table-element-center"} src={LoadingSVG}/>
            }

            <div className={"table w100"}>
                <div className={"table-row grid header w100"}>
                    {columns.filter(d=>d.isChecked).map((d, idx) => {
                        return d.isFiltrable ?
                            <div key={`${d.id}-${idx}`} className={`flex-column w100 filter-group${d.hideOnPhone ? " not-on-small-screen" : ""}`}>
                                <input className={"filter-input br05 w100"} id={`${d.id}-${idx}`} placeholder={d.id} name={d.id} type={d.type}
                                       onChange={handleChange} onKeyDown={handleKeyDown} defaultValue={filter[d.id]}/>
                                <label htmlFor={`${d.id}-${idx}`} className={"filter-label br05"}>{d.label}</label>
                            </div>
                            :
                            <div className={`filter-input br05 w100 flex-column${d.hideOnPhone ? " not-on-small-screen" : ""}`} key={`${d.id}-${idx}`}>
                                <label className={"label"}>{d.label}</label>
                            </div>
                    })}
                </div>
                <div className={"w100 flex scrollable"}>
                    {
                        !hideFilter &&

                        <div className={"filter-bar secondary-section flex-row"}>
                            <Tooltip title={'Vue en mode liste ou cartes'}><div className={"dashboard-switcher flex-row"}>

                                {
                                    setShowMode &&
                                    <>
                                        <img src={switchToCard} width={40} onClick={()=>setShowMode("card")}/>
                                    </>
                                }
                            </div>
                            </Tooltip>
                            <p style={{margin: 3}}>
                                {rowCount} {text.RESULTS} | {text.PAGE} {pageNumber + 1} {text.OF} {Math.floor(rowCount / pageSize) + 1} | {text.RESULTS_PER_PAGE} :
                            </p>
                            <div className={"flex-row"}>
                                <IconButton onClick={firstPage}> <img alt={"img"} src={FirstPage}/> </IconButton>
                                <IconButton onClick={previousPage}> <img alt={"img"} src={PreviousPage}/> </IconButton>

                                <select id={"pageNumber"} value={pageSize} onChange={onHandleChangeRowsPerPage}>
                                    <option value={10}>10</option>
                                    <option value={20}>20</option>
                                    <option value={50}>50</option>
                                    <option value={100}>100</option>
                                </select>

                                <IconButton onClick={nextPage}> <img alt={"img"} src={NextPage}/> </IconButton>
                                <IconButton onClick={lastPage}> <img alt={"img"} src={LastPage}/> </IconButton>
                            </div>
                        </div>
                    }
                    {
                        ((list && list?.length===0) || !list) && !loading &&
                        <div className={"primary-section"}>{text.NO_RESULT}</div>
                    }
                    {
                        list?.length > 0 &&
                        list.map((ligne, idx) =>
                            <Row
                                key={ligne.id}
                                keyRow={ligne.id}
                                onClick={() => {
                                    if (fastMode){
                                        setFastModeModal(idx)
                                    }
                                    else if (showSupplierIntervention) {
                                        showSupplierIntervention(ligne.id)
                                    }
                                    setSelectedRow(ligne.id)
                                }}
                                tableName={tabName}
                                columns={columns} ligne={ligne}
                                trigger={onGetData}
                                selected={selectedRow === ligne.id}
                                prioritize={ligne.prioritize}
                                fastMode={fastMode}
                            />
                        )
                    }
                </div>
            </div>
            {
                children &&
                <aside className={`flex-column criteria-bar${developCriteriaBar ? " developped" : ""}`}>
                    <div className={`margin-content`}>
                        {children}
                        <div className={`bord1 only-for-small-screen${developCriteriaBar ? " developped" : ""}`} onClick={()=>setDevelopCriteriaBar(!developCriteriaBar)}>
                            >
                        </div>
                    </div>
                </aside>
            }
            <Modal  classNames={{modal: 'noOverflow'}} open={fastModeModal !== null} onClose={()=>setFastModeModal(null)}>
                <div className={"fastMode flex-row"}>
                    {fastModeModal>0 ?
                        <Tooltip title={"Intervention précédente"}><div className={"switch bord1"}>
                            <IconButton className={"switchBtn"} onClick={previous}>‹</IconButton>
                        </div></Tooltip> : <div/>
                    }
                    <div className={"fastModeContent"}>
                        <Intervention fastMode={list && list[fastModeModal]?.id} />
                    </div>

                    {fastModeModal<list?.length ?
                        <Tooltip title={"Intervention suivante"}><div className={"switch bord1"}>
                            <IconButton className={"switchBtn"} onClick={next}>›</IconButton>
                        </div></Tooltip> : <div/>
                    }
                </div>
            </Modal>


        </>
    )
}
