import React, {useEffect, useState} from "react";
import UserManager, {UserEditorMod} from "../lists/UserManager";
import constants, {text} from "../util/constants";
import IbatApi from "../../utils/IbatApi";
import {Accordion, AccordionDetails, AccordionSummary} from "@mui/material";
import {ExpandMore} from '@mui/icons-material';
import usersImg from "../../images/users-config.png";
import miscImg from "../../images/misc-icon.png";
import CompanyEditForm from "../forms/CompanyEditForm";
import ebatLogo from "../../images/ebat-logo.png";


export default function Parameter(props) {
    const api = new IbatApi()
    const [company, setCompany] = useState()
    const [companyId] = useState(api.getTokenData().companyId)
    const [expanded, setExpanded] = useState(false);
    const [userToMod, setUserToMod] = useState(false)
    const [userList, setUserList] = useState([])
    const handleUserToMod = (data) => {
        setUserToMod(data)
    }

    useEffect(() => {
        setUserToMod(userToMod)
        getCompany()
        getAllUser()
    }, [userToMod, company])
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    function handleCompany(c) {
        setCompany(c)
    }

    function getCompany() {
        if (companyId && !company) {
            api.getCompanyById(companyId).then(d => {
                handleCompany(d)
            })
        }
    }
    function getAllUser(){
        api.getAllUser()
            .then(data=> {
            setUserList(data)
        })
    }


    return (
        <div className={"page flex scrollable parameter"}>
            {(api.hasRole(constants.ADMIN) ?
                    <div className={"flex-column primary-section"}>
                        <h1 className={"flex-row"}>Paramètres</h1>
                        <Accordion className={"custom-accordion br05"} expanded={expanded === 'panel1'}
                                   onChange={handleChange('panel1')}>
                            <AccordionSummary className={"custom-accordion-title"}
                                              expandIcon={<ExpandMore/>}
                                              aria-controls="panel-content">
                                <div className={"flex-row custom-accordion-title-content"}>
                                    <img alt={""} src={usersImg}/>
                                    Gestion des Utilisateurs
                                </div>
                            </AccordionSummary> <div className={"userInfos primary-section"}>
                            {text.PARAM_INFO1}</div>
                            <AccordionDetails
                                className={"accordion-details primary-section flex-row user"}>
                                <UserManager handleClick={handleUserToMod}
                                             userList={userList} />
                                <UserEditorMod user={userToMod} allUser={userList ?? []} updateUserList={getAllUser} updateUserToMod={setUserToMod}/>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion className={"custom-accordion br05"} expanded={expanded === 'panel2'}
                                   onChange={handleChange('panel2')}>
                            <AccordionSummary className={"custom-accordion-title"}
                                              expandIcon={<ExpandMore/>}
                                              aria-controls="panel2bh-content">
                                <div className={"flex-row custom-accordion-title-content"}>
                                    <img alt={""} src={miscImg}/>
                                    {text.OTHER_PARAM}
                                </div>
                            </AccordionSummary>
                            <AccordionDetails
                                className={"accordion-details primary-section grid edit-company"}>
                                {
                                    company &&
                                    <CompanyEditForm company={company}/>
                                }
                            </AccordionDetails>
                        </Accordion>
                        {/*<Accordion className={"custom-accordion"} expanded={expanded === 'panel3'}
                                   onChange={handleChange('panel3')}>
                            <AccordionSummary className={"custom-accordion-title"}
                                              expandIcon={<ExpandMore/>}
                                              aria-controls="panel3bh-content">
                                <div className={"flex-row custom-accordion-title-content"}>
                                    <img alt={""} src={statImg}/>
                                    Statistiques de l'Entreprise
                                </div>
                            </AccordionSummary>
                            <AccordionDetails className={"accordion-details primary-section stats"}>
                                <Typography>
                                    <Statistiques id={companyId} handleCompany={handleCompany}/>
                                </Typography>
                            </AccordionDetails>
                        </Accordion>*/}
                    </div> :
                    <div>
                        {text.MAINTENANCE_TEXT}
                    </div>
            )}

            <div className={"flex-column"}>
                <a href={"/download-apk"}>Télécharger l'application mobile</a>
                <div className={"flex-column"}><img alt={""} src={ebatLogo}/><br/>Merci d'utiliser eBat
                    !
                </div>
            </div>

        </div>
    )
}
