import React, {useEffect, useState} from "react";
import IbatApi from "../../utils/IbatApi";
import {IconButton} from "@mui/material";
import CatalogEdit from "../admin/catalog/CatalogEdit";
import NomenclatureEdit from "./nomenclature/NomenclatureEdit";
import ContactCrud from "./beans/ContactHelp";
import CompanyCrud from "./beans/CompanyUpdater";
import RiskAnalysisManager from "./beans/RiskAnalysisManager";
import JobManager from "./beans/JobManager";
// import Papa from 'papaparse';

const api = new IbatApi()



function JobTypeCrud({jtList, refresh}){
  const [newJt, setNewJt] = useState();
  function showJobType(jt){
    return(
      <div className={"jobType"}>
        <span>id: <b>{jt?.id}</b></span><br />
        <span>name: <b>{jt?.name}</b></span><br /><br />
      </div>
    )
  }
  function handleNewJobType(){
    const jt = document.getElementById("jobTypeCreator")?.value
    setNewJt(false)
    api.saveJobType({id:null, name:jt}).then(resp=> {
      refresh()
    })
  }
  return (
    <div>
      {jtList?.map(jt => showJobType(jt))}
      {
        newJt &&
          <div>
            <input type={"text"} placeholder={"Roofer"} id={"jobTypeCreator"}/>
            <IconButton className={"action-btn validation"} onClick={handleNewJobType}>Valider</IconButton>

          </div>
      }
      <IconButton className={"action-btn validation"} onClick={()=>setNewJt(true)}>Créer un corps de métier</IconButton>
    </div>
  )
}


export default function AdminCrud() {
  const api = new IbatApi()
  const [godMode, setGodMode] = useState("Waiting")
  const [showQuestion, setShowQuestion] = useState(false);




  const [jobTypeList, setJobTypeList] = useState();



  function getJobTypeNameList(idList){
    return jobTypeList.filter(jt => idList?.includes(jt.id)).map(jt => jt.name)
  }


  useEffect(() => {
    if (!godMode || godMode === "Waiting"){
      api.amISuperAdmin().then(r => {
        setGodMode(r)
      })
    }
    if (godMode === true){
      refreshJobType()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [godMode])



  function refreshJobType(){
    api.getJobTypes().then(resp=> setJobTypeList(resp))
  }


  if (godMode === true){
    return (
      <div className={"page grid admin"}>
        <aside className={"menu secondary-section flex-column"}>
          <h2 className={"MuiIconButton-root action-btn neutral"} onClick={() => setShowQuestion("contacts")}>Contacts</h2>
          <h2 className={"MuiIconButton-root action-btn neutral"} onClick={() => setShowQuestion("company")}>Entreprises</h2>
          <h2 className={"MuiIconButton-root action-btn neutral"} onClick={() => setShowQuestion("raConfig")}>Analyses de risques</h2>
          <h2 className={"MuiIconButton-root action-btn neutral"} onClick={() => setShowQuestion("job")}>Métier</h2>
          <h2 className={"MuiIconButton-root action-btn neutral"} onClick={() => setShowQuestion("jobtype")}>Corps de métier</h2>
          <h2 className={"MuiIconButton-root action-btn neutral"} onClick={() => setShowQuestion("catalogEdit")}>Catalogue </h2>
          <h2 className={"MuiIconButton-root action-btn neutral"} onClick={() => setShowQuestion("nomenclatureEdit")}>Nomenclatures </h2>
        </aside>

        <article>
          {showQuestion === "contacts" && <ContactCrud/>}
          {showQuestion === "company" && <CompanyCrud/>}
          {showQuestion === "raConfig" && <RiskAnalysisManager getJobTypeNameList={getJobTypeNameList}/>}
          {showQuestion === "job" && <JobManager getJobTypeNameList={getJobTypeNameList}/>}


          {showQuestion === "jobtype" && <JobTypeCrud refresh={refreshJobType} jtList={jobTypeList}/>}
          {showQuestion === "catalogEdit" && <CatalogEdit jobTypes={jobTypeList?.map(jt => {var jobtype = {id: jt.id, value: jt.id, name: jt.name, label: jt.name};return jobtype})} />}
          {showQuestion === "nomenclatureEdit" && <NomenclatureEdit jobTypes={jobTypeList?.map(jt => {var jobtype = {id: jt.id, value: jt.id, name: jt.name, label: jt.name};return jobtype})} />}
        </article>
      </div>
    )
  }
  else {
    return "Not Found"
  }
}

