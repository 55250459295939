import React, {Fragment, useEffect, useState} from "react";
import IbatApi from "../../utils/IbatApi";
import Select from "react-select";
import {text} from "../util/constants";

export default function GestionnaireSelector({id ,onSet}){
    const api= new IbatApi()
    const [gestionnaire,setGestionnaire]=useState([])

    useEffect(()=>{
        api.getAllGestionnaire().then(data=>
            setGestionnaire(data)
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])


    function handleChange(e) {
        api.setClientGestionnaire(id,e.value).then(onSet)
    }

    return(
        <Fragment>
            <Select
                inputId="aria-example-input"
                name="aria-live-color"
                placeholder={text.CHOOSE}
                options={gestionnaire.map(ge=>({value:ge.id,label:ge.name}))}
                onChange={handleChange}

            />
        </Fragment>
    )

}