import React, {Fragment, useEffect, useState} from "react";
import {IconButton} from "@mui/material";
import constants, {text} from "../util/constants";
import {useSnackbar} from "notistack";
import IbatApi from "../../utils/IbatApi";
import Modal from "react-responsive-modal";
import EditSector from "../forms/EditSector";

export default function SectorCreator({secondarySection}){
    const [sector, setSector]=useState();
    const [edit, setEdit]=useState(false);
    const [sectorList, setSectorList]=useState();
    const { enqueueSnackbar} = useSnackbar();
    const api=new IbatApi()

    useEffect(() => {
        if (!sectorList){
            getSectorList()
        }
    }, []);

    function getSectorList() {
        api.hasRole(constants.ADMIN) && api.getSecteurWithHidden().then(r => setSectorList(r))
            .catch(e=>enqueueSnackbar('Erreur',{variant:"error"}))
    }


    function handleSave() {
        let data={name:sector}
        api.hasRole(constants.ADMIN) &&
        api.createSector(data)
            .then(r => {
                getSectorList()
                enqueueSnackbar('Enregistré',{variant:"success"})
            })
            .catch(e=>enqueueSnackbar('Erreur',{variant:"error"}))
    }

    return <div className={`${secondarySection ? "secondary" : "primary"}-section sector-creator fs12 flex-column w100 parameter-widget`}>
        <div>
            {text.ZONE}
            <Modal open={edit} onClose={()=>setEdit(false)}>
                <div className={"primary-section flex-column"}>
                    {
                        sectorList?.sort((a,b) => a.name > b.name ? 1 : -1)?.map((s, idx) => <EditSector key={idx} sector={s} handleUpdate={getSectorList} />)
                    }
                </div>
            </Modal>
            <input className={"large w100"} type={"text"} placeholder={text.CREATE_SECTOR}
                   onChange={e => setSector(e.target.value)}/>
        </div>
        <div className={"flex-row"}>
            <IconButton className={"action-btn neutral"} onClick={()=>setEdit(true)}>{text.EDIT}</IconButton>
            <IconButton className={"action-btn validation"} onClick={handleSave}>{text.CREATE}</IconButton>
        </div>


    </div>
}