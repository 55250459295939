import React from 'react';
import * as ReactDOM from 'react-dom';
// import './style/index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';


document.addEventListener('copy', function(e) {
    // Obtenez le texte sélectionné
    var selectedText = window.getSelection().toString();

    // Annulez l'événement de copie par défaut
    e.preventDefault();

    // Copiez uniquement le texte brut dans le presse-papiers
    e.clipboardData.setData('text/plain', selectedText);
});

ReactDOM.render(<React.StrictMode><App/></React.StrictMode>, document.getElementById("root"));

/*
root.render(
    <React.StrictMode>
        <App/>
    </React.StrictMode>,
);
*/

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
