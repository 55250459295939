import {EditText} from "react-edit-text";
import CheckBox from "../util/Checkbox";
import {constants, text} from "../util/constants";
import IconSee from "../../images/eye-see.png";
import IconNotSee from "../../images/eye-no-see.png";
import {IconButton} from "@mui/material";
import React from "react";
import IbatApi from "../../utils/IbatApi";
import {useSnackbar} from "notistack";


export default function SectorCreator({sector, handleUpdate}) {
    const api=new IbatApi()
    const { enqueueSnackbar} = useSnackbar();


    const updateSectorName = (e) => {
        console.log(e)
        api.hasRole(constants.ADMIN) &&
        api.updateSectorName(sector.id, e.value)
            .then(() => {
                handleUpdate()
            })
            .catch((e) => {
                enqueueSnackbar(e.message ?? text.SNACKBAR_ERROR, {variant: 'error'})
            })
    }
    const updateSectorVisibility = (e) => {
        api.hasRole(constants.ADMIN) &&
        api.updateSectorVisibility(sector.id, !sector.isHidden)
            .then(() => {
                handleUpdate()
            })
            .catch((e) => {
                enqueueSnackbar(e?.response?.data ?? text.SNACKBAR_ERROR, {variant: 'error'})
            })
    }

    return (
        <div className={"flex-row w100"}>
            <IconButton onClick={updateSectorVisibility}>
                <img alt={text.INVISIBLE} src={sector?.isHidden ? IconNotSee : IconSee}/>
            </IconButton>
            <EditText className={"secondary-section w100"} defaultValue={sector.name} onSave={updateSectorName} />
        </div>
    )
}