import React, { useState } from 'react';

export default function ExpandableDiv({className, children, style, corner}) {
    const [isExpanded, setIsExpanded] = useState(false);

    return (
        <div className={"w100 bord1"}>
            <div className={`${className??""} expandable-div${isExpanded ? ' expanded' : ''}`} style={style}>
            <div onClick={() => setIsExpanded(!isExpanded)}
                 className={`bord1 br20 ${corner ?? "tr-corner"} extend-button ${isExpanded ? "is-expanded" : "is-folded"}`}>
                >
            </div>
            <div className={"content"}>
                {children}
            </div>
        </div>
        </div>
    );
}