import React, {useState} from "react";
import {IconButton} from "@mui/material";
import constants, {text} from "../components/util/constants";
import {timeToDate} from "../helpers/timeToDate";
import {Calendar} from '@hassanmojab/react-modern-calendar-datepicker';
import {Timepicker} from "../TimePicker/TimePicker";

export default function EventModifier({event, onSave}){


    const defaultValue = {
        year: new Date().getFullYear(),
        month: new Date().getMonth()+1,
        day: new Date().getDate(),
    };
    const [selectedHour, setSelectedHour] = React.useState({hour:0, minute:0})
    const [selectedDate, setSelectedDate] = React.useState(defaultValue)
    let [duration,setDuration] = useState({id:event.id,time:timeToDate(event.duration),duration:event.duration*constants.S1});

    const myCustomLocale = {
        // months list by order
        months: [
            'Janvier',
            'Février',
            'Mars',
            'Avril',
            'Mai',
            'Juin',
            'Juillet',
            'Août',
            'Septembre',
            'Octobre',
            'Novembre',
            'Décembre',
        ],

        // week days by order
        weekDays: [
            {
                name: 'Lundi', // used for accessibility
                short: 'L', // displayed at the top of days' rows
            },
            {
                name: 'Mardi',
                short: 'Ma',
            },
            {
                name: 'Mercredi',
                short: 'Me',
            },
            {
                name: 'Jeudi',
                short: 'J',
            },
            {
                name: 'Vendredi',
                short: 'V',
            },
            {
                name: 'Samedi',
                short: 'S',
                isWeekend: true, // is it a formal weekend or not?
            },
            {
                name: 'Dimanche',
                short: 'D',
                isWeekend: true,
            },
        ],

        // just play around with this number between 0 and 6
        weekStartingIndex: 6,

        // return a { year: number, month: number, day: number } object
        getToday(gregorianTodayObject) {
            return gregorianTodayObject;
        },

        // return a native JavaScript date here
        toNativeDate(date) {
            return new Date(date.year, date.month - 1, date.day);
        },

        // return a number for date's month length
        getMonthLength(date) {
            return new Date(date.year, date.month, 0).getDate();
        },

        // return a transformed digit to your locale
        transformDigit(digit) {
            return digit;
        },

        // texts in the date picker
        nextMonth: 'Mois suivant',
        previousMonth: 'Mois précédent',
        openMonthSelector: 'Sélectionner un mois',
        openYearSelector: 'Sélectionner une année',
        closeMonthSelector: 'Fermer',
        closeYearSelector: 'Fermer',
        defaultPlaceholder: 'Sélection...',

        // for input range value
        from: 'du',
        to: 'au',


        // used for input value when multi dates are selected
        digitSeparator: ',',

        // if your provide -2 for example, year will be 2 digited
        yearLetterSkip: 0,

        // is your language rtl or ltr?
        isRtl: false,
    }
    // let [state,setState] = useState({id:event.id,time:timeToDate(event.duration),duration:event.duration*constants.S1});

    /*  function handleDate(e) {
          let value = new Date(e.target.value).getTime();
          let name= e.target.name;
          setState({...state,[name]:value})
      }
  */
      function handleSave() {
          let startValue = myCustomLocale.toNativeDate(selectedDate).getTime() + (selectedHour.hour * constants.H1) + (selectedHour.minute * constants.M1)
          let newEvent = {
              id: event.id,
              duration: duration.duration,
              endEvent: startValue + duration.duration,
              start: startValue,
              time: duration.time
          }
          onSave({...newEvent})
          // onSave(state)

      }


    function handleChangeTimePicker(hour, minute){
        if (selectedHour.hour !== hour || selectedHour.minute !== minute){
            setSelectedHour({hour:hour, minute: minute})
        }
    }
    function handleDuration(e) {
        let time = e.target.value;
        let [heure,minute]=time.split(':')
        let duration= heure*constants.H1+minute*constants.M1
        setDuration({...duration,time:time,duration:duration})
    }


    return(
        <div className={"eventCreator flex-column"}>
            <h2>Modifier la planification </h2>
            <div className={"content"}>
                <div className={"secondary-section flex-column"}>
                    <span className={"dateTimeDuration"}>La date</span>
                    <Calendar
                        value={selectedDate}
                        onChange={setSelectedDate}
                        locale={myCustomLocale}
                        shouldHighlightWeekends
                    />
                </div>
                <div className={"secondary-section flex-column"}>
                    <span className={"dateTimeDuration"}>L'heure</span>
                    <Timepicker onChange={(h, m)=> handleChangeTimePicker(h, m)}/>
                </div>
                <div className={"secondary-section flex-column span2"}>
                    <span className={"dateTimeDuration"}>La durée</span>
                    <input className={"large w100"} type={"time"} step={900}  onChange={handleDuration} value={duration.time} name={"time"}/>
                </div>
            </div>
            <div className={"flex-row"}>
                <IconButton className={"action-btn validation button"} onClick={handleSave}>{text.ADD_BUTTON}</IconButton>
            </div>
        </div>

    )
}
