import React, {useEffect, useState} from "react";
import IbatApi from "../../utils/IbatApi";
import {IconButton, Tooltip} from "@mui/material";
import FileUploader from "../util/FileUploader";
import DeleteIcon from "../../images/delete.png";
import ReadyIcon from "../../images/ready.png";
import NotReadyIcon from "../../images/not-ready.png";
import DocumentLoader from "../util/DocumentLoader";
import { text } from "../util/constants";

export default function SupplyDocument({id, supplyId, typeName, issueDate, isReady, receptionDate, onChange, documents, updateSupply, deleteSupply, interventionId}) {
    const [state, setState] = useState({
        id: id,
        supplyId: supplyId,
        documents: documents?documents:[],
        type: typeName,
        issueDate: issueDate,
        receptionDate: receptionDate ? new Date(receptionDate).toLocaleDateString().split("/").reverse().reduce((s1, s2) => s1 + "-" + s2) : "",
        isReady: isReady,
    })

    const api = new IbatApi();

    function sendDocument(state) {
        const d = {
            type: state.type.id,
            issueDate: state.issueDate,
            receptionDate: state.receptionDate,
            ready: state.isReady,
        }
        api.sendDocument(state.id, d);
    }

    useEffect(() => {
        if (updateSupply === undefined){
            sendDocument(state)
        } else {
            updateSupply(state)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state])

    function handleDocument(e) {
        if (e.target.type === "checkbox") {
            setState({...state, [e.target.name]: e.target.checked})
        }
        else {
            setState({...state, [e.target.name]: e.target.value})
        }
    }

    function deleteDocument() {
        if (deleteSupply === undefined){
            api.deleteDocument(interventionId, state.supplyId, state.id).then(r => onChange(r))
        } else {
            deleteSupply(state)
        }
    }

    function handleFiles(files){
        if (updateSupply === undefined){
            api.sendDocumentDocs(state.id,files).then(cDoc=>setState({...state,...cDoc}))
        } else {
            setState({...state, documents: files})
        }
    }

    return (
        <div className={`docs grid${state.isReady ? " ready" : ""}`}>
            <Tooltip title={state.type.name}>
                <p className={"flex-row"}>
                    <b>
                        {state.type.name} {new Date(issueDate).toLocaleDateString()}
                    </b>
                </p>
            </Tooltip>
            <input className={"large w100"} type={"date"} id={state.id} name={"receptionDate"} value={state.receptionDate} placeholder={text.RECEPTION_DATE} onChange={handleDocument}/>
            <div className={"flex-row"}>
                <FileUploader files={[]} onUpload={handleFiles} id={state.id}/>
                {
                    state.documents.length >0 &&
                    <div className={"images secondary-section flex-row"}>
                        {state.documents.map(file => <DocumentLoader name={file}/>)}
                    </div>
                }
            </div>
            <Tooltip title={text.REMOVE}>
                <IconButton onClick={deleteDocument}><img alt={""} src={DeleteIcon}/></IconButton>
            </Tooltip>
            <label><input type={"checkbox"} id={state.id} name={"isReady"} checked={state.isReady} onChange={handleDocument}/><img alt={""} src={state.isReady?ReadyIcon:NotReadyIcon}/></label>
        </div>
    )
}