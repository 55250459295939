import React, {useState} from "react";
import IbatApi from "../../utils/IbatApi";
import {useSnackbar} from "notistack";
import {text} from "../util/constants"
import FileUploader from "../util/FileUploader";
import {IconButton} from "@mui/material";
import editRequest from "../../images/ebat-logo.png";

export function TegeoContact({user, message, onClose}){
  const api = new IbatApi()
  const {enqueueSnackbar}=useSnackbar()
  const [charCount,setCharCount] = useState(0)
  const [status,setStatus] = useState("Joindre un fichier")
  const [contact, setContact] = useState({
    from: api.getMyUserId(),
    title: "",
    contact: "",
    attachment:[]
  })


  function handleContact(ev){
    setContact({...contact, [ev.target.id]: ev.target.value})
  }
  const updateCharCount = (ev) =>{
    setCharCount(ev.target.value.length)
  }
  const handleFile = (file) => {
    setContact({...contact, attachment: file})
  }
  function sendContact(){
    if (contact.title.length === 0 || contact.contact.length === 0){
      enqueueSnackbar("Merci de remplir les champs munis d'une astérisque (*)",{variant:"error"})
      return
    }
    setStatus("Envoi en cours...")
    api.sendContact(contact)
      .then(resp => {
        setStatus("Envoyé")
        enqueueSnackbar(text.SNACKBAR_SUCCESS,{variant:"success"})
        onClose()
      })
      .catch(e => {
        setStatus("Échec")
        enqueueSnackbar("SendContact : "+e ,{variant:"error"})
      })
  }
  return (
    <div className={"flex-column contactForm w100 secondary-section"}>
        <img alt={""} src={editRequest}/><br />
        <h3>Formulaire de prise de contact avec Tegeo</h3>
        <div className={"flex-row w100"}>

        <p className={"title"}>{message ?? "Formulaire de contact"}</p>
        </div>
        <div className={"elementForm w50"}>
        Objet du message*
        <input type={"text"} defaultValue={""} id={"title"} onChange={handleContact} placeholder={"Changement d'adresse suite à déménagement"}/>
        </div>
        <div className={"elementForm w100"}>
        Message* ({charCount}/3000)<br />
        <textarea className={"textarea w100"} rows={7} defaultValue={""} id={"contact"} maxLength={"3000"} onChange={handleContact} onKeyUp={updateCharCount} placeholder={"Bonjour, je souhaite changer l'adresse de mon entreprise. Vous trouverez un KBIS ci-joint."}/>
        </div>
        <div className={"elementForm flex-row w100"}>
        <FileUploader files={[]} className={"fileUploader br05"} single={true} onUpload={handleFile}/>
          {status}
        </div>

        <IconButton onClick={sendContact} className={"action-btn validation"}>Envoyer</IconButton>
    </div>
  )
}
