import React, {useEffect, useState} from "react";
import IbatApi from "../../utils/IbatApi";
import {text} from "../util/constants";
import {enqueueSnackbar} from "notistack";

export default function TeamInteraction({id, teamInteraction}){
    const [team, setTeam] = useState(teamInteraction)
    const api = new IbatApi()

    function handleTeamInteraction(e) {
        const value = e.target.value;
        setTeam(value.substring(0, 255))
    }
    useEffect(()=>
        {
            id && api.getIntervention(id)
                .then(data=>setTeam(data.teamInteraction))
                .catch(e => enqueueSnackbar(text.SNACKBAR_ERROR,{variant:"error"}))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        ,[])

    function saveTeamInteraction(){
        api.addTeamInteraction(id, team)
    }

    return(
        <div className={"secondary-section grid flex-column w100 teamInteraction"}>
            <div className={"flex-column"}>
                <textarea className={"textareaMobile"} maxLength={255} value={team ?? ""} onChange={handleTeamInteraction} onBlur={saveTeamInteraction}/>
                <div className={"bori label"}>{team?.length ?? 0}/255</div>
            </div>
        </div>
    )
}
