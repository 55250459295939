import IbatApi from "../utils/IbatApi";
import constants from "../components/util/constants";
import React, {useState} from "react";
// import "../style/state-manager.css"
import {getColor} from "../helpers/interventionManager";




export default function StateManager({opId,level,max}){

    let api = new IbatApi();
    const [colorList,setColorList]=useState(false)
    const [myLvl,setLvl]=useState(level)


    function showColorList() {
        setColorList(!colorList)
    }

    function setColor(e) {
        api.setStateLevel(opId,e.target.value).then(()=>setLvl(e.target.value)).then(setColorList(false))
    }

    return(
        <div className={"flex-row"}>
            <div  style={{...getColor(myLvl),borderRadius:"50%",width:40,height:40,border: "3px solid white"} } onClick={showColorList}/>
            {colorList && api.hasRole(constants.PERMISSION_AM) &&
            <ul className={"flex-row"}>
                { Array.from(Array(max).keys())
                    .map(lvl=><li value={lvl} onClick={setColor} style={{...getColor(lvl, max), borderRadius: "50%", width: 40, height: 40, listStyle: "none"}}/> )
                }
            </ul>
            }
        </div>)
}