import React, {useEffect, useState} from "react";
import {IconButton} from "@mui/material";
import {text} from "../components/util/constants";
import IbatApi from "../utils/IbatApi";
import {useSnackbar} from "notistack";
import DocumentLoader from "../components/util/DocumentLoader";
import Modal from "react-responsive-modal";
import Layer from "../layer/Layer";
import {v4} from "uuid";


export default function QuotationCreateForm({interventionId, operations, devisId, onSaveSucced, existingQuotation, image, provisionalWork, jobRepair}){
    const [points, setPoints] = useState(false)
    const [creating, setCreating] = useState(false)
    const [editPoi, setEditPoi] = useState(false)
    const {enqueueSnackbar}=useSnackbar()
    const api = new IbatApi()

    useEffect(()=> {
        if (!points){
            setPoints(operations.map(op => op.metadata).filter( op => op !== null).map(m => m.points).flat().filter(p => p.selectedProducts?.length > 0))
        }
    }, [points, operations])


    const validQuotation = (deletedPoint) => {
        let newWindow = window.open("", "_blank")
        let quotation = []

        operations.forEach( op => {
            let quotationLine = {}
            quotationLine["typeName"] = op.typeName
            quotationLine["typeId"] = op.typeId
            quotationLine["lineGroup"] = []
            op.metadata?.points.filter(p => !deletedPoint.includes(p.id) && p.selectedProducts?.length > 0).forEach(point => {
                let line = {}
                line["product"] = []
                line["comment"] = point.comment
                line["point"] = {id: point.id}
                point.selectedProducts.filter(sp => sp.deleted !== true).forEach(sp => {
                    let p = {}
                    p["actionType"] = sp.actionType
                    p["productName"] = sp.product.name
                    p["id"] = sp.product.id
                    p["productExternalId"] = sp.product.externalId
                    p["variantValue"] = sp.variantValue
                    p["ttva"] = 20
                    p["unit"] = "U"
                    p["qty"] = parseFloat(sp.qty ?? 0)
                    line["product"].push(p)
                })
                quotationLine.lineGroup.push(line)
            })
            if (quotationLine.lineGroup.length > 0){
                quotation.push(quotationLine)
            }
        })
        api.createQuotation({quotationGroup: quotation}, interventionId, devisId)
            .then(r =>{
                enqueueSnackbar('Brouillon créé', {variant: "success"})
                newWindow.location = `/quotation/${r}`
                setCreating(false)
                // onSaveSucced()
            })
            .catch (e => {
                enqueueSnackbar('Erreur', {variant: "error"})
                console.log(e)
            })
    }

    return (
        <div className={"quotation form flex-column"}>
            <h1>Création d'un nouveau devis</h1>
            {
                existingQuotation?.length > 0 &&
                <div className={"primary-section"}>
                    <p>Des devis existent déjà pour cette intervention</p>
                    {existingQuotation?.map(q => <div key={v4()} className={"flex-row"}>
                        {q.odooPdfId && <DocumentLoader name={q.odooPdfId}/>}
                        <a href={`/quotation/${q.id}`} target={"_blank"} rel={"noreferrer"}>{q.odooRef ?? q.id}</a>
                    </div>)}
                </div>
            }


            {/*{points && points?.map((p,idx) => showPoiQuotation(p,idx))}*/}

            {operations &&
                <div className={"flex-row"}>
                    <IconButton className={"action-btn cancel"} onClick={()=>{}}>{text.CANCEL}</IconButton>
                    <IconButton disabled={creating} className={"action-btn neutral save"} onClick={() => {
                        setEditPoi(true)
                        // setCreating(true)
                        // validQuotation()
                    }}>{text.CREATE}</IconButton>
                </div>
            }

            <Modal open={editPoi} onClose={()=>setEditPoi(false)}>
                <Layer points={points}
                       background={image}
                       provisionalWork={provisionalWork}
                       jobRepair={jobRepair}
                       quotationMode={true}
                       close = {() => setEditPoi(false)}
                       onSave = {(deletedPoint) => {
                           setCreating(true)
                           validQuotation(deletedPoint)
                       }}
                />
            </Modal>
        </div>
    )
}