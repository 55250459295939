import React, {useEffect, useState} from "react";
import IbatApi from "../../utils/IbatApi";
import Table from "../../table/Table";
import {IconButton} from "@mui/material";
import ClientIcon from "../../images/client-add.png";
import Modal from "react-responsive-modal";
import ClientForm from "../forms/ClientForm";
import {text} from "../util/constants";

const columns = [
    {id: "id", isFiltrable: true, label: text.NUMBER, type: "linkText", isChecked: true},
    {id: 'mail', isFiltrable: true, label: text.MAIL, type: "text", isChecked: true, hideOnPhone: true},
    {id: "name", isFiltrable: true, label: text.NAME, type: "text", isChecked: true},
    {id: "phone", isFiltrable: true, label: text.NUMBER, type: "text", isChecked: true, hideOnPhone: true},
    {id: "address", isFiltrable: true, label: text.ADDRESS, type: "text", isChecked: true},
    {id: "address2", isFiltrable: true, label: text.ADDRESS2, type: "text", isChecked: true, hideOnPhone: true},
]

export default function Clients() {
    const [clientForm,setClientForm]=useState(false)
    const [state, setState] = useState({
        columns: columns,
        clientsList: [],
        pageSize: 20,
        pageNumber: 0,
        count: 0,
        filters: {
            id: "",
            mail: "",
            name: "",
            phone: "",
            addess: "",
            addess2: "",

        }
    })
    const [typing,setTyping]=useState(false)

    let api = new IbatApi();
    useEffect(() => {
        getClients()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [typing,state.pageNumber,state.pageSize])

    const handleColumns = (event) => {
        let columns = state.columns
        let filter = {...state.filters}
        let find = columns.find(col => col.id === event.target.name);
        find.isChecked = event.target.checked
        if (find.isFiltrable) {
            filter[find.id] = ""
        }
        setState(state => ({...state, columns: columns, filters: filter}))
    }


    function getClients() {
        const result = api.getClient({
            ...state.filters,
            pageNumber: state.pageNumber,
            pageSize: state.pageSize
        })
        result.then(res => {
            var sendedAt = new Date()
            if(!state.lastQueryAt || sendedAt >= state.lastQueryAt){
                setState(state => ({...state, clientsList: res.content,count:res.totalElements}))
            }
        })
    }


    function handleFilter(e) {
        let value = e.target.value
        let name = e.target.name
        setState(state => ({...state, filters: {...state.filters, [name]: value}, lastQueryAt: new Date()}));
    }

    function handleChangePage(newPage) {
        setState(state => ({...state, pageNumber: newPage}))
    }

    function handleChangeRowsPerPage(event) {
        setState(state => ({...state, pageSize: event.target.value, pageNumber: 0}))
    }
    function handleClientForm(){
        setClientForm(!clientForm)
    }


    function handleSave(d){
        return api.createClient(d).then(d=>{
            getClients()
            handleClientForm()
        })
    };
    return (
        <div className={"page list-page"}>
            <Table
                onHandleFilter={handleFilter}
                onHandleChangePage={handleChangePage}
                onHandleChangeRowsPerPage={handleChangeRowsPerPage}
                onGetData={getClients}
                onHandleColumns={handleColumns}
                rowCount={state.count}
                filter={state.filters}
                onEndTyping={setTyping}
                pageNumber={state.pageNumber}
                pageSize={state.pageSize}
                columns={state.columns}
                list={state.clientsList}
                tabName={"client"}
            >
                <IconButton onClick={handleClientForm} ><img alt={""} src={ClientIcon}/></IconButton>
                <Modal open={clientForm} onClose={handleClientForm}>
                    <ClientForm onSave={handleSave} name={""} onCancel={handleClientForm}/>
                </Modal>
            </Table>
        </div>
    )
}
