import React, {useEffect, useState} from "react";
import IbatApi from "../../utils/IbatApi";
import Select from "react-select";
import {text} from "../util/constants";

export default function SearchTeam({defaultValue, onSave, jobType}) {
    const [teamList, setTeamList] = useState([])
    const [team, setTeam] = useState(defaultValue ?? [])


    let api = new IbatApi();
    useEffect(()=> {
        getTeams()
    }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    ,[])

    useEffect(()=>{
        setTeam(defaultValue)
    }, [defaultValue])


    let loadOptions=(inptv)=>{
        return api.getTeams(inptv)
            .then(resp => {
                let map = resp
                    .sort((a, b)=>a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1)
                    .map(s => ({
                    label: s.name.charAt(0).toUpperCase() + s.name.slice(1),
                    id: s.id,
                    value:s.id,
                    name: s.name
                }))
                setTeamList(map)
                return map
            })
            .catch(err=>console.log(err))
    }

    function getTeams() {
        api.getTeams(null)
            /*.then(teams=>{
                let filteredTeams = teams.sort((a, b)=>{
                    if (a.name < b.name) {
                        return -1
                    }
                    if (a.name > b.name){
                        return 1
                    }
                    return 0
                }).filter(t => t.jobType?.includes(jobType))
                filteredTeams.length>1?
                    setTeamList(filteredTeams.map(s=>({label: s.name, id: s.id, value:s.id, name: s.name})))
                    :
                    filteredTeams.length > 0 ?
                        handleTeam({...filteredTeams[0],value:filteredTeams[0].id,label:filteredTeams[0].name}):
                        setTeamList(teams.map(s=>({label: s.name, id: s.id, value:s.id, name: s.name})))
            })*/
            .then(teams=>{
                /*let filteredTeams = teams
                    .sort((a, b)=>a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1)
                    .filter(t => t.jobType?.includes(jobType))
                    .map(s=>({label: s.name.charAt(0).toUpperCase() + s.name.slice(1), id: s.id, value:s.id, name: s.name}))*/

                let map = teams
                    .sort((a, b)=>a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1)
                    .map(s => ({
                        label: s.name.charAt(0).toUpperCase() + s.name.slice(1),
                        id: s.id,
                        value:s.id,
                        name: s.name.charAt(0).toUpperCase() + s.name.slice(1)
                    }))

                !defaultValue.value && !defaultValue.label && handleTeam({...map[0]})
                setTeamList(map)
            })
            .catch(err=>console.log(err))
    }

    function handleTeam(data){
        setTeam({value:data.id,label:data.name})
        onSave(data)
    }
    /*function handleSave() {
        onSave(team)
    }*/

    function handleInputChange(d){
        loadOptions(d)
    }

    function handleChange(d){
        onSave({id:d.value,name:d.label})
    }

    return (
        <div className={"w100"}>
            <Select
                defaultValue={team}
                classNamePrefix={"search"}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999, color: '#000' }) }}
                menuPortalTarget={document.body}
                onInputChange={handleInputChange}
                placeholder={text.TEAM_SEARCH}
                options={teamList}
                onChange={handleChange}
                value={team}
            />
        </div>
    )
}

