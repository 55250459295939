import React, {Fragment, useEffect, useState} from "react";
import RepairIcon from "../images/repair-icon.png"
import ExportIcon from "../images/export.png"
import EmergencyIcon from "../images/emergency.png"
import NullIcon from "../images/null-statelevel-icon.png"
import IconSee from "../images/eye-see.png"
import IconNotSee from "../images/eye-no-see.png"
import IbatApi from "../utils/IbatApi";
import Modal from "react-responsive-modal";
import {IconButton, Tooltip} from "@mui/material";
import Layer from "../layer/Layer";
import ImportAnswer from "../images/import-answer.png"
import ImportData from "../images/import-data.png"
import AnswerValidate from "../images/answer.png"
import QuestionIcon from "../images/ask-question.png"
import constants, {text} from "../components/util/constants";
import Caroussel from "../components/util/Caroussel";
import {useSnackbar} from "notistack";
import StateManager from "../state-manager/StateManager.js";
import AudioComponents from "./AudioComponents";
import FamilyPicture from "./FamilyPicture";
import WorkForceDisplayer from "../work-force-displayer/WorkForceDisplayer";
// import {EventStreamContentType, fetchEventSource} from '@microsoft/fetch-event-source';


let api = new IbatApi()

export default function Operation({
                                      devisId,
                                      interventionId,
                                      operation,
                                      pictures,
                                      onExport,
                                      image,
                                      visible,
                                      otherOperations,
                                      depot,
                                      provisionalWork,
                                      jobRepair,
                                      jobProblem,
                                      ibatApi
                                  }) {

    let reducer = (acc, object) => {
        let findIndex = acc.findIndex(obj => obj.dataId === object.dataId);
        if (findIndex === -1) {
            acc.push(object)
        }
        return acc
    }
    const [state, setState] = useState(operation)
    const [imgIsActive, setImgIsActive] = useState(false)
    const repair = operation?.metadata?.points?.filter(poi => poi.name === constants.REPAIR)
    const works = operation?.metadata?.points?.filter(poi => poi.name === constants.WORK).reduce(reducer, [])
    const [interventions, setInterventions] = useState([])
    const [layer, setLayer] = useState(false)
    const [checked, setChecked] = useState(visible)
    const [pictureList, setPictureList] = useState(pictures)
    const [caroussel, setCaroussel] = useState(false)
    const [exportModal, setExportModal] = useState(false)
    const [exportOperation, setExportOperation] = useState(false)
    const [change, setChange] = useState({})
    const {enqueueSnackbar} = useSnackbar();

    useEffect(() => {
        setPictureList(pictures)
    }, [pictures])

    useEffect(() => {
        /*api.getFileList(pictures.map(pic=>pic.filePath))
            .then(resp=>{
                setPictureList(pictures.map(p => {return {...p, data: "data:image/png;base64," + resp[p.filePath]}}))
            })*/
        // fetchDataAndUpdatePictureList()
        // api.getFileList()
    }, [pictures?.length])

    /*function fetchDataAndUpdatePictureList(){
        let updatedPictureList = [...pictureList];
        const queryString = pictures.map(pic => `fileList=${encodeURIComponent(pic.filePath)}`).join('&');
        const url = api.getURL() + "/core/utils/streamImages?" + queryString;

        fetchEventSource(url, {
            method: 'GET',
            headers: {
                Authorization: api.getAuthorizationHeader(),
            },
            onmessage(ev) {


                // Convert the Base64 string back to binary data (byte array)
                const binaryImageData = Buffer.from(ev.data, 'base64')

                // Convert the binary data to a Blob
                const blobImage = new Blob([new Uint8Array(binaryImageData)], { type: 'image/png' });
                const url = URL.createObjectURL(blobImage);

                // Find the index of the existing image in the pictureList array based on the filename
                const index = pictures.findIndex((pic) => pic.filePath === ev.event);
                if (index !== -1) {
                    updatedPictureList = updatedPictureList.map((pic, i) => {
                        if (i === index) {
                            return { ...pic, url: url };
                        }
                        return pic;
                    });

                    setPictureList(updatedPictureList)
                }
                if (ev.event === 'FatalError') {
                    throw new Error(ev.data);
                }
            },
            async onopen(response) {
                if (response.ok && response.headers.get('content-type') === EventStreamContentType) {
                } else {
                    throw new Error();
                }
            },
            onerror(err) {
                if (err instanceof Error) {
                    throw err; // rethrow to stop the operation
                } else {
                    // do nothing to automatically retry. You can also
                    // return a specific retry interval here.
                }
            }
        })
    }*/

    function onChangePictureVisible(visible, picId) {
        api.setVisible(picId, !visible)
    }

    function handleZoom() {
        setImgIsActive(!imgIsActive)
    }

    /*function saveChanges() {
        const data = {
            commentaireRT: state.commentaireRT?encodeURIComponent(state.commentaireRT):"",
            commentaireGestionnaire: state.commentaireGestionnaire?encodeURIComponent(state.commentaireGestionnaire):"",
        }
        api.updateOperation(state.id, data)
    }*/

    const saveChanges = (e) => {
        if (!e) {
            return
        }
        let target = e.target.name.replace("commentaire", "").toLowerCase()

        if (target && !change[target]) {
            return
        }

        const data = {
            rt: state.commentaireRT ? encodeURIComponent(state.commentaireRT) : "",
            gestionnaire: state.commentaireGestionnaire ? encodeURIComponent(state.commentaireGestionnaire) : "",
        }
        api.updateOperation(target, state.id, data[target])
            .then(resp => {
                setChange({...change, [target]: false})
                setState(resp)
                enqueueSnackbar("Enregistré", {variant: "success"})
            })
            .catch(() => {
                enqueueSnackbar("Une erreur est survenue", {variant: "error"})
            })
    }

    function handleChange(e) {
        const name = e.target.name
        setChange({...change, [name.replace("commentaire", "").toLowerCase()]: true})
        setState({...state, [name]: e.target.value})
    }

    function handlePicture(listPictures) {
        let mothers = listPictures.filter(pic => !pic.motherId)
        let families = []
        for (let mother of mothers) {
            let fam = [...listPictures.filter(childPic => childPic.motherId === mother.id)]
            fam.push(mother)
            families.push(fam)
        }
        return families.filter(fam => fam.length > 0)
    }

    function handleLayer() {
        setLayer(!layer)
    }

    function moveRTComments() {
        if (!api.hasRole(constants.GESTIONNAIRE)) {
            return
        }
        let states = state.states.replaceAll("\\n", "\n")
        let suggestion = state.metadata.suggestion.replaceAll("\\n", "\n")
        let comment = state.commentaireIntervenant
        let text1 = `${states ?? ""}${suggestion ? "\n" + suggestion : ""}${comment ? "\n" + comment : ""}`
        setState({...state, commentaireGestionnaire: state.commentaireRT ? state.commentaireRT : text1})

        api.updateOperation("gestionnaire", state.id, encodeURIComponent(state.commentaireRT ? state.commentaireRT : text1))
            .then(resp => {
                setState(resp)
                enqueueSnackbar("Enregistré", {variant: "success"})
            })
            .catch(() => {
                enqueueSnackbar("Une erreur est survenue", {variant: "error"})
            })
    }

    function askQuestion() {
        if (!api.hasRole(constants.GESTIONNAIRE)) {
            return
        }
        setState({...state, question: true})
        api.askQuestion(state.id, true)
    }

    function response() {
        if (!api.hasRole(constants.PERMISSION_TRE)) {
            return
        }
        setState({...state, question: false})
        api.askQuestion(state.id, false)
    }

    function moveIntervComments() {
        if (!api.hasRole(constants.PERMISSION_TRE)) {
            return
        }
        let states = state.states.replaceAll("\\n", "\n")
        let suggestion = state.metadata.suggestion.replaceAll("\\n", "\n")
        let comment = state.commentaireIntervenant
        let emergencyReport = state.metadata.points.flatMap(p => `${p.reportComment ? p.reportComment+"\n" : ""}${p.reportRecommendation ? p.reportRecommendation+"\n" : ""}${p.reportSolution ?? ""}`).join("\n\n")
        console.log(emergencyReport)
        let text1 = `${states ? states+"\n" : ""}${suggestion ? suggestion + "\n" : ""}${comment ? comment+"\n" : ""}${emergencyReport ? emergencyReport : ""}`
        setState({...state, commentaireRT: text1})
        api.updateOperation("rt", state.id, encodeURIComponent(text1))
            .then(resp => {
                setState(resp)
                enqueueSnackbar("Enregistré", {variant: "success"})
            })
            .catch(() => {
                enqueueSnackbar("Une erreur est survenue", {variant: "error"})
            })
    }

    function refreshOperation(id) {
        onExport(id)
    }

    function importDevis() {
        api.getDevisIntervention(devisId)
            .then(intervs => setInterventions(intervs))
            .then(() => setExportModal(true))
    }

    function handleexport() {
        api.moveOperation(interventionId, state.id, exportOperation)
            .then(() => setExportModal(false))
            .then(() => enqueueSnackbar(text.SNACKBAR_SAVED, {variant: "success"}))
            .catch(() => enqueueSnackbar(text.SNACKBAR_ERROR, {variant: "error"}))

    }

    /*function getPicture(fileName){
        let filterElement = pictureList.filter(p=>p.filePath===fileName)[0];
        return filterElement.layer
    }*/

    return (
        <tr key={state.id} className={"fs1 operation-row"} style={state.question ? {backgroundColor: "#500"} : {}}>
            <td>
                <div className={"operationName"}>
                    <div>{state && <div className={"as-link"} name={state.typeName}>{state.typeName}</div>}</div>
                    {/*<WorkForceDisplayer ibatApi={ibatApi} operations={[operation]}/>*/}
                    <Tooltip title={text.TOOLTIP_PDF_VISIBLE_OPERATION}>
                        <IconButton onClick={() => {
                            api.setVisibleOperation(state.id, !checked).then(setChecked(!checked))
                        }}>
                            <img alt={text.INVISIBLE} src={checked ? IconSee : IconNotSee}/>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={text.EXPORT_OPERATION}>
                        <IconButton onClick={importDevis}>
                            <img src={ExportIcon} alt={"export-icon"}/>
                        </IconButton>
                    </Tooltip>
                </div>
                <Fragment>
                    <Modal open={exportModal} onClose={() => setExportModal(false)}>
                        <div className={"export-operation"}>
                            <select onChange={e => setExportOperation(e.target.value)}>
                                <option disabled selected defaultValue={""}>{text.EXPORT_TO}</option>
                                {interventions.filter(i => i.id !== interventionId)
                                    .map(intv => <option
                                        value={intv.id}>{intv.id} {intv.type.name} {new Date(intv.dateIntervention).toLocaleDateString()}</option>)}
                            </select>
                            <IconButton className={"action-btn validation"} onClick={handleexport}>
                                {text.EXPORT}
                            </IconButton>
                        </div>

                    </Modal>
                </Fragment>
            </td>
            <td className={"commentaire-cell"}>
                {
                    state.isEmergency ?
                        <img alt={"emergency_operation"} src={EmergencyIcon}/> :
                        <Tooltip title={text.DEGRADATION_STATE}>
                            <Fragment>
                                {state.metadata ?
                                    <StateManager opId={state.id} level={state.metadata.stateLevel}
                                                  max={state.typeId === 2 ? 4 : 5}/> :
                                    <img alt={"no state"} src={NullIcon}/>}
                            </Fragment>
                        </Tooltip>
                }
                <ul>
                    {state.states.split("\\n").map((s, i) => <li key={i}>{s}</li>)}
                </ul>
                {(state.commentaireIntervenant || state.vocal) &&
                    <div>
                        <span className={"titleSection"}>{text.AUDIO_AND_COMMENT} </span>
                        <div className={"section"}>
                            {state.commentaireIntervenant}
                            {state.vocal && <AudioComponents filename={state.vocal}/>}
                        </div>

                    </div>
                }
                {state.metadata && (state.metadata.suggestion.length > 0 || works.length > 0) &&
                    <div>
                        <span className={"titleSection"}>{text.SUGGESTIONS_AND_ADVICES}</span>
                        <div className={"section"}>
                            <ul className={"flex suggestion"}>
                                {state.metadata && state.metadata.suggestion.split("\\n").map(s => s.split("\n")).flat(2).map((s, i) => s ?
                                    <li key={i}>{s}</li> : s
                                )}
                            </ul>
                            <ul className={"preconisation"}>
                                {works?.map((s, i) => s ? <li key={i}>{s.dataName}</li> : s)}
                            </ul>
                        </div>
                    </div>
                }

                {state.metadata && (state?.metadata?.points?.length > 0) &&
                    <div>
                        <span className={"titleSection"}>{text.REPAIRS}</span>
                        <div className={"section"}>
                            {state.metadata?.points.length > 0 &&
                                <Tooltip title={text.DISPLAY_REPAIR_MAP + state.typeName}>
                                    <IconButton onClick={handleLayer}><img alt={""} src={RepairIcon}/></IconButton>
                                </Tooltip>
                            }
                            <ul className={"report-item"}>
                                {
                                    repair.map((r, i) =>
                                        (r.dataName || r.reportComment || r.reportSolution) &&
                                        <li className={"secondary-section"} key={i}>
                                            {
                                                r.dataName ?
                                                    <div> {text.REPAIRS} {r.dataTemporary ? text.TEMP : text.PERM} {text.DONE_WITH} {r.dataName} </div>:
                                                    (
                                                        r.reportComment ?
                                                            <div>{r.reportComment}{r.reportRecommendation ? <><br />{r.reportRecommendation}</> : ""}</div>:
                                                            (r.reportSolution ? <div>{r.reportSolution}</div> : "")
                                                    )
                                            }
                                        </li>
                                    )
                                }
                            </ul>
                        </div>
                    </div>
                }
            </td>
            {state.metadata &&
                <Modal open={layer} onClose={handleLayer}>
                    <Layer points={state.metadata?.points}
                           background={image}
                           provisionalWork={provisionalWork}
                           jobRepair={jobRepair}
                           jobProblem={jobProblem}
                           ibatApi={ibatApi}/>
                </Modal>
            }

            <td className={"commentaire-cell"}>
                <div className={"flex-column"}>
                    {state.lastGestionnaireCommentEditUser &&
                        <div>
                            Modifié
                            le {new Date(state.lastGestionnaireCommentEditDate).toLocaleString()} par {state.lastGestionnaireCommentEditUser}
                        </div>
                    }
                    <div className={"comments flex-row"}>
                        <Tooltip className={"not-on-small-screen"} title={text.TOOLTIP_IMPORT_STATE_AND_COMMENTS}>
                            <IconButton disabled={!api.hasRole(constants.PERMISSION_TRE)}
                                        onClick={moveIntervComments}><img alt={""} src={ImportData}/></IconButton>
                        </Tooltip>
                        <textarea className={"large w100"} name={"commentaireRT"}
                                  disabled={!api.hasRole(constants.PERMISSION_TRE)}
                                  value={state.commentaireRT ? state.commentaireRT : ""}
                                  onChange={handleChange} onBlur={saveChanges}
                        />
                        <Tooltip className={"not-on-small-screen"} title={text.TOOLTIP_SIGNAL_NEW_ANSWER}>
                            <IconButton disabled={!api.hasRole(constants.PERMISSION_TRE)} onClick={response}><img
                                alt={""} src={AnswerValidate}/></IconButton>
                        </Tooltip>
                    </div>
                </div>
            </td>
            <td className={"commentaire-cell"}>
                {state.lastRTCommentEditUser &&
                    <div>
                        Modifié
                        le {new Date(state.lastRTCommentEditDate).toLocaleString()} par {state.lastRTCommentEditUser}
                    </div>
                }
                <div className={"comments flex-row"}>
                    <Tooltip className={"not-on-small-screen"} title={text.IMPORT_STATE_AND_COMMENTS}>
                        <IconButton disabled={!api.hasRole(constants.PERMISSION_CRE)} onClick={moveRTComments}>
                            <img alt={""} src={ImportAnswer}/>
                        </IconButton>
                    </Tooltip>
                    <textarea className={"large w100"} name={"commentaireGestionnaire"}
                              disabled={!api.hasRole(constants.PERMISSION_CRE)}
                              value={state.commentaireGestionnaire ? state.commentaireGestionnaire : ""}
                              onChange={handleChange} onBlur={saveChanges}/>
                    <Tooltip className={"not-on-small-screen"} title={text.TOOLTIP_ASK_QUESTION}>
                        <IconButton disabled={!api.hasRole(constants.PERMISSION_CRE)} onClick={askQuestion}>
                            <img alt={""} src={QuestionIcon}/>
                        </IconButton>
                    </Tooltip>
                </div>
            </td>
            <td className={"operation-picture"}>
                <>
                    {pictureList &&
                        <div className={"flex-row"}>
                            {
                                handlePicture(pictureList)
                                    .sort((pF1, pF2) => pF1[0].date - pF2[0].date)
                                    .map((pF, idx) =>
                                        <FamilyPicture index={idx + 1} pictures={pF}
                                                       onChangePictureVisible={onChangePictureVisible}
                                                       onCaroussel={e => setCaroussel(e)} depot={depot}/>
                                    )
                            }
                            <Modal classNames={{modal: `${imgIsActive ? 'overflow-visible' : ""}`}} open={caroussel}
                                   onClose={() => setCaroussel(false)}>
                                <Caroussel listOperation={otherOperations} handleZoom={handleZoom}
                                           ibatApi={ibatApi}
                                           list={handlePicture(pictureList).sort((pF1, pF2) => pF1[0].date - pF2[0].date).map((pF, idx) => ({
                                               index: idx,
                                               family: pF
                                           }))}
                                    // findComplementData={getPicture}

                                           firstFile={caroussel}
                                           onExport={refreshOperation}/>
                            </Modal>
                        </div>
                    }
                </>
            </td>

        </tr>
    )
}