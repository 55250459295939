import React, {Fragment, useEffect, useState} from "react";
import FileUploader from "../util/FileUploader";
import {IconButton, Tooltip} from "@mui/material";
import {useSnackbar} from "notistack";
import IbatApi from "../../utils/IbatApi";
import {text} from "../util/constants";
import RiskSelector from "../../risk-selector/RiskSelector";
import SecteurCreator from "../bean/SecteurCreator";
import {TegeoContact} from "./TegeoContact";
import Modal from "react-responsive-modal";
import 'react-image-crop/dist/ReactCrop.css';
import StyleSelector from "../../style-selector/StyleSelector";
import Select from "react-select";
import EditPen from "../../images/pen-edit-icon.png";


export default function CompanyEditForm({company}){
  const api = new IbatApi();
  const [state,setState]=useState({
    id: company?.id,
    name:company?.name,
    siren:company?.siren,
    address:company?.address,
    mail:company?.mail,
    ville:company?.ville,
    cp:company?.cp,
    phone: company?.phone,
    logo: company?.logo,
    frontStyle: company?.frontStyle,
    jobType:company?.jobType,
    emergencyMode: company?.emergencyMode
  })
  const [fullAddress, setFullAddress] = useState(company?.address+", "+company?.cp+", "+company?.ville)
  const [logo,setLogo]=useState(false)
  const [formContact,setFormContact]=useState(false)
  const [jobTypeList, setJobTypeList] = useState();
  const { enqueueSnackbar} = useSnackbar()

  useEffect(() => {
    setState({
      id: company?.id,
      name:company?.name,
      siren:company?.siren,
      address:company?.address,
      mail:company?.mail,
      ville:company?.ville,
      cp:company?.cp,
      phone: company?.phone,
      logo: company?.logo,
      frontStyle: company?.frontStyle,
      jobType:company?.jobType,
      stormMode: company?.emergencyMode
    })
    if (!jobTypeList){
      api.getJobTypes().then(resp => {
        setJobTypeList(
            resp.map(jt => {
                  return {
                    label: jt.name,
                    value: jt.id
                  }
                }
            ))
      })
    }
    setFullAddress(state?.address+", "+state?.cp+", "+state?.ville)
    if (!logo){
      api.getFile(state?.logo).then(z => setLogo(z))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logo, company, formContact])
  useEffect(()=>{}, [state])

  function handleLogo(mlogo){
    setLogo(mlogo[0])
    api.updateLogoCompany(company.id, mlogo)
      .then(resp=>{
        setState({...state, logo: resp})
        setLogo()
        enqueueSnackbar(text.SNACKBAR_SUCCESS,{variant:"success"})
      })
      .catch(e=>enqueueSnackbar(e.message,{variant:"error"}))
  }
  function pickFile(){
    let doc = document.getElementById("fileUploader")
    doc.click()
  }

  function handleStormMode(e){
    setState({...state,emergencyMode:e.target.checked})
    api.setEmergencyMode(e.target.checked)
        .then(r=> enqueueSnackbar(text.SNACKBAR_SUCCESS,{variant:"success"}))
        .catch(e=>enqueueSnackbar(e.message,{variant:"error"}))

  }
  function handleCompanyChange(e){
    const name =e.target.name
    const value =e.target.value
    setState({...state,[name]:value})
  }
  function editCompany(){
    api.updateCompany(state)
        .then(resp=>{
          enqueueSnackbar(text.SNACKBAR_SUCCESS,{variant:"success"})
        })
        .catch(e=>enqueueSnackbar(e.message,{variant:"error"}))
  }

  const saveRAL = (RAL, emergency = false) => {
    api.setCompanyDefaultRiskLevel(emergency, RAL)
        .then(()=>enqueueSnackbar(text.SNACKBAR_SUCCESS,{variant:"success"}))
        .catch(e=>enqueueSnackbar(e.message,{variant:"error"}))
  }

  const updateStyle = (style) => {
    api.setCompanyDefaultStyle(style)
        .then(()=> {
          enqueueSnackbar(text.SNACKBAR_SUCCESS,{variant:"success"})
          api.setStyleCookie(style)
          window.location.reload();
        })
        .catch(e=>enqueueSnackbar(e.message,{variant:"error"}))
  }

  function handleJobType(jt) {
    setState({...state,jobType:jt.map(n=>(n.value))})
  }

  function linkToMaps(){
    return <a rel={"noreferrer"} target="_blank" href={"https://maps.google.com/?q="+fullAddress}>{fullAddress}</a>
  }

  return(
      <>
        <div className={"user-infos primary-section"}>{text.PARAM_INFO2}</div>

        <div className={"flex-row company-info secondary-section"}>
          <div className={"flex-column secondary-section w100"}>
            {logo &&
                <div className={"editable-img flex-column"}>
                  <img alt={"serviceProviderLogo"} src={logo.url} onClick={pickFile}/>
                  <FileUploader files={[]} onUpload={handleLogo} id={""} single/>
                </div>
            }
            <div className={"logoUploadInfo"}>
              Envoyer un logo (Dimensions max. conseillées '500 x 500')</div>
              <input className={"large w50"} type={"text"} defaultValue={company?.mail ?? ""} name={"mail"} placeholder={text.MAIL} onChange={handleCompanyChange}/>
              <input className={"large w50"} type={"text"} defaultValue={company?.phone ?? ""} name={"phone"} placeholder={text.NUMBER} onChange={handleCompanyChange}/>
              <Select
                  styles={{menuPortal: (base) => ({...base, zIndex: 9999})}}
                  menuPortalTarget={document.body}
                  isMulti
                  onChange={handleJobType}
                  placeholder={"Corps de métier concernés"}
                  options={jobTypeList}
                  value={jobTypeList?.filter(jt=> state?.jobType?.includes(jt.value))}
              />
              <IconButton onClick={editCompany} className={"action-btn save validation"}>{text.SAVE}</IconButton>
          </div>
          <div className={"flex-column w100"}>
            <span>
              Nom de l'entreprise :&nbsp;
              <b>
                {company?.name ?? text.NO_NAME_SAVED}
              </b>
            </span>
            <span>
              Siren :&nbsp;
              <b>
                {company?.siren ?? text.MISSING_SIREN}
              </b>
            </span>
            <span>
              Adresse de l'entreprise : <br />{company?.address ? linkToMaps(): text.NO_NAME_SAVED}
            </span>
            <Tooltip title={text.EDIT_INFOS}><img alt={""} src={EditPen} onClick={()=>setFormContact(true)}/></Tooltip>
          </div>
        </div>
        <SecteurCreator secondarySection={true}/>
        <div className={"secondary-section fs12 parameter-widget risk-selector flex-column"}>
          <div className={"widget-label"}>Niveau d'Analyse de Risques</div>
          <RiskSelector onSelect={saveRAL}/>
        </div>
        <div className={"secondary-section fs12 parameter-widget risk-selector flex-column"}>
          <div className={"widget-label"}>Niveau d'Analyse de Risques (Urgences)</div>
          <RiskSelector onSelect={saveRAL} emergency={true}/>
        </div>
        <div className={"secondary-section fs12 parameter-widget style-selector flex-column"}>
          <div className={"widget-label"}>Style de l'application</div>
          <StyleSelector onSelect={updateStyle}/>
        </div>
        {company?.id === 1 &&
            <div className={"secondary-section fs12 parameter-widget storm-mode flex-column"}>
              <div className={"flex-row"}>
                <div className={"widget-label"}>Mode tempête</div>
                <input className={"checkbox"} onChange={handleStormMode} type="checkbox" checked={state?.emergencyMode ?? false}/>
              </div>
            </div>
        }
        <Modal open={formContact} onClose={()=>setFormContact(false)}>
          <div>
            <TegeoContact onClose={()=>setFormContact(false)} message={text.COMPANY_MSG1 +text.COMPANY_MSG2}/>
          </div>
        </Modal>
        {/*<Modal open={cropModal} onClose={()=>setCropModal(false)}>
        <div>
          {newLogo && <ReactCrop className={"cropTool"} src={newLogo.data} crop={croppedImg} onChange={newCrop => setCroppedImg(newCrop)} onImageLoaded={image => setCroppedImg({...croppedImg, width:image.width, height:image.height})} />}

          <IconButton onClick={updateLogo} className={"action-btn validation"}>{text.SAVE}</IconButton>
        </div>
      </Modal>*/}
      </>
  )
}
