import React, {useEffect, useState} from "react";
import IbatApi from "../../utils/IbatApi";
import {IconButton, Tooltip} from "@mui/material";
import FileUploader from "../util/FileUploader";
import DeleteIcon from "../../images/delete.png"
import ReadyIcon from "../../images/ready.png";
import NotReadyIcon from "../../images/not-ready.png";
import DocumentLoader from "../util/DocumentLoader";
import constants, {text} from "../util/constants";

export default function SupplyMachinery({id, supplyId, typeName, supplier, pickupLocation, pickupDay, isReady, issueDate, onChange,documents, updateSupply, deleteSupply, interventionId}) {
    const [state, setState] = useState(
        {
            type: typeName,
            issueDate: issueDate,
            supplyId: supplyId,
            id: id,
            supplier: supplier ?? "",
            pickupLocation: pickupLocation,
            pickupDay: pickupDay ? new Date(pickupDay).toLocaleDateString().split("/").reverse().reduce((s1, s2) => s1 + "-" + s2) : "",
            isReady: isReady,
            documents:documents?documents:[]
        })



    const api = new IbatApi();

    function sendMachinery(state) {
        const d = {
            type: state.type.id,
            issueDate: state.issueDate,
            supplier: state.supplier,
            pickUpLocation: state.pickupLocation,
            pickUpDay: state.pickupDay,
            ready: state.isReady
        }
        api.sendMachinery(state.id, d)
    }

    useEffect(() => {
        if (updateSupply === undefined){
            sendMachinery(state)
        } else {
            updateSupply(state)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state])


    function handleMachinery(e) {

        if (e.target.type === "checkbox") {
            setState({...state, [e.target.name]: e.target.checked})
        } else {
            setState({...state, [e.target.name]: e.target.value})
        }
    }

    function deleteMachinery() {
        if (deleteSupply === undefined){
            api.deleteMachinery(state.supplyId, state.id, interventionId).then(r => onChange(r))
        } else {
            deleteSupply(state)
        }
    }

    function handleFile(files){
        if (updateSupply === undefined){
            api.sendMachineryDocs(state.id,files).then(m=>setState({...state,...m}))
        } else {
            setState({...state, documents: files})
        }
    }

    return (
        <div className={`machinery grid${state.isReady ? " ready" : ""}`}>
            <Tooltip title={state.type.name}>
                <p>
                    <b>
                        {state.type.name} {new Date(state.issueDate).toLocaleDateString()}
                    </b>
                </p>
            </Tooltip>
            <input className={"large w100"} type={"text"} name={"supplier"} value={state.supplier} placeholder={"Fournisseur"}
                   onChange={handleMachinery}/>
            <input className={"large w100"} type={"date"} pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}" id={state.id} name={"pickupDay"}
                   value={state.pickupDay} placeholder={"Date de livraison"} onChange={handleMachinery}/>
            <div className={"docsLoader flex-row"}> <FileUploader files={[]} onUpload={handleFile} id={state.id}/>
            <div className={"loadedDocs"}>{state.documents.length >0 && state.documents.map(file=><DocumentLoader name={file}/>)}</div>
            </div>
            <Tooltip title={text.REMOVE}>
                <IconButton onClick={deleteMachinery}><img alt={""} src={DeleteIcon}/></IconButton>
            </Tooltip>
            <label className={"flex-row"} htmlFor={state.id}>
                <select className={"large w100"} id={state.id} name={"isReady"} value={state.isReady} onChange={handleMachinery}>
                        <option value={constants.UNKNOWN}>- </option>
                        <option value={constants.IN_STOCK}>En Stock </option>
                        <option value={constants.SUPPLIER}>Fournisseur </option>
                        <option value={constants.ON_SITE}>Sur site </option>
                </select>
                <img alt={""} src={state.isReady!==constants.UNKNOWN?ReadyIcon:NotReadyIcon}/>
            </label>
        </div>
    )
}