import React, {useEffect, useState} from "react";
import {Checkbox, IconButton} from "@mui/material";
import Modal from "react-responsive-modal";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {text} from "../../util/constants";
import IbatApi from "../../../utils/IbatApi";


const api = new IbatApi()


function normalizeRAConfig(ra){
    ra.question = ra.question.sort((a,b) => a.position > b.position ? 1 : -1)
    ra.question = ra.question.map((q, idx)=> {
        q.position = idx
        return q
    })
    return ra
}
function normalizeSelectedQuestion(q){
    q.newAnswers = q.newAnswers.sort((a,b) => a.position > b.position ? 1 : -1)
    q.newAnswers = q.newAnswers.map((a, idx)=> {
        a.position = idx
        return a
    })
    return q
}
function RiskAnalysisModal({ra, questions, answers, refreshAnswers, refreshQuestions, refreshRiskAnalysis, getJobTypeNameList}) {

    const [raSelected, setRaSelected] = useState(ra)
    const [questionSelected, setQuestionSelected] = useState()
    const [answerSelected, setAnswerSelected] = useState()

    const [raConfigEditMode, setRaConfigEditMode] = useState(!ra.id)

    const [objectToSet, setObjectToSet] = useState(!ra.id ? ra : null)

    const [addAnswerToQuestion, setaddAnswerToQuestion] = useState(false)
    const [addQuestionToRa, setaddQuestionToRa] = useState(false)
    const [answerFilter, setAnswerFilter] = useState("")
    const [answerEditMode, setAnswerEditMode] = useState(false)
    const [questionEditMode, setQuestionEditMode] = useState(false)


    const onDragEndQuestionOrder = (result): void => {
        let from = result.source.index
        let to = result.destination.index
        let id = result.draggableId
        if (to !== from){
            raSelected.question.filter(question=>question.id === id)[0].position = -10
        }
        if (to > from){
            raSelected.question.map((q, idx) => {
                if (idx > 0 && idx <= to){
                    q.position --
                }
                return q
            })
            raSelected.question.filter(question=>question.id === id)[0].position = to
        }
        if (to < from){
            raSelected.question.map((q, idx) => {
                if (idx > 0 && idx <= to){
                    q.position ++
                }
                return q
            })
            raSelected.question.filter(question=>question.id === id)[0].position = to
        }
        setRaSelected(normalizeRAConfig(raSelected))
    };
    const onDragEndAnswerOrder = (result): void => {
        let from = result?.source?.index
        let to = result?.destination?.index
        let id = result?.draggableId
        if (to !== from){
            questionSelected.newAnswers.filter(a=>a.id === id)[0].position = -10
        }
        if (to > from){
            questionSelected.newAnswers.map((a, idx) => {
                if (idx > 0 && idx <= to){
                    a.position --
                }
                return a
            })
            questionSelected.newAnswers.filter(question=>question.id === id)[0].position = to
        }
        if (to < from){
            questionSelected.newAnswers.map((a, idx) => {
                if (idx > 0 && idx <= to){
                    a.position ++
                }
                return a
            })
            questionSelected.newAnswers.filter(question=>question.id === id)[0].position = to
        }
        setQuestionSelected(normalizeSelectedQuestion(questionSelected))
    };

    const handleConflictChange= (ev) => {
        let checked = ev.target.checked
        let id = ev.target.value
        if (checked === true){
            if (!answerSelected.conflicts.includes(id)){
                answerSelected.conflicts.push(id)
            }
        }
        else if (checked === false){
            if (answerSelected.conflicts.includes(id)){
                answerSelected.conflicts = answerSelected.conflicts.filter(c=> c !== id)
            }
        }
    }

    function handleAnswerEdit(){
        setAnswerEditMode(false)
        Object.entries(answerSelected).map(([key, value]) => {
            let component = document.getElementById(key)
            if (component){
                let val = component.value
                if (component.type === "checkbox"){
                    val = component.checked
                }
                answerSelected[key] = val
            }
            return [key, value]
        })
        setObjectToSet(answerSelected)
        api.sendRAAnswer(answerSelected).then(r=>{
            setAnswerSelected({...answerSelected, id: r})
            setObjectToSet(answerSelected)
            refreshAnswers()
        })
    }
    function handleQuestionEdit(){
        setQuestionEditMode(false)
        Object.entries(questionSelected).map(([key, value]) => {
            let component = document.getElementById(key)
            if (component){
                let val = component.value
                if (component.type === "checkbox"){
                    val = component.checked
                }
                questionSelected[key] = val
            }
            return [key, value]
        })
        setObjectToSet(questionSelected)
        api.sendRAQuestion(questionSelected).then(q=>{
            setQuestionSelected({...questionSelected, id: q})
            setObjectToSet(questionSelected)
            refreshQuestions()
        })
    }
    function handleRaEdit(){
        setRaConfigEditMode(false)
        objectToSet.company = document.getElementById("company").value
        let newQuestionList = objectToSet.question.map(q=> {
            let aExist = answers.filter(a => a.id === q.prerequisite?.answer?.trim()).length > 0
            let qExist = questions.filter(quest => quest.id === q.prerequisite?.question?.trim()).length > 0
            if(q.prerequisite && (!aExist || !qExist)){
                q.prerequisite = null
            }
            return q
        })
        objectToSet.question = newQuestionList
        setRaSelected(objectToSet)
        api.sendRAConfig(raSelected).then(q=>{
            refreshRiskAnalysis()
        })
    }


    function addAnswer(id){
        setaddAnswerToQuestion(false)
        questionSelected.newAnswers.push({id:id, position:questionSelected.newAnswers?.length?? 0})
        setQuestionSelected({...questionSelected, newAnswers: questionSelected['newAnswers']})
    }
    function deleteAnswer(id){
        setQuestionSelected({...questionSelected, answers: questionSelected.newAnswers.filter(a=> a.id !== id)})
    }
    function addQuestion(id){
        setaddQuestionToRa(false)
        raSelected.question.push({id:id, position:raSelected.question?.length?? 0, prerequisite:null})
        setRaSelected({...raSelected, question: raSelected['question']})
    }
    function deleteQuestion(id){
        setRaSelected({...raSelected, question: raSelected.question.filter(q=> q.id !== id)})
    }

    function showAnswer(a, provided){
        return (
            <li className={provided? "draggable" : ""} ref={provided?.innerRef} {...provided?.draggableProps} {...provided?.dragHandleProps}>
                <b className={"inline"}>{a?.answer}</b>
                {(questionSelected && questionEditMode && provided) &&
                    <b className={"delete inline"} onClick={()=>deleteAnswer(a.id)}>
                        X
                    </b>

                }
            </li>
        )
    }
    function showQuestionFromConfig(q, index){
        let question = questions?.filter(question=>question.id === q.id)[0]
        return (
            <Draggable key={q.id} draggableId={q.id} index={index} isDragDisabled={!raConfigEditMode}>
                {(provided) => (
                    <li className={raConfigEditMode? "displayTable draggable" : ""} onClick={()=>{
                        setQuestionSelected(question)
                    }}  ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                        <div>
                            <span>{q?.position + 1}: {question?.question}</span>
                            <ul className={"answer-list"}>{question?.newAnswers?.map(a => showAnswer(answers.filter(answer => answer.id === a.id)[0]))}</ul>
                        </div>
                        {(raSelected && raConfigEditMode) &&
                            <b className={"delete inline"} onClick={()=>deleteQuestion(q.id)}>
                                X
                            </b>
                        }

                    </li>
                )}
            </Draggable>
        )
    }

    function CrudQuestion(){
        let prerequisite = raSelected.question.filter(q=>q.id === questionSelected.id)[0]?.prerequisite
        return (
            <div className={"secondary-section"}>
                <span>Id : {questionSelected.id}</span><br />
                {!questionEditMode ?
                    <span>Intitulé : <b className={"inline"}>{questionSelected.question}</b></span> :
                    <input defaultValue={objectToSet?.question} id={"question"} type={"text"} placeholder={"Question ?"}/>
                }
                <br />
                <div className={"dnd"}>
                    <DragDropContext onDragEnd={onDragEndAnswerOrder}>
                        <Droppable droppableId="questionConfigDnd">
                            {(provided) => (
                                <ul className="questionConfigDnd questionConfig"  {...provided.droppableProps} ref={provided.innerRef}>
                                    {questionSelected?.newAnswers ?
                                        <div>
                                            {questionSelected.newAnswers.map(a => {
                                                let ans = answers.filter(answer => answer.id === a.id)[0]
                                                return (
                                                    <div onClick={()=>{
                                                        setAnswerSelected(ans)
                                                    }}>
                                                        {questionEditMode ?
                                                            <Draggable key={ans.id} draggableId={ans.id} index={a.position}>
                                                                {(provided) => (showAnswer(ans, provided))}</Draggable> :
                                                            showAnswer(ans)
                                                        }
                                                    </div>
                                                )})}</div> :
                                        ""}
                                    {provided.placeholder}
                                    {questionEditMode ? <IconButton onClick={()=>{
                                        // setAnswerSelected()
                                        setaddAnswerToQuestion(true)}} className={"action-btn validation"}>Ajouter une Réponse</IconButton> : ""}
                                </ul>
                            )}
                        </Droppable>
                    </DragDropContext>
                </div><br />
                <span>JobType :</span>
                <ul>{getJobTypeNameList(questionSelected.new_jobtype)?.map(jt=><li><b>{jt}</b></li>)}</ul>


                {!questionEditMode ?
                    <span><b>{questionSelected.multiple ? "QCM" : "QCU"}</b></span> :
                    <span><span className={"inline"}>QCM : <Checkbox className={"inline"} id={"multiple"} defaultChecked={questionSelected.multiple?? false}/></span></span>
                }

                {!questionEditMode ?
                    <span>La question est <b className={"inline"}>{questionSelected.modifiable ? "" : "non"} modifiable</b> pendant l'intervention</span> :
                    <span><span className={"inline"}>Modifiable une fois l'analyse de risque finie ?<Checkbox className={"inline"} id={"modifiable"} defaultChecked={questionSelected.modifiable?? false}/></span></span>
                }


                {questionEditMode ?
                    <span>Nom de la photo (si question à photo unique) ?
          <input defaultValue={objectToSet?.pictureTitle?? ""} id={"pictureTitle"} type={"text"} placeholder={"Question ?"}/></span>:
                    questionSelected.pictureTitle ?
                        <span>Question à photo unique ({questionSelected.pictureTitle})</span> :
                        ""
                }

                {raConfigEditMode ?
                    <span>Pré-requis :<br/>
            <span>ID de la question : </span>
            <input defaultValue={prerequisite?.question ?? ""} onChange={(ev)=> {
                let oldP = {...objectToSet.question.filter(q=>q.id === questionSelected.id)[0].prerequisite, question:ev.target.value}
                objectToSet.question.filter(q=>q.id === questionSelected.id)[0].prerequisite = oldP
            }} type={"text"} placeholder={"a1bb83fc-dc71-4e04-827e-c6b9150ba937"}/>
            <span>ID de la réponse : </span>
            <input defaultValue={prerequisite?.answer ?? ""}  onChange={(ev)=> {
                let oldP = {...objectToSet.question.filter(q=>q.id === questionSelected.id)[0]?.prerequisite?? {question:null}, answer:ev.target.value}
                objectToSet.question.filter(q=>q.id === questionSelected.id)[0].prerequisite = oldP
            }} type={"text"} placeholder={"a1bb83fc-dc71-4e04-827e-c6b9150ba937"}/>
          </span> :
                    prerequisite &&
                    <span>Pré-requis :<br/>
            <b>Cette question sera affichée si la réponse "{answers.filter(a => a.id === prerequisite.answer)[0].answer}" a été sélectionnée à la question "{questions.filter(q => q.id === prerequisite.question)[0].question}"</b>
          </span>
                }


                {!questionEditMode && !addQuestionToRa &&
                    <IconButton onClick={()=>{
                        setAnswerEditMode(false)
                        setQuestionEditMode(true)
                        setRaConfigEditMode(false)
                        setObjectToSet(questionSelected)
                    }} className={"action-btn validation"}>Éditer</IconButton>
                }
                {addQuestionToRa &&
                    <IconButton onClick={()=>addQuestion(questionSelected.id)} className={"action-btn validation"}>Ajouter</IconButton>
                }
                {questionEditMode &&
                    <div>
                        <IconButton onClick={()=>{
                            setaddAnswerToQuestion(false)
                            setQuestionEditMode(false)
                        }} className={"action-btn cancel"}>{text.CANCEL}</IconButton>
                        <IconButton onClick={handleQuestionEdit} className={"action-btn validation"}>{questionSelected?.id? text.SAVE : text.CREATE}</IconButton>
                    </div>
                }
            </div>
        )
    }
    function CrudAnswer(){
        return (
            <div className={"secondary-section"}>
                <span>Id : {answerSelected.id}</span>

                <span>Intitulé : {!answerEditMode?
                    <b>{answerSelected?.answer}</b>:
                    <input defaultValue={objectToSet?.answer} id={"answer"} type={"text"} placeholder={"Réponse"}/>
                }</span><br />

                <span>JobType :</span>
                {/*<ul>{answerSelected.jobtype?.map(jt=><li><b>{jt}</b></li>)}</ul> <br />*/}
                <ul>{getJobTypeNameList(answerSelected.new_jobtype)?.map(jt=><li><b>{jt}</b></li>)}</ul> <br />

                <span>Nom de la photo (si une par réponse choisie) ?</span>
                {!answerEditMode?
                    <b>{answerSelected?.picture_title ? answerSelected?.picture_title :  "Pas de photo requise"}</b>:
                    <input defaultValue={objectToSet?.picture_title} id={"picture_title"} type={"text"} placeholder={"EPI gants"}/>
                }<br />

                <span>Demande d'info complémentaire à l'utilisateur (si besoin) :</span>
                {!answerEditMode ?
                    <b>{answerSelected?.popup_info ?? "Aucune saisie manuelle demandée"}</b> :
                    <input defaultValue={objectToSet?.popup_info} id={"popup_info"} type={"text"}
                           placeholder={"Merci de préciser"}/>
                }<br />

                <span>Alerte à l'utilisateur (si besoin) :</span>
                {!answerEditMode ?
                    <b>{answerSelected?.prevention_text ?? "Aucune information données"}</b> :
                    <input defaultValue={objectToSet?.prevention_text} id={"prevention_text"} type={"text"} placeholder={"Attention, c'est dangereux"}/>
                }<br />

                {(!answerSelected.conflicts || answerSelected.conflicts?.length === 0) && !answerEditMode ?
                    "" :
                    <div><span>Réponse en conflit avec :</span>
                        {answerEditMode ? questionSelected?.newAnswers?.map(a => {
                                if(a.id !== answerSelected.id){
                                    return (
                                        <span>
          <Checkbox onChange={handleConflictChange} className={"inline"} value={a.id} defaultChecked={answerSelected.conflicts?.includes(a.id)}/> {answers.filter(answer => answer.id === a.id && answer)[0].answer}
          </span>)
                                }
                                return <span></span>
                            }) :
                            <ul>
                                {answerSelected.conflicts?.map(c=>

                                    showAnswer(answers.filter(answer => answer.id === c)[0])
                                )}
                            </ul>}</div>

                }


                {answerEditMode?
                    <span>
              <span className={"inline"}>
                Rédhibitoire (stop l'intervention): <Checkbox id={"crippling"} className={"inline"} defaultChecked={answerSelected.crippling?? false}/>
              </span>
            </span> :
                    answerSelected.crippling?
                        <span>Rédhibitoire (stop l'intervention)</span>:
                        ""
                }

                {(!answerEditMode && !addAnswerToQuestion) &&
                    <IconButton onClick={()=> {
                        setAnswerEditMode(true)
                        setQuestionEditMode(false)
                        setRaConfigEditMode(false)
                        setObjectToSet(answerSelected)
                    }} className={"action-btn validation"}>Éditer</IconButton>
                }
                {addAnswerToQuestion &&
                    <IconButton onClick={()=>addAnswer(answerSelected.id)} className={"action-btn validation"}>Ajouter</IconButton>
                }
                {answerEditMode &&
                    <div>
                        <IconButton onClick={()=>setAnswerEditMode(false)} className={"action-btn cancel"}>{text.CANCEL}</IconButton>
                        <IconButton onClick={handleAnswerEdit} className={"action-btn validation"}>{answerSelected?.id? text.SAVE : text.CREATE}</IconButton>
                    </div>
                }

            </div>
        )
    }

    function AnswerList(){
        return (
            <div className={"secondary-section"}>
                <input type={"text"} defaultValue={answerFilter} onKeyDown={e => {
                    if (e.key === 'Enter'){
                        setAnswerFilter(e.target.value)
                    }
                }}/>
                <ul>
                    {answers.filter(a => a.answer?.includes(answerFilter)).map(a => {
                        return (
                            <div onClick={()=>setAnswerSelected(a)}>
                                {showAnswer(a)}
                            </div>
                        )
                    })}
                </ul>
                <div className={"sticky-bottom flex-column"}>
                    <IconButton onClick={()=>{
                        setAnswerEditMode(true)
                        setQuestionEditMode(false)
                        setRaConfigEditMode(false)
                        setAnswerSelected({answer: null, conflicts: null, crippling: null, datatype:"answer", jobtype: null, picture_title:null, popup_info: null, prevention_text: null})
                        setObjectToSet()
                    }} className={"action-btn validation"}>Créer une réponse</IconButton>
                </div>
            </div>
        )
    }
    function QuestionList(){
        return (
            <div className={"secondary-section"}>
                <ul>
                    {questions?.map(q => {
                        return (
                            <div onClick={()=>setQuestionSelected(q)}>
                                <li>{q.question}</li>
                            </div>
                        )
                    })}
                </ul>
                <div className={"sticky-bottom flex-column"}>
                    <IconButton onClick={()=>{
                        setAnswerEditMode(false)
                        setQuestionEditMode(true)
                        setRaConfigEditMode(false)
                        setQuestionSelected({answers: [], datatype: "question", inclinometer: null, modifiable: null, multiple:null, pictureTitle: null, question: null})
                        setObjectToSet()
                    }} className={"action-btn validation"}>Créer une Question</IconButton>
                </div>
            </div>
        )
    }

    return (
        <div className={"grid edit-ra-modal"}>
            <div className={"secondary-section"}>
                {!raConfigEditMode ?
                    `${raSelected?.company ?? "Générique"} - ${raSelected?.isPartial ? "Partielle": "Complète"} - ${getJobTypeNameList(raSelected?.new_jobtype)}` :
                    <span>
                        <input defaultValue={raSelected.company} placeholder={"id de company"} id={"company"} type={"number"}/>
                        Partielle ? <Checkbox className={"inline"} defaultChecked={raSelected?.isPartial?? false} id={"isPartial"}/>
                    </span>
                }
                <div>
                    <DragDropContext onDragEnd={onDragEndQuestionOrder}>
                        <Droppable droppableId="questionConfigDnd">
                            {(provided) => (
                                <ul  className="questionConfigDnd questionConfig" {...provided.droppableProps} ref={provided.innerRef}>
                                    {raSelected?.question ? raSelected.question.map(q => showQuestionFromConfig(q, q.position)): ""}
                                    {provided.placeholder}
                                </ul>
                            )}
                        </Droppable>
                    </DragDropContext>
                </div>

                <div className={"sticky-bottom flex-column"}>
                    {raConfigEditMode ?
                        <>
                            <IconButton onClick={()=>setaddQuestionToRa(true)} className={"action-btn validation add-button"}>{text.ADD_BUTTON}</IconButton><br />
                            <IconButton onClick={()=>{
                                setaddQuestionToRa(false)
                                setRaConfigEditMode(false)
                            }} className={"action-btn cancel"}>{text.CANCEL}</IconButton>
                            <IconButton onClick={handleRaEdit} className={"action-btn validation"}>{text.SAVE}</IconButton>
                        </>
                        :
                        <IconButton onClick={()=>{
                            setAnswerEditMode(false)
                            setQuestionEditMode(false)
                            setRaConfigEditMode(true)
                            setObjectToSet(raSelected)
                        }} className={"action-btn validation"}>Éditer</IconButton>
                    }
                </div>
            </div>
            <div className={"secondary-section grid scinded"}>
                <QuestionList/>
                {questionSelected && <CrudQuestion/>}
            </div>
            <div className={"secondary-section grid scinded"}>
                <AnswerList/>
                {answerSelected && <CrudAnswer/>}
            </div>
        </div>
    )
}
function RiskAnalysisItem({editPopup, ra, jtName}){
    return(
        <div className={"grid  ra-grid MuiIconButton-root action-btn neutral"} onClick={()=>editPopup(ra)}>
            <div>{ra.company??"Générique"}</div>
            <div>{ra.isPartial? "Partielle": "Complète"}</div>
            <div>{jtName}</div>
        </div>
    )
}

export default function RiskAnalysisManager({getJobTypeNameList}){

    const [questions, setQuestions] = useState();
    const [answers, setAnswers] = useState();
    const [raModal, setRaModal] = useState(false)
    const [raSelected, setRaSelected] = useState()
    const [raList, setRaList] = useState();

    useEffect(() => {
        refreshQuestions()
        refreshAnswers()
        refreshRiskAnalysis()
    }, []);

    const editRAModal = (ra) => {
        setRaSelected(normalizeRAConfig(ra))
        setRaModal(true)
    }

    function refreshQuestions() {
        api.getAllQuestion().then(q => setQuestions(q))
    }
    function refreshAnswers() {
        api.getAllAnswers().then(a => setAnswers(a))
    }
    function refreshRiskAnalysis(){
        api.getAllRa().then(ra => setRaList(ra))
    }


    return(
        <div className={"flex-column"}>
            <div className={"flex-column"}>

                <div className={"grid ra-grid"}>
                    <div>Entreprise concernée</div>
                    <div>Type d'AR</div>
                    <div>Corps de métier</div>
                </div>
                {raList?.map(ra=>{
                    const jt = getJobTypeNameList(ra.new_jobtype)
                    return <RiskAnalysisItem editPopup={editRAModal} ra={ra} jtName={jt}/>
                })}
            </div>

            <IconButton onClick={()=>editRAModal({company:null, datatype:"config", isPartial: null, jobtype: null, question:[]})} className={"action-btn validation"}>Créer une AR</IconButton>
            <Modal open={raModal} onClose={() => setRaModal(false)}>
                <RiskAnalysisModal
                    getJobTypeNameList={getJobTypeNameList}
                    ra={raSelected}
                    questions={questions}
                    answers={answers}
                    refreshRiskAnalysis={refreshRiskAnalysis}
                    refreshQuestions={refreshQuestions}
                    refreshAnswers={refreshAnswers}
                />
            </Modal>
        </div>
    )
}