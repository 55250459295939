import 'react-responsive-modal/styles.css';
import './style/review.css';
import {BrowserRouter, Route, Routes} from "react-router-dom"
import Intervention from "./components/bean/Intervention";
import Site from "./components/bean/Site";
import Clients from "./components/lists/Clients"
import Client from "./components/bean/Client";
import Sites from "./components/lists/Sites";
import Interventions from "./components/lists/Interventions";
import Parameter from "./components/bean/Parameter";
import PrivateRoute from "./components/util/PrivateRoute";
import AuthPage from "./components/util/AuthPage";
import AdminCrud from "./components/admin/AdminCrud";
import Devis from "./components/bean/Devis";
import ErrorManager from "./utils/Error";
import IbatApi from "./utils/IbatApi";
import {SnackbarProvider} from "notistack";
import React, {useEffect, useState} from "react";
import SignInForm from "./components/forms/SignInForm";
import Supply from "./components/bean/Supply";
import TimeSheet from "./time-sheet/TimeSheet";
import Download from "./components/bean/Download";
import SockJsClient from 'react-stomp';
import {NotificationContainer, NotificationManager} from 'react-notifications'
import Notifications from "./components/bean/Notifications";
import Modal from "react-responsive-modal";
import InteractionClient from "./components/bean/InteractionClient";
import Messenger from "./messenger/Messenger";
import Quotations from "./components/lists/Quotations";
import Quotation from "./quotation/Quotation";
import Navbar from "./components/util/Navbar";
import TrackFile from "./components/bean/TrackFile";


function App() {
    const api = new IbatApi()

    const myUsername = api.getTokenData()?.username
    const [lastMessage, setLastMessage] = useState(0)
    const [notificationNumber, setNotificationNumber] = useState()
    const [token, setToken] = useState("")
    const [logo] = useState(api.getLogoCookie)
    const [style] = useState(api.getStyleCookie() ?? "") //(window.location.href.split("//")[1].split(".")[0].includes("localhost") ? "" : window.location.href.split("//")[1].split(".")[0]+"-"))
    const [showInteractionClient, setShowInteractionClient] = useState(false);

    useEffect(() => {
        refreshToken()
        import(`./style/variable/${style}variable.css`)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token])
    useEffect(()=>{
        handleNotificationNumberUpdate()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notificationNumber])

    const refreshToken = () => {
        setToken(api.getToken())
        // getCompanyFrontData()
    }
    /*function getCompanyFrontData(){
      if (token){
        api.getCompany().then(resp=>{
          let style1 = resp.frontStyle
          if (style1 !== style || !api.getStyleCookie()){
            api.setStyleCookie(style1)
          }
          api.getFile(resp.logo).then(r=>{
            api.setLogoCookie(r.b64)
            setLogo(r.url)
          })
          import(`./style/variable/${style}variable.css`)
        })
      }
    }
    function getDefaultStatus() {
        let tokenData = api.getTokenData();
        if (!tokenData) {
            return
        }
        let role = 1
        switch (role) {
            case "GESTIONNAIRE" :
                return {defaultFilter: ""};
            case "TECHNICALMANAGER" :
                return {defaultFilter: ""};
            case "BILLMANAGER":
                return {defaultFilter: ""}
            default :
                return {defaultFilter: ""}
        }
    }*/

    const handleDeconnexion = () => {
        api.deconexion()
    }
    /*function getNotificationsNumber(){
        api.getNotificationsNumber()
            .then(resp => {
                setNotificationNumber(resp < 100 ? resp : "99+")
            })
    }*/
    function handleNotificationNumberUpdate(){
        let element = document.getElementById("notificationNumber")
        if (!element){
            return
        }
        if (notificationNumber && notificationNumber!==0){
            element.style.visibility = "visible"
            element.innerHTML = notificationNumber
        } else {
            element.style.visibility = "hidden"
        }
    }

    /*let onConnected = () => {
        getNotificationsNumber()

    }*/
    let onMessageReceived = (msg) => {
        if (msg.target?.includes("msg/")){
            setLastMessage(msg)
            return
        }
        // getNotificationsNumber()
        if (!msg.target || window.location.pathname === "/notifications"){
            return;
        }
        NotificationManager.info(msg.message.replace("\n", "\r\n"), msg.from?.name, 20000, ()=>{
            if (msg.target.includes("interactionClient")){
                let vars = {}
                let filter = msg.target.split("?")[1].split("&")
                for (const filterKey in filter) {
                    let values = filter[filterKey].split("=")
                    vars[values[0].replace("interventionId", "id")] = values[1]
                }
                setShowInteractionClient(vars)
            }
            else {
                window.open(`/${msg.target}`)
            }
        });
    }

    return (
        <div className={"grid myBody"}>
            {api.getToken()
                ?
                <Navbar logo={logo} myUsername={myUsername} handleDeconnexion={handleDeconnexion} notificationNumber={notificationNumber}/>
                :
                <div></div>
            }

            <ErrorManager>
                <SnackbarProvider maxSnack={3}>
                    <BrowserRouter>
                        <Routes>
                            <Route path="/login" element={<AuthPage refreshToken={refreshToken} />}/>
                            <Route path="/subscribe" element={<SignInForm refreshToken={refreshToken} />}/>

                            <Route path={"/interventions/:id"} element={
                                <PrivateRoute>
                                    <Intervention />
                                </PrivateRoute>
                            }/>

                            <Route path={"/trackFile/:token"} element={
                                /*<PrivateRoute>
                                </PrivateRoute>*/
                                <TrackFile />
                            }/>
                            <Route path={"/interventions"} element={
                                <PrivateRoute>
                                    <Interventions />
                                </PrivateRoute>
                            }/>
                            <Route path={"/supplies"} element={
                                <PrivateRoute>
                                    <Supply />
                                </PrivateRoute>
                            }/>
                            <Route path={"/parameter"} element={
                                <PrivateRoute>
                                    <Parameter />
                                </PrivateRoute>
                            }/>
                            <Route path={"/admin"} element={
                                <PrivateRoute>
                                    <AdminCrud />
                                </PrivateRoute>
                            }/>

                            <Route path={"/devis/:id"} element={
                                <PrivateRoute>
                                    <Devis />
                                </PrivateRoute>
                            }/>
                            <Route path={"/times"} element={
                                <PrivateRoute>
                                    <TimeSheet />
                                </PrivateRoute>
                            }/>
                            <Route path={"/client/:id"} element={
                                <PrivateRoute>
                                    <Client />
                                </PrivateRoute>
                            }/>
                            <Route path={"/clients"} element={
                                <PrivateRoute>
                                    <Clients />
                                </PrivateRoute>
                            }/>
                            <Route path={"/site/:id"} element={
                                <PrivateRoute>
                                    <Site />
                                </PrivateRoute>
                            }/>
                            <Route path={"/sites"} element={
                                <PrivateRoute>
                                    <Sites />
                                </PrivateRoute>
                            }/>
                            <Route path={"/download-apk"} element={
                                <PrivateRoute>
                                    <Download />
                                </PrivateRoute>
                            }/>
                            <Route path={"/notifications"} element={
                                <PrivateRoute>
                                    <Notifications />
                                </PrivateRoute>
                            }/>
                            <Route path={"/quotation/:id"} element={
                                <PrivateRoute>
                                    <Quotation />
                                </PrivateRoute>
                            }/>
                            <Route path={"/quotations"} element={
                                <PrivateRoute>
                                    <Quotations />
                                </PrivateRoute>
                            }/>
                            <Route path={""} element={
                                <PrivateRoute>
                                    <Interventions />
                                </PrivateRoute>
                            }/>

                            <Route path="*" element={
                                <PrivateRoute>
                                    {/*<PageNotFound />*/}
                                    <Interventions />
                                </PrivateRoute>
                            } />

                            {/*<PrivateRoute path={"/interventions/:id"} component={Intervention}
                                            properties={getDefaultStatus()}/>
                              <PrivateRoute path={"/interventions"} component={Interventions}/>
                              <PrivateRoute path={"/supplies"} component={Supply} properties={getDefaultStatus()}/>
                              <PrivateRoute path={"/parameter"} component={Parameter}/>
                              <PrivateRoute path={"/admin"} component={AdminCrud}/>

                              <PrivateRoute path={"/devis/:id"} component={Devis}/>
                              <PrivateRoute path={"/times"} component={TimeSheet}/>

                              <PrivateRoute path={"/client/:id"} component={Client}/>
                              <PrivateRoute path={"/clients"} component={Clients}/>

                              <PrivateRoute path={"/site/:id"} component={Site}/>
                              <PrivateRoute path={"/sites"} component={Sites}/>

                              <PrivateRoute path={"/download-apk"} component={Download}/>

                              <PrivateRoute path={"/notifications"} component={Notifications}/>

                              <PrivateRoute path={"/quotation/:id"} component={Quotation}/>
                              <PrivateRoute path={"/quotations"} component={Quotations}/>

                              <PrivateRoute path={""} component={Interventions} properties={getDefaultStatus()}/>*/}

                        </Routes>
                    </BrowserRouter>

                    <Modal open={showInteractionClient} onClose={()=>setShowInteractionClient(false)}>
                        <InteractionClient {...showInteractionClient}/>
                    </Modal>
                </SnackbarProvider>
            </ErrorManager>
            {
                window.location.hostname !== "localhost" &&
                <SockJsClient
                    url={"https://backend.ebat.tegeo.fr/notification-server/socket"}
                    topics={[`/topic/${myUsername}`]}
                    // onConnect={onConnected}
                    onDisconnect={()=>console.log("Disconnected!")}
                    onMessage={msg => onMessageReceived(msg)}
                    debug={true}
                />
            }

            <NotificationContainer />
            {
                token && <Messenger lastNotification={lastMessage}/>
            }
        </div>
    );
}

export default App;
