import React, {useState} from "react"
import SearchTeam from "./SearchTeam";
import IbatApi from "../../utils/IbatApi";
import EditInterventionTeamIcon from "../../images/team-maj.png";
import {IconButton} from "@mui/material";
import constants, {text} from "../util/constants";
import {useSnackbar} from "notistack";

export default function EditInterventionTeam({id, update}) {
    let api = new IbatApi();
    const { enqueueSnackbar} = useSnackbar();
    const [state, setState] = useState({team: {id: "", name: ""},
    })

    function handleTeam(data) {
        setState({...state, team: data})
    }

    function updateTeam() {

        api.setInterventionTeam(id, state.team.id)
            .then(r => {
                update()
                enqueueSnackbar(text.SNACKBAR_SAVED,{variant:"success"})
            })
            .catch(e=>{
                enqueueSnackbar(text.SNACKBAR_ERROR,{variant:"error"})
            })
    }
    function deleteTeam() {
        api.removeInterventionTeam(id).then(r => update())
            .then(r => enqueueSnackbar(text.SNACKBAR_SAVED,{variant:"success"}))
            .catch(e=>enqueueSnackbar(text.SNACKBAR_ERROR,{variant:"error"}))
    }

    if(!api.hasRole(constants.PERMISSION_PM)){
        return <h3>{text.NO_RIGHT}</h3>
    }
    return (
        <div className={"editIntervention form flex-column primary-section"}>
            <div className={"img-container"}>
                <img alt={""} src={EditInterventionTeamIcon}/>
            </div>
            <h2>{text.SELECT_TEAM}</h2>
            <SearchTeam onSave={handleTeam} value={state.team}/>
            <div className={"flex-column"}>
                <IconButton className={"action-btn cancel"} onClick={deleteTeam}>{text.UNPROGRAM_TEAM}</IconButton>
                <IconButton className={"action-btn validation"} onClick={updateTeam}>{text.SAVE}</IconButton>
            </div>
        </div>
    )
}