import React, {useEffect, useState} from "react";
import {IconButton} from "@mui/material";
import DocumentLoader from "../../util/DocumentLoader";
import Modal from "react-responsive-modal";
import IbatApi from "../../../utils/IbatApi";



const api = new IbatApi()

function ShowUser({user}){
    const [logo, setLogo] = useState()
    useEffect(() => {
        api.getFile(user.company?.logo).then(resp => {
            setLogo(resp.url)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return(
        <div className={"userModal"}>
            <span>id: <b>{user.id}</b></span>
            <span>Nom d'utilisateur : <b>{user.name}</b></span><br />
            <span>Company :</span>
            <div className={"companyCoord"}>
                <div>
                    <span>id: <b>{user.company?.id}</b></span>
                    <span>Nom: <b>{user.company?.name}</b></span>
                    <span>Siren: <b>{user.company?.siren}</b></span>
                    <span>Adresse: <b>{user.company?.address}, {user.company?.cp}, {user.company?.ville}</b></span>
                    <span>Mail: <b>{user.company?.mail}</b></span>
                    <span>Tel: <b>{user.company?.phone}</b></span>
                    <span><b>{user.company?.billable ? "Facturable" : "Non facturable"}</b></span>
                    <span>RAL: <b>{user.company?.defaultRiskAnalysisLevel}</b></span>
                </div>
                <img alt={"logo"} src={logo}/>
            </div>
        </div>
    )
}

function ShowContact({contact, refresh}){
    const [userModal, setUserModal] = useState(false)
    function processContact(){
        api.processContact(contact.id, true)
            .then(resp=> refresh())
    }
    return (
        <div className={"secondary-section"}>
            <div className={"contact"}>
                <div className={"flex sender"}>
                    De : &nbsp;
                    <p className={"as-link"} onClick={() => setUserModal(true)}>
                        {contact.from.name}
                    </p>
                </div>
                <div>
                    <span className={"title"}>{contact.title}</span><br />
                    <span className={"contact"}>{contact.contact}</span>
                </div>
                <IconButton className={"action-btn validation"} id={contact.id} onClick={processContact}> Traité !</IconButton>
            </div>
            {contact.attachment &&
                <div className={"attachment"}>
                    <DocumentLoader name={contact.attachment}/>
                </div>}

            <Modal open={userModal} onClose={()=>setUserModal(false)}>
                <ShowUser user={contact.from}/>
            </Modal>
        </div>
    )
}
export default function ContactCrud(){
    const [contacts, setContacts] = useState([]);

    useEffect(() => {
        refreshContacts()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function refreshContacts(){
        api.getContact(false).then(resp => setContacts(resp))
    }
    return(
        <div className={"grid contact-grid"}>
            {contacts?.map(c => <ShowContact contact={c} refresh={refreshContacts}/>)}
        </div>
    )

}