import React, {Fragment, useEffect, useRef, useState} from "react";
import GoogleMap from "google-map-react"
import Pin from "../util/Pin";
import constants, {text} from "../util/constants";
import useSupercluster from "use-supercluster";
import {IconButton, Drawer, Tooltip} from "@mui/material";
import CriteriaIcon from "../../images/button-filter.png";
// import "../../style/MapBeanStyle.css";
import LoadingSVG from "../../images/LoadingSVG.svg";
import Supercluster from "supercluster";


export default function MapBean({
                                    loading,
                                    columns,
                                    pageSize,
                                    onHandleFilter,
                                    onEndTyping,
                                    rowCount,
                                    onHandleChangeRowsPerPage,
                                    pinList,centerPin,width,height}) {
    const style = {
        container: {
            width: width?width:'100%',
            height: height?height:'90%',
        }
    }

    useEffect(()=>{
        onHandleChangeRowsPerPage({target:{value:constants.DEFAULT_MAP_PIN_NUMBER}})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const [bounds, setBounds] = useState(null);
    const [zoom,setZoom]=useState(6)
    const [timer, setTimer] = useState(null)
    const [typing, setTyping] = useState(false)
    const [openDrawer,setOpenDrawer]=useState(false)
    const [hoveredPin,setHoveredPin]=useState(null)
    const [state,setState]=useState({
        pinCenter: centerPin?centerPin: constants.MAPCENTER,
        pinZoom: 6,
    })
    const mapRef = useRef(null)
    const points =pinList.map(pin => ({
        type: "Feature",
        properties: { cluster: false, id: pin.id,  site:pin.site,dateIntervention:pin.dateIntervention, type:pin.type, ...pin, pin:pin},
        geometry: {
            type: "Point",
            coordinates: [
                pin.longitude,
                pin.latitude
            ]
        }
    }))
    /*const {clusters}=useSupercluster({
        points,//:points,
        bounds,//:bounds,
        zoom,//:zoom,
        options:{
            maxZoom: 22,
            radius:80,
            map: (cluster)=>({list:[cluster.pin]}),
            reduce:(currentCluster,clustertoAdd)=>{currentCluster.list=[...currentCluster.list,...clustertoAdd.list]}
        }
    });*/
    const index = new Supercluster({radius: 40, maxZoom: 16});
    index.load(points);

    useEffect(() => {
    }, [hoveredPin])



    function handleChange(e){
        // console.log(e)
        // console.log(mapRef)
        setZoom(e.zoom)
        const bound=[e.bounds.sw.lng, e.bounds.sw.lat, e.bounds.ne.lng, e.bounds.ne.lat];
        setBounds(bound)
        // setState({...state, ...e})
    }

    function getColor(id){
        switch (id){
            case constants.INTERVENTION_URGENCE:return "rgba(219,2,2,0.5)";
            case constants.INTERVENTION_ENTRETIEN:return "rgba(0,88,253,.5)";
            case constants.INTERVENTION_SAV:return"rgba(255,100,0,.5)";
            case constants.INTERVENTION_CHANTIER:return "rgba(106,0,255,.5)";
            case constants.INTERVENTION_DECHETTERIE:return "rgba(42,176,42,0.8)";
            case constants.INTERVENTION_FOURNISSEUR:return "rgba(140,138,138,0.8)";
            default :return "rgba(58,58,58,0.8)";
        }
    }


    function handleFilterChange(value) {
        clearTimeout(timer);

        onHandleFilter(value)

        let time = setTimeout(triggerChange, 600);
        setTimer(time)
    }

    function handleKeyDown(e) {
        if (e.keyCode === 13) {
            triggerChange();
        }
    }

    function triggerChange() {
        setTyping(!typing)
        onEndTyping(!typing);
    }

    /*const handleChangeRowsPerPage = (event) => {
        onHandleChangeRowsPerPage( event)
    };*/

    function getFilter(){
        return(
                columns.filter(d=>d.isFiltrable).map((d) =>
                        <input key={d.id} className={"map-criteria-icon"} id={d.id} placeholder={d.label} name={d.id} type={d.type}
                               onChange={handleFilterChange} onKeyDown={handleKeyDown}/>
                    )

        )
    }


    return (
        <Fragment>
            <Tooltip title={text.FILTERS_DISPLAY}>
                <IconButton className={"map-criteria-icon"} onClick={()=>setOpenDrawer(true)}>
                    <img alt={"img"} className={"criteria-icon"} src={CriteriaIcon} />
                </IconButton>
            </Tooltip>
            <Drawer className={"mapDrawer"} variant={"persistent"} anchor={"left"} open={openDrawer} onClose={()=>setOpenDrawer(false)}>
                <IconButton className={"map-criteria-icon"} onClick={()=>setOpenDrawer(false)}> ‹ </IconButton>
                {loading && <img alt={""} className={"LoadingSVG"} src={LoadingSVG}/>}
                {getFilter()}
            </Drawer>
            <div style={style.container}>
                <GoogleMap
                    bootstrapURLKeys={{key: process.env.REACT_APP_GOOGLE_API_KEY}} // set if you need stats etc ...
                    defaultCenter={constants.MAPCENTER}
                    defaultZoom={6}
                    options={{mapId:process.env.REACT_APP_GOOGLE_MAP_ID,mapTypeControl: true,gestureHandling:"greedy", clickableIcons: false}}
                    //{...state}
                    yesIWantToUseGoogleMapApiInternals={true}
                    onChange={handleChange}
                >

                    {index.points.length > 0 && bounds &&
                        index?.getClusters(bounds, zoom)?.map((p, idx) =>
                            <div
                                key={idx}
                                onMouseEnter={()=>setHoveredPin(idx)}
                                onMouseLeave={()=>setHoveredPin(null)}
                                lat={p.geometry.coordinates[1]}
                                lng={p.geometry.coordinates[0]}
                            >

                                <Pin
                                    data={p.properties}
                                    text={p.properties.cluster?p.properties.point_count:p.properties.id}
                                    scale={p.properties.cluster?p.properties.point_count/5:0}
                                    cluster={p.properties.cluster}
                                    // onHover={setHoveredPin}
                                    color={p.properties.cluster?"rgb(134,134,134)":getColor(p.properties.type.id)}
                                >
                                    {
                                        hoveredPin === idx &&
                                        <div className={"bord1 planning-card br05"}>
                                            <div>
                                                {
                                                    !p.properties.cluster?
                                                    <>
                                                        <div>{p.properties.id}</div>
                                                        <div>{p.properties.type.name}</div>
                                                        <div>{text.STATUS_TRADUCTION[p.properties.status]}</div>
                                                        <div>{p.properties.estimateDuration.name}</div>
                                                        <div>{p.properties.dateIntervention?p.properties.dateIntervention:constants.NULL}</div>
                                                        <a rel={"noreferrer"} href={`/intervention/${p.properties.id}`} target={"_blank"}>
                                                            {`${p.properties.site.name} ${p.properties.address.name} ${p.properties.cp} ${p.properties.ville}`}
                                                        </a>


                                                    </>
                                                    :<Fragment>
                                                        <div>{p.properties.point_count} {text.INTERVENTIONS} </div>
                                                        <ol>
                                                            {index.getLeaves(p.id, 100, 0)?.map(elem=><li><a href={`/intervention/${elem.properties.id}`}>{elem.properties.site.name} : {elem.properties.type.name} [{elem.properties.status}]</a></li>)}
                                                        </ol>
                                                    </Fragment>
                                                }
                                            </div>

                                        </div>
                                    }
                                </Pin>
                            </div>
                        )
                    }
                </GoogleMap>
            </div>
        </Fragment>
    );
}