import React, {useEffect, useState} from "react";
import FileUploader from "../util/FileUploader";
import {IconButton, Tooltip} from "@mui/material";
import {enqueueSnackbar, useSnackbar} from "notistack";
import IbatApi from "../../utils/IbatApi";
import {text} from "../util/constants";
import {Navigate} from "react-router-dom";
import Logo from "../../images/ebat-logo.png";
import {validateEmail, validateSiren} from "../../utils/Utils";
import Select from "react-select";


export default function SignInForm({refreshToken}) {
    const [user, setUser] = useState({username: "", password: "", confirmPassword: ""})
    const [company, setCompany] = useState({
        name: "",
        siren: "",
        address: "",
        mail: "",
        ville: "",
        cp: ""
    })
    const [logo, setLogo] = useState()
    const {enqueueSnackbar} = useSnackbar()
    const api = new IbatApi();
    const [jobTypeList, setJobTypeList] = useState();
    const notLogged = api.getToken() === "" || api.getToken() === null


    useEffect(() => {
        api.getJobTypes().then(resp => {
            setJobTypeList(
                resp.map(jt => {
                        return {
                            label: jt.name,
                            value: jt.id
                        }
                    }
                ))
        })
    }, []);

    function handleLogin() {
        api.login({email: user.email, password: user.password}).then(respLogin => {
            api.getCompany()
                .then(resp=>{
                    let style1 = resp.frontStyle
                    if (style1 !== api.getStyleCookie()){
                        api.setStyleCookie(style1)
                    }
                    api.getFile(resp.logo)
                        .then(r=>{
                            api.setLogoCookie(r.b64)
                            // setLogo(r.url)
                            endLoginProcess(respLogin)
                        })
                        .catch(e => {
                            endLoginProcess(respLogin)
                        })
                })
                .catch(e => enqueueSnackbar(e.message, {variant: "error"}))
        })
            .catch(e => enqueueSnackbar(e.message, {variant: "error"}))
    }

    function endLoginProcess(resp){
        if (resp) {
            refreshToken()
            window.location.href = "/parameter"
        }
    }

    function sanitizeSiren(siren) {
        return siren.replace(/[^\d]/g, "");
    }

    function handleLogo(mlogo) {
        setLogo(mlogo)
    }

    function handleJobType(e) {
        setCompany({...company, jobType: e.map(jt => jt.value)})
    }

    function handleUserChange(e) {
        const name = e.target.name
        const value = e.target.value
        setUser({...user, [name]: value})
    }

    function handleCompanyChange(e) {
        const name = e.target.name
        let value = e.target.value
        if (name === "siren") {
            value = sanitizeSiren(value.substring(0, 11))
        }
        setCompany({...company, [name]: value})
    }

    function subscribe() {
        if (!user.username || !user.password || !company.name || !company.siren || !company.address || !company.cp || !company.ville) {
            enqueueSnackbar(text.FILL_ALL_INPUTS, {variant: "warning"})
            return
        }
        if (user.password !== user.confirmPassword) {
            enqueueSnackbar(text.SNACKBAR_PASSWORD_ERROR, {variant: "error"})
            return
        }
        if (!logo) {
            enqueueSnackbar(text.NO_LOGO, {variant: "warning"})
            return
        }
        if (!validateEmail(user.email) || !validateEmail(company.mail)) {
            return;
        }
        if (!validateSiren(company.siren)) {
            return;
        }
        api.subscribe({user: user, company: {...company, logo: logo}})
            .then(d => handleLogin())
            .catch(e => {
                enqueueSnackbar(e?.response?.data ?? e.message, {variant: "error"})
            })
    }

    if (notLogged) {
        return (
            <div className={"company-creation flex-column primary-section"}>
                <div className={"header-section"}>
                    <div className={"flex-row"}>
                        <img src={Logo} alt={"logo"}/>
                    </div>
                    <div className={"flex-row"}>
                        <h2>{text.H2_SUBSCRIBE}</h2>
                    </div>
                </div>
                <div className={"light-frame"}>
                    <Tooltip title={text.TOOLTIP_ENTER_USERNAME} aria-label="!">
                        <input className={"large"} type={"text"} value={user.username}
                               name={"username"} placeholder={text.ENTER_USERNAME}
                               onChange={handleUserChange}/>
                    </Tooltip>
                    <Tooltip title={text.TOOLTIP_ENTER_USER_EMAIL}>
                        <input className={"large"} type={"email"} value={user.email} name={"email"}
                               placeholder={text.MAIL} onChange={handleUserChange}/>
                    </Tooltip>
                    <Tooltip title={text.TOOLTIP_PASSWORD}>
                        <input className={"large"} type={"password"} value={user.password}
                               name={"password"} placeholder={text.ENTER_PASSWORD}
                               onChange={handleUserChange}/>
                    </Tooltip>
                    <input className={"large"} type={"password"} value={user.confirmPassword}
                           name={"confirmPassword"} placeholder={text.CONFIRM_PASSWORD}
                           onChange={handleUserChange}/>
                </div>
                <div className={"company light-frame primary-section"}>

                    <Select
                        styles={{menuPortal: (base) => ({...base, zIndex: 9999})}}
                        menuPortalTarget={document.body}
                        isMulti
                        onChange={handleJobType}
                        placeholder={"Corps de métier concernés"}
                        options={jobTypeList}
                        value={jobTypeList?.filter(jt=> company?.jobType?.includes(jt.value))}
                    />

                    <Tooltip title={text.TOOLTIP_SIREN}>
                        <input className={"large"} type={"text"} value={company.siren.replace(/(.{3})/g, "$1 ").substring(0, 11)}
                               name={"siren"} placeholder={text.SIREN}
                               onChange={handleCompanyChange}/>
                    </Tooltip>
                    <input className={"large"} type={"text"} value={company.name} name={"name"}
                           placeholder={text.COMPANY_NAME} onChange={handleCompanyChange}/>
                    <Tooltip title={text.TOOLTIP_COMPANY}>
                        <input className={"large"} type={"text"} value={company.address}
                               name={"address"} placeholder={text.ADDRESS}
                               onChange={handleCompanyChange}/>
                    </Tooltip>
                    <div className={"zipTown flex-row"}>
                        <input className={"townInput large"} type={"text"} value={company.cp}
                               name={"cp"} placeholder={text.ZIPCODE}
                               onChange={handleCompanyChange}/>
                        <input className={"large"} type={"text"} value={company.ville}
                               name={"ville"} placeholder={text.TOWN}
                               onChange={handleCompanyChange}/>
                    </div>
                    <Tooltip title={text.TOOLTIP_ENTER_COMPANY_EMAIL}>
                        <input className={"large"} type={"email"} value={company.mail} name={"mail"}
                               placeholder={text.MAIL} onChange={handleCompanyChange}/>
                    </Tooltip>
                    <div className={"flex-row"}>
                        <div className={"logo-to-upload light-frame flex-column"}>
                            {text.COMPANY_LOGO}
                            <FileUploader files={[]} onUpload={handleLogo} single/>
                        </div>
                        {
                            logo && logo[0]?.data &&
                            <img src={"data:image/png;base64,"+logo[0].data} alt={"logo"} className={"logo-to-go"}/>
                        }
                    </div>
                </div>
                <IconButton onClick={subscribe}
                            className={"action-btn validation"}>{text.SUBSCRIBE}</IconButton>
                <a href={"/login"}>{text.RETURN_TO_LOGIN}</a>
            </div>
        )
    } else {
        return <Navigate to={{pathname: '/'}}/>
    }

}
