import React, {Fragment, useEffect, useState,} from "react";
import {useNavigate, useParams} from "react-router-dom"
import Operation from "../../operation/Operation";
import OperationMobileView from "../../operation/OperationMobileView";
import IbatApi from "../../utils/IbatApi";
import OperationForm from "../forms/OperationForm";
import BonCommande from "../../images/bc-icon.png"
import IntervIcon from "../../images/new-interv.png"
import ValidateIcon from "../../images/validate-icon.png"
import FinishIcon from "../../images/finish-icon.png"
import InteractionIcon from "../../images/icon-contact.png";
import SupplyIcon from "../../images/supply-icon.png";
import DevisIcon from "../../images/devis-icon.png"
import RiskIcon from "../../images/warning.png"
import InfoIcon from "../../images/info.png"
import CaseIcon from "../../images/case2.png"
import GenInterventionPdfIcon from "../../images/interventionPdf.png"
import GenSecurityPdfIcon from "../../images/securityPdf.png"
import FuiteIcon from "../../images/fuite-icon.png"
import DocumentIcon from "../../images/file.png"
import SignatureIllust from "../../images/noSignature-icon.png"
import SignatureIcon from "../../images/signature-icon.png"
import CustomImage from "../util/CustomImage";
import Risk from "./Risk";
import {IconButton, Step, StepLabel, Stepper, Tooltip} from "@mui/material";
import Modal from "react-responsive-modal";
import Layer from "../../layer/Layer";
import UpdateSiteImage from "../forms/UpdateSiteImage";
import CancelIcon from "../../images/cancel-interv-icon.png"
import {DocumentLoaderList} from "../util/DocumentLoader";
import InteractionClient from "./InteractionClient";
import EditSupply from "../forms/EditSupply";
import EditInterventionTeam from "../forms/EditInterventionTeam";
import constants, {text} from "../util/constants";
import PdfBean from "./PdfBean";
import {useSnackbar} from "notistack";
import EditEstimateDuration from "../forms/EditEstimateDuration";
import {isSpecialIntervention} from "../../helpers/interventionManager";
import WorkForceDisplayer from "../../work-force-displayer/WorkForceDisplayer";
import RiskSelector from "../../risk-selector/RiskSelector";
import CancelInterventionModal from "../forms/CancelInterventionModal";
import {EditText} from "react-edit-text";
import QuotationCreateForm from "../../quotation/QuotationCreateForm";
import {EventStreamContentType, fetchEventSource} from "@microsoft/fetch-event-source";
import {Buffer} from 'buffer';
import Table from "../../table/Table";
import {useMediaQuery} from 'react-responsive'
import ExpandableDiv from "../util/ExpandableDiv";
import {getStatusIcon, getTypeIcon} from "../../utils/Utils";


export default function Intervention({fastMode}) {

    let {id} = useParams()
    const api = new IbatApi();
    if (!id) {
        id = fastMode
    }
    const [state, setState] = useState({})
    const [feedback, setFeedback] = useState({})
    const [openTeamInteraction, setOpenTeamInteraction] = useState(false)
    const [openSupply, setOpenSupply] = useState(false)
    const [openQuotation, setOpenQuotation] = useState(false)
    const [openCancel, setOpenCancel] = useState(false)
    const [pdfPopup, setPdfPopup] = useState(false)
    const [pdfType, setPdfType] = useState(false)
    const [object, setObject] = useState("")
    const [conclusion, setConclusion] = useState("")
    const [resume, setResume] = useState("")
    const [status, setStatus] = useState([])
    const [operations, setOperations] = useState([])
    const [developCriteriaBar, setDevelopCriteriaBar] = useState(false)
    const {enqueueSnackbar} = useSnackbar();

    const [provisionalWork, setProvisionalWork] = useState([])
    const [jobRepair, setJobRepair] = useState([])
    const [jobProblem, setJobProblem] = useState([])


    const clientColumns = [
        {id: "signataire", isChecked: true, label: text.TD_NAME, type: "text"},
        {id: "function", isChecked: true, label: text.TD_FUNCTION, type: "text"},
        {id: "mail", isChecked: true, label: text.MAIL, type: "text"},
        {id: "comment", isChecked: true, label: text.TD_COMMENT, icon: FuiteIcon, type: "popup", popup: Layer},
    ]
    const infosColumns = [
        {id: "id", isChecked: true, label: text.ID, type: "linkText", hideOnPhone: true},
        {id: "devis", label: text.COMMAND_NUM, isChecked: true, type: "objectLink", hideOnPhone: true},
        {id: "client", label: text.CLIENT, isChecked: true, type: "objectLink"},
        {id: "site", label: text.SITE, isChecked: true, type: "objectLink"},
        {id: "status", label: text.STATUS, isChecked: true, type: "textWithIcon", function: getStatusIcon, hideOnPhone: true},
        {id: "type", label: text.FILE_TYPE, isChecked: true, type: "objectWithIcon", function: getTypeIcon},
        {
            id: "riskAnalysisLevel",
            isChecked: true,
            label: text.RISK_ANALYSIS_LEVEL,
            type: "component",
            component: RiskSelector,
            props: {onSelect: handleRiskAnalysisChange, selected: state.riskAnalysisLevel}
        },
        {id: "team", label: text.TEAM, isChecked: true, type: "popup", popup: EditInterventionTeam},
        {id: "intervention", label: text.INTERVENTION, isChecked: true, type: "object"},
        {id: "end", label: text.INTERVENTION_END, isChecked: true, type: "text"},
        {id: "address", label: text.ADDRESS, isChecked: true, type: "object"},
        {id: "cp", label: text.ZIPCODE, isChecked: true, type: "text"},
        {id: "ville", label: text.TOWN, isChecked: true, type: "text"},
        {id: "phone", label: text.PHONE_NUMBER, isChecked: true, type: "text"},
        {
            id: "estimateDuration",
            isChecked: true,
            label: text.DURATION_ESTIMATION,
            type: "popup",
            popup: EditEstimateDuration,
            hideOnPhone: true
        },
        {id: "insurance", isChecked: true, label: text.INSURANCE, type: "text"},
    ]
    const operationsColumns = [
        text.TD_NAME,
        text.TH_CONTRIBUTOR,
        text.TH_TECHNICAL_MANAGER,
        text.TH_ADMINISTRATOR,
        text.TH_IMAGES
    ]

    const history = useNavigate()
    const isMobile = useMediaQuery({ query: '(max-width: 915px)' })

    useEffect(() => {
        if (!id) {
            return
        }

        getIntervention()
            .then(r => {
                api.getStatus().then(s => {
                    setStatus(s)
                })
                api.getPoiTypes("jobRepair")
                    .then(r => setJobRepair(r))
                    .catch(e => enqueueSnackbar(text.SNACKBAR_ERROR, {variant: "error"}))
                api.getPoiTypes("jobProblem")
                    .then(r => setJobProblem(r))
                    .catch(e => enqueueSnackbar(text.SNACKBAR_ERROR, {variant: "error"}))
                api.getPoiTypes("provisionalWork")
                    .then(r => setProvisionalWork(r))
                    .catch(e => enqueueSnackbar(text.SNACKBAR_ERROR, {variant: "error"}))
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    useEffect(() => {
        // const start = new Date()
        //
        //4Min 36Sec ------ 4Min 17Sec
        //
        /*const operationsPictures = operations.flatMap(op => op.pictures)
        const duplicateOperations = [...operations]
        api.getFileList(operationsPictures.map(pic => pic.filePath)).then(r => {
            const end = new Date()
            const ms = end.getTime() - start.getTime()
            var min = Math.floor(ms / 60000);
            var sec = ((ms % 60000) / 1000).toFixed(0);
            console.log(min + ':' + sec)
            for (const idx in operations) {
                const operation = duplicateOperations[idx];
                for (const [key, value] of Object.entries(r)) {
                    const index = operation.pictures.findIndex((pic) => pic.filePath === key);
                    if (index !== -1) {
                        let updatedPictureList = operation.pictures
                        updatedPictureList = updatedPictureList.map((pic, i) => {
                            if (i === index) {
                                return { ...pic, url: "data:image/png;base64,"+value };
                            }
                            return pic;
                        });
                        duplicateOperations[idx].pictures = updatedPictureList
                    }
                }
            }
            setOperations(duplicateOperations)
        })*/

        //
        //5Min 13Sec ------ 5Min 8Sec
        fetchDataAndUpdatePictureList()
        /*for (let i = 0; i < operationsPictures.length; i += chunkSize) {
            // fetchDataAndUpdatePictureList(operationsPictures.slice(i, i + chunkSize))
        }*/
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [operations?.length])


    async function fetchDataAndUpdatePictureList() {
        const operationsPictures = operations?.flatMap(op => op.pictures)
        // const operationsPictures = list

        if (!operations || operationsPictures?.length === 0) {
            return
        }
        const url = "https://backend.ebat.tegeo.fr/core/utils/streamImages"// + queryString;

        const start = new Date()
        fetchEventSource(url, {
            method: 'POST',
            headers: api.getAuthorizationHeader(),
            body: JSON.stringify(operations?.flatMap(op => op.pictures).map(pic => pic?.filePath)),
            onmessage(ev) {
                // Convert the Base64 string back to binary data (byte array)
                const binaryImageData = Buffer.from(ev.data, 'base64')

                // Convert the binary data to a Blob
                const blobImage = new Blob([new Uint8Array(binaryImageData)], {type: 'image/png'});
                const url = URL.createObjectURL(blobImage);

                // Find the index of the existing image in the pictureList array based on the filename
                for (const idx in operations) {
                    const operation = operations[idx];
                    const index = operation.pictures.findIndex((pic) => pic.filePath === ev.event);
                    if (index !== -1) {
                        let updatedPictureList = operation.pictures
                        updatedPictureList = updatedPictureList.map((pic, i) => {
                            if (i === index) {
                                return {...pic, url: url};
                            }
                            return pic;
                        });
                        operation.pictures = updatedPictureList
                        const updatedOperationsList = operations.filter(o => o.id !== operation.id)
                        updatedOperationsList.push(operation)
                        setOperations(updatedOperationsList)
                    }
                    // setPictureList(updatedPictureList)
                }
                if (ev.event === 'FatalError') {
                    throw new Error(ev.data);
                }
            },
            async onopen(response) {
                if (response.ok && response.headers.get('content-type') === EventStreamContentType) {
                } else {
                    throw new Error();
                }
            },
            onerror(err) {
                if (err instanceof Error) {
                    throw err; // rethrow to stop the operation
                } else {
                    // do nothing to automatically retry. You can also
                    // return a specific retry interval here.
                }
            },
            onclose(e) {
                const end = new Date()
                const ms = end.getTime() - start.getTime()
                var min = Math.floor(ms / 60000);
                var sec = ((ms % 60000) / 1000).toFixed(0);
            }
        })
    }

    function getIntervention() {
        return api.getIntervention(id).then(i => {
            setState({...state, ...i})
            setObject(i.object)
            setResume(i.resume)
            setConclusion(i.conclusion)
            setOperations(i.operations)
            setFeedback({...i.feedBack, comment: JSON.parse(i.feedBack?.comment?.replaceAll("\n", "\\n") ?? null)})
        })
    }

    function operationHandler() {
        getIntervention()
    }

    function cancel(cause) {
        api.cancelIntervention(id, cause).then(e => {
            getIntervention()
            setOpenCancel(false)
        })
    }


    function compareRsk(r1, r2) {
        if (r1.question > r2.question) {
            return 1
        }
        if (r1.question < r2.question) {
            return -1
        } else {
            return 0
        }
    }

    function handleStatus() {
        api.setInterventionStatus(id, state.status === constants.STATUS_DONE ? constants.STATUS_WRITING : constants.STATUS_DONE)
            .then(i => setState({...state, ...i}))
            .then(r => enqueueSnackbar(text.CHANGED_STATUS, {variant: "success"}))
            .catch(e => enqueueSnackbar('Erreur', {variant: "error"}))
    }

    function handleInsuranceFinish() {
        api.finishLastBillIntervention(id)
            .catch(e => {
                enqueueSnackbar(e.data, {variant: "error"})
            })
    }

    function saveConclusion() {
        conclusion && api.saveConclusion(id, encodeURIComponent(conclusion))
    }

    function saveObject() {
        object && api.saveObject(id, encodeURIComponent(object))
    }

    function saveResume() {
        resume && api.saveResume(id, encodeURIComponent(resume))
    }

    function handleExport(id) {
        let operationCopy = operations
        operationCopy = operationCopy.filter(op => op.id !== id);
        api.getOperation(id).then(op => setOperations([...operationCopy, op]))
    }

    function handleRiskAnalysisChange(ral) {
        api.setInterventionRiskAnalysisLevel(id, ral)
            .then(r => enqueueSnackbar(text.SNACKBAR_SUCCESS, {variant: "success"}))
            .catch(e => enqueueSnackbar('Erreur', {variant: "error"}))
    }


    function handleChangeMontant(value) {
        setState({...state, estimate: value.target.value})
    }

    function saveEstimate() {
        api.setEstimate(id, encodeURIComponent(state.estimate))
            .then(r => enqueueSnackbar(text.SNACKBAR_SAVED, {variant: "success"}))
            .catch(e => enqueueSnackbar(text.SNACKBAR_ERROR, {variant: "error"}))
    }

    function handleChangeAcompte(value) {
        setState({...state, bill: value.target.value})
    }

    function saveBill() {
        api.setBill(id, encodeURIComponent(state.bill))
            .then(r => enqueueSnackbar(text.SNACKBAR_SAVED, {variant: "success"}))
            .catch(e => enqueueSnackbar(text.SNACKBAR_ERROR, {variant: "error"}))
    }


    return (
        <div className={"w100 page flex scrollable"}>
            <aside className={`criteria-bar${developCriteriaBar ? " developped" : ""}`}>
                <div className={`margin-content`}>
                    <IconButton onClick={() => history(`/devis/${state.devis.id}`)}><img alt={"return to order"}
                                                                                         src={BonCommande}/></IconButton>
                    <IconButton disabled><img alt={"create intervention Icon"} src={IntervIcon}/></IconButton>
                    {state && <IconButton onClick={() => setOpenTeamInteraction(true)}><img alt={"client interaction"}
                                                                                            src={InteractionIcon}/></IconButton>}
                    {state && <IconButton onClick={() => setOpenSupply(true)}><img alt={"supply popup"}
                                                                                   src={SupplyIcon}/></IconButton>}


                    <div className={"pdfButtons flex-column"} onClick={() => {
                        setPdfPopup(true)
                    }}>
                        <Tooltip title={"Rapport d'intervention"}>
                            <IconButton className={"pdfMenu left"} onClick={() => setPdfType("intervention")}>
                                <img alt={"generate a pdf"} src={GenInterventionPdfIcon}/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={"Rapport analyse de risque"}>
                            <IconButton className={"pdfMenu right"} onClick={() => setPdfType("security")}>
                                <img alt={"generate a pdf"} src={GenSecurityPdfIcon}/>
                            </IconButton>
                        </Tooltip>
                    </div>

                    {state && <IconButton onClick={() => setOpenQuotation(true)}><img alt={"quotation popup"}
                                                                                      src={DevisIcon}/></IconButton>}

                    {state && <IconButton disabled={!api.hasRole(constants.PERMISSION_PM)}
                                          onClick={() => setOpenCancel(true)}><img alt={"cancel intervention"}
                                                                                   src={CancelIcon}/></IconButton>}

                    <div className={`bord1 only-for-small-screen${developCriteriaBar ? " developped" : ""}`}
                         onClick={() => setDevelopCriteriaBar(!developCriteriaBar)}>
                        >
                    </div>

                    <Modal open={openTeamInteraction} onClose={() => setOpenTeamInteraction(false)}>
                        <InteractionClient id={id} site={state?.devis?.site} ibatApi={api}/>
                    </Modal>
                    <Modal open={pdfPopup} onClose={() => setPdfPopup(false)}>
                        <PdfBean id={id} type={pdfType} onUpload={() => {
                            getIntervention();
                            setPdfPopup(false)
                        }}/>
                    </Modal>
                    <Modal open={openSupply} onClose={() => setOpenSupply(false)}>
                        <EditSupply id={id} externalId={state.externalId} title={`${state?.devis?.clientIdBCommande} - ${state?.devis?.site?.name}`}/>
                    </Modal>
                    <Modal open={openCancel} onClose={() => setOpenCancel(false)}>
                        <CancelInterventionModal onSave={cancel} onClose={() => setOpenCancel(false)}/>
                    </Modal>
                    <Modal open={openQuotation} onClose={() => setOpenQuotation(false)}>
                        <QuotationCreateForm
                            image={state?.devis?.site?.imageUrl}
                            existingQuotation={state.quotations}
                            onSaveSucced={() => setOpenQuotation(false)}
                            interventionId={id}
                            provisionalWork={provisionalWork}
                            jobRepair={jobRepair}
                            operations={state?.operations}
                            devisId={state?.devis?.id}/>
                    </Modal>
                </div>
            </aside>
            <div
                className={`intervention${isMobile ? " flex-column" : " grid"}${fastMode ? "flex-column fastMode bg-wh secondary-section" : ""}`}>
                {!fastMode && state.status &&
                    <Stepper className={"stepper w100"} activeStep={status.indexOf(state.status)}
                             alternativeLabel>

                        {
                            isMobile ?
                                <Step key={state.status}>
                                    <StepLabel>{text.STATUS_TRADUCTION[state.status]}</StepLabel>
                                </Step> :
                                status.map((st) => (
                                    <Step key={st}>
                                        <StepLabel>{text.STATUS_TRADUCTION[st]}</StepLabel>
                                    </Step>
                                ))}
                    </Stepper>}
                {!fastMode &&
                    <div className={"status br10"}>
                        {state.status &&
                            <IconButton disabled={!api.hasRole(constants.PERMISSION_AM)}
                                        checked={state.status.id >= 9}
                                        onClick={handleStatus}><img alt={"desk-status"}
                                                                    src={state.status === constants.STATUS_WRITING ? ValidateIcon : FinishIcon}/></IconButton>}
                        <br/>
                        {/*{state.status && status.indexOf(state.status)>=8 && state.externalId && <IconButton disabled={!api.hasRole(constants.PERMISSION_AM)} checked={state.status.id>=9}  onClick={handleInsuranceFinish}><img alt={"desk-status"} src={state.status===constants.STATUS_FINISHED?ValidateIcon:FinishIcon}/></IconButton>}*/}
                    </div>
                }
                {state.devis && <div className={"de secondary-section flex-row"}>
                    <div className={"de-section"}>
                        <div className={"tabcell"}> {text.ESTIMATE_NUMBER}</div>
                        <div className={"tabcell"}>
                            <EditText className={"bord1 tabinput"} value={state.estimate ?? ""}
                                      name={"montant"} inline={true}
                                      onChange={handleChangeMontant} onBlur={saveEstimate}/>
                        </div>
                    </div>
                    <div className={"de-section"}>
                        <div className={"tabcell"}>{text.BILL_NUMBER}</div>
                        <div className={"tabcell"}>
                            <EditText className={"bord1 tabinput"} value={state.bill ?? ""}
                                      name={"acompte"}
                                      inline={true} onChange={handleChangeAcompte}
                                      onBlur={saveBill}/>
                        </div>
                    </div>
                </div>}
                <div className={"infos primary-section"}>
                    {
                        isMobile ?
                            <div className={"bord1 w100"}> <h1 className={"title flex-row"}>
                                <img alt={""} src={InfoIcon} className={"icon"}/>{text.H1_GENERAL_INFO}
                            </h1>
                            <ExpandableDiv className={"infosMobile widfit card-section"}>
                                <>
                                    <div className={"secondary-section"}><a target={"_blank"} href={`/devis/${state?.devis?.id}`}>Commande
                                        : {state?.devis?.clientIdBCommande}</a></div><br />
                                    <div className={"secondary-section"}><a target={"_blank"} href={`/client/${state?.devis?.client?.id}`}>Client
                                        : {state?.devis?.client?.name}</a></div>
                                    <div className={"secondary-section flex-row auto-line"}>
                                    <span className={"secondary-section flex-row widfit"}><img src={getTypeIcon(state?.type?.id)}/>{state?.type?.name}</span>
                                        <span className={"secondary-section widfit"}>Equipe : {state?.team?.name}</span>
                                        {state?.devis?.insurance && <span
                                            className={"secondary-section widfit"}>Assurance : {state?.devis?.insurance?.name}</span>}
                                        <div className={"secondary-section widfit"}><span className={" widfit"}>
                                            Début : {state.dateIntervention > 0 ? `${new Date(state.dateIntervention).toLocaleDateString()} ${new Date(state.dateIntervention).toLocaleTimeString()}` : text.UNDEFINED_DATE}
                                    </span>
                                        <br /><span className={"widfit"}
                                            >Fin : {state.endIntervention > 0 ? `${new Date(state.endIntervention).toLocaleDateString()} ${new Date(state.endIntervention).toLocaleTimeString()}` : text.UNDEFINED_DATE}
                                    </span></div>
                                    </div>
                                    <span className={"secondary-section w100"}>
                                    Analyse de risques :
                                    <RiskSelector selected={state.riskAnalysisLevel}
                                                  onSelect={handleRiskAnalysisChange}/>
                                </span>
                                    <div className={"secondary-section"}>
                                        <h3>Site :</h3>
                                        <div className={"flex-column"}>
                                            <span>{state?.devis?.site?.name ?? ""}</span>
                                            <span>
                                                <a href={`site/${state?.devis?.site?.id}`} target={"_blank"}>
                                                    {`${state?.devis?.site?.address ?? ""}, ${state?.devis?.site?.cp ?? ""}, ${state?.devis?.site?.ville ?? ""}`}
                                            </a>
                                            </span>
                                            <span>{state?.devis?.site?.contactPhone.length > 0 ? `${state?.devis?.site?.contactPhone.join(", ")}` : "Aucun numéro communiqué"}</span>
                                            <span>{`${state?.devis?.site?.contactMail ?? "Aucune adresse mail communiquée"}`}</span>
                                        </div>
                                    </div>
                                    {
                                        state?.generatedReport?.length > 0 &&
                                        <div className={"documents flex-column"}>
                                            <div className={"secondary-section"}>
                                                <DocumentLoaderList name={state.generatedReport}
                                                                    noHeader={true}/>
                                            </div>
                                        </div>
                                    }
                                </>
                            </ExpandableDiv></div>
                            :
                            <Table
                                tabName={"interventions"}
                                columns={infosColumns}
                                list={!state.id ? [] : [api.normalize({...state})]}
                                hideFilter={true}
                                onGetData={getIntervention}
                            />

                    }
                </div>
                {!fastMode &&
                    <div className={"client primary-section"}>
                        {
                            isMobile ?
                            <div className={"bord1 w100"}>
                                <h1 className={"title flex-row"}>
                                    <img alt={""} src={InfoIcon} className={"icon"}/>{text.H1_REPORTED_INFO}
                                </h1>
                                <ExpandableDiv className={"w100 infosMobile flex-column"}>
                                    <div className={"flex-column w100"}>
                                        <span>Signataire : {feedback?.comment?.signataire ?? ""}</span>
                                        <span>Fonction : {feedback?.comment?.function ?? ""}</span>
                                        <span>Mail : {feedback?.mail ?? ""}</span>
                                    </div>
                                </ExpandableDiv>
                            </div>
                                :
                                <Table
                                    columns={clientColumns}
                                    list={[feedback].map(f => {
                                            return {
                                                signataire: f?.comment?.signataire,
                                                ["function"]: f?.comment?.function,
                                                mail: f?.mail,
                                                comment: f?.comment?.comments,
                                                points: f?.points ?? [],
                                                background: state?.devis?.site?.imageUrl
                                            }
                                        }
                                    )}
                                    hideFilter={true}
                                />
                        }
                    </div>
                }
                {!fastMode && <div className={"plan primary-section flex-column"}>
                    <div>
                        <h2 className={"title"}>{text.PLAN}</h2>
                        <UpdateSiteImage
                            ibatApi={api}
                            voie={state?.devis?.site?.address + " " + state?.devis?.site?.cp + " " + state?.devis?.site?.ville}
                            img={state?.devis?.site?.imageUrl ?? null}
                            siteId={state?.devis?.site?.id} latitude={state?.devis?.site?.latitude}
                            longitude={state?.devis?.site?.longitude} update={getIntervention}/>
                    </div>
                </div>}
                {
                    !isMobile &&
                    <div className={"documents primary-section flex-column"}>
                        <h2><img alt={""} src={DocumentIcon}/> {text.H2_DOCUMENTS}</h2>
                        <div className={"secondary-section"}>
                            {<DocumentLoaderList name={state.generatedReport} noHeader={true}/>}
                        </div>
                    </div>
                }
                {(!fastMode && ((isMobile && state?.feedBack?.signature) || !isMobile)) &&
                    <div className={"signature flex-column primary-section"}>
                        {state.feedBack && state.feedBack.signature ?
                            <CustomImage ibatApi={api} name={state.feedBack.signature} height={230} width={300}/> :
                            <img alt={"signature"} src={SignatureIllust}/>}
                    </div>
                }
                <div className={"object flex-column primary-section"}>
                    <h2>{text.OBJECT}</h2>
                    <div className={"secondary-section"}>
                        <input className={"large w100"}
                               disabled={!api.hasRole(constants.PERMISSION_CRE)} type={"text"}
                               value={object ? object : ""}
                               onChange={e => setObject(e.target.value ? e.target.value : "")}
                               onBlur={saveObject}/>
                    </div>
                </div>
                {!fastMode &&
                        <OperationForm idIntervention={id} isMobile={isMobile} onCreate={getIntervention} mandatories={state.addedOperation}/>
                }

                <div className={"resume flex-column primary-section"}>
                    <h2>{text.RESUME}</h2>
                    <div className={"secondary-section h100"}>
                        <textarea className={"large w100"}
                                  disabled={!api.hasRole(constants.PERMISSION_CRE)}
                                  value={resume ? resume : ""}
                                  onChange={e => setResume(e.target.value ? e.target.value : "")}
                                  onBlur={saveResume}/>
                    </div>
                </div>
                <div className={"rapport"}>
                    <h1 className={"title"}><img alt={""} src={CaseIcon}/> {text.REPORT}  </h1>
                    <div className={"primary-section"}>
                        {
                            operations?.length > 0 ?
                                <>
                                    <WorkForceDisplayer
                                        ibatApi={api}
                                        operations={operations}
                                        provisionalWork={provisionalWork}
                                        jobRepair={jobRepair}
                                        jobProblem={jobProblem}
                                        background={state?.devis?.site?.imageUrl}
                                    />

                                    {
                                        isMobile ?
                                            <div className={"w100"}>
                                                {operations
                                                    // .sort((o1, o2) => o1.defaultPosition > o2.defaultPosition ? 1 : -1)
                                                    .sort((o1, o2) => {
                                                        if (+o1.defaultPosition === +o2.defaultPosition) {
                                                            console.log("hey")
                                                            console.log(o1.id.localeCompare(o2.id))
                                                            return o1.id.localeCompare(o2.id);
                                                        }
                                                        console.log("Yo")
                                                        return o1.defaultPosition > o2.defaultPosition ? 1 : -1;
                                                    })
                                                    .map((o) => <OperationMobileView key={o.id}
                                                                                     ibatApi={api}
                                                                                     interventionId={state.id}
                                                                                     devisId={state.devis.id}
                                                                                     onChange={operationHandler}
                                                                                     image={state.devis.site.imageUrl}
                                                                                     operation={o}
                                                                                     pictures={o?.pictures?.map(p => {
                                                                                         return {
                                                                                             ...p,
                                                                                             url: p.url ?? "url"
                                                                                         }
                                                                                     })}
                                                            // pictures={o.pictures}
                                                                                     visible={o.visible}
                                                                                     depot={isSpecialIntervention(state.type.id)}
                                                                                     otherOperations={operations.filter(o1 => o.id !== o1.id).map(o1 => ({
                                                                                         id: o1.id,
                                                                                         typeName: o1.typeName
                                                                                     }))}
                                                                                     onExport={handleExport}
                                                                                     provisionalWork={provisionalWork}
                                                                                     jobRepair={jobRepair}
                                                                                     jobProblem={jobProblem}
                                                        />
                                                    )}
                                            </div>
                                            :
                                            <table className={"fs1 operation-table w100"}>
                                                <thead>
                                                <tr>
                                                    {
                                                        operationsColumns.map(c => <th>{c}</th>)

                                                    }
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    operations
                                                        // .sort((o1, o2) => o1.defaultPosition > o2.defaultPosition ? 1 : -1)
                                                        .sort((o1, o2) => {
                                                            if (o1.defaultPosition === null) return 1;
                                                            if (o2.defaultPosition === null) return -1;
                                                            if (o1.defaultPosition === o2.defaultPosition) {
                                                                return o1.id.localeCompare(o2.id);
                                                            }
                                                            return o1.defaultPosition > o2.defaultPosition ? 1 : -1;
                                                        })
                                                        .map((o) => <Operation key={o.id}
                                                                               ibatApi={api}
                                                                               interventionId={state.id}
                                                                               devisId={state.devis.id}
                                                                               onChange={operationHandler}
                                                                               image={state.devis.site.imageUrl}
                                                                               operation={o}
                                                                               pictures={o?.pictures?.map(p => {
                                                                                   return {
                                                                                       ...p,
                                                                                       url: p.url ?? "url"
                                                                                   }
                                                                               })}
                                                            // pictures={o.pictures}
                                                                               visible={o.visible}
                                                                               depot={isSpecialIntervention(state.type.id)}
                                                                               otherOperations={operations.filter(o1 => o.id !== o1.id).map(o1 => ({
                                                                                   id: o1.id,
                                                                                   typeName: o1.typeName
                                                                               }))}
                                                                               onExport={handleExport}
                                                                               provisionalWork={provisionalWork}
                                                                               jobRepair={jobRepair}
                                                                               jobProblem={jobProblem}
                                                            />
                                                        )
                                                }
                                                </tbody>
                                            </table>

                                    }


                                </>
                                :
                                <div>{text.NO_OPERATION_TRANSMITTED}</div>
                        }
                    </div>
                </div>
                <div className={"conclusion flex-column primary-section"}>
                    <h2>{text.TH_CONCLUSION}</h2>
                    <div className={"secondary-section"}>
                        <textarea className={"large w100"}
                                  disabled={!api.hasRole(constants.PERMISSION_CRE)}
                                  value={conclusion ? conclusion : ""}
                                  onChange={e => setConclusion(e.target.value ? e.target.value : "")}
                                  onBlur={saveConclusion}/>
                    </div>
                </div>

                <div className={"risk-analysis"}>
                    <h1><img alt={""} src={RiskIcon}/> {text.H1_RISK_ANALYSIS} </h1>

                    <div className={"flex-row primary-section"}>
                        {state.riskAnalysis && state.riskAnalysis.length > 0 ?
                            (
                                isMobile ?
                                    <ExpandableDiv>
                                        <div className={"bord1 flex-row auto-line"}>
                                            {state.riskAnalysis.sort((r1, r2) => compareRsk(r1, r2)).map((risk) =>
                                                <Risk {...risk}
                                                      ibatApi={api}/>)}
                                        </div>
                                    </ExpandableDiv>
                                    :
                                    state.riskAnalysis.sort((r1, r2) => compareRsk(r1, r2)).map((risk) =>
                                        <Risk {...risk}
                                              ibatApi={api}/>)
                            )
                            : <div>{text.NO_RISK_ANALYSIS}</div>
                        }
                    </div>

                </div>


            </div>
        </div>
    )

}
