import IbatApi from "../../utils/IbatApi";
import React, {useEffect, useState} from "react";
import EditInterventionSecteurIcon from "../../images/secteur.png";
import {IconButton} from "@mui/material";
import constants, {text} from "../util/constants";
import {useSnackbar} from "notistack";
import {v4} from "uuid";
import Select from "react-select";

export default function EditSecteur({id,update}){
    let api = new IbatApi();
    const { enqueueSnackbar} = useSnackbar();
    const [idSecteur, setIdSecteur] = useState("null")
    const [secteur, setSecteur] = useState([])
    useEffect(() => {
        api.getSecteur().then(secteur => setSecteur(secteur.map(s => {
                return {
                    value: s.id,
                    label: s.name
                }
            }
        )))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    function handleSecteur(e) {
        console.log(e)
        setIdSecteur(e.value)
    }
    function updateSecteur() {
        api.setInterventionSecteur(id, idSecteur).then(r => update())
            .then(r => enqueueSnackbar(text.SNACKBAR_SAVED,{variant:"success"}))
            .catch(e=>enqueueSnackbar(text.SNACKBAR_ERROR,{variant:"error"}))
    }

    if(!api.hasRole(constants.PERMISSION_AM)){
        return <h3>{text.NO_RIGHTS_PROMPT}</h3>
    }
    return (
        <div className={"editIntervention form flex-column primary-section"}>
            <div className={"img-container"}>
                <img alt={""} src={EditInterventionSecteurIcon}/>
            </div>
                <h2>{text.ZONE}</h2>
            <Select
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999}) }}
                menuPortalTarget={document.body}
                placeholder={"Secteur"}
                onChange = {d => handleSecteur(d)}
                options={secteur}/>

                <IconButton className={"action-btn validation"} onClick={updateSecteur}>{text.SAVE}</IconButton>
        </div>

    )
}