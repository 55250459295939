import React, {useEffect, useRef, useState} from 'react';
import IbatApi from "../../utils/IbatApi";
import SupplyDocument from "../bean/SupplyDocument";
import SupplyMachinery from "../bean/SupplyMachinery";
import SupplyMaterial from "../bean/SupplyMaterial";
import MaterialIcon from "../../images/materiel.png"
import MachineryIcon from "../../images/nacelle.png"
import DocumentIcon from "../../images/newdoc.png"
import {IconButton} from "@mui/material";
import SupplyAdminDocument from "../bean/SupplyAdminDocument";
import constants, {text} from "../util/constants";
import {useSnackbar} from "notistack";
import ExtensibleComponent from "../util/ExtensibleComponent";

export default function EditSupply({id,handleSupply, externalId, updateParentSupply, title, idDev}) {
    let api = new IbatApi();
    const [state, setState] = useState({
        id: id,
        document: {
            type: "",
            issueDate: "",
        },
        adminDocument: {
            type: "",
            issueDate: "",
        },
        machinery: {
            type: "",
            issueDate: ""
        },
        material: {
            type: "",
            quantity: "1",
        },
    })


    const [materials, setMaterials] = useState([])
    const [machineries, setMachineries] = useState([])
    const [machineriesType, setMachineriesType] = useState([])
    const [documents, setDocuments] = useState([])
    const [documentsType, setDocumentsType] = useState([])
    const [adminDocuments, setAdminDocuments] = useState([])
    const [aminDocumentsType, setAdminDocumentsType] = useState([])
    const [originIntervention, setOriginIntervention] = useState(null)
    const { enqueueSnackbar} = useSnackbar()
    const materialListRef = useRef(null)

    useEffect(()=>{
        getTypes()
        getOriginIntervention()
        // eslint-disable-next-line react-hooks/exhaustive-deps
        },[])
    useEffect(() => {
        id && getSupply()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    function getTypes() {
        api.getConstructionTypesMachinery().then(d => setMachineriesType(d))
        api.getContructionTypesDocument().then(d => setDocumentsType(d))
        api.getAdministrationTypesDocument().then(d => setAdminDocumentsType(d))
    }
    function getOriginIntervention() {
        api.getOriginIntervention(idDev)
            .then(d => setOriginIntervention(d))
            .catch(e => {})
    }

    function getSupply(goToBottom) {
        return (handleSupply?handleSupply():api.getSupply(id))
            .then(a => {
            setState({...state, id: a.id,})
            updateTable(a.elements, goToBottom)
                updateParentSupply && updateParentSupply(a)
        })
    }

    function updateTable(elements, goToBottom){
        setMaterials(elements.filter(e=>e["@type"]===constants.MATERIAL_CLASS))
        setDocuments(elements.filter(e=>e["@type"]===constants.DOCUMENT_CLASS))
        setAdminDocuments(elements.filter(e=>e["@type"]===constants.ADMIN_DOC_CLASS))
        setMachineries(elements.filter(e=>e["@type"]===constants.MACHINERY_CLASS))

        goToBottom && materialListRef.current.scrollTo({top: materialListRef.current.scrollHeight+30, behavior: 'smooth'});
    }


    function addDocument() {
        const document = {
            type: state.document.type,
            issueDate: new Date(state.document.issueDate).getTime(),
        }
        api.addDocumentSupply(id, state.id, document).then(d => getSupply())
    }

    function addAdminDocument() {
        const document = {
            type: state.adminDocument.type,
            issueDate: new Date(state.adminDocument.issueDate).getTime(),
        }
        api.addAdminDocumentSupply(state.id, document).then(d =>getSupply())
    }

    function addMachinery() {
        const machinery = {
            type: state.machinery.type,
            issueDate: new Date(state.machinery.issueDate).getTime()
        }
        api.addMachinerySupply(id, state.id, machinery).then(d => getSupply())
    }

    function addMaterial() {
        const material = {
            type: state.material.type,
            quantity: state.material.quantity
        }
        api.addMaterialSupply(state.id,[material])
            .then(d=>getSupply(true))
            .then(state.material.type="")
    }

    function importDevis() {
        api.importDevis(state.material.type,)
            .then(c=> c.devisData.map(elem=>({type: elem.name, supplier: elem.supplier, quantity: elem.quantity,})))
            .then(materials=>api.addMaterialSupply(state.id,[...materials]))
            .then(d=>getSupply())
            .then(r => enqueueSnackbar(text.SNACKBAR_SAVED,{variant:"success"}))
            .catch(e=>enqueueSnackbar(text.SNACKBAR_ERROR,{variant:"error"}))
    }

    function handleMaterialCreate(e) {
        setState({...state, material: {...state.material, [e.target.name]: e.target.value}})
    }

    function handleDocumentCreate(e) {
        setState({...state, document: {...state.document, [e.target.name]: e.target.value}})
    }

    function handleAdminDocumentCreate(e) {
        setState({...state, adminDocument: {...state.adminDocument, [e.target.name]: e.target.value}})
    }


    function handleMachineryCreate(e) {
        setState({...state, machinery: {...state.machinery, [e.target.name]: e.target.value}})
    }

    if(!api.hasRole(constants.PERMISSION_AM)){
        return <h3>{text.NO_RIGHT}</h3>
    }
    return (
        <>
            <div className={"flex-row"}>
                {originIntervention && <a href={`/interventions/${originIntervention}`} target={"_blank"}><center><h5>(Voir l'intervention d'origine)</h5></center></a>}
                {title && <a href={`/devis/${idDev}`} target={"_blank"} ><center><h2>{title}</h2></center></a>}
            </div>

            <div className={`supply${api.getSettingsCookie(text.INTERVENTIONS+"_supplyMode") === "true" ? " supply-mode" : ""}`} >

                <div className={"secondary-section material-case"} style={{zIndex: "1"}}>
                    <div className={"flex-row case-title"}>
                        <img alt={""} src={MaterialIcon}/>{text.EQUIPMENT}
                    </div>

                    <div className={"extensible-component-wrapper"}>
                        <ExtensibleComponent content={"+"}>
                            <div className={"flex-row primary-section"}>
                                <input className={"large w100"} type={"text"}
                                       value={state.material.type}
                                       name={"type"} placeholder={text.EQUIPMENT_NAME}
                                       onChange={handleMaterialCreate}/>
                                <IconButton onClick={addMaterial}
                                            className={"action-btn validation"}>{text.ADD_BUTTON}</IconButton>
                            </div>
                        </ExtensibleComponent>
                        <div className={"supply-list"}>
                            <ul className={"primary-section material"} ref={materialListRef}>
                                {materials.map((d, k) => <li className={"w100"} key={d.id}>
                                    <SupplyMaterial type={d.type} supplyId={state.id}
                                                    isReady={d.isReady}
                                                    supplierCmd={d.supplierCmd} comment={d.comment}
                                                    id={d.id} quantity={d.quantity} unit={d.unit}
                                                    onChange={e => updateTable(e.elements)}/>
                                </li>)}
                            </ul>
                        </div>
                    </div>

                </div>
                <div className={"secondary-section admin-doc-case"}>
                    <div className={"flex-row case-title"}>
                        <img alt={""} src={DocumentIcon}/>{text.H2_ADMIN_DOCS}
                    </div>

                    <div className={"extensible-component-wrapper"}>
                        <ExtensibleComponent content={"+"}>
                            <div className={"flex-row primary-section"}>
                                <select className={"large w100"} required name={"type"}
                                        onChange={handleAdminDocumentCreate}
                                        value={state?.adminDocument?.type === "" ? "null" : state.adminDocument.type}>
                                    <option disabled value={"null"}>{text.DOC_TYPE}</option>
                                    {
                                        aminDocumentsType
                                            ?.filter(d => (externalId || (!d.name.startsWith("*") && !externalId)))
                                            .sort((d1, d2) => d1.name.toLowerCase() > d2.name.toLowerCase() ? 1 : -1)
                                            .map((d, k) => <option key={k}
                                                                   value={d.id}>{d.name}</option>)}
                                </select>
                                <input className={"large w100"} type={"date"} name={"issueDate"}
                                       value={state.adminDocument.issueDate}
                                       pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
                                       onChange={handleAdminDocumentCreate}/>
                                <IconButton onClick={addAdminDocument}
                                            className={"action-btn validation"}>{text.ADD_BUTTON}</IconButton>
                            </div>
                        </ExtensibleComponent>
                        <div className={"supply-list"}>
                            <ul className={"primary-section"}>
                                {adminDocuments?.map((d, k) =>
                                    <li key={d.id}><SupplyAdminDocument issueDate={d.issueDate}
                                                                        supplyId={state.id}
                                                                        typeName={d.type}
                                                                        id={d.id} isReady={d.ready}
                                                                        receptionDate={d.receptionDate} {...d}
                                                                        onChange={e => updateTable(e.elements)}/>
                                    </li>)}
                            </ul>
                        </div>
                    </div>
                </div>
                <div className={"secondary-section doc-case"}>
                    <div className={"flex-row case-title"}>
                        <img alt={""} src={DocumentIcon}/>{text.DOCS}
                    </div>


                    <div className={"extensible-component-wrapper"}>
                        <ExtensibleComponent content={"+"}>
                            <div className={"flex-row primary-section"}>
                                <select className={"large w100"} required name={"type"}
                                        onChange={handleDocumentCreate}
                                        value={state?.document?.type === "" ? "null" : state.document.type}>
                                    <option disabled value={"null"}>{text.DOC_TYPE}</option>
                                    {documentsType
                                        ?.sort((d1, d2) => d1.name.toLowerCase() > d2.name.toLowerCase() ? 1 : -1)
                                        .map((d, k) => <option key={k}
                                                               value={d.id}>{d.name}</option>)
                                    }
                                </select>
                                <input className={"large w100"} type={"date"} name={"issueDate"}
                                       value={state.document.issueDate}
                                       pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
                                       onChange={handleDocumentCreate}/>
                                <IconButton onClick={addDocument}
                                            className={"action-btn validation"}>{text.ADD_BUTTON}</IconButton>
                            </div>
                        </ExtensibleComponent>
                        <div className={"supply-list"}>
                            <ul className={"primary-section"}>
                                {documents.map((d, k) =>
                                    <li key={d.id}><SupplyDocument issueDate={d.issueDate}
                                                                   supplyId={state.id} typeName={d.type}
                                                                   interventionId={id}
                                                                   id={d.id} isReady={d.ready}
                                                                   receptionDate={d.receptionDate} {...d}
                                                                   onChange={e => updateTable(e.elements)}/>
                                    </li>)}
                            </ul>
                        </div>
                    </div>
                </div>
                <div className={"secondary-section machinery-case"}>

                    <div className={"flex-row case-title"}>
                        <img alt={""} src={MachineryIcon}/>{text.ENGINES_AND_OTHER}
                    </div>


                    <div className={"extensible-component-wrapper"}>
                        <ExtensibleComponent content={"+"}>
                            <div className={"flex-row primary-section"}>
                                <select className={"large w100"} required name={"type"}
                                        onChange={handleMachineryCreate}
                                        value={state?.machinery?.type === "" ? "null" : state.machinery.type}>
                                    <option disabled value={"null"}>{text.DEVICE_TYPE}</option>
                                    {machineriesType.sort((d1, d2) => d1.name.toLowerCase() > d2.name.toLowerCase() ? 1 : -1).map((d, k) =>
                                        <option key={k} value={d.id}>{d.name}</option>)}
                                </select>
                                <input className={"large w100"} type={"date"} name={"issueDate"}
                                       value={state.machinery.issueDate}
                                       pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
                                       onChange={handleMachineryCreate}/>
                                <IconButton onClick={addMachinery}
                                            className={"action-btn validation"}>{text.ADD_BUTTON}</IconButton>
                            </div>
                        </ExtensibleComponent>
                        <div className={"supply-list"}>
                            <ul className={"primary-section"}>
                                {machineries.map((d, k) => <li key={d.id}>
                                    <SupplyMachinery isReady={d.ready} id={d.id} supplyId={state.id}
                                                     pickupDay={d.pickUpDay} interventionId={id}
                                                     pickupLocation={d.pickUpLocation}
                                                     issueDate={d.issueDate} typeName={d.type}
                                                     supplier={d.supplier}
                                                     onChange={e => updateTable(e.elements)} {...d}/>
                                </li>)}
                            </ul>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}