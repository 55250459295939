import GoogleMap from "google-map-react";
// import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import Pin from "./Pin";
import MapIcon from "../../images/map-illus.png";
import React, {Fragment, useEffect, useRef, useState} from "react";
import {IconButton, Tooltip} from "@mui/material";
import PictureIcon from "../../images/new-capture.png";
import InfoToolIcon from "../../images/info-tool-icon.png"
// import "../../style/mapPointerStyle.css"
import SEARCHICON from "../../images/gmaps-search.png"
import Select from "react-select";
import {text} from "../util/constants";
import {useSnackbar} from "notistack";
import IbatApi from "../../utils/IbatApi";

export default function MapPointer({voie,onSiteSelected, siteId, update, handleModal, onImageClick, creation, latitude, longitude}){
    const [screenShot, setScreenshot] = useState(false)
    const [mapProps, setMapProps] = useState({})
    const [image, setImage] = useState("")
    const [adresse, setAdresse] = useState([])
    const [site, setSite] = useState({value:{latitude:latitude ?? 0,longitude:longitude ?? 0},label:voie})
    const [inputValue, setInput] = useState(voie)
    const selectField = useRef(null)
    const[state,setState]=useState({})
    const api = new IbatApi();
    const {enqueueSnackbar} = useSnackbar();


    useEffect(()=>{
        const loadedVal=loadOptions(voie)
            if(loadedVal){
                loadedVal
                    .then(map=>map[0])
                    .then(data=> {
                        if (!data) {
                            alert(text.ADDRESS_NOT_FOUND)
                            return
                        }
                        setInput(data.label)
                        if (!latitude || !longitude) {
                            setState({...state,longitude:data.coordinates[0],latitude:data.coordinates[1],radius:150})
                            return setSite(data)
                        }
                    })
            }
            selectField.current.blur()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])



    const handleMapPropsChange = (e) => {
        setMapProps(mapProps => ({...mapProps, ...e}))
    }

    const setLongLat = (e) => {
        if(!mapProps.bounds){
            // const data = {latitude: site.value.latitude,longitude: site.value.longitude, id: "+", radius:0}
            const data = {latitude: e.lat ?? e.latitude,longitude: e.lng ?? e.longitude , id: "+", radius:0}
            handleLongLat({longitude: data.longitude, latitude: data.latitude, radius:0})
            return
        }
        const R = 6371000;
        const a1 = mapProps.bounds.nw.lat * Math.PI/180;
        const a2 = mapProps.center.lat * Math.PI/180;
        const Δφ = (mapProps.center.lat - mapProps.bounds.nw.lat) * Math.PI/180;
        const Δλ = (mapProps.center.lng - mapProps.bounds.nw.lng) * Math.PI/180;
        const a = Math.sin(Δφ/2) * Math.sin(Δφ/2) +
            Math.cos(a1) * Math.cos(a2) *
            Math.sin(Δλ/2) * Math.sin(Δλ/2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
        const d = R * c; // in metres
        // const data = {latitude: e.lat, longitude: e.lng, id: "+", radius:d}
        const data = {latitude: e.lat ?? e.latitude,longitude: e.lng ?? e.longitude , id: "+", radius:0}
        setSite({...site,value: data})
        handleLongLat({longitude: data.longitude, latitude: data.latitude, radius:d})

    }

    const generateStaticImage = (marker = false) => {
        let imageUrl
        if (site.value.latitude > 0) {
            const begin = !marker ? "https://maps.googleapis.com/maps/api/staticmap?center=" + site.value.latitude + "," + site.value.longitude + "&" :
                "https://maps.googleapis.com/maps/api/staticmap?markers=" + site.value.latitude + "," +site.value.longitude + "&"
            imageUrl = begin +
                "zoom=" + mapProps.zoom + "&" +
                "maptype=satellite&" +
                "size=" + 844 + "x" + 400 + "&" +
                "key="+process.env.REACT_APP_GOOGLE_API_KEY
        }
        setImage(imageUrl)
        setScreenshot(true)
        handleImage({site:site,imageUrl: imageUrl})
    }

    const loadOptions=(voie)=>{
        if(typeof voie!="string" || !voie){
            return null
        }
        let location = `https://api-adresse.data.gouv.fr/search/?q=${voie}`
        return fetch(location)
            .then(resp => resp.json()
            .then(jres => {
                setAdresse([])
                let voies = jres.features.map(obj => ({
                        ...obj.geometry,
                        data:{
                            cp: obj.properties.postcode,
                            city: obj.properties.city,
                            adresse: obj.properties.name,
                            latitude:obj.geometry.coordinates[1],
                            longitude:obj.geometry.coordinates[0]
                        },
                        value:{
                            latitude: obj.geometry.coordinates[1],
                            longitude: obj.geometry.coordinates[0]
                        },
                        label: obj.properties.label,
                    }))
                setAdresse(voies)
                return voies
            }))
            .catch(r => {
                setAdresse([]);
                return []})
    };

    function handleInputChange(d,action){
        if (action.action !== "input-blur" && action.action !== "menu-close" /*&& action.action!=="set-value"*/ ) {
            loadOptions(d)
            setInput(d)
        }
    }

    function handleLongLat(position){
        setState({...state,longitude:position.longitude,latitude:position.latitude,radius:position.radius})
    }
    function handleChange(d){
        onSiteSelected && onSiteSelected(d)
        setLongLat(d.value)
        setInput(d.label)
        setSite(d)
    }
    function handleImage(image){
        let newState = {...state,imageUrl:image.imageUrl}
        setState(newState)
        let data = {site: {...site}, latitude: state.latitude, longitude: state.longitude, imageUrl: image.imageUrl}
        onImageClick && onImageClick(data)
    }

    function isSavable() {
        return state.imageUrl!==""&& state.longitude!==""&&state.latitude!==""
    }
    function handleCopy() {
        window.open(`https://www.google.com/maps/search/${inputValue}`,"_blank")
    }

    function save(){
        if(isSavable()){
           api.updateSite(siteId,state).then(resp=>{
                update()
                handleModal && handleModal()
            })
                .then(r => enqueueSnackbar(text.SNACKBAR_SAVED,{variant:"success"}))
                .catch(e=>enqueueSnackbar(text.SNACKBAR_ERROR,{variant:"error"}))
        }
    }

    return (
        <Fragment>
            <div className={"mapPointer br20"}>
                <div className={"bord1 search-area flex-column br20"}>

                    <div className={"flex-row"}>
                        <Select
                            ref={selectField}
                            isClearable={false}
                            placeholder={"Rechercher une adresse"}
                            onChange={handleChange}
                            onInputChange={handleInputChange}
                            onCreateOption={handleCopy}
                            value={site}
                            className={"select-search"}
                            inputValue={inputValue}
                            noOptionsMessage={()=>"Aucun choix"}
                            options={adresse}

                        />
                        <IconButton className={"button-search"} onClick={handleCopy}>
                            <Tooltip title={text.GMAPS_SEARCH}><img alt={""} src={SEARCHICON}/></Tooltip>
                        </IconButton>
                    </div>

                    <div className={"w100 search-suggest br10 flex-row"}>{inputValue && inputValue}</div>

                </div>

                {site.value.latitude ?

                    <div className={"map"}>
                        <GoogleMap
                            {...mapProps}
                            bootstrapURLKeys={{key: process.env.REACT_APP_GOOGLE_API_KEY}} // set if you need stats etc ...
                            options={{mapTypeId: "satellite",tilt:15,heading:30,gestureHandling:"greedy"}}
                            center={[site.value.latitude, site.value.longitude]}
                            defaultZoom={18}
                            onClick={setLongLat}
                            onChange={handleMapPropsChange}
                        >
                            <Pin
                                 lat={site.value.latitude}
                                 lng={site.value.longitude}
                                 color={"rgb(0,0,255)"}
                                 pulse={"rgba(255,255,255,0.7)"}
                                 hovered={false}
                                 orientation={-1}
                                 text={""} />
                        </GoogleMap>
                    </div>
                    : <img alt={""} src={MapIcon}/>
                }

                <div className={"grid tools"}>
                    <div className={"info-tools"}>
                        <img alt={""} src={InfoToolIcon}/>
                    </div>
                    <div className={"picture"}>
                        <IconButton onClick={()=>{generateStaticImage()}}>
                            <Tooltip title={"Capturer"}><img alt={""} src={PictureIcon}/></Tooltip>
                        </IconButton>
                    </div>
                    <Tooltip title={text.THUMBNAIL}><div className={`screenshot br20 flex-row${screenShot ? " front" : ""}`}>{image && <img alt={"siteImage"} src={image}  />}</div></Tooltip>
                </div>

            </div>

            {
                !creation && <IconButton className={"action-btn neutral save"} onClick={save}>Mettre à jour</IconButton>
            }
        </Fragment>


    )
}