import React from "react"
import IbatApi from "../../utils/IbatApi";
import constants, {text} from "../util/constants";
import EditInterventionTeam from "./EditInterventionTeam";
import {Planning} from "../../planning/Planing";
import {timetoWeek} from "../../helpers/timeToDate";

export default function EditInterventionDate({id, day, team,update}) {

    let api = new IbatApi();


    if(!api.hasRole(constants.PERMISSION_PM)){
        return <h3>{text.NO_RIGHT}</h3>
    }
    return (team?.id===-1 ?
            <EditInterventionTeam id={id} update={update}/>
            :
            <Planning date={day ? timetoWeek(day) : undefined} team={team}/>
    )
}