import Table from "../../table/Table";
import constants, {text} from "../util/constants";
import React, {useEffect, useState} from "react";
import {useSnackbar} from "notistack";
import IbatApi from "../../utils/IbatApi";
import {Tooltip, IconButton} from "@mui/material";
import OpenFormIcon from "../../images/new-interv.png";
import Modal from "react-responsive-modal";
import SearchSite from "../forms/SearchSite";
import SearchClient from "../forms/SearchClient";

export default function Quotations({defaultFilter}) {
    const {enqueueSnackbar}=useSnackbar()

    let vars = {};
    let filters = window.location.search.substring(1).split("&").filter(f => f.length>0)
    for (const filterKey in filters) {
        let filter = filters[filterKey].split("=")
        vars[filter[0]] = filter[1]
    }
    const columns = [
        {id: "id", label: text.NUMBER, isChecked: true, isFiltrable: true, type: "linkText"},
        {id: "name", label: text.NAME, isChecked: true, isFiltrable: true, type: "objectLink"},
        {id: "devis", label: text.FROM_COMMANDE, isChecked: true, isFiltrable: true, type: "objectLink"},
        {id: "odooRef", label: text.REF_COMMANDE, isChecked: true, isFiltrable: true, type: "objectLink"},
        {id: "Interventions", label: text.FROM_INTERVENTION, isChecked: true, isFiltrable: true, type: "objectLink"},
        {id: "facturationEntity", label: text.FACTURATION_ENTITY, isChecked: true, isFiltrable: false, type: "objectLink"},
        {id: "site", label: text.DELIVERY_ENTITY, isChecked: true, isFiltrable: true, type: "objectLink"},
        {id: "status", label: text.STATUS, isChecked: true, isFiltrable: true, type: "text"},
        {id: "dateSent", label: text.DATE_SENT, isChecked: true, isFiltrable: true, type: "objectLink"},
    ]
    const [state, setState] = useState(
        {
            columns: columns,
            quotationList: [],
            pageSize: 50,
            pageNumber: 0,
            count: 0,
            filters: {
                id: vars['id'] ?? "",
                name: vars['name'] ?? (defaultFilter??""),
                devis: vars['devis'] ?? "",
                odooRef: vars['odooRef'] ?? "",
                Interventions: vars['Interventions'] ?? "",
                site: vars['site'] ?? "",
                validityDate: vars['validityDate'] ?? "",
                status: vars['status'] ?? "",
            }
        }
    )
    const [typing,setTyping]=useState(false)
    const [loading,setLoading]=useState(false)
    const [newQuotation,setNewQuotation]=useState(false)
    const api = new IbatApi()

    useEffect(() => {
        setColumnsPref()
        getQuotations()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [typing,state.pageNumber,state.pageSize])

    function setColumnsPref(){
        let c = api.getSettingsCookie(text.QUOTATIONS+"_columns")
        if (!c){
            return
        }
        c = c.split(",")
        let columns = state.columns
        columns.map(col=> {
            if (!c.includes(col.id)){
                col.isChecked = false
            }
            return col
        })
        setState( ({...state, columns: columns, mapping: false}))
    }
    const handleFilter = (e) => {
        let value = e.target.value
        let name = e.target.name
        vars[name] = value
        setState( ({...state, filters: {...state.filters, [name]: value,sort: name}, lastQueryAt: new Date()}))
    }
    const handleChangePage = (newPage) => {
        setState( ({...state, pageNumber: newPage}))
    };
    const handleChangeRowsPerPage = (event) => {
        setState( ({...state, pageSize: event.target.value, pageNumber: 0}))
    };
    function clearFilter() {
        setState({
            ...state, filters: {
                id: "",
                status: "",
                type: "",
                site: "",
                client: "",
                supply: "",
                team: "",
                reception: "",
                intervention: "",
                devis: "",
                sort: "",
                maps: false
            }
        })
        setTyping(!typing)
    }
    const handleColumns = (event) => {
        let columns = state.columns
        let filter = {...state.filters}
        let find = columns.find(col => col.id === event.target.name);
        find.isChecked = event.target.checked
        if (find.isFiltrable) {
            filter[find.id] = ""
        }
        api.setCookie(text.QUOTATIONS+"_columns", columns.filter(d=>d.isChecked).map(c=> c.id))
        setState( ({...state, columns: columns, filters: filter, mapping: false}))
    }

    function getQuotations() {
        setLoading(true)
        var sendedAt = new Date()
        api.getFilterQuotations({
            ...state.filters,
            pageNumber: state.pageNumber,
            pageSize: state.pageSize
        })
            .then(res => {
                setLoading(false)
                if(!state.lastQueryAt || sendedAt >= state.lastQueryAt){
                    setState( ({...state, quotationList: res.quotations ?? [], count: res.count ?? 0}))
                }
            })
            .catch(e=> {
                // enqueueSnackbar(text.SNACKBAR_ERROR, {variant: "error"})
                enqueueSnackbar(e?.data?.error ?? text.SNACKBAR_ERROR, {variant: "error"})
            })
    }

    const isSavable = () => {
        console.log(newQuotation)
        return newQuotation.billEntity.id && newQuotation.deliveryEntity.id && newQuotation.name
    }

    const createQuotation = () => {
        api.createQuotation(newQuotation)
            .then(r =>{
                enqueueSnackbar('Brouillon créé', {variant: "success"})
                window.open(`/quotation/${r}`, "_blank")
            })
            .catch (e => {
                enqueueSnackbar('Erreur', {variant: "error"})
                console.log(e)
            })
    }

    return (
        <div className={"page list-page"}>
            <Table onHandleChangeRowsPerPage={handleChangeRowsPerPage}
                   loading={loading}
                   onHandleChangePage={handleChangePage}
                   onGetData={getQuotations}
                   onHandleColumns={handleColumns}
                   onHandleFilter={handleFilter}
                   rowCount={state.count}
                   onEndTyping={setTyping}
                   filter={state.filters}
                   pageNumber={state.pageNumber}
                   pageSize={state.pageSize}
                   columns={state.columns}
                   list={state.quotationList}
                   onClearFilters={clearFilter}
                   mapdisplay={true}
                   tabName={text.QUOTATIONS}
            >

                <Tooltip title={text.TOOLTIP_CREATE_COMMAND}>
                    <IconButton disabled={!api.hasRole(constants.PERMISSION_AM) } onClick={() => {setNewQuotation({billEntity: {}, deliveryEntity: {}, name: "", quotationGroup: []})}}>
                        <img alt={""} src={OpenFormIcon}/>
                    </IconButton>
                </Tooltip>

                <Modal classNames={{modal:"scrollable"}} open={newQuotation} onClose={()=>setNewQuotation(false)}>
                    <div className={"commande-form"}>
                        <div className={"commande-head"}>
                            <h2>{text.H2_CREATION_QUOTATION}</h2>
                        </div>
                        <div className={"primary-section flex-column"}>
                            <input value={newQuotation?.name} placeholder={"Nom du devis"} onChange={(e)=>setNewQuotation({...newQuotation, name: e.target.value})}/><br />
                            <SearchSite onSave={(d)=>setNewQuotation({...newQuotation, deliveryEntity: d})}/><br />
                            <SearchClient onSave={(d)=>setNewQuotation({...newQuotation, billEntity: d})}/>
                        </div>
                        <IconButton
                                    onClick={() => {
                                        isSavable() ?
                                            createQuotation()
                                            :
                                            setNewQuotation(false)
                                    }}
                                    className={`action-btn ${isSavable? "validation" : "cancel"}`}>
                            {isSavable? text.CREATE : text.CANCEL}
                        </IconButton>

                    </div>
                </Modal>
            </Table>
        </div>
    )
}