import React from "react";

/*export default function Pin1(clickable,shape,text,pulse,data,onClick,color,onChildClick,onLeftClick,hovered,children,scale,cluster, onHover) {
    const [selected,setSelected]=useState(false)
    const pinColor={background:color?color:"rgba(200,0,0,0.5)",width:Math.min(scale?25+scale:25,50),height:Math.min(scale?25+scale:25,50)}

    function viewData() {
        clickable && setSelected(!selected)
    }

    function leftClick() {
        onLeftClick?onLeftClick():setSelected(!selected)
    }


    function childrenClick() {
        onChildClick &&onChildClick()
    }


    return (
            <div className={`myPin${cluster?" cluster ":" "}${color}`} onMouseEnter={()=>onHover(data.id)} onMouseLeave={() => onHover(null)}>
                <div className={`pin bounce ${shape}`} style={pinColor} onContextMenu={leftClick} onClick={()=>{viewData(); onClick && onClick(data)}}>
                    <span>{text}</span>
                </div>
                <div onClick={childrenClick} className={!selected&&!hovered?"child":"child force"}>{children}</div>
                <div className={hovered?"pulse fast bounce-shadow":"pulse bounce-shadow"}>
                    {pulse && <div className={"wave"} style={{borderColor: pulse}}/>}
                </div>
            </div>
        );
}*/

export default class Pin extends React.Component{

    constructor(props) {
        super(props);
        this.props = props
        this.selected = false
        let translateX
        let translateY
        /*if (props.orientation > 3 && props.orientation<6){
            translateX = -150
            translateY =  -180-((props.orientation-5)*110)
        }
        if(props.orientation >= 6){
            let odd = props.orientation % 2 === 0
            rotation = 0
            translateX = odd?-300:200
            translateY = -250+(Math.trunc((props.orientation-6)/2)*120)
        }*/
        if (props.orientation === 1) {
            translateX = 50
            translateY = -40
        }
        else if (props.orientation === 2) {
            translateX = 50
            translateY = 130
        }
        else if (props.orientation === 3) {
            translateX = -50
            translateY = 280
        }
        else if (props.orientation === 4) {
            translateX = -150
            translateY = 130
        }
        else if (props.orientation === 5) {
            translateX = -150
            translateY = -40
        }
        else {
            translateX = -50
            translateY = -120
        }

        if (this.props.shape === "circle") {
            translateX = -50
            translateY = 50
        }

        this.rotate = props.orientation!== -1 ? this.props.orientation*60-45 : -45
        if (props.shape === "triangle") {
            this.rotate -= 13
        }
        this.translateText = {transform: `translate(${translateX}%, ${translateY}%)`}
    }

    shouldComponentUpdate(prevProps){
        // this.props.hovered && console.log((prevProps.data?.id === this.props.hovered))
        // return prevProps.hovered !== this.props.hovered || prevProps.shape !== this.props.shape || prevProps.children !== this.props.children
        return (prevProps.data?.id === this.props.hovered || ((prevProps.data?.id === prevProps.hovered) && !this.props.hovered)) || prevProps.shape !== this.props.shape //|| prevProps.children !== this.props.children
    }

    render() {
        const pinStyle={
            transform: `rotate(${this.rotate}deg)`,
            background: this.props.shape !== "triangle" ? (this.props.color ?? "rgba(200,0,0,0.5)") : "none",
            width: Math.min(this.props.scale?25+this.props.scale:25,50),
            height: Math.min(this.props.scale?25+this.props.scale:25,50),
            borderBottom: this.props.shape === "triangle" ? `25px solid ${this.props.color ?? "rgba(200,0,0,0.5)"}` : "none"
        }
        /*function viewData() {
            this.props.clickable && this.props.setSelected(!this.props.selected)
        }
        function leftClick() {
            this.props.onLeftClick?this.props.onLeftClick():this.props.setSelected(!this.props.selected)
        }
        function childrenClick() {
            this.props.onChildClick && this.props.onChildClick()
        }*/
        return (
            <div className={`widfit pin-container${this.props.hovered === this.props.data?.id ? " hovered" : ""}`}
                 onMouseEnter={(e)=>this.props.onHover && this.props.onHover(e, this.props.data?.id)}
                 onMouseLeave={(e)=>this.props.onHover && this.props.onHover(e, null)}
            >
                {
                    this.props?.text &&
                    <div className={"pin-text br05"} style={this.translateText}>
                        {this.props.text}
                    </div>
                }
                <div
                    className={`bord1 pin${this.props.hovered === this.props.data?.id ? " hovered" : ""}${this.props.prioritize ? " prioritize" : ""}${this.props.waitingForEvent ? " waiting-for-event" : ""} ${this.props.shape ?? ""}`}
                    style={pinStyle}
                    onContextMenu={()=>this.props.onLeftClick?this.props.onLeftClick():this.selected = !this.selected}
                    onClick={()=>{this.props.onClick && this.props.onClick(this.props.data)}}
                />
                <div
                    //onClick={this.props.onChildClick && this.props.onChildClick()}
                    className={"child"}>
                    {this.props.children}
                </div>
            </div>
        );
    }
}