import React, {useState} from "react";
import {IconButton} from "@mui/material";
import {text} from "../util/constants";
import {useSnackbar} from "notistack";

export default function CancelInterventionModal({onSave, onClose}){
    const { enqueueSnackbar} = useSnackbar();
    const [selected, setSelected] = useState();

    function handleSave(){
        if (selected){
            onSave(selected)
        } else {
            enqueueSnackbar("Aucune cause sélectionnée",{variant:"error"})
        }
    }

    return (
        <div className={"cancel-intervention flex-column"}>
            <h1>Cause de l'annulation</h1>

            <IconButton className={`action-btn neutral ${selected === "client" ? "selected" : ""}`} id={"client"} onClick={()=>setSelected("client")}>Client</IconButton>
            <IconButton className={`action-btn neutral ${selected === "company" ? "selected" : ""}`} id={"company"} onClick={()=>setSelected("company")}>Entreprise</IconButton>
            <IconButton className={`action-btn neutral ${selected === "site" ? "selected" : ""}`} id={"site"} onClick={()=>setSelected("site")}>Site</IconButton>

            <div className={"flex-row"}>
                <IconButton className={"action-btn validation"} onClick={handleSave}>{text.SAVE}</IconButton>
                <IconButton className={"action-btn cancel"} onClick={onClose}>{text.CANCEL}</IconButton>
            </div>
        </div>
    )
}