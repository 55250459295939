import React, {Fragment, useState} from "react";
import IbatApi from "../../utils/IbatApi";
import {Navigate} from "react-router-dom"
import logo from "../../images/ebat-logo.png"
import Check from "../../images/check-user.png"
import {IconButton} from "@mui/material";
import {text} from "./constants";
import {enqueueSnackbar} from "notistack";

export default function AuthPage({refreshToken, modal, closeModal}) {
  const [login, setLogin] = useState({email: "", password: ""})

  let api = new IbatApi();
  const notLogged = api.getToken() === "" || !api.getTokenData()?.iat || api.getTokenData().iat<new Date().getTime()/1000

  function handleChange(e) {
    let name = e.target.name;
    let value = e.target.value;
    setLogin({...login, [name]: value})
  }

  function handleLogin() {
    api.login(login).then(respLogin => {
      api.getCompany()
          .then(resp=>{
            let style1 = resp.frontStyle
            if (style1 !== api.getStyleCookie()){
              api.setStyleCookie(style1)
            }
            api.getFile(resp.logo)
                .then(r=>{
                  api.setLogoCookie(r.b64)
                  // setLogo(r.url)
                  endLoginProcess(respLogin)
                })
                .catch(e => {
                  endLoginProcess(respLogin)
                })
          })
          .catch(e => enqueueSnackbar(e.message, {variant: "error"}))
    })
        .catch(e => enqueueSnackbar(e.message, {variant: "error"}))
  }

  function endLoginProcess(resp){
    if (resp && !modal) {
      refreshToken()
      // history.replace("/")
        window.location.href = window.location.origin
    }
    else if (modal) {
      closeModal()
    }
  }

  function handleKey(e) {
    e.key === "Enter" && handleLogin();
  }

  if (notLogged){
    return (
        <div className={"flex-column"}>
            <div className={`login-form br05 flex-column primary-section ${modal ? "" : "fullPage"} `}>
                <img alt={""} src={logo}/>
                <Fragment>
                    <h3>EBAT FACILITE LE BÂTIMENT<br /><br />Bienvenue !</h3>
                    <div className={"flex-column"}>
                        <input className={"large w100"} type={"email"} name={"email"} placeholder={text.LOGIN_DETAILS} value={login.email}
                               onKeyDown={handleKey} onChange={handleChange}/>
                        <input className={"large w100"} type={"password"} name={"password"} placeholder={text.PASSWORD} value={login.password}
                               onKeyDown={handleKey} onChange={handleChange}/>
                        <a href={"/subscribe"}>{text.SUBSCRIBE}</a>

                    </div>
                    <IconButton className={"action-btn validation"} onClick={handleLogin}> {text.LOGIN_BUTTON}
                    </IconButton>
                </Fragment>

            </div>

        </div>
    )
  }
  else {
    return <Navigate to={{ pathname: '/'}} />
    }

}
