import {forwardRef, useImperativeHandle, useState} from "react";

const ExtensibleComponent = forwardRef(({className, content, children}, ref) =>{

    const [hovered, setHovered] = useState(false)
    const [timer, setTimer] = useState(null)

    const foldComponent = () => {
        let time = setTimeout(onEndTimeOut, 800);
        setTimer(time)

    }

    const onEndTimeOut = () => {
        clearTimeout(timer);
        setHovered(false)
    }

    useImperativeHandle(ref, () => ({
        onEndTimeOut,
    }));

    return (
        <div title={content}
             className={`br20 secondary-section extensible-component${hovered ? " hovered" : ""}${className ? " "+className : ""}`}
             onMouseEnter={()=> {
                 setHovered(true)
                clearTimeout(timer)
             }} onMouseLeave={foldComponent}
        >
            {
                hovered &&
                <>
                    {children}
                </>
            }
        </div>

    )

})

export default ExtensibleComponent