import {IconButton} from "@mui/material";
import {useEffect, useState} from "react";
import Select from "react-select";
import IbatApi from "../../../utils/IbatApi";

export default function FamilyTab({families, jobTypes, updateList}){

    const [selectedFamily, setSelectFamily] = useState()
    const [newFamilyObject, setNewFamilyObject] = useState({})
    const api = new IbatApi()

    useEffect(()=> {
    }, [newFamilyObject, selectedFamily])

    function handleJobTypeNewFamily(d){
        setNewFamilyObject({...newFamilyObject, jobTypes: d.map(jt => jt.id)})
    }
    function handleJobType(d){
        setSelectFamily({...selectedFamily, jobTypes: d.map(jt => jt.id)})
    }
    function handleName(e) {
        setNewFamilyObject({...newFamilyObject, name: e.target.value})
    }
    function addNewFamily() {
        api.newCatalogFamily({...newFamilyObject, id: null, reference: '-1'})
            .then(() => {updateList()})
            .catch(() => {
                console.log("une erreur est survenue")
            })
    }
    function updateFamily() {
        api.newCatalogFamily({...selectedFamily})
            .then(() => {updateList()})
            .catch(() => {
                console.log("une erreur est survenue")
            })
    }

    function newFamily(){
        return (
        <div className={"catalog-content flex-column"}>
            <div className={"flex-row"}>
                <div>
                    <label>Nom de la famille</label><br />
                    <input type={"text"} placeholder={"Toiture"} value={newFamilyObject?.name ?? ""} onChange={e=>handleName(e)}/>
                </div>
                <div>
                    <label>Corps de métier concerné</label>< br />
                    <Select
                        isMulti
                        placeholder={"Couverture, Plomberie, ..."}
                        value = {jobTypes?.filter(jt => newFamilyObject?.jobTypes?.includes(jt.id))}
                        onChange = {d => handleJobTypeNewFamily(d)}
                        options={jobTypes}/>
                </div>
            </div>
            <div className={"flex-row"}>
                <IconButton className={"action-btn cancel"} onClick={()=>setNewFamilyObject({})}>Annuler</IconButton>
                <IconButton className={"action-btn validation"} onClick={addNewFamily}>Ajouter</IconButton>
            </div>
        </div>
        )
    }
    function editFamily(){
        return (
        <div className={"catalog-content flex-column"}>
            <div className={"flex-row"}>
                <div>
                    <label>Nom de la famille</label><br />
                    <b>{selectedFamily?.name}</b>
                </div>
                <div>
                    <label>Corps de métier concerné</label>< br />
                    <Select
                        isMulti
                        placeholder={"Couverture, Plomberie, ..."}
                        value = {jobTypes?.filter(jt => selectedFamily?.jobTypes?.includes(jt.id))}
                        onChange = {d => handleJobType(d)}
                        options={jobTypes}/>
                </div>
            </div>
            <div className={"flex-row"}>
                <IconButton className={"action-btn cancel"} onClick={()=>setSelectFamily(families.filter(f => f.id === selectedFamily.id)[0])}>Annuler</IconButton>
                <IconButton className={"action-btn validation"} onClick={updateFamily}>Sauvegarder</IconButton>
            </div>
        </div>
        )
    }

    return (
        <div className={"family-tab br05 flex-row"}>
            <ul>
                <li>
                    <IconButton className={"action-btn validation"} onClick = {() => {
                        setNewFamilyObject({})
                        setSelectFamily()
                    }}>Nouvelle famille</IconButton>
                </li>
                {families?.sort((a, b) => a.name > b.name ? 1 : -1)?.map(i =>
                    <li>
                        <div className={"as-link"} onClick={()=>{
                            setSelectFamily(i)
                            setNewFamilyObject(i)
                        }}>{i.name}</div>
                    </li>
                )}
            </ul>
            {
                !selectedFamily && newFamily()
            }
            {
                selectedFamily && editFamily()
            }
        </div>
    )
}