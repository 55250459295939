import React, {useEffect, useState} from "react";
import IbatApi from "../../utils/IbatApi";
import BillIcon from "../../images/new-devis.png";
import {IconButton} from "@mui/material";
import constants from "../util/constants";
import {useSnackbar} from "notistack";
import { text } from "../util/constants";
import DocumentLoader from "../util/DocumentLoader";

export default function EditBillForm({id,update, content, bills}){
    const { enqueueSnackbar} = useSnackbar();
    const [bill,setBill] =useState(content ?? "");
    const [billList, setBillList] = useState(bills ?? false);
    let api = new IbatApi();

    useEffect(()=>{
        !billList && api.getIntervention(id).then(r=>{
            setBillList(r.bills)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[content])

    function handleTime(e) {
        setBill(e.target.value);
    }
    function handleEnter(e){
        if (e.key === "Enter"){
            handleSave()
        }
    }

    function handleSave() {
        api.setBill(id, encodeURIComponent(bill))
            .then(c=>update())
            .then(r => enqueueSnackbar(text.SNACKBAR_SAVED,{variant:"success"}))
            .catch(e=>enqueueSnackbar(text.SNACKBAR_ERROR,{variant:"error"}))
    }



    if(!api.hasRole(constants.PERMISSION_BILL)){
        return <h3>{text.NO_RIGHT_PROMPT}</h3>
    }
    return(<div className={"editIntervention flex-column primary-section"}>
            <div className={"img-container"}>
                <img alt={""} src={BillIcon}/>
            </div>

            {
                billList && billList.length > 0 &&
                <div className={"secondary-section"}>
                        <p>Factures</p>
                    {billList?.map(b => <div className={"flex-row"}>
                        {b.odooPdfId && <DocumentLoader name={b.odooId} type={"bill"}/>}
                        {b.odooRef}
                    </div>)}
                </div>
            }
            <div className={"secondary-section"}>
                <h2>{text.BILL_NUMBER}</h2>
                <input type={"text"} placeholder={text.BILL_NUMBER} value={bill} onChange={handleTime} onKeyDown={handleEnter}/>
                <IconButton className={"action-btn validation"} onClick={handleSave}>Enregistrer</IconButton>
            </div>
        </div>

    )
}