import React, {useEffect, useState} from "react";
import BinICon from "../../images/delete.png"
import ReadyIcon from "../../images/ready.png"
import NotReadyIcon from "../../images/not-ready.png"
import IbatApi from "../../utils/IbatApi";
import {IconButton, Tooltip} from "@mui/material";
import FileUploader from "../util/FileUploader";
import DocumentLoader from "../util/DocumentLoader";
import constants, {text} from "../util/constants";
import {EditText} from "react-edit-text";
import Select from "react-select";
import Modal from "react-responsive-modal";
import Sites from "../lists/Sites";
import {useSnackbar} from "notistack";
import Row from "../../table/row/Row";
import EditSupply from "../forms/EditSupply";
import IconEye from "../../images/contact-interaction.png";
import InteractionClient from "./InteractionClient";
import InterventionForm from "../forms/InterventionForm";
import {useMediaQuery} from "react-responsive";
import Layer from "../../layer/Layer";
import {v4} from "uuid";
import CustomImage from "../util/CustomImage";

export default function SupplyMaterial({type, supplyId, id, isReady, quantity, unit, onChange,documents, comment, deleteSupply, updateSupply, supplierCmd}) {
    const [state, setState] = useState({
        type: type,
        id: id,
        supplyId: supplyId,
        isReady: isReady,
        quantity: quantity ?? 0,
        unit: unit ?? "",
        comment: comment ?? "",
        documents:documents?documents:[],
        supplierCmd: supplierCmd,
    })
    const [handleSupplierCmd, setHandleSupplierCmd] = useState(false)
    const [showSupplierIntervention, setShowSupplierIntervention] = useState(false)
    const [futuresSupplierIntervention, setFuturesSupplierIntervention] = useState([])
    const [supplierSite, setSupplierSite] = useState(false)
    const [selectedRow, setSelectedRow] = useState(false)
    const [infos, setInfos] = useState(false)
    const [filterPicture,setFilterPicture]=useState(false)
    const [current,setCurrent]=useState(0)
    const isMobile = useMediaQuery({ query: '(max-width: 915px)' })

    const unitList = [
        {value: "m", label: "m"},
        {value: "m²", label: "m²"},
        {value: "m³", label: "m³"},
        {value: "mL", label: "mL"},
        {value: "kg", label: "kg"},
        {value: "L", label: "L"},
        {value: "F", label: "F"},
        {value: "H", label: "H"},
        {value: "J", label: "J"},
        {value: "U", label: "U"},
    ]
    const columns = [
        {id: "id", label: text.NUMBER, isChecked: true, isFiltrable: true, type: "linkText"},
        {id: "status", label: text.STATUS, isChecked: true, isFiltrable: true, type: "text"},
        {id: "type", label: text.FILE_TYPE, isChecked: true, isFiltrable: true, type: "object"},
        {id: "devis", label: text.COMMAND_NUM, isChecked: true, isFiltrable: true, type: "objectLink"},
        {id: "site", label: text.SITE, isChecked: true, isFiltrable: true, type: "objectLink"},
        {id: "address", label: text.ADDRESS, isChecked: true, isFiltrable: true, type: "object"},
        {id: "cp", label: text.ZIPCODE, isChecked: true, isFiltrable: true, type: "text"},
        {id: "ville", label: text.TOWN, isChecked: true, isFiltrable: true, type: "text"},
        {id: "supply", label: text.SUPPLY, isChecked: true, isFiltrable: true, type: "popup", popup: EditSupply, hideOnPhone: true},
        {id: "phone", label: text.PHONE_NUMBER, isChecked: true, isFiltrable: true, type: "text"},
        {id: "interactions", label: text.INTERACTIONS, isChecked: true, isFiltrable: true, icon: IconEye, type: "popup", popup: InteractionClient, hideOnPhone: true},
    ]
    let api = new IbatApi();
    const {enqueueSnackbar}=useSnackbar()
    useEffect(() => {
        if (!updateSupply){
            sendMaterial(state)
        } else {
            updateSupply(state)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    function sendMaterial() {
        let d = {
            type: state.type,
            ready: state.isReady,
            supplier: state.supplier,
            quantity: state.quantity,
            unit: state.unit,
            comment: state.comment
        }
        if (!updateSupply){
            api.sendMaterial(state.id, d)
                .then(r => {
                    setState({...r, supplyId: state.supplyId})
                })
                .catch(e => enqueueSnackbar(e.message,{variant:"error"}))
        }
        else {
            updateSupply(state)
        }
    }

    function handleFile(files){
        if (!updateSupply) {
            api.sendMaterialDocs(state.id,files).then(MDocs=>setState({...state,...MDocs}))
        } else {
            setState({...state, documents: files})
        }

    }


    function handleMaterial(e) {
        if (e?.target?.type === "checkbox") {
            setState(state => ({...state, [e.target.name]: e.target.checked}))
        }
        else if (e?.target?.type === "file") {
            setState(state => ({...state, [e.target.name]: e.target.files}))
        }
        else if (e.target.name === "isReady" && +e.target.value === constants.SUPPLIER && !state.supplierCmd) {
            setHandleSupplierCmd(id)
        }
        else {
            setState(state => ({...state, [e.target.name]: e.target.value}))
        }
    }
    function handleUnit(e) {
        setState(state => ({...state, unit: e.value}))
    }


    function deleteMaterial() {
        if (!deleteSupply){
            api.deleteMaterial(state.supplyId,state.id).then(r => onChange(r))
        }
        else {
            deleteSupply(state)
        }

    }

    function getFuturesSupplierInterventions(siteId){
        api.getFuturesSupplierInterventions(siteId)
            .then(r=>setFuturesSupplierIntervention(r))
            .catch(e=>enqueueSnackbar(e.message,{variant:"error"}))
    }

    function handleSupplierIntervention(siteId){
        setShowSupplierIntervention(siteId)
        api.getSite(siteId)
            .then(r=>setSupplierSite(r))
            .catch(e=>enqueueSnackbar(e.message,{variant:"error"}))
        getFuturesSupplierInterventions(siteId)
    }

    function injectSuppyForSupplier(interventionId) {
        api.joinSupplyForSupplier(interventionId, state.id)
            .then(r => {
                enqueueSnackbar("Fourniture ajoutée à l'intervention fournisseur",{variant:"success"})
                setShowSupplierIntervention(false)
                setHandleSupplierCmd(false)
                setState(state => ({...state, isReady: constants.SUPPLIER, supplierCmd: r}))
            })
            .catch(e => enqueueSnackbar(e.message,{variant:"error"}))
    }

    function getData() {
        handleSupplierIntervention(supplierSite.id)
    }


    function showSelectedProduct(p) {
        return (
            <div key={v4()} className={"secondary-section flex-column selected-product w100"} style={p.deleted === true ? {textDecoration: "line-through"} : {}}>
                <h2>{text[p.actionType] ? `(${text[p.actionType]})` : ""} {p.product.name} {p.variantValue ? `(${p.variantValue})` : ""} : </h2>
                {p.qty && <div className={"w100"}>Qte : {p.qty}</div>}
                {p.mandatoriesFilter?.map(f =>
                    <div className={"w100"} key={v4()}>
                        {f.userValue && f.userValue !== "-" && <div className={"as-link"} onClick={()=>setFilterPicture(f)}>{f.name} : {f.userValue}</div>}
                    </div>
                )}
            </div>
        )
    }


    function next(){
        setCurrent(Math.min((current+1),filterPicture.userPicture.length-1))
    }
    function previous(){
        setCurrent(Math.max(current-1,0))
    }

    return (

        <div>
            <EditText className={"bord1 h2r"} name={"type"} value={state.type} inputClassName={"w100 h100"} onChange={handleMaterial} onBlur={sendMaterial}
                      inline={true} id={"type"}/>

            <div className={`material ${isMobile ? "flex-row auto-line" : "grid"} ${state.isReady ? "ready" : ""}`}>

                <input type={"number"} name={"quantity"} placeholder={text.QUANTITY}
                       value={state.quantity}
                       className={`${isMobile ? "w50" : "large"}`}
                       onChange={handleMaterial} onBlur={sendMaterial}/>

                <Select
                    name={"unit"}
                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                    menuPortalTarget={document.body}
                    options={unitList}
                    placeholder={"Unité"}
                    defaultValue={unitList.filter(f => f.value === (state.unit ?? "U"))[0]}
                    onChange={handleUnit}
                    onBlur={sendMaterial}
                    className={"selectable"}
                    classNames={{control:(base) => "h100"}}
                />


                <textarea
                    className={`${isMobile ? "w100" : ""}`}
                    name={"comment"}
                          disabled={!api.hasRole(constants.PERMISSION_TRE)}
                          defaultValue={state.comment ?? ""}
                          onChange={handleMaterial}
                          onBlur={sendMaterial}
                />

                <div className={"flex-row wmax"}>
                    <FileUploader files={[]} onUpload={handleFile} id={state.id}/>
                    {
                        state.documents.length > 0 &&
                        state.documents.map(file => <DocumentLoader name={file}/>)
                    }
                </div>
                <Tooltip title={text.REMOVE}>
                    <IconButton onClick={deleteMaterial}><img alt={""} src={BinICon}/></IconButton>
                </Tooltip>
                <div className={"supply-status flex-row"}>
                    <select className={"large w100"} name={"isReady"} value={state.isReady ?? false} onChange={handleMaterial} onBlur={sendMaterial}>
                        <option value={constants.UNKNOWN}>-</option>
                        <option value={constants.IN_STOCK}>{text.AVAILABLE_PRODUCT} </option>
                        <option value={constants.SUPPLIER}>{text.SUPPLIER}</option>
                        <option value={constants.ON_SITE}>{text.SUPPLY_ON_SITE} </option>
                        <option value={constants.ON_SITE_DELIVERY}>{text.SUPPLY_ON_SITE_DELIVERY} </option>
                        <option value={constants.WAREHOUSE_DELIVERY}>{text.SUPPLY_WAREHOUSE_DELIVERY} </option>


                    </select>
                    <img alt={""} src={state.isReady !== constants.UNKNOWN ? ReadyIcon : NotReadyIcon}/>
                </div>

                <div style={{overflowX: "auto"}}>
                    <a rel={"noreferrer"} href={`/Interventions/${state.supplierCmd?.id}`} target={"_blank"}>
                        {state.supplierCmd?.devis?.site?.name}
                    </a>
                </div>

                <Modal open={handleSupplierCmd} onClose={() => setHandleSupplierCmd(false)}>
                    <Sites showSupplierIntervention={handleSupplierIntervention}/>
                </Modal>

                <Modal open={showSupplierIntervention} onClose={() => setShowSupplierIntervention(false)}>
                    <div className={"flex-column"}>
                        <h2>{state.type}</h2>
                        <h3>Sur quelle intervention fournisseur souhaitez-vous intégrer cette fourniture ?</h3>
                        {
                            futuresSupplierIntervention.length > 0 ?
                            futuresSupplierIntervention?.map(ligne=>
                                <div className={"flex w70vw"}>
                                    <Row
                                        key={ligne.id}
                                        keyRow={ligne.id}
                                        onClick={() => {
                                            setSelectedRow(ligne.id)
                                        }}
                                        columns={columns}
                                        ligne={ligne}
                                        selected={selectedRow === ligne.id}
                                        prioritize={ligne.prioritize}
                                    />
                                    <IconButton className={"action-btn validation flex"} onClick={() => injectSuppyForSupplier(ligne.id)}>
                                       Intégrer ici
                                    </IconButton>
                                </div>
                            ) :
                                "Aucune intervention existante chez ce fournisseur."
                        }

                        <InterventionForm className={"site-newIntervention"} onSaveSucceed={getData} site={{...supplierSite}} supplierMode={true}/>
                    </div>
                </Modal>


            </div>
            {
                state.point &&
                <div className={"info"} onClick={() => setInfos(state.point)}>
                    <Tooltip title={"Informations Fourniture"}>
                        <b className="clientinfo-title bord1 as-link bg-wh">ℹ</b>
                    </Tooltip>
                </div>
            }
            <Modal open={infos} onClose={()=>setInfos(false)}>
                <div className={infos.picture?.filePath ? "flex-row" : ""}>
                    {
                        infos.picture?.filePath &&
                        <CustomImage ibatApi={api} width={500} className={"layer-img"} name={infos.picture?.filePath}/>
                    }
                    <div className={"flex-column w100"}>
                        <div className={"primary-section"}>
                            <h1>Fournitures sélectionnées</h1>
                            {infos?.selectedProducts?.map(p => showSelectedProduct(p))}

                            <div className={"secondary-section"}>
                                <h2>Commentaire : </h2>
                                <p>
                                    {infos.comment}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            {
                filterPicture?.userPicture?.length > 0 &&
                <Modal open={filterPicture} onClose={()=> {
                    setFilterPicture(false)
                    setCurrent(0)
                }}>
                    <div>
                        <h1>{filterPicture.name} : {filterPicture.userValue}</h1>
                        <div className={"caroussel-container primary-section"}>
                            <div className={"caroussel flex-row rounded"}>
                                <div className={"indexNumber rounded"}>{current+1}/{filterPicture.userPicture.length}</div>
                                <IconButton onClick={previous}>‹</IconButton>

                                <div className={"keep-space"}>
                                    <CustomImage className={"rounded"} imgClassName={"w100"} name={filterPicture.userPicture[current]}/>
                                </div>
                                <IconButton onClick={next}>›</IconButton>

                            </div>
                        </div>
                    </div>
                </Modal>
            }
        </div>
    )
}