import GoogleMapReact from "google-map-react";
import constants, {text} from "../components/util/constants";
import {getPinColor, getShape, isModifiable} from "../helpers/interventionManager";
import Pin from "../components/util/Pin"
import Modal from "react-responsive-modal";
import InteractionClient from "../components/bean/InteractionClient";
import React, {
    Fragment,
    useCallback,
    useEffect,
    useImperativeHandle,
    useRef,
    useState
} from "react";
import EventCreator from "../event-creator/EventCreator";
import IbatApi from "../utils/IbatApi";
import Select from "react-select";
import Checkbox from "../components/util/Checkbox";
import {Tooltip} from "@mui/material";
import EditSupply from "../components/forms/EditSupply";
import {timeToDate} from "../helpers/timeToDate";
import {isMobile} from "react-device-detect";

const {forwardRef} = require("react");
const PlanningMap=forwardRef(({onApiLoaded,hovered,handleAddPin, onHover, switchTeam, headerHeight},ref)=> {

    const api=new IbatApi()
    const [openInteractionClient,setOpenInteractionClient]=useState()
    const [pinList,setPinList]=useState([])
    const [filteredPinList,setFilteredPinList]=useState([])
    const [otherTeamPinList,setOtherTeamPinList]=useState([])
    const [filteredOtherTeamPinList,setFilteredOtherTeamPinList]=useState([])
    const [openEventForm,setOpenEventForm]=useState()
    const [openSupplyModal,setOpenSupplyModal]=useState()
    const [emergencyOnly, setEmergencyOnly]=useState(false)
    const [readyOnly, setReadyOnly]=useState(false)
    const [showOtherTeam, setShowOtherTeam]=useState(false)
    const [selectedCoord, setSelectedCoord]=useState()
    const [zoom, setZoom]=useState(6)
    const [map, setMap]=useState()
    const [maps, setMaps]=useState()
    const [path, setPath]=useState()

    let timeout = null;

    useImperativeHandle(ref,()=>({
        getinterventions(teamId,begin,end){
            api.getInterventionToPlann(teamId,begin,end+constants.H24).then(res=>{
                let s = count(res, function (item){
                    return item.devis.site.id
                })
                res = res.map(r => {
                    let orientation = s[r.devis.site.id] - 1
                    r.orientation = orientation
                    s[r.devis.site.id] = s[r.devis.site.id] === 2 ? 0 : orientation
                    return r
                })
                setPinList(res)
            })
            api.getInterventionOfOtherTeam(teamId, begin,null).then(res=>{
                setOtherTeamPinList(res)
            })
            api.getInterventionLinkToSupplierIntervention(begin).then(res=>{
                const links = res.map(l => {
                    return [new maps.LatLng(parseFloat(l.siteLat), parseFloat(l.siteLong)), new maps.LatLng(parseFloat(l.supplierLat), parseFloat(l.supplierLong))]
                })
                var polygons = [];
                links.forEach(l => {
                    // Construct the polygon.
                    polygons.push(new maps.Polyline({
                        path: l,
                        strokeColor: '#FF0000',
                        strokeOpacity: 0.8,
                        strokeWeight: 2,
                        fillColor: '#FF0000',
                        fillOpacity: 0.35
                    }));
                    polygons[polygons.length - 1]?.setMap(map);
                })
            })
        },
        setGeodesicPath(pathList){
            path?.setPath(pathList)
            path?.setMap(map)
        }
    }))

    useEffect(()=> {
        filterPinListByBounds()
    }, [pinList, otherTeamPinList])





    const handleApiLoaded = (map, mMaps) => {
        // use map and maps objects
        // setMaps(maps)
        setMap(map)
        setMaps(mMaps)
        const line = new mMaps.Polyline({
            geodesic: true,
            strokeColor: "#219dd3",
            strokeOpacity: 1.0,
            strokeWeight: 3,
            icons: [
                {
                    icon: {
                        path: mMaps.SymbolPath.CIRCLE,
                        strokeColor: "#000",
                        strokeOpacity: .7,
                    },
                    offset: "100%",
                    repeat: "200px"
                }
            ],
        })
        setPath(line)
        animateCircle(line);
    };

    function animateCircle(line) {
        let count = 0;

        window.setInterval(() => {
            count = (count + 1) % 1000;

            const icons = line.get("icons");

            icons[0].offset = count / 10 + "%";
            line.set("icons", icons);
        }, 100);
    }

    const filterPinListByBounds = (mMap) => {
        const m = map ?? mMap
        if (!m || typeof(m) === "number") return

        const bounds = m.getBounds()
        const minLat = bounds.getSouthWest().lat()
        const maxLat = bounds.getNorthEast().lat()
        const minLng = bounds.getSouthWest().lng()
        const maxLng = bounds.getNorthEast().lng()
        if (pinList.length > 0){
            setFilteredPinList(pinList.filter(p => p.devis.site.latitude > minLat && p.devis.site.latitude < maxLat && p.devis.site.longitude > minLng && p.devis.site.longitude < maxLng))
        }
        if (otherTeamPinList.length > 0){
            setFilteredOtherTeamPinList(otherTeamPinList.filter(p => p.devis.site.latitude > minLat && p.devis.site.latitude < maxLat && p.devis.site.longitude > minLng && p.devis.site.longitude < maxLng))
        }
    }
    const delayedFilterPinList = (mMap) => {
        if (timeout) {
            clearTimeout(timeout);
        }

        timeout = setTimeout(() => {
            filterPinListByBounds(mMap);
        }, 1000); // Délai d'une seconde (1000ms)
    }


    function count(array, classifier) {
        classifier = classifier || String;
        return array.reduce(function (counter, item) {
            var p = classifier(item);
            counter[p] = counter.hasOwnProperty(p) ? counter[p] + 1 : 1;
            return counter;
        }, {})
    }

    useEffect(() => {

    }, [pinList, otherTeamPinList, selectedCoord]);

    return ( <div className={"planning-map"} style={isMobile ? {transform: `translateY(-${headerHeight??0}px)`} : {}}>
        <div className={"plan-search"}>
            <div className={"flex-row secondary-section"}>
                <Checkbox handleCheckChieldElement={()=>setEmergencyOnly(!emergencyOnly)} className={"flex-row"} label={"Urgence uniquement"}/>
                <Checkbox handleCheckChieldElement={()=>setReadyOnly(!readyOnly)} className={"flex-row"} label={"Prête uniquement"}/>
                <Checkbox handleCheckChieldElement={()=>setShowOtherTeam(!showOtherTeam)} className={"flex-row"} label={"Autres équipes"}/>
            </div>
            <Select
                classNamePrefix={"search"}
                placeholder={text.SITE_SEARCH}
                options={pinList.map(i=>({value:i.id,label:`${i.id} ${i.devis.site.name} ${i.devis.site.address} ${i.devis.site.cp} ${i.devis.site.ville} [${i.type.name}]`, coordinate: [i.devis.site.latitude, i.devis.site.longitude]}))}
                onChange={e=>{
                    setSelectedCoord(e.coordinate)
                    setZoom(12)
                    onHover(null, e.value)
                    delayedFilterPinList(map)
                }}
                isClearable
            />
        </div>
        <div className={"map"}>
            {
                <GoogleMapReact
                bootstrapURLKeys={{key: process.env.REACT_APP_GOOGLE_API_KEY}}
                center={selectedCoord ?? constants.MAPCENTER}
                zoom={zoom}
                options={{mapId: "b989d61f03f5b1dc", gestureHandling: "greedy", clickableIcons: false,}}
                yesIWantToUseGoogleMapApiInternals={true}
                onGoogleApiLoaded={({map, maps}) => handleApiLoaded(map, maps)}
                onDragEnd={(mMap)=> delayedFilterPinList(mMap)}
                onZoomAnimationEnd={delayedFilterPinList}
            >
                {filteredPinList
                    .filter(
                        p=>(!emergencyOnly || (emergencyOnly && p.type.id === 2)) && (!readyOnly || (readyOnly && p.devis?.supply?.ready && p.devis?.supply?.ready===constants.READY))
                    )
                    // .filter(i=>isModifiable(i) || i.status===constants.STATUS_PROCESSING)
                    // .slice(0, 800)
                    .map((p, idx) =>
                        <Pin key={p.id}
                             latLng={{lat: p.devis.site.latitude, lng: p.devis.site.longitude}}
                             // lat={p.devis.site.latitude}
                             // lng={p.devis.site.longitude}
                             text={p?.devis?.site?.name.substring(0, 5)}
                             data={p}
                             hovered={hovered}
                             prioritize={p.prioritize}
                             waitingForEvent={p.emergencyEventStatus === constants.WAITING_FOR_EVENT}
                             color={getPinColor(p.type.id, p.status)}
                             shape={getShape(p.status)}
                             onHover={onHover}
                             orientation={p.orientation}
                             onClick={data => setOpenEventForm(data)}>
                            {
                                hovered === p.id ?
                                    <div className={"bord1 br05 planning-card flex-column"}>
                                        <div style={{backgroundColor: getPinColor(p.type.id)}}
                                             className={"intervention-type w100"}>
                                            {p.id} : {p.type.name} [{text.STATUS_TRADUCTION[p.status]}]
                                        </div>

                                        <a className={"secondary-section"} rel={"noreferrer"} href={`/devis/${p.devis.id}`} target={"_blank"}>Référence : {p.devis.clientIdBCommande}</a>
                                        <a className={"secondary-section"} rel={"noreferrer"} href={`/interventions/${p.id}`} target={"_blank"}>
                                            {p.devis.site.name} {p.devis.site.adresse} {p.devis.site.cp} {p.devis.site.ville}
                                        </a>

                                        {p.sisterNumber > 0 &&
                                            <span>{p.sisterNumber} autre(s) intervention(s) sur ce devis.</span>}

                                        <div className={"flex-row"}>
                                            <div className={"addEvent"} onClick={()=>setOpenEventForm(p)}/>
                                            <div className={"editSupply"} onClick={()=>setOpenSupplyModal(p)}/>
                                        </div>
                                        <ul onClick={() => setOpenInteractionClient(p)}>
                                            {p.devis.site?.contactPhone?.map((ph, id) => <li key={id}>{ph}</li>)}
                                        </ul>
                                        <div className={"date-intervention"}>
                                            {(p.dateIntervention > 0 || p.event?.start > 0) ? `${new Date(p.dateIntervention ?? p.event?.start).toLocaleDateString()} ${new Date(p.dateIntervention ?? p.event?.start).toLocaleTimeString()}` : text.UNDEFINED_DATE}
                                        </div>
                                        <div className={"estimated-duration"}>
                                            Temps estimé : {timeToDate(p.estimateDuration*1000)}
                                        </div>
                                    </div>
                                    :
                                    <></>
                            }
                        </Pin>
                    )
                }
                {
                    showOtherTeam &&
                    filteredOtherTeamPinList.map((p, idx) =>
                        <Pin key={p.id}
                             // position={{lat: p.devis.site.latitude, lng: p.devis.site.longitude}}
                             lat={p.devis.site.latitude}
                             lng={p.devis.site.longitude}
                             text={p.team.name}
                             data={p}
                             hovered={hovered}
                             prioritize={p.prioritize}
                             color={"grey"}
                             shape={"circle"}
                             onHover={onHover}
                             orientation={p.orientation}
                             onClick={()=>{}}>
                            {
                                hovered === p.id  ?
                                    <div className={"bord1 planning-card flex-column"}>
                                        <div style={{backgroundColor: getPinColor(p.type.id)}}
                                             className={"intervention-type w100"}>
                                            {p.id} : {p.type.name} [{text.STATUS_TRADUCTION[p.status]}]
                                        </div>

                                        <a className={"secondary-section"} rel={"noreferrer"} href={`/devis/${p.devis.id}`} target={"_blank"}>Référence : {p.devis.clientIdBCommande}</a>
                                        <a className={"secondary-section"} rel={"noreferrer"} href={`/interventions/${p.id}`} target={"_blank"}>
                                            {p.devis.site.name} {p.devis.site.adresse} {p.devis.site.cp} {p.devis.site.ville}
                                        </a>

                                        {p.sisterNumber > 0 &&
                                            <span>{p.sisterNumber} autre(s) intervention(s) sur ce devis.</span>}

                                        <Tooltip title={`Afficher le planning de ${p.team?.name}`}>
                                            <div className={"showPlanningOfSelectedTeam"} onClick={()=>switchTeam(p.team)}/>
                                        </Tooltip>
                                        <ul onClick={() => setOpenInteractionClient(p)}>
                                            {p.devis.site?.contactPhone?.map((ph, id) => <li key={id}>{ph}</li>)}
                                        </ul>
                                        <div className={"date-intervention"}>
                                            {(p.dateIntervention > 0 || p.event?.start > 0 || p.headEventDate > 0) ? `${new Date(p.dateIntervention ?? (p.event?.start ?? p.headEventDate)).toLocaleDateString()} ${new Date(p.dateIntervention ?? (p.event?.start ?? p.headEventDate)).toLocaleTimeString()}` : text.UNDEFINED_DATE}
                                        </div>
                                    </div>
                                    :
                                    <></>
                            }
                        </Pin>
                    )
                }
            </GoogleMapReact>}

            <Modal open={openInteractionClient}
                   onClose={() => setOpenInteractionClient(false)}>
                <InteractionClient id={openInteractionClient?.id} site={openInteractionClient?.devis?.site}/>
            </Modal>

            <Modal classNames={{modal: 'onTheRight', root: 'transparent'}} open={openEventForm} onClose={() => setOpenEventForm(false)}>
                <EventCreator intervention={{...openEventForm}} onSave={handleAddPin}
                              onClose={() => setOpenEventForm(false)}/>
            </Modal>
            <Modal open={openSupplyModal} onClose={() => setOpenSupplyModal(false)}>
                <EditSupply id={openSupplyModal?.id} onClose={() => setOpenSupplyModal(false)} title={`${openSupplyModal?.devis?.clientIdBCommande} - ${openSupplyModal?.devis?.site?.name}`}/>
            </Modal>
        </div>
    </div>)
})
export default PlanningMap;