import React, {useEffect, useState} from "react";
import IbatApi from "../../utils/IbatApi";
import constants, {text} from "../util/constants";
import {IconButton, Tooltip} from "@mui/material";
import {v4} from "uuid";
import SupplyDocument from "../bean/SupplyDocument";
import SupplyAdminDocument from "../bean/SupplyAdminDocument";
import SupplyMachinery from "../bean/SupplyMachinery";
import MaterialIcon from "../../images/materiel.png";
import SupplyMaterial from "../bean/SupplyMaterial";
import DocumentIcon from "../../images/newdoc.png";
import MachineryIcon from "../../images/nacelle.png";
import ExtensibleComponent from "../util/ExtensibleComponent";


export default function DevisForm({temp,trigger,onSave}) {
    const [state, setState] = useState({...temp})
    // const [state, setState] = useState({clientIdBCommande: temp.clientIdBCommande,montant:temp.montant,acompte:temp.acompte})
    const [metadata]=useState({
        supplyDocument:[...temp.supplyDocument],
        supplyMachinery:[...temp.supplyMachinery],
        material:[...temp.material],
        supplyAdminDocument:[...temp.supplyAdminDocument]
    })

    const [newMaterial, setNewMaterial] = useState({'@type':constants.MATERIAL_CLASS})
    const [newAdminDocument, setNewAdminDocument] = useState({'@type':constants.ADMIN_DOC_CLASS})
    const [newDocument, setNewDocument] = useState({'@type':constants.DOCUMENT_CLASS})
    const [newMachinery, setNewMachinery] = useState({'@type':constants.MACHINERY_CLASS})

    const [comments, setComment] = useState(temp.comments)
    const [documents]=useState( {...temp.documents})

    const [importedDocuments, setImportedDocuments] = useState([])
    const [importedAdminDocuments, setImportedAdminDocuments] = useState([])
    const [importedMachineries, setImportedMachineries] = useState([])
    const api=new IbatApi()

    useEffect(()=>{
        getTypes()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    })
    useEffect(()=>{
    }, [state])


    function getTypes() {
        importedAdminDocuments.length === 0 && api.getAdministrationTypesDocument().then(d => {
            // setImportedAdminDocuments(d.map(d=>({value:d.id,label:d.name})))
            setImportedAdminDocuments(s => d)
        })
        importedMachineries.length === 0 && api.getConstructionTypesMachinery().then(d => {
            // setImportedMachineries(d.map(d=>({value:d.id,label:d.name})))
            setImportedMachineries(s => d)
        })
        importedDocuments.length === 0 && api.getContructionTypesDocument().then(d => {
            setImportedDocuments(s => d)
            // setImportedDocuments(d.map(d=>({value:d.id,label:d.name})))
        })
    }

    function handleChange(e) {
        const name = e.target.name
        setState({
            ...state,
            [name]: e.target.value
        })
    }

    /*function handleFile(files,name) {
        setDocuments({...documents,[name]:files})
    }
    function handleMetadataDocument(data) {
        setMetadata({...metadata,supplyDocument:data})
    }
    function handleMetadataAdminDocument(data) {
        setMetadata({...metadata,supplyAdminDocument:data})
    }
    */

    function importDevis() {
        api.importDevis(state.clientIdBCommande).then(c=>{
            setState({...state,montant:c.montant})
            // setDevisData([...c.devisData].reverse())
        })
    }




    function handleMaterialCreate(e) {
        setNewMaterial({...newMaterial, [e.target.name]: e.target.value})
    }
    function addMaterial(){
        let uid = v4()
        if (!newMaterial.type || newMaterial.type === "") return alert("Veuillez saisir le nom du matériel")
        let material = state.material ?? []
        material.push({...newMaterial, id:uid, isReady:0})
        setState({...state, material: [...material]})
        setNewMaterial({'@type':constants.MATERIAL_CLASS})
    }
    function updateMaterial(m){
        setState({...state, material:state.material.map(sm => (sm.id === m.id)? {...sm, ...m}: sm)})
    }
    function deleteMaterial(m){
        setState({...state, material:state.material.filter(sm=>sm.id !== m.id)})
    }



    function handleAdminDocumentDate(e) {
        setNewAdminDocument({...newAdminDocument, [e.target.name]: e.target.value})
    }
    function handleAdminDocumentCreate(e) {
        const index = e.target.selectedIndex;
        const el = e.target.childNodes[index]
        const option =  el.getAttribute('id');
        const value =  el.getAttribute('value');
        setNewAdminDocument({...newAdminDocument, [e.target.name]: {id:option, name:value}})
    }
    function addAdminDocument(){
        if (!newAdminDocument.type) return alert("Veuillez choisir un type de document")
        let uid = v4()
        let adminDoc = state.supplyAdminDocument ?? []
        adminDoc.push({...newAdminDocument, id:uid, isReady:0})
        setState({...state, supplyAdminDocument: [...adminDoc]})
        setNewAdminDocument({'@type':constants.ADMIN_DOC_CLASS})
    }
    function updateAdminDocument(m){
        // state.supplyMachinery?.filter(s=> s.id === m.id)
        setState({...state, supplyAdminDocument:state.supplyAdminDocument.map(sm => (sm.id === m.id)? {...sm, ...m}: sm)})
    }
    function deleteAdminDocument(m){
        setState({...state, supplyAdminDocument:state.supplyAdminDocument.filter(sm=>sm.id !== m.id)})
    }



    function handleDocumentDate(e) {
        setNewDocument({...newDocument, [e.target.name]: e.target.value})
    }
    function handleDocumentCreate(e) {
        const index = e.target.selectedIndex;
        const el = e.target.childNodes[index]
        const option =  el.getAttribute('id');
        const value =  el.getAttribute('value');
        setNewDocument({...newDocument, [e.target.name]: {id:option, name:value}})
    }
    function addDocument(){
        if (!newDocument.type) return alert("Veuillez choisir un type de document")
        let uid = v4()
        let doc = state.supplyDocument ?? []
        doc.push({...newDocument, id:uid, isReady:0})
        setState({...state, supplyDocument: [...doc]})
        setNewDocument({'@type':constants.DOCUMENT_CLASS})
    }
    function updateDocument(m){
        // state.supplyMachinery?.filter(s=> s.id === m.id)
        setState({...state, supplyDocument:state.supplyDocument.map(sm => (sm.id === m.id)? {...sm, ...m}: sm)})
    }
    function deleteDocument(m){
        setState({...state, supplyDocument:state.supplyDocument.filter(sm=>sm.id !== m.id)})
    }


    function handleMachineryDate(e) {
        setNewMachinery({...newMachinery, [e.target.name]: e.target.value})
    }
    function handleMachineryCreate(e) {
        const index = e.target.selectedIndex;
        const el = e.target.childNodes[index]
        const option =  el.getAttribute('id');
        const value =  el.getAttribute('value');
        setNewMachinery({...newMachinery, [e.target.name]: {id:option, name:value}})
    }
    function addMachinery(){
        if (!newMachinery.type) return alert("Veuillez choisir un type de document")
        let uid = v4()
        let machineries = state.supplyMachinery ?? []
        machineries.push({...newMachinery, id:uid, isReady:0})
        setState({...state, supplyMachinery: [...machineries]})
        setNewMachinery({'@type':constants.MACHINERY_CLASS})
    }
    function updateMachinery(m){
        // state.supplyMachinery?.filter(s=> s.id === m.id)
        setState({...state, supplyMachinery:state.supplyMachinery.map(sm => (sm.id === m.id)? {...sm, ...m}: sm)})
    }
    function deleteMachinery(m){
        setState({...state, supplyMachinery:state.supplyMachinery.filter(sm=>sm.id !== m.id)})
    }



    function save() {
        const devData={...state,
            material:state.material.map(m=>({...m, ready:m.isReady})),
            supplyDocument:state.supplyDocument.map(d=>({...d, type:d.type.id, ready:d.isReady, issueDate:new Date(d.issueDate).getTime(), receptionDate:new Date(d.receptionDate).getTime()})),
            supplyMachinery:state.supplyMachinery.map(m=>({...m, type:m.type.id, ready:m.isReady, pickUpDay:new Date(m.pickupDay).getTime(), issueDate:new Date(m.issueDate).getTime()})),
            supplyAdminDocument:state.supplyAdminDocument.map(d=>({...d, type:d.type.id, ready:d.isReady, issueDate:new Date(d.issueDate).getTime(), receptionDate:new Date(d.receptionDate).getTime()})),
            comments:comments,
        }
        trigger(devData)
        onSave(state,metadata,comments,documents)
    }


    return (
    <div className={"flex-column"}>
        <h2>{text.CMD_CREATION}</h2>
        <div className={"flex-column"}>
            <div className={"secondary-section flex-row"}>
                <div className={"flex-column"}>
                    <div className={"devis-data-number-row-content"}>
                        <div>{text.AMOUNT_CURRENCY}</div>
                        <Tooltip title={text.AMOUNT_CURRENCY}>
                            <input type={"number"} id={"montant"} name={"montant"} placeholder={text.AMOUNT_CURRENCY}
                                   value={state.montant} onChange={handleChange}/>
                        </Tooltip>€
                    </div>
                </div>
                <div className={"flex-column"}>
                    <div className={"devis-data-number-row-content"}>
                        <div>Montant de l'acompte</div>
                        <Tooltip title={text.DEPOSIT_CURRENCY}>
                            <input type={"number"} id={"acompte"} name={"acompte"} placeholder={text.DEPOSIT_CURRENCY}
                                   value={state.acompte} onChange={handleChange}/>
                        </Tooltip>€
                    </div>
                </div>
                <div className={"devis-data-number"}>
                    <div className={"flex-column"}>
                        <div className={"devis-data-number-row-content"}>
                            <div>Numéro de devis</div>
                            <input type={"text"} name={"clientIdBCommande"} value={state.clientIdBCommande}
                                   placeholder={text.REF_NUM} onChange={handleChange}/>
                            {/*{
                                api.getTokenData().companyId===constants.ABS_COMPANYID &&
                                <IconButton onClick={importDevis}>
                                    <Tooltip title={text.IMPORT_DEVIS}>
                                        <img alt={""} src={ImportIcon}/>
                                    </Tooltip>
                                </IconButton>
                            }*/}
                        </div>
                    </div>
                </div>


            </div>

            <div className={"supply"}>

                <div className={"grid secondary-section"} style={{zIndex: "1"}}>
                    <div className={"flex-row case-title"}>
                        <img alt={""} src={MaterialIcon}/>{text.EQUIPMENT}
                    </div>

                    <div className={"extensible-component-wrapper"}>
                        <ExtensibleComponent content={"+"}>
                            <div className={"flex-row primary-section"}>
                                <input className={"large w100"} type={"text"}
                                       value={state.material.type}
                                       name={"type"} placeholder={text.EQUIPMENT_NAME}
                                       onChange={handleMaterialCreate}/>
                                <IconButton onClick={addMaterial}
                                            className={"action-btn validation"}>{text.ADD_BUTTON}</IconButton>
                            </div>
                        </ExtensibleComponent>

                        <div className={"supply-list"}>
                            <ul className={"primary-section"}>
                                {state.material.map((d, k) => <li key={d.id}>
                                <SupplyMaterial type={d.type} supplyId={state.id} isReady={d.ready} supplier={d.supplier}
                                                    comment={d.comment}
                                                    id={d.id} quantity={d.quantity} unit={d.unit}
                                                    deleteSupply={deleteMaterial}
                                                    updateSupply={updateMaterial} {...d}
                                    />
                                </li>)}
                            </ul>
                        </div>
                    </div>

                </div>
                <div className={"secondary-section"}>
                    <div className={"flex-row case-title"}>
                        <img alt={""} src={DocumentIcon}/>{text.H2_ADMIN_DOCS}
                    </div>

                    <div className={"extensible-component-wrapper"}>
                        <ExtensibleComponent content={"+"}>
                            <div className={"flex-row primary-section"}>
                                <select className={"large w100"} required name={"type"}
                                        onChange={handleAdminDocumentCreate}
                                        value={newAdminDocument?.type?.name ?? "null"}>
                                    <option disabled value={"null"}>{text.DOC_TYPE}</option>
                                    {importedAdminDocuments?.sort((d1, d2) => d1?.name?.toLowerCase() > d2?.name?.toLowerCase() ? 1 : -1).map((d, k) =>
                                        <option key={k} id={d.id}
                                                selected={newAdminDocument?.name === d.name}
                                                value={d.name}>{d.name}</option>)}
                                </select>
                                <input className={"large w100"} type={"date"} name={"issueDate"}
                                       value={newAdminDocument?.issueDate ?? 0}
                                       pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
                                       onChange={handleAdminDocumentDate}/>
                                <IconButton onClick={addAdminDocument}
                                            className={"action-btn validation"}>{text.ADD_BUTTON}</IconButton>
                            </div>
                        </ExtensibleComponent>
                        <div className={"supply-list"}>
                            <ul className={"primary-section"}>
                                {state.supplyAdminDocument?.map((d, k) =>
                                    <li key={d.id}><SupplyAdminDocument issueDate={d.issueDate}
                                                                        supplyId={state.id}
                                                                        typeName={d.type}
                                                                        id={d.id} isReady={d.ready}
                                                                        receptionDate={d.receptionDate}
                                                                        deleteSupply={deleteAdminDocument} {...d}
                                                                        updateSupply={updateAdminDocument}/>
                                    </li>)}
                            </ul>
                        </div>
                    </div>

                </div>
                <div className={"secondary-section"}>
                    <div className={"flex-row case-title"}>
                        <img alt={""} src={DocumentIcon}/>{text.DOCS}
                    </div>

                    <div className={"extensible-component-wrapper"}>
                        <ExtensibleComponent content={"+"}>
                            <div className={"flex-row primary-section"}>
                                <select className={"large w100"} required name={"type"}
                                        onChange={handleDocumentCreate}
                                        value={newDocument?.type?.name ?? "null"}>
                                    <option disabled value={"null"}>{text.DOC_TYPE}</option>
                                    {importedDocuments.sort((d1, d2) => d1?.name?.toLowerCase() > d2?.name?.toLowerCase() ? 1 : -1).map((d, k) =>
                                        <option key={k} selected={newDocument?.name === d.name}
                                                id={d.id}
                                                value={d.name}>{d.name}</option>)}
                                </select>
                                <input className={"large w100"} type={"date"} name={"issueDate"}
                                       value={newDocument?.issueDate ?? 0}
                                       pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
                                       onChange={handleDocumentDate}/>

                                <IconButton onClick={addDocument}
                                            className={"action-btn validation"}>{text.ADD_BUTTON}</IconButton>
                            </div>
                        </ExtensibleComponent>
                        <div className={"supply-list"}>
                            <ul className={"primary-section"}>
                                {state.supplyDocument.map((d, k) =>
                                    <li key={d.id}><SupplyDocument issueDate={d.issueDate}
                                                                   supplyId={state.id}
                                                                   typeName={d.type}
                                                                   id={d.id} isReady={d.ready}
                                                                   receptionDate={d.receptionDate}
                                                                   deleteSupply={deleteDocument} {...d}
                                                                   updateSupply={updateDocument}/>
                                    </li>)}
                            </ul>
                        </div>
                    </div>
                </div>
                <div className={"secondary-section"}>
                    <div className={"flex-row case-title"}>
                        <img alt={""} src={MachineryIcon}/>{text.ENGINES_AND_OTHER}
                    </div>
                    <div className={"extensible-component-wrapper"}>
                        <ExtensibleComponent content={"+"}>
                            <div className={"flex-row primary-section"}>
                                <select className={"large w100"} required name={"type"}
                                        onChange={handleMachineryCreate}
                                        value={newMachinery?.type?.name ?? "null"}>
                                    <option disabled value={"null"}>{text.DEVICE_TYPE}</option>
                                    {importedMachineries.sort((d1, d2) => d1?.name?.toLowerCase() > d2?.name?.toLowerCase() ? 1 : -1).map((d, k) =>
                                        <option key={k} id={d.id} value={d.name}
                                                selected={newMachinery?.name === d.name}>{d.name}</option>)}
                                </select>
                                <input className={"large w100"} type={"date"} name={"issueDate"}
                                       value={newMachinery?.issueDate ?? 0}
                                       pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
                                       onChange={handleMachineryDate}/>
                                <IconButton onClick={addMachinery}
                                            className={"action-btn validation"}>{text.ADD_BUTTON}</IconButton>
                            </div>
                        </ExtensibleComponent>
                        <div className={"supply-list"}>
                            <ul className={"primary-section"}>
                                {state?.supplyMachinery?.map((d, k) =>
                                    <li key={d.id}>
                                        <SupplyMachinery isReady={d.ready} id={d.id}
                                                         supplyId={state.id}
                                                         pickupDay={d.pickUpDay}
                                                         pickupLocation={d.pickUpLocation}
                                                         issueDate={d.issueDate}
                                                         typeName={d.type}
                                                         supplier={d.supplier}
                                                         updateSupply={updateMachinery}
                                                         deleteSupply={deleteMachinery} {...d}/>
                                    </li>)}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>


            <IconButton className={"action-btn validation w100 sticky-bottom"} style={{bottom: 0}}
                        onClick={save}>{text.ADD_BUTTON}</IconButton>
        </div>
    </div>
    )

}