import CustomImage from "./CustomImage";
import React, {useEffect, useMemo, useState} from "react";
import {IconButton, Tooltip} from "@mui/material";
import IbatApi from "../../utils/IbatApi";
import constants, {text} from "../util/constants";

function indexation(list){
    list.forEach(elem=> {
        let famindex = elem.index
        let fam = elem.family
        fam.forEach((elem, index) => {
            elem.index = `${famindex+1}${fam.length > 1 ? String.fromCharCode(index + 97) : ""}`
        })
    })
    return list.map(pf=>pf.family.sort((p1,p2)=>p1.date-p2.date))
        .flat(1)
    // .sort((elem1,elem2)=>elem1.index>elem2.index?1:-1)
}


export default function Caroussel({listOperation,firstFile,list,findComplementData,onPrevious,onNext,onExport, handleZoom, ibatApi, readOnly}){
    const [exportArea,setExportArea]=useState()
    const [listPics, setListPics]=useState(indexation(list))
    const api= new IbatApi()
    const [current,setCurrent]=useState(firstFile ? listPics.findIndex(elem=>elem.filePath===firstFile) : 0)
    const [currentComplement,setCurrentComplement]=useState()
    const [style, setStyle]=useState({})
    const zoomElements = useMemo(() =>({
        scale: 1,
        panning: false,
        pointX: 0,
        pointY: 0,
        start: { x: 0, y: 0 },
    }), [])
/*
    useEffect(()=>{
        if (!findComplementData){
            return
        }
        let complementData = findComplementData(listPics[current].filePath);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[current])*/
    useEffect(()=>{
        setListPics(indexation(list))
        console.log(listPics)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[list])


    function next(){
        setCurrent(Math.min((current+1),listPics.length-1))
        onNext&&onNext()
    }
    function previous(){
        setCurrent(Math.max(current-1,0))
        onPrevious&&onPrevious()
    }
    function exportPicture(){
        if(!exportArea){
            alert(text.ADD_DESTINATION_TO_OPERATION)
            return;
        }
        api.addPicture(exportArea,[listPics[current].filePath]).then(()=>onExport(exportArea))
    }
    function handleExportOperation(e) {
        setExportArea(e.target.value)
    }

    function setTransform() {
        // setStyle({transform: "translate(" + pointX + "px, " + pointY + "px) scale(" + scale + ")"});
        setStyle({transform: "translate(" + zoomElements.pointX + "px, " + zoomElements.pointY + "px) scale(" + zoomElements.scale + ")"})
    }
    const onMouseUp = function (e) {
        zoomElements.panning = false;
    }
    const onMouseDown = function (e) {
        zoomElements.start = {x: e.clientX - zoomElements.pointX, y: e.clientY - zoomElements.pointY};
        zoomElements.panning = true;
    }
    const onMouseMove = function (e) {
        if (!zoomElements.panning || zoomElements.scale <= 1) {
            return;
        }
        let pointX = e.clientX - zoomElements.start.x
        let pointY = e.clientY - zoomElements.start.y

        // Limiter le déplacement horizontal
        const maxX = (e.target.offsetWidth * zoomElements.scale - e.target.offsetWidth)/2
        if (Math.abs(pointX) < maxX) {
            zoomElements.pointX = pointX;
        }

        // Limiter le déplacement vertical
        const maxY = (e.target.offsetHeight * zoomElements.scale - e.target.offsetHeight)/2
        if (Math.abs(pointY) < maxY) {
            zoomElements.pointY = pointY;
        }
        setTransform();
    }

    const onWheel = function(e) {
        e.preventDefault()
        var delta = (e.wheelDelta ? e.wheelDelta : -e.deltaY);

        if ((delta > 0 && zoomElements.scale > 2) || (delta < 0 && zoomElements.scale < 0.7) ) {
            return
        }
        (delta > 0) ? (zoomElements.scale *= 1.2) : (zoomElements.scale /= 1.2);


        // Limiter le déplacement horizontal
        const maxX = (e.target.offsetWidth * zoomElements.scale - e.target.offsetWidth)/2
        if (zoomElements.pointX > maxX) {
            zoomElements.pointX = maxX;
        }
        else if (zoomElements.pointX < -maxX) {
            zoomElements.pointX = -maxX;
        }
        // Limiter le déplacement vertical
        const maxY = (e.target.offsetHeight * zoomElements.scale - e.target.offsetHeight)/2
        if (zoomElements.pointY > maxY) {
            zoomElements.pointY = maxY;
        }
        else if (zoomElements.pointY < -maxY) {
            zoomElements.pointY = -maxY;
        }

        if (zoomElements.scale <= 1) {
            zoomElements.pointX = 0;
            zoomElements.pointY = 0;
        }

        setTransform();
    }

    /*function handleClick(e) {
        e.preventDefault()
        console.log(e)

        console.log(e.clientX)
        console.log(e.target.offsetLeft)
        console.log(e.target.offsetWidth)
        console.log(e.nativeEvent.layerX)

        console.log(e.clientY)
        console.log(e.target.offsetTop)
        console.log(e.target.offsetHeight)
        console.log(e.nativeEvent.layerY)
        setStyle({
            // transform: `scale(1.5) translate(${e.clientX - e.target.offsetLeft - e.target.offsetWidth}px, ${e.clientY - e.target.offsetHeight}px)`
            transform: `scale(1.5) translate(${e.nativeEvent.layerX}px, ${e.nativeEvent.layerY}px)`
        })
    }*/
    return(
        <div className={"primary-section flex-column caWrapper"}>
                <div className={"not-on-small-screen"}>
                    {text.ZOOMHOWTO}
                </div><div className={"indexNumber flex-column rounded bord1"}>{listPics[current]?.index}</div>
            <div className={"caroussel br10 flex-row"}>

                <div  className={"bord1"}><IconButton onClick={previous}>‹</IconButton></div>

                <div className={"keep-space br10"}  onMouseDown={onMouseDown} onMouseUp={onMouseUp} onMouseMove={onMouseMove} onWheel={onWheel}>
                    <CustomImage ibatApi={ibatApi} className={"carousselImg"} imgClassName={"w100 br10"} style={style} name={listPics[current]?.filePath} layerName={currentComplement}/>
                </div>

                <div  className={"bord1"}><IconButton onClick={next}>›</IconButton></div>

            </div>

            {!readOnly && listOperation?.length > 0 && <div className={"bord1"}><div className={"flex-row"}>
                <select className={"operationList"} onChange={handleExportOperation} value={exportArea ?? "null"}>
                    <option disabled value={"null"}>{text.EXPORT_TO}</option>
                    {listOperation?.map(op => <option key={op.id} value={op.id}>{op.typeName}</option>)}
                </select>
                <IconButton disabled={!api.hasRole(constants.PERMISSION_CRE)} className={"action-btn validation"}
                            onClick={exportPicture}> {text.EXPORT} </IconButton>
            </div></div>}
        </div>
    )
}