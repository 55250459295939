import React, {useState} from "react";
import Modal from "react-responsive-modal";
import MapPointer from "../util/MapPointer";
import IbatApi from "../../utils/IbatApi";
import ImageManquante from "../../images/map-add.png"
import CustomImage from "../util/CustomImage";
import constants from "../util/constants";

export default function UpdateSiteImage({img,siteId,latitude,longitude,update,voie, ibatApi}){
    const[open,setOpen]=useState(false)
    const api = ibatApi ?? new IbatApi();

    function handleModal() {
        setOpen(!open)
    }

    return(
    <div className={"site-img"} style={{cursor:"pointer"}}>
        {
            img?
                <CustomImage ibatApi={api} name={img} onClick={handleModal}/>
                :
                <img className={"w100"} alt={""} onClick={handleModal} src={ImageManquante}/>
        }

        <Modal open={api.hasRole(constants.PERMISSION_AM) && open} onClose={handleModal}>
            <MapPointer voie={voie} siteId={siteId} update={update} handleModal={handleModal} latitude={latitude} longitude={longitude}/>
        </Modal>
    </div>
)
}