import React, {useEffect, useState} from "react";

import IbatApi from "../../utils/IbatApi";
import {IconButton} from "@mui/material";
import {useSnackbar} from "notistack";
import {constants, text} from "../util/constants";
import {v4} from "uuid";
import Select from "react-select";

export default function ClientForm({onSave,onCancel,id,name,mail,address,address2,cp,ville,idGestionnaire,type,siren, phone, interlocutorPoste, interlocutorName, interlocutorPhone, interlocutorMail, newRequest, accountantAccount, contactPhone, contactMail}) {
    const { enqueueSnackbar} = useSnackbar();
    const [clientType, setClientType] = useState([])
    const [state, setState] = useState({
        name: name ?? "",
        phone: phone ?? (contactPhone?.length > 0 ? contactPhone[0] : ""),
        mail: contactMail ?? (mail ?? ""),
        interlocutorPoste: interlocutorPoste ?? "",
        cp: cp ?? null,
        ville: ville ?? "",
        idGestionnaire: idGestionnaire ?? "",
        address: address ?? "",
        address2: address2 ?? "",
        siren: siren ?? "",
        interlocutorName: interlocutorName ?? "",
        interlocutorPhone: interlocutorPhone ?? "",
        interlocutorMail: interlocutorMail ?? "",
        type: type ?? null
    })

    const [gestionnaire, setGestionnaire] = useState([])
    const api = new IbatApi()
    useEffect(() => {
        api.getClientType()
            .then(ct => setClientType(ct.map((ct, idx) => {
          return {label:constants[ct], value:idx}
        })))
            .catch(e => enqueueSnackbar(text.SNACKBAR_ERROR,{variant:"error"}))
        api.getGestionnaire()
            .then(gest => setGestionnaire(gest
                .sort((a,b) => a.name > b.name ? 1 : -1)
                .map(s => {
                    return {value: s.id, label: s.name}
                })
            ))
            .catch(e => enqueueSnackbar(text.SNACKBAR_ERROR,{variant:"error"}))
        id && api.getClientGestionnaire(id)
            .then(r => setState({...state,
                name:name?name:"",
                id: newRequest ? null : (id ?? ""),
                phone: phone ?? (contactPhone?.length > 0 ? contactPhone[0] : ""),
                mail:mail?mail:"",
                address:address?address:"",
                address2:address2?address2:"",
                ville:ville?ville:"",
                cp:cp?cp:null,
                idGestionnaire:idGestionnaire??(r.id??-2),
                type: type??null,
                siren: siren??"",
                interlocutorName: interlocutorName??"",
                interlocutorPhone: interlocutorPhone??"",
                interlocutorMail: interlocutorMail??"",
                interlocutorPoste: interlocutorPoste??"",
                newRequest: newRequest ?? false,
                accountantAccount: accountantAccount ?? null
            }))
            .catch(e => enqueueSnackbar(text.SNACKBAR_ERROR,{variant:"error"}))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    function handleChange(e, d) {

        const name = d?.name ?? e?.target?.name ?? e.label
        let value = e?.target?.value ?? e.value
        if (name === "type"){
          value = Number(value)
        }
        setState({
            ...state,
            [name]: value
        })
    }

    function isSavable() {
        return state?.name !== "" && state.idGestionnaire;
    }

    function handleSave() {
        onSave(state)
            .then(r => enqueueSnackbar(text.SNACKBAR_SAVED,{variant:"success"}))
            .catch(e=>enqueueSnackbar(text.SNACKBAR_ERROR,{variant:"error"}))
    }

    function handleCancel() {
        onCancel()
    }

    return (
            <div className={"client-form flex-column primary-section"}>
                <h1 className={"flex-row"}>Création Client</h1>
                <div className={"form flex-column"}>
                    <input className={"large w100"} type={"text"} id={"name"} name={"name"} placeholder={text.CLIENT_NAME} value={state.name ?? ""}
                           onChange={handleChange}/>

                    <div className={"flex-column"}>
                        <input className={"large w100"} type={"text"} id={"address"} name={"address"} placeholder={text.ADDRESS}
                               value={state.address ?? ""} onChange={handleChange}/>
                        <input className={"large w100"} type={"text"} id={"address2"} name={"address2"} placeholder={text.ADDRESS2}
                               value={state.address2 ?? ""} onChange={handleChange}/>

                        <div className={"flex-row"}>
                            <input className={"large w100"} type={"number"} name={"cp"} placeholder={text.ZIPCODE}
                                   value={state.cp ?? ""} onChange={handleChange}/>
                            <input className={"large w100"} type={"text"} name={"ville"} placeholder={text.TOWN}
                                   value={state.ville ?? ""} onChange={handleChange}/>
                        </div>

                        <input className={"large w100"} type={"text"} id={"accountantAccount"} name={"accountantAccount"} placeholder={text.ACCOUNTANT_ACCOUNT}
                               value={state.accountantAccount ?? ""} onChange={handleChange}/>
                    </div>

                    <input className={"large w100"} type={"tel"} id={"phone"} name={"phone"} placeholder={text.CLIENT_PHONE} value={state.phone ?? ""}
                           onChange={handleChange}/>

                    <input className={"large w100"} type={"email"} id={"mail"} name={"mail"} placeholder={text.CLIENT_MAIL} value={state.mail ?? ""}
                           onChange={handleChange}/>

                    <Select
                        required
                        name={"type"}
                        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999}) }}
                        menuPortalTarget={document.body}
                        placeholder={"Type de client"}
                        value={clientType[clientType.findIndex(s => s.label === constants[state.type])]}
                        onChange = {(d, e) => handleChange(d, e)}
                        options={clientType}/>

                  {(state?.type && state.type !== 0 && state.type !== "PRIVATE_PERSON" ) ?
                    <input className={"large w100"} type={"text"} id={"siren"} name={"siren"} placeholder={text.SIREN} value={state?.siren ?? ""}
                           onChange={handleChange}/> : ""
                    }
                    <Select
                        required
                        name={"idGestionnaire"}
                        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999}) }}
                        menuPortalTarget={document.body}
                        placeholder={"Gestionnaire*"}
                        value={gestionnaire[gestionnaire.findIndex(s => s.value === state.idGestionnaire)]}
                        onChange = {(d, e) => handleChange(d, e)}
                        options={gestionnaire}/>

                    <h2>Interlocuteur (si différent du client)</h2>
                    <input className={"large w100"} type={"text"} id={"interlocutorName"} name={"interlocutorName"} placeholder={text.NAME} value={state?.interlocutorName ?? ""}
                           onChange={handleChange}/>
                    <input className={"large w100"} type={"text"} id={"interlocutorPhone"} name={"interlocutorPhone"} placeholder={text.PHONE_NUMBER} value={state?.interlocutorPhone ?? ""}
                           onChange={handleChange}/>
                    <input className={"large w100"} type={"text"} id={"interlocutorMail"} name={"interlocutorMail"} placeholder={text.MAIL} value={state?.interlocutorMail ?? ""}
                           onChange={handleChange}/>
                    <input className={"large w100"} type={"text"} id={"interlocutorPoste"} name={"interlocutorPoste"} placeholder={text.CLIENT_FUNCTION} value={state?.interlocutorPoste ?? ""}
                           onChange={handleChange}/>

                </div>
                {
                    isSavable() ?
                        <IconButton className={"action-btn validation"} onClick={handleSave}>{text.ADD_BUTTON}</IconButton>
                        :
                        <IconButton className={"action-btn cancel"} onClick={handleCancel}>{text.CANCEL}</IconButton>
                }
            </div>
    )
}
