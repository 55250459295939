import React, {useState} from "react";
import {text} from "../util/constants";

export default function  ContactForm({onChange, dataSite}) {


    const [state,setState]=useState({
        name: dataSite?.name,
        phone: dataSite?.contactPhone[0],
        phone2: dataSite?.contactPhone[1]
    })
    function handleChange(e) {
        const name = e.target.name
        setState({...state,
            [name]: e.target.value

        })
        onChange({...state, [name]: e.target.value})

    }

     return (

            <div className={"flex contactForm primary-section"}>
                <input type={"text"} name={"name"} placeholder={text.CONTACT_ONSITE} value={state?.name} onChange={handleChange}/>

                <input type={"text"} name={"phone"}
                       pattern="[0-9]{10};*"
                       placeholder={text.PHONE_ONSITE}
                       value={state?.phone}
                       onChange={handleChange}/>

                <input type={"text"}  name={"phone2"}
                       pattern="[0-9]{10};*"
                       placeholder={text.PHONE2_ONSITE}
                       value={state?.phone2}
                       onChange={handleChange}/>

                <input type={"text"} id={"status"} name={"status"} value={state?.status}
                       placeholder={text.ONSITE_CONTACT_FUNCTION}
                       onChange={handleChange}/>

            </div>

        )
}
