import React, {useEffect, useState} from 'react';
import IbatApi from "../../utils/IbatApi";
import InterventionForm from "../forms/InterventionForm";
import InteractionClient from "../bean/InteractionClient";
import EditInterventionTeam from "../forms/EditInterventionTeam";
import EditInterventionDate from "../forms/EditInterventionDate";
import IconEye from "../../images/contact-interaction.png";
import Table from "../../table/Table";
import EditSupply from "../forms/EditSupply";
import EditEstimateDuration from "../forms/EditEstimateDuration";
import EditSecteur from "../forms/EditSecteur";
import EditBillForm from "../forms/EditBillForm";
import {IconButton, Tooltip} from "@mui/material";
import Modal from "react-responsive-modal";
import {Planning} from "../../planning/Planing";
import PlanningIcon from "../../images/planning-icon.png"
import IconMap from "../../images/button-map.png";
import CSVDl from "../../images/csv-dl.png";
import MapBean from "./MapBean";
import {useSnackbar} from "notistack";
import {text} from "../util/constants";
import EditEstimate from "../../estimate/EditEstimate";
import fastModeIcon from "../../images/fastMode.png"
import fastModeActivatedIcon from "../../images/fastModeActivated.png"
import {isBrowser} from 'react-device-detect'
import MapPointer from "../util/MapPointer";
import GridCardTable from "../../dashboard/GridCardTable";
import SupplyModeEnabled from "../../images/supply-mode-enabled.png";
import SupplyModeDisabled from "../../images/supply-mode-disabled.png";
import {getStatusIcon, getSupplyStatusIcon, getTypeIcon} from "../../utils/Utils";
import {useMediaQuery} from "react-responsive";

export default function Interventions({defaultFilter}) {
    const api = new IbatApi()
    const {enqueueSnackbar}=useSnackbar()

    let vars = {};
    let filters = window.location.search.substring(1).split("&").filter(f => f.length>0)
    for (const filterKey in filters) {
        let filter = filters[filterKey].split("=")
        vars[filter[0]] = filter[1]
    }

    const isMobile = useMediaQuery({ query: '(max-width: 915px)' })

    const columns = [
        {id: "id", label: text.NUMBER, isChecked: true, isFiltrable: true, type: "linkText"},
        {id: "status", label: text.STATUS, isChecked: true, isFiltrable: true, type: "textWithIcon", function: getStatusIcon},
        {id: "type", label: text.FILE_TYPE, isChecked: true, isFiltrable: true, type: "objectWithIcon", function: getTypeIcon},
        {id: "devis", label: text.COMMAND_NUM, isChecked: true, isFiltrable: true, type: "objectLink"},
        {id: "reception", label: text.RECEPTION_DATE, isChecked: true, isFiltrable: true, type: "text"},
        {id: "client", label: text.CLIENT, isChecked: true, isFiltrable: true, type: "objectLink"},
        {id: "gestionnaire", label: text.TH_ADMINISTRATOR, isChecked: true, isFiltrable: true, type: "object", hideOnPhone: true},
        {id: "site", label: text.SITE, isChecked: true, isFiltrable: true, type: "objectLink"},
        // {id: "address", label: text.ADDRESS, isChecked: true, isFiltrable: true, type: "toUpdate", popup: SearchSiteModal},
        {id: "address", label: text.ADDRESS, isChecked: true, isFiltrable: true, type: "toUpdate", popup: MapPointer},
        {id: "cp", label: text.ZIPCODE, isChecked: true, isFiltrable: true, type: "text"},
        {id: "ville", label: text.TOWN, isChecked: true, isFiltrable: true, type: "text"},
        {id: "supply", label: text.SUPPLY, isChecked: true, isFiltrable: true, type: "popupWithIcon", popup: EditSupply, hideOnPhone: true, function: getSupplyStatusIcon},
        {id: "phone", label: text.PHONE_NUMBER, isChecked: true, isFiltrable: true, type: "text"},
        {id: "interactions", label: text.INTERACTIONS, isChecked: true, isFiltrable: true, icon: IconEye, type: "popup", popup: InteractionClient, hideOnPhone: true},
        {id: "secteur", label: text.ZONE, isChecked: true, isFiltrable: true, type: "popup",popup: EditSecteur, hideOnPhone: true},
        {id: "team", label: text.TEAM, isChecked: true, isFiltrable: true, type: "popup", popup: EditInterventionTeam},
        {id: "intervention", label: text.INTERVENTION, isChecked: true, isFiltrable: true, type: "popup", popup: EditInterventionDate},
        {id: "estimateDuration", label: text.DURATION_ESTIMATION, isChecked: true, isFiltrable: false, type: "popup", popup: EditEstimateDuration, hideOnPhone: true},
        {id: "estimate", label: text.ESTIMATE, isChecked: true, isFiltrable: true, type: "popup", popup: EditEstimate, hideOnPhone: true},
        {id: "bill", label: text.BILL, isChecked: true, isFiltrable: true, type: "popup", popup: EditBillForm, hideOnPhone: true},
    ]

    const [state, setState] = useState(
        {
            columns: columns,
            interventionList: undefined,
            pageSize: 50,
            pageNumber: 0,
            count: 0,
            filters: {
                id: vars['id'] ?? "",
                status: vars['status'] ?? (defaultFilter??""),
                type: vars['type'] ?? "",
                site: vars['site'] ?? "",
                client: vars['client'] ?? "",
                supply: vars['supply'] ?? "",
                team: vars['team'] ?? "",
                reception: vars['reception'] ?? "",
                intervention: vars['intervention'] ?? "",
                devis: vars['devis'] ?? "",
                sort:"",
            }
        }
    )
    const [typing,setTyping]=useState(false)
    const [planning,setPlanning]=useState(false)
    const [map,setMap]=useState(false)
    const [loading,setLoading]=useState(false)
    const [fastMode,setFastMode]=useState(false)
    const [supplyMode,setSupplyMode]=useState(api.getSettingsCookie(text.INTERVENTIONS+"_supplyMode") === "true" ?? false)
    const [showMode,setShowMode]=useState(api.getPrefs(text.INTERVENTIONS+"_showMode")??(isMobile ? "card" : "list"))
    const abortController = new AbortController();

    /*const [urlHandled,setUrlHandled]=useState(!filters)
    if (!urlHandled){

        setState( ({...state, filters: {...state.filters, ...vars, sort: ""}}))
        setUrlHandled(true)
    }*/

    function switchShowMode(value) {
        api.setCookie(text.INTERVENTIONS+"_showMode", value)
        setShowMode(value)
    }

    function getInterventions() {
        abortController.abort();
        setLoading(true)
        var sendedAt = new Date()
        api.getFilteredInterventions({
            ...state.filters,
            pageNumber: state.pageNumber,
            pageSize: state.pageSize,
        }, supplyMode, { signal: abortController.signal })
            .then(res => {
                setLoading(false)
                if(!state.lastQueryAt || sendedAt >= state.lastQueryAt){
                    setState( ({...state, interventionList: res.interventions, count: res.count}))
                }
            })
            .catch(e=> {
                setLoading(false)
                enqueueSnackbar(e?.data?.error ?? text.SNACKBAR_ERROR, {variant: "error"})
            })

    }

    useEffect(() => {
        setColumnsPref()
        getInterventions()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [typing,state.pageNumber,state.pageSize, supplyMode])

    function isFilterEmpty() {
        // let reduce = Object.keys(state.filters).map((k)=> state.filters[k]==="").reduce((b1, b2) => b1 && b2);
        return false
    }


    function setColumnsPref(){
        let c = api.getSettingsCookie(text.INTERVENTIONS+"_columns")
        if (!c){
            return
        }
        c = c.split(",")
        let columns = state.columns
        columns.map(col=> {
            if (!c.includes(col.id)){
                col.isChecked = false
            }
            return col
        })
        setState( ({...state, columns: columns, mapping: false}))
    }
    const handleColumns = (event) => {
        let columns = state.columns
        let filter = {...state.filters}
        let find = columns.find(col => col.id === event.target.name);
        find.isChecked = event.target.checked
        if (find.isFiltrable) {
            filter[find.id] = ""
        }
        api.setCookie(text.INTERVENTIONS+"_columns", columns.filter(d=>d.isChecked).map(c=> c.id))
        setState( ({...state, columns: columns, filters: filter, mapping: false}))
    }

    const handleFilter = (e) => {
        let value = e.target.value
        let name = e.target.name
        vars[name] = value
        setState( ({...state, filters: {...state.filters, [name]: value,sort: name}, lastQueryAt: new Date()}))
    }

    const handleChangePage = (newPage) => {
         setState( ({...state, pageNumber: newPage}))
    };

    const handleChangeRowsPerPage = (event) => {
        setState( ({...state, pageSize: event.target.value, pageNumber: 0}))
    };

    function clearFilter() {
        setState({
            ...state, filters: {
                id: "",
                status: "",
                type: "",
                site: "",
                client: "",
                supply: "",
                team: "",
                reception: "",
                intervention: "",
                devis: "",
                sort: "",
                maps: false
            }
        })
        setTyping(!typing)
    }

    function generatecsv() {
        api.generateCsv(state.filters,state.columns.filter(c=>c.isChecked))
    }

    function handleFastMode(){
        setFastMode(!fastMode)
    }

    function handleSupplyMode(){
        api.setCookie(text.INTERVENTIONS+"_supplyMode", !supplyMode)
        setSupplyMode(supplyMode => !supplyMode)
    }

    return (
        <div className={"page list-page"}>
            {
                showMode === "list" ?
                    <Table onHandleChangeRowsPerPage={handleChangeRowsPerPage}
                           loading={loading}
                           onHandleChangePage={handleChangePage}
                           onGetData={getInterventions}
                           onHandleColumns={handleColumns}
                           onHandleFilter={handleFilter}
                           rowCount={state.count}
                           onEndTyping={setTyping}
                           filter={state.filters}
                           pageNumber={state.pageNumber}
                           pageSize={state.pageSize}
                           columns={state.columns}
                           list={state.interventionList}
                           onClearFilters={clearFilter}
                           mapdisplay={true}
                           tabName={text.INTERVENTIONS}
                           fastMode={fastMode}
                           setShowMode={switchShowMode}
                    >
                        <Tooltip title={text.TOOLTIP_DISPLAY_INTERVENTIONS_MAP}>
                            <IconButton disabled={isFilterEmpty()} onClick={()=>setMap(true)}><img alt={"img"} src={IconMap }/></IconButton>
                        </Tooltip>
                        <InterventionForm onSaveSucceed={getInterventions}/>
                        <Tooltip title={text.TOOLTIP_DISPLAY_PLANNING}>
                            <IconButton onClick={()=>setPlanning(true)}><img alt={"planning"} src={PlanningIcon}/></IconButton>
                        </Tooltip>
                        <Tooltip title={text.CSV}>
                            <IconButton onClick={generatecsv}><img alt={"CSV"} src={CSVDl}/></IconButton>
                        </Tooltip>

                        {isBrowser &&
                            <Tooltip title={"Mode Modifications rapides"}>
                                <IconButton onClick={handleFastMode}><img alt={"CSV"} src={fastMode ? fastModeActivatedIcon : fastModeIcon} width={77} /></IconButton>
                            </Tooltip>
                        }
                        <Tooltip title={"Gestion des fournitures"}>
                            <IconButton onClick={handleSupplyMode}><img alt={"Supply"} src={supplyMode ? SupplyModeEnabled : SupplyModeDisabled} width={77} /></IconButton>
                        </Tooltip>

                    </Table>
                    :
                    <GridCardTable
                        loading={loading}
                        onEndTyping={setTyping}
                        onHandleFilter={handleFilter}
                        interventions={state.interventionList}
                        pageNumber={state.pageNumber}
                        pageSize={state.pageSize}
                        rowCount={state.count}
                        onGetData={getInterventions}
                        onHandleChangePage={handleChangePage}
                        onHandleChangeRowsPerPage={handleChangeRowsPerPage}
                        columns={state.columns}
                        filter={state.filters}
                        setShowMode={switchShowMode}
                    >
                        <Tooltip title={text.TOOLTIP_DISPLAY_INTERVENTIONS_MAP}>
                            <IconButton disabled={isFilterEmpty()} onClick={()=>setMap(true)}><img alt={"img"} src={IconMap }/></IconButton>
                        </Tooltip>
                        <InterventionForm onSaveSucceed={getInterventions}/>
                        <Tooltip title={text.TOOLTIP_DISPLAY_PLANNING}>
                            <IconButton onClick={()=>setPlanning(true)}><img alt={"planning"} src={PlanningIcon}/></IconButton>
                        </Tooltip>
                        <Tooltip title={text.CSV}>
                            <IconButton onClick={generatecsv}><img alt={"CSV"} src={CSVDl}/></IconButton>
                        </Tooltip>

                        {isBrowser &&
                            <Tooltip title={"Modifications rapides"}>
                                <IconButton onClick={handleFastMode}><img alt={"CSV"} src={fastMode ? fastModeActivatedIcon : fastModeIcon} width={77} /></IconButton>
                            </Tooltip>
                        }
                        <Tooltip title={"Gestion des fournitures"}>
                            <IconButton onClick={handleSupplyMode}><img alt={"Supply"} src={SupplyModeEnabled} width={77} /></IconButton>
                        </Tooltip>

                    </GridCardTable>
            }



            <Modal classNames={{modal: "no-scroll"}} open={planning} onClose={()=>setPlanning(false)} closeOnOverlayClick={false}>
                <Planning onFinish={()=>setPlanning(false)}/>
            </Modal>
            <Modal open={map} onClose={()=>{clearFilter();setMap(false)}} >
                <div className={"map-bean"}>
                    <MapBean
                        onHandleChangeRowsPerPage={handleChangeRowsPerPage}
                        loading={loading}
                        onHandleChangePage={handleChangePage}
                        onGetData={getInterventions}
                        onHandleColumns={handleColumns}
                        onHandleFilter={handleFilter}
                        rowCount={state.count}
                        onEndTyping={setTyping}
                        filter={state.filters}
                        pageNumber={state.pageNumber}
                        pageSize={state.pageSize}
                        columns={state.columns}
                        pinList={state.interventionList??[]} pinClickable={true}/>
                </div>
            </Modal>


        </div>
    )
}
