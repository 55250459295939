import {IconButton} from "@mui/material";
import React, {Fragment, useEffect, useState} from "react";
import Select from "react-select";
import IbatApi from "../../../utils/IbatApi";
import DocumentLoader from "../../util/DocumentLoader";
import FileUploader from "../../util/FileUploader";

export default function ProductTab({preciserFamilies, products, updateList, jtList}){

    const [newCarac, setNewCarac] = useState({})
    const [newVariant, setNewVariant] = useState({})
    const [selectedCaracteristics, setSelectedCaracteristics] = useState([])
    const mappedPreciserFamilies = preciserFamilies.map(p => {
        return {value: p.id, label: p.name}
    })

    const [selectedProduct, setSelectProduct] = useState()
    const [jobTypes, setJobTypes] = useState()
    const [product, setProduct] = useState({})
    const [productTypes, setProductTypes] = useState([])
    const api = new IbatApi()

    useEffect(()=> {}, [product, selectedProduct, preciserFamilies])

    function handleUpload(k, d) {
        api.addProductVariantImg(selectedProduct.id, k.value, d)
            .then(r => {
                updateList()
                setProduct(r)
            })
            .catch(e => console.log(e))
    }


    function handleSelectFamily(d){
        setProduct({...product, preciserFamily: d.value, jobTypes:[]})
        api.getProductTypesByPreciserFamily(d.value)
            .then(r => {
                setProductTypes(r)
            })
            .catch(e => console.log(e))
    }
    function handleProductType(d){
        let targetProductType = productTypes.filter(pt => pt.id === d.value)[0]
        setProduct({...product, productType: d.value})
        setJobTypes(jtList.filter(jt => targetProductType.jobTypes?.includes(jt.id)))
        // setJobTypes(targetProductType.jobTypes?.map(jt => { return {label: jt.name, value: jt.id}}))
        setSelectedCaracteristics(targetProductType?.caracteristics)
    }

    function handleJobTypeNewFamily(d){
        setProduct({...product, jobTypes: d.map(jt => jt.value)})
    }
    function handleName(e) {
        setProduct({...product, name: e.target.value})
    }
    function createProduct() {
        console.log(product)
        api.newProduct({...product, id: product.id ?? null, reference: product.reference ?? '-1'})
            .then(() => {updateList()})
            .catch(() => {
                console.log("une erreur est survenue")
            })
    }

    function addCarac(){
        let currentCarac = product?.caracteristics ?? {}
        currentCarac = {...currentCarac, [newCarac.name]: {[newCarac.type]: newCarac.value}}
        console.log(currentCarac)
        setProduct({...product, caracteristics: currentCarac})
        setNewCarac({})
    }
    function addVariant(){
        let currentVariant = product?.variants?.filter(v => v.value !== newVariant.value) ?? []
        currentVariant = [...currentVariant, {name: newVariant.name, value: newVariant.value, pictures: newVariant.pictures ?? []}]
        console.log(currentVariant)
        setProduct({...product, variants: currentVariant})
    }
    function removeCarac(k){
        delete product?.caracteristics[k]
        setProduct({...product})
    }
    function removeVariant(k){
        console.log(product.variants)
        console.log(k)
        setProduct({...product, variants: product?.variants.filter(v => v.value !== k.value)})
    }

    function newProduct(){
        return (
            <div className={"catalog-content flex-column"}>
                <div className={"flex-row"}>
                    <div>
                        <label>Désignation</label><br />
                        <input type={"text"} placeholder={"H14"} value={product?.name ?? ""} onChange={e=>handleName(e)}/>
                    </div>
                    <div>
                        <label>Sous-famille concernée</label>< br />
                        <Select
                            placeholder={"Couverture, Évacuations, ..."}
                            value = {mappedPreciserFamilies.filter(f=> f.value === product?.preciserFamily)[0]  ?? null}
                            onChange = {d => handleSelectFamily(d)}
                            options={mappedPreciserFamilies.sort((a, b)=> a.label>b.label ? 1 : -1)}/>
                    </div>
                    {product.preciserFamily &&
                        <div>
                            <label>Type de produit</label>< br />
                            <Select
                                placeholder={"Tuiles, Gouttières, ..."}
                                value = {productTypes?.map(pt => {return {value: pt.id, label: pt.name}})?.filter(pt => pt.value === product.productType)}
                                onChange = {d => handleProductType(d)}
                                options={productTypes?.map(pt => {return {value: pt.id, label: pt.name}})?.sort((a, b)=> a.label>b.label ? 1 : -1)}/>
                        </div>
                    }
                    {product.productType &&
                        <div>
                            <label>Corps de métier concerné</label>< br />
                            <Select
                                isMulti
                                placeholder={"Couverture, Plomberie, ..."}
                                value = {jobTypes?.filter(jt => product?.jobTypes?.includes(jt.value))}
                                onChange = {d => handleJobTypeNewFamily(d)}
                                options={jobTypes}/>
                        </div>
                    }
                </div>
                <div className={"flex-row"}>
                    <div>
                        Caractéristiques du produit
                        <ul>
                            {product?.caracteristics && Object.keys(product?.caracteristics)?.map((k) =>
                                <li>
                                    <IconButton className={"action-btn cancel"} onClick={()=>removeCarac(k)}>X</IconButton> {k} : { Object.keys(product?.caracteristics[k]).map(k1=> `${product?.caracteristics[k][k1]}${k1}`) }
                                </li>
                            )}
                            <div className={"flex-row"}>
                                {
                                    Object.keys(selectedCaracteristics).filter(c => !product?.caracteristics || !Object.keys(product?.caracteristics).includes(c))?.length>0 &&
                                    <Fragment>
                                        <Select
                                            className={"large w100"}
                                            onChange = {d => setNewCarac({...newCarac, name: d.value, type: d.type, label: d.label, valueType : d.valueType})}
                                            options={selectedCaracteristics
                                                .filter(c => !product?.caracteristics || !Object.keys(product?.caracteristics).includes(c.name))
                                                ?.map(c => {
                                                    return {
                                                        value: c.name ,
                                                        label: `${c.name} ${typeof c.value === "string" && c.value !== "" ? `en ${c.value}` : "" }`,
                                                        type: typeof c.value === "string" && c.value !== "" ? c.value : " ",
                                                        valueType: c.valueType
                                                    }
                                                })}
                                        />
                                        {
                                            newCarac.valueType !== "Array" ?
                                                <input type={"text"} name={"caracteristic"} placeholder={newCarac.label} value={newCarac?.value ?? ""}
                                                       onChange={event => setNewCarac({...newCarac, value: event.target.value})}
                                                />
                                                :
                                                <Select
                                                    className={"large w100"}
                                                    onChange = {d => setNewCarac({...newCarac, value: d.value})}
                                                    options={selectedCaracteristics.filter(sc => sc.name === newCarac.name)[0].value?.map(c => {return {value: c, label: c}})}
                                                />
                                        }
                                        <IconButton className={"action-btn validation"} onClick={addCarac}>Ajouter</IconButton>
                                    </Fragment>
                                }
                            </div>
                        </ul>
                    </div>
                    <div>
                        Variantes du produit :
                        <ul>
                            <label>Caractéristique variante : </label><br />
                            <input type={"text"} name={"caracteristic"} placeholder={"Teinte"} value={newVariant?.name ?? ""}
                                   onChange={event => setNewVariant({...newVariant, name: event.target.value})}
                            />
                            <div className={"flex-row"}>
                                {
                                    newVariant?.name &&
                                    <Fragment>
                                        <label><b>{newVariant?.name} : </b></label>
                                        <input type={"text"} value={newVariant?.value ?? ""}
                                               onChange={event => setNewVariant({...newVariant, value: event.target.value})}
                                        />
                                        <IconButton className={"action-btn validation"} onClick={addVariant}>Ajouter</IconButton>
                                    </Fragment>
                                }

                            </div>
                            {product?.variants && product?.variants?.map(k =>
                                <li>
                                    <IconButton className={"action-btn cancel"} onClick={()=>removeVariant(k)}>X</IconButton> {k.value}{/*k[Object.keys(k)]}*/}
                                </li>
                            )}
                        </ul>
                    </div>
                </div>
                <div className={"flex-row"}>
                    <IconButton className={"action-btn cancel"} onClick={()=>setProduct({})}>Annuler</IconButton>
                    <IconButton className={"action-btn validation"} onClick={createProduct}>Ajouter</IconButton>
                </div>
            </div>
        )
    }
    function editProduct(){
        return (
            <div className={"catalog-content flex-column"}>
                <div className={"flex-row"}>
                    <div>
                        <label>Désignation</label><br />
                        <input type={"text"} placeholder={"H14"} value={product?.name ?? ""} onChange={e=>handleName(e)}/>
                    </div>
                    <div>
                        <label>Membre de la sous-famille</label>< br />
                        <Select
                            placeholder={"Tuiles, Gouttières, ..."}
                            value = {mappedPreciserFamilies.filter(f=> f.value === product?.preciserFamily)[0]  ?? null}
                            onChange = {d => handleSelectFamily(d)}
                            options={mappedPreciserFamilies.sort((a, b)=> a.label>b.label ? 1 : -1)}/>
                    </div>

                    {product.preciserFamily &&
                        <div>
                            <label>Type de produit</label>< br />
                            <Select
                                placeholder={"Tuiles, Gouttières, ..."}
                                value = {productTypes?.map(pt => {return {value: pt.id, label: pt.name}})?.filter(pt => pt.value === product.productType)}
                                onChange = {d => handleProductType(d)}
                                options={productTypes?.map(pt => {return {value: pt.id, label: pt.name}})?.sort((a, b)=> a.label>b.label ? 1 : -1)}/>
                        </div>
                    }
                    {product?.productType &&
                        <div>
                            <label>Corps de métier concerné</label>< br />
                            <Select
                                isMulti
                                placeholder={"Couverture, Plomberie, ..."}
                                value = {jtList.filter(jt => product?.jobTypes?.includes(jt.id))}
                                onChange = {d => handleJobTypeNewFamily(d)}
                                options={jtList.filter(jt => preciserFamilies.filter(p => p.id === product.preciserFamily)[0]?.jobTypes?.includes(jt.id))}/>
                        </div>
                    }
                </div>
                <div className={"flex-row"}>
                    <div>
                        Caractéristiques du produit
                        <ul>
                            {product?.caracteristics && Object.keys(product?.caracteristics)?.map((k) =>
                                <li>
                                    {k} : { Object.keys(product?.caracteristics[k]).map(k1=> `${product?.caracteristics[k][k1]}${k1}`) }
                                </li>
                            )}
                        </ul>
                    </div>
                    <div>
                        Variantes du produit :
                        <ul>
                            <label>Caractéristique variante : </label><br />
                            <input type={"text"} name={"caracteristic"} placeholder={"Teinte"} value={selectedProduct.variants[0]?.name ?? ""} disabled/>
                            <div className={"flex-row"}>
                                {
                                    selectedProduct.variants[0]?.name &&
                                    <Fragment>
                                        <label><b>{selectedProduct.variants[0]?.name} : </b></label>
                                        <input type={"text"} value={newVariant?.value ?? ""}
                                               onChange={event => setNewVariant({...newVariant, name: selectedProduct.variants[0]?.name,value: event.target.value})}
                                        />
                                        <IconButton className={"action-btn validation"} onClick={addVariant}>Ajouter</IconButton>
                                    </Fragment>
                                }

                            </div>
                            {product?.variants && product?.variants?.map(k =>
                                <li className={"flex-row product-variant"}>
                                    <div>
                                        <IconButton className={"action-btn cancel"} onClick={()=>removeVariant(k)}>X</IconButton> {k.value}
                                        {k.pictures?.map(n => <DocumentLoader name={n}/>)}
                                    </div>
                                    <FileUploader files={[]} onUpload={(d)=>handleUpload(k, d)} single={false} id={k.value} accept={"image/*"}/>
                                </li>
                            )}
                        </ul>
                    </div>
                </div>
                <div className={"flex-row"}>
                    <IconButton className={"action-btn cancel"} onClick={()=>setProduct({})}>Annuler</IconButton>
                    <IconButton className={"action-btn validation"} onClick={createProduct}>Enregistrer</IconButton>
                </div>
            </div>
        )
    }

    return (
        <div className={"family-tab br05 flex-row"}>
            <ul>
                <li>
                    <IconButton className={"action-btn validation"} onClick = {() => {
                        setProduct({})
                        setSelectProduct()
                    }}>Nouveau Produit</IconButton>
                </li>
                {products?.sort((a, b) => a.name > b.name ? 1 : -1)?.map(i =>
                    <li>
                        <div className={"as-link"} onClick={()=>{
                            setSelectProduct(i)
                            setProduct(i)
                            api.getProductTypesByPreciserFamily(i.preciserFamily)
                                .then(r => {
                                    setProductTypes(r)
                                })
                                .catch(e => console.log(e))
                        }}>{i.name}</div>
                    </li>
                )}
            </ul>
            {
                !selectedProduct && newProduct()
            }
            {
                selectedProduct && editProduct()
            }
        </div>
    )
}