import React, {useEffect, useState} from "react";
import IbatApi from "../../utils/IbatApi";
import CreatableSelect from "react-select/creatable";
import {Modal} from "react-responsive-modal";
import ClientForm from "./ClientForm";
import { text } from "../util/constants";

export default function SearchClient({onSave, selectMultiDest, preSelectionnedClient}) {
    const [clientList, setClientList] = useState([])
    const [client, setClient] = useState()
    const [popup, setPopup] = useState(false)
    const [name, setName] = useState()
    let api = new IbatApi();


    useEffect(()=>{
        if (!client && preSelectionnedClient?.id){
            let s = mapDataClient(preSelectionnedClient)
            handleChange(s)
        }
    })

    function mapDataClient(s){
        var name = `${s.name} [${!s.ville? "": s.ville+","} ${!s.cp? "": s.cp}]`.replace("[ ]", "")
        return ({label: name, name: name, id: s.id,value:s.id, mail: s.mail})
    }

    let loadOptions=(inptv)=>{
        return api.findClient(inptv).then(resp => {
            let map = resp.map(s => {
                var name = `${s.name} [${!s.ville? "": s.ville+","} ${!s.cp? "": s.cp}]`.replace("[ ]", "")
                return ({label: name, name: name, id: s.id,value:s.id, mail: s.mail})
            })
            setClientList(map)
            return map
        })
    }

    function handleInputChange(d){
        setName(d)
        loadOptions(d)
    }

    function handleCreate(d){
        setName(d)
        setPopup(true)
    }

    function handleClient(data){
        return api.createClient(data)
            .then(res => {
            setClient({value:res.id,label:res.name})
            onSave({id:res.id,name:res.name, mail: res.mail})
            if (selectMultiDest) {
                setClientList([])
                setClient(null)
            }
            setPopup(false)
            })
    }

    function handleCancel(){
        setClient(null)
        setPopup(false)

    }

    function handleChange(d){
        if(d!==null){
            onSave({id:d.value,name:d.label, mail: d.mail})
            setClient({value:d.value,label:d.label})
            if (selectMultiDest) {
                setClientList([])
                setClient(null)
            }
        }
    }
    return (
        <>
            <CreatableSelect
                className={"w100"}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                menuPortalTarget={document.body}
                classNamePrefix={"search"}
                placeholder={text.CLIENT_SEARCH}
                isClearable
                onChange={handleChange}
                onInputChange={handleInputChange}
                isLoading={popup}
                onCreateOption={handleCreate}
                value={client}
                formatCreateLabel={(input)=>`${text.CREATE} ${input}`}
                noOptionsMessage={()=>text.NO_ENTRY_SELECTED}
                options={clientList}
            />
            <Modal open={popup} onClose={() => setPopup(false)}>
                <ClientForm name={name} onCancel={handleCancel} onSave={handleClient}/>
            </Modal>
        </>
    )
}
