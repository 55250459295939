import constants from "../components/util/constants";

export function timeToDate(time){
    // return new Date(time).toLocaleTimeString()
    return Math.floor(time/3600000).toLocaleString('fr-FR', {
        minimumIntegerDigits: 2,
        useGrouping: false
    })+':'+Math.floor((time%3600000)/60000).toLocaleString('fr-FR', {
        minimumIntegerDigits: 2,
        useGrouping: false
    })
}

export function timetoWeek(time){
    let date = new Date(time);
    let fullYear = date.getFullYear();
    let fstJan = new Date(fullYear,0,1);
    let dayNumber=((date.getTime()-fstJan.getTime())/constants.H24)
    let weekNumber=Math.ceil(dayNumber/7).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping: false})
    return `${fullYear}-W${weekNumber}`
}