import React, {useEffect, useState} from "react";
import IbatApi from "../utils/IbatApi";
import SearchTeam from "../components/forms/SearchTeam";
import GoogleMap from "google-map-react";
// import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import Pin from "../components/util/Pin";
import {IconButton} from "@mui/material";
import {
    Timeline,
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineItem,
    TimelineOppositeContent,
    TimelineSeparator
} from '@mui/lab';
import constants, {text} from "../components/util/constants";
import {timeToDate} from "../helpers/timeToDate";

export default function Timesheet() {
    let api = new IbatApi();
    const [pinTimeSheet, setPinTimeSheet] = useState([])
    const [state, setState] = useState({start: 0, end: 0})
    const [declaredTime, setDelaredTime] = useState(0)
    const [pinPositionList, setPinPositionList] = useState([])
    const [interventions, setInterventions] = useState([])
    const [interval, setInterval] = useState([])
    const [hover, setHover] = useState()
    const [clocks,setClocks]=useState([])
    const [map, setMap]=useState()
    const [geodesicPath, setGeodesicPath]=useState()

    useEffect(()=>{
    }, [hover])

    function getWorkPosition(id, start, end) {
        if (state.team) {
            api.getTeamClock(id, new Date(start).getTime(), new Date(end).getTime()).then(tms => {
                setPinTimeSheet(tms)
                setDelaredTime(getTimeOf(tms.sort((p1, p2) => p1.position.date - p2.position.date)))
            })
        }
    }
    function getPosition(id, start, end) {
        if (state.team) {
            api.getTeamPosition(id, new Date(start).getTime(), new Date(end).getTime()).then(tms => {
                setPinPositionList(tms.map(p => ({...p, visible: false})))
            })
        }
    }
    function getInterventionPosition(id, start, end) {
        api.getTeamPositionDuringIntervention(id, new Date(start).getTime(), new Date(end).getTime()).then(data => {
            return setInterventions(data)
        })
    }
    function getTimeOf(data) {
        let list = data
        let time_counter = 0
        let currentStatus = null
        for (let i = 0; i < list.length; i++) {
            let current = list[i];
            if (currentStatus !== current.status) {
                currentStatus = current.status
                let time = current.status === "Active" ? current.position.date : -current.position.date
                time_counter = time_counter + time
            }
        }
        return Math.abs(time_counter)

    }

    function getAllPins() {
        let map1 = interventions.map(i => ({...i.timeSheets[0], interventionId: i.id, site: i.devis.site}));
        let newTab = [...map1, ...pinTimeSheet];
        newTab = newTab.sort((ts1, ts2) => ts1.position.date - ts2.position.date)
        return newTab;
    }

    function handleState(e) {
        let name = e.target.name;
        let value = e.target.value;
        setState({...state, [name]: value})
    }

    function handleTeam(d) {
        setState({...state, team: d.id})
    }

    function getNewTimeShit() {
        api.getClock( new Date(state.start).getTime(), new Date(state.end).getTime(),state.team).then(cls=>setClocks(cls))
    }
    function getData() {
        if (!state.team || !state.start || !state.end) {
            alert(text.FILL_ALL_INPUTS)
            return;
        }
        if(new Date(state.start).getTime()<1636156800000) { //todo modifier ceux truc tout pourris par un truc propre le nombre correpsond a la date du 06/11/2021
            getPosition(state.team, state.start, state.end);
            getWorkPosition(state.team, state.start, state.end)
            getInterventionPosition(state.team, state.start, state.end)
            return;
        }
        getNewTimeShit()
    }

    function isInInterval(p, intv = interval) {
        if (intv < 2) {
            return false
        }
        let b = p.date < Math.max(intv[0], intv[1]) && p.date > Math.min(intv[0], intv[1]);
        return b;
    }
    function handleInterval(p) {
        let intvCopy = interval
        intvCopy.push(p.timeSheets[0].position.date)
        if (intvCopy.length > 2) {
            intvCopy.shift()
        }
        let newPosList = pinPositionList.map(p => ({...p, visible: isInInterval(p, intvCopy)}))
        // let shows = newPosList.filter(p => p.visible);
        setInterval(intvCopy)
        setPinPositionList(newPosList)
    }
    function handleTraffic(index) {
        let allPins = getAllPins();
        let length = allPins.length;
        if (index === length - 1) {
            return
        }
        let origin = allPins[index].position
        let destination = allPins[index + 1].position
        window.open(`https://www.google.com/maps/dir/?api=1&origin=${origin.latitude},${origin.longitude}&destination=${destination.latitude},${destination.longitude}`, "_blank", "noreferrer")
    }

    function handleTraffic2(origin,destination) {
        // let nextPos = tmsList[currentIdx+1];
        // let origin = pos
        // let destination = nextPos
        window.open(`https://www.google.com/maps/dir/?api=1&origin=${origin.latitude},${origin.longitude}&destination=${destination.latitude},${destination.longitude}`, "_blank", "noreferrer")
    }
    function getTimeLine(clock){
      const filteredTS = clock.timeSheets.filter((ts,idx)=>ts.interventionStatus!=null || idx===0 || idx===clock.timeSheets.length-1)

        return filteredTS
            .sort((p1, p2) => p1.date >= p2.date ? 1 : -1)
            .map((p,idx)=>
            <TimelineItem key={idx}>
                {(p.interventionStatus )?
                  <TimelineOppositeContent>

                      {<div className={`timeline-card${hover === p?.id ? " hovered" : ""}`} onMouseEnter={() => {
                          setHover(p.id)
                      }}  onMouseLeave={() => setHover(null)}>
                          {text.STATUS_TRADUCTION[p.interventionStatus]} {new Date(p.date).toLocaleDateString()} {new Date(p.date).toLocaleTimeString()}
                          {hover === p.id &&
                          <div className={"grid infos"}>
                              <a href={`/interventions/${p.interventionId?.id}`} rel={"noreferrer"} target={"_blank"}>
                                      {p.interventionId?.devis?.site?.name} {p.interventionId?.devis?.site?.address}
                                      {p.interventionId?.devis?.site?.cp} {p.interventionId?.devis?.site?.ville}
                              </a>
                          </div>}
                      </div>}
                  </TimelineOppositeContent> :
                  <TimelineOppositeContent/>
                }

                <TimelineSeparator>
                    <TimelineDot
                        className={`flex-column separator-dot w2 ${!(idx===0 || (idx === filteredTS.length-1 && !p.interventionStatus))? "primary" : "secondary"}`}
                    >
                        <div className={"status-pic"} id={p.interventionStatus?.toLowerCase()}/>
                    </TimelineDot>
                    {idx!==filteredTS.length-1 &&<TimelineConnector onClick={() => handleTraffic2(p,filteredTS[idx+1])}/>}
                </TimelineSeparator>

                {!p.interventionStatus?
                <TimelineContent >
                        <div className={"timeline-card"} onMouseEnter={()=>setHover(p.id)} onMouseLeave={()=>setHover(null)} onClick={() =>handleInterval2(clock)} >
                            {idx===0 ? "Début": "Fin"} {new Date(p.date).toLocaleDateString()} {new Date(p.date).toLocaleTimeString()}
                        </div>
                </TimelineContent>:<TimelineContent />}


            </TimelineItem>)
    }
    function handleInterval2(clock) {
      if (!pinPositionList || pinPositionList.id !== clock.id){
        const  pinList = clock?.timeSheets?.filter((ts, idx) => idx%2 === 0 && ts.interventionStatus == null && ts.interventionId == null)
        setPinPositionList({id: clock.id, pinList:pinList})
        const pathList = clock?.timeSheets?.filter((ts, idx) => idx%2 === 0 && ts.interventionStatus == null && ts.interventionId == null).map(ts => {
          return {lat:ts.latitude, lng:ts.longitude}
        })
        // setPathCoordinates(pathList)

        geodesicPath.setPath(pathList)
        geodesicPath.setMap(map)
      }
      else{
        setPinPositionList()
        geodesicPath.setPath(null)
        geodesicPath.setMap(null)
      }
    }
    const handleApiLoaded = (map, maps) => {
      // use map and maps objects
      // setMaps(maps)
      setMap(map)
      setGeodesicPath(new maps.Polyline({
        geodesic: true,
        strokeColor: "#219dd3",
        strokeOpacity: 1.0,
        strokeWeight: 8
      }))
    };

    const handleHover = (e, pin) => {
        setHover(pin)
    }

    return (
        <div className={"page grid flex time-sheet"}>
            <div className={"search-bar primary-section flex-row"} >
                <SearchTeam onSave={handleTeam}/>
                <input className={"large w100"} type={"date"} name={"start"} onChange={handleState}/>
                <input className={"large w100"} type={"date"} name={"end"} onChange={handleState}/>
                <IconButton onClick={getData} className={"action-btn validation"}>{text.IMPORT}</IconButton>
            </div>
            {/*
               new Date(state.start).getTime()>0 &&
            */}

            <aside className={"flex-column"}>
                <Timeline className={"timeline secondary-section"} align="right">
                    {
                        clocks
                            .filter(ts => ts.timeSheets.filter(ts=>ts.interventionStatus!=null).length>0)
                            .sort((c1,c2)=>c1.timeSheets[0].date-c2.timeSheets[0].date)
                            .map(clock =>getTimeLine(clock))
                    }
                </Timeline>
                <div className={"flex w100 declared primary-section"}>
                    {text.DECLARED_TIME}<br />
                    {
                        timeToDate(clocks
                            .filter(c=>c.end)
                            .map(c=>c.end-c.start)
                            .reduce((c1,c2)=>c1+c2,0)
                        )
                    }

                </div>
            </aside>

            <div className={"map"}>
                <GoogleMap
                    bootstrapURLKeys={{key: process.env.REACT_APP_GOOGLE_API_KEY}} // set if you need stats etc ...
                    defaultCenter={constants.MAPCENTER}
                    defaultZoom={6}
                    yesIWantToUseGoogleMapApiInternals
                    onGoogleApiLoaded={({map, maps}) => handleApiLoaded(map, maps)}
                    options={{
                        mapId: process.env.REACT_APP_GOOGLE_MAP_ID,
                        mapTypeControl: true,
                        gestureHandling: "greedy"
                    }}
                    // onChange={handleChange}
                >
                    {clocks
                        .map(c=>
                            c.timeSheets
                                .filter((pos,i)=>i===0||i===c.timeSheets.length-1)
                                .map((p,idx) => {
                                    return <Pin key={p.id} lat={p.latitude} data={p} lng={p.longitude} text={idx===0?"début":"fin"} hovered={hover} onHover={handleHover}>
                                        {
                                            hover === p?.id &&
                                            <div className={"bord1 planning-card"}>
                                                <div>
                                                    <div style={{backgroundColor: "black"}} className={"intervention-type w100"}>
                                                        [{idx===0?"début":"fin"}]
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </Pin>
                                })
                        )
                        .flat(1)}


                    {pinPositionList?.pinList?.map((p,idx)=>
                        <Pin key={p.id} lat={p.latitude} lng={p.longitude} color={"white"} hovered={hover} onHover={handleHover} data={p}>
                            {
                                hover === p.id &&
                                <div className={"bord1 planning-card"}>
                                    <div>
                                        <div style={{backgroundColor: "black"}} className={"intervention-type w100"}>
                                            {new Date(p.date).toLocaleDateString()} {new Date(p.date).toLocaleTimeString()}
                                        </div>
                                    </div>
                                </div>
                            }
                        </Pin>
                    )}

                    {
                        clocks
                        .map(c=>c.timeSheets.filter((pos,i)=>(i!==0||i!==c.timeSheets.length-1) && pos.interventionStatus!=null)
                            .map((p, idx) =>
                                <Pin lat={p.latitude} key={p.id} lng={p.longitude} text={p.interventionId?.id} /*data={p.interventionId}*/ onHover={handleHover} data={p}
                                     hovered={hover} color={"yellow"}>
                                    {
                                        hover === p.id &&
                                        <div className={"bord1 planning-card"}>
                                            <div>
                                                <div style={{backgroundColor: "black"}} className={"intervention-type w100"}>

                                                    {new Date(p.date).toLocaleDateString()} {new Date(p.date).toLocaleTimeString()} : [{p.interventionStatus}]
                                                </div>
                                                <a href={`/interventions/${p.interventionId.id}`} rel={"noreferrer"} target={"_blank"}>
                                                    {p.interventionId?.devis?.site?.name} {p.interventionId?.devis?.site?.address} {p.interventionId?.devis?.site?.cp} {p.interventionId?.devis?.site?.ville}
                                                </a>

                                            </div>
                                        </div>
                                    }


                                </Pin>)).flat(1)
                    }
                </GoogleMap>
            </div>
        </div>
    )
}
