import React, {useEffect, useState} from "react";
import {IconButton, Tooltip} from "@mui/material";
import {text} from "../components/util/constants";
import FirstPage from "../images/page-first.png";
import PreviousPage from "../images/page-previous.png";
import NextPage from "../images/page-next.png";
import LastPage from "../images/page-last.png";
import LoadingSVG from "../images/LoadingSVG.svg";
import Modal from "react-responsive-modal";

import link from "../images/link.png"
import site from "../images/site.png"
import city from "../images/city.png"
import iconEye from "../images/contact-illus.png"
import iconCall from "../images/min-call.png"
import calendar from "../images/calendar.png"
import switchToList from "../images/switch-to-list.png"
import switchToCard from "../images/switch-to-card.png"
import filterCard from "../images/filter-card.png"
import camion from "../images/camion.png"

import received from "../images/received.png"
import canceled from "../images/canceled.png"
import toSchedule from "../images/to-schedule.png"
import scheduled from "../images/scheduled.png"
import processing from "../images/processing.png"
import done from "../images/done.png"
import writing from "../images/writing.png"
import finished from "../images/finished.png"


import maintenance from "../images/maintenance.png"
import emergency from "../images/emergency.png"
import sav from "../images/sav.png"
import chantier from "../images/chantier.png"
import dechetterie from "../images/dechetterie.png"
import depot from "../images/depot.png"
import atelier from "../images/atelier.png"
import fournisseur from "../images/fournisseur.png"
import metrage from "../images/metrage.png"
import expertise from "../images/expertise.png"
import appro from "../images/appro.png"

import EditInterventionDate from "../components/forms/EditInterventionDate";
import InteractionClient from "../components/bean/InteractionClient";
import EditSupply from "../components/forms/EditSupply";
import EditSecteur from "../components/forms/EditSecteur";
import EditInterventionTeam from "../components/forms/EditInterventionTeam";
import EditEstimateDuration from "../components/forms/EditEstimateDuration";
import EditEstimate from "../estimate/EditEstimate";
import EditBillForm from "../components/forms/EditBillForm";
import MapPointer from "../components/util/MapPointer";
import IbatApi from "../utils/IbatApi";
import {getStatusIcon, getTypeIcon} from "../utils/Utils";


function Card({intervention, handleClick, getStatusIcon, getTypeIcon, getStatusClassName}) {

    return (

        <div className={"secondary-section br10 grid card-section"} onClick={handleClick}>
            <div className={`w100 status-bar ${getStatusClassName(intervention.status)}`}/>
            <div className={"secondary-section card-intervention flex-row"}>
                <img src={link}/> <a href={`interventions/${intervention.id}`} target={"_blank"} rel={"noreferrer"} onClick={(e) => e.stopPropagation()}>
                    {intervention.id}
                </a>
            </div>
            <div className={"card-status flex-column"}>
                <img src={getStatusIcon(intervention.status)}/>
                {intervention.status}
            </div>
            <div className={"card-type flex-column"}>
                <img src={getTypeIcon(intervention.type.id)}/>
                {intervention.type.name}
            </div>
            <div className={"w100 card-line-2 flex secondary-section"}>
                   <div className={"cardsite"}><img src={site}/>
                    <div title={"Site"} >
                        {intervention.site.warning ? <p style={{color: "red"}}>{intervention.site.name}</p> : intervention.site.name}
                    </div></div>
                    <div className={"cardtown"}> <img src={city}/>
                    <div title={"Ville"}>
                        {intervention.site.warning ? <p style={{color: "red"}}>{intervention.ville} ({intervention.cp})</p> : `${intervention.ville} (${intervention.cp})`}
                    </div>
                    </div>
                <div className={"cardclient"} title={"Client"}>
                    {intervention.client.name}
                </div>
                </div>
            </div>
    )
}

export default function GridCardTable({columns, setShowMode, onHandleFilter, interventions, hideFilter, filter, pageNumber, pageSize, rowCount, onHandleChangeRowsPerPage, onHandleChangePage, loading, children, onEndTyping, onGetData}) {

    const api = new IbatApi()
    const [selectedIntervention, setSelectedIntervention] = useState(null)
    const [developCriteriaBar, setDevelopCriteriaBar] = useState(false)
    const [visible, setVisible] = useState(false)
    const [timer, setTimer] = useState(null)
    const [typing, setTyping] = useState(false)
    const [activeFilter, setActiveFilter] = useState([])
    const [modalAction, setModalAction] = useState(null)


    useEffect(() => {
        setActiveFilter(columns.map(c => {
            return {
                id: c.id,
                label: c.label,
                isFiltrable: c.isFiltrable,
                value: filter[c.id]
            }
        })
            .filter(c => c.value !== undefined && c.value !== null && c.value !== ""))
    }, [filter]);

    function firstPage() {
        onHandleChangePage(0)
    }
    function previousPage() {
        let k=pageNumber-1
        if(k>=0) {
            onHandleChangePage(k)
        }
    }
    function nextPage() {
        let k=pageNumber+1
        if(k<Math.floor(rowCount/pageSize)) {
            onHandleChangePage(k)
        }
    }
    function lastPage() {
        let k =Math.floor(rowCount/pageSize)
        onHandleChangePage(k)
    }

    function handleKeyDown(e) {
        if (e.keyCode === 13) {
            triggerChange();
        }
    }
    function handleChange(value) {
        clearTimeout(timer);

        onHandleFilter(value)

        let time = setTimeout(triggerChange, 600);
        setTimer(time)
    }
    function triggerChange() {
        setTyping(!typing)
        onEndTyping(!typing);
    }

    function getStatusClassName(status) {
        switch (status) {
            case "Annulée" :
                return "canceled";
            case "Reçue" :
                return "received";
            case "À planifier" :
                return "to-schedule";
            case "Planifiée" :
                return "scheduled";
            case "En cours" :
                return "processing";
            case "Fait" :
                return "done";
            case "Rédaction" :
                return "writing";
            case "Fini" :
                return "finished";
            default:
                return

        }
    }
    function handleUpdadte(){
        setModalAction(null)
        onGetData()
        api.getIntervention(selectedIntervention.id).then(data => {
            setSelectedIntervention(api.normalize(data))
        })
    }

    return (
        <>
            <Modal open={selectedIntervention} onClose={()=>setSelectedIntervention(null)}>
                <div className={`w100 flex-column br10 main-card`}>

                    <div className={"primary-section"}>
                    <h1>
                        Intervention n°
                        <a href={`interventions/${selectedIntervention?.id}`} target={"_blank"} rel={"noreferrer"}>
                            {selectedIntervention?.id}
                        </a>
                    </h1>
                </div>
                    <div className={`w100 status-bar ${getStatusClassName(selectedIntervention?.status)}`}/>
                    <div className={"grid w100 a-grid"}>
                        <div className={"flex-column"} title={"Statut"}>
                            <div className={"flex-row"}>
                                <img src={getStatusIcon(selectedIntervention?.status)}/>
                                {selectedIntervention?.status}
                            </div>
                        </div>
                        <div className={"flex-column"} title={"Nature du dossier"}>

                            <div className={"flex-row"}>
                                <img src={getTypeIcon(selectedIntervention?.type.id)}/>
                                {selectedIntervention?.type.name}
                            </div>
                        </div>
                        <div className={"secondary-section flex-column elmt-link"} title={"Date Intervention"}
                             onClick={()=>setModalAction({modal:EditInterventionDate, props:{id: selectedIntervention.id, day: selectedIntervention?.value??0, team: selectedIntervention.team, update: handleUpdadte}})}>
                            <div className={"flex-row"}>
                                <img src={calendar}/> {selectedIntervention?.intervention?.name}
                            </div>
                        </div>
                        <div className={"address"} title={"Adresse"}>
                            <div className={"flex-row"}
                                 onClick={() => {
                                     selectedIntervention?.site.warning &&
                                     setModalAction({modal:MapPointer, props:{voie: selectedIntervention.address.voie, siteId: selectedIntervention.site.id, update: handleUpdadte}})
                                 }}>
                                <img src={city}/>
                                {selectedIntervention?.site.warning ? <p style={{color: "red", textDecoration: "underline"}}>{selectedIntervention?.address.name}, {selectedIntervention?.cp}, {selectedIntervention?.ville}</p> : `${selectedIntervention?.address.name}, ${selectedIntervention?.cp}, ${selectedIntervention?.ville}`}
                            </div>
                        </div>
                        <div className={"secondary-section flex-column elmt-link"} title={"Échanges"}
                             onClick={()=>setModalAction({modal:InteractionClient, props:{id: selectedIntervention.id, siteId: selectedIntervention.site.id, update: handleUpdadte}})}>
                            <img src={iconEye}  height={19}/>
                        </div>
                        <div className={"secondary-section"} title={"Téléphone"}>
                            {selectedIntervention?.site?.contactPhone?.map((phone, index) => {
                                return (
                                    <div className={"flex-row"} key={index}>
                                        <img src={iconCall}/> {phone}
                                    </div>
                                )
                            })}
                        </div>
                        <div className={"secondary-section elmt-link"} title={"Site"}>
                            <div className={"flex-row"}>
                                <img src={site}/>
                                <a href={`site/${selectedIntervention?.site.id}`} target={"_blank"} rel={"noreferrer"}>

                                    {selectedIntervention?.site.name}
                                </a>
                            </div>
                        </div>
                        <div className={"secondary-section elmt-link"} title={"Client"}>
                            <a href={`client/${selectedIntervention?.client.id}`} target={"_blank"} rel={"noreferrer"}>
                                {selectedIntervention?.client.name}
                            </a>
                        </div>
                    </div>
                    <div className={`w100 status-bar`}/>
                    <div className={"grid w100 a-grid"}>
                        <div className={"secondary-section elmt-link"} title={"Réception"}>
                            {selectedIntervention?.reception}
                        </div>
                        <div className={"secondary-section elmt-link"} title={"Gestionnaire"}>
                            {selectedIntervention?.gestionnaire.name}
                        </div>
                        <div className={"secondary-section elmt-link"} title={"Fourniture"}
                             onClick={()=>setModalAction({modal:EditSupply, props:{id: selectedIntervention.id, update: handleUpdadte}})}
                        >
                            {selectedIntervention?.supply.name}
                        </div>
                        <div className={"secondary-section elmt-link"} title={"Durée estimée"}
                             onClick={()=>setModalAction({modal:EditEstimateDuration, props:{id: selectedIntervention.id, update: handleUpdadte}})}>
                            {selectedIntervention?.estimateDuration.name}
                        </div>
                        <div className={"secondary-section elmt-link"} title={"Équipe"}
                             onClick={()=>setModalAction({modal:EditInterventionTeam, props:{id: selectedIntervention.id, update: handleUpdadte}})}>
                            {selectedIntervention?.team.name}
                        </div>
                        <div className={"secondary-section elmt-link"} title={"Commande #"}>

                            <a href={`devis/${selectedIntervention?.devis.id}`} target={"_blank"} rel={"noreferrer"}>
                                {selectedIntervention?.devis.name}
                            </a>
                        </div>
                        <div className={"secondary-section elmt-link"} title={"Secteur"}
                             onClick={()=>setModalAction({modal:EditSecteur, props:{id: selectedIntervention.id, update: handleUpdadte}})}>
                            {selectedIntervention?.secteur.name}
                        </div>
                        <div className={"secondary-section elmt-link"} title={"Devis"}
                             onClick={()=>setModalAction({modal:EditEstimate, props:{id: selectedIntervention.id, content: selectedIntervention?.estimate.name ?? "", update: handleUpdadte}})}>
                            {selectedIntervention?.estimate.name ?? "-"}
                        </div>
                        <div className={"secondary-section elmt-link"} title={"Facture"}
                             onClick={()=>setModalAction({modal:EditBillForm, props:{id: selectedIntervention.id, content: selectedIntervention?.bill.name ?? "", update: handleUpdadte}})}>
                            {selectedIntervention?.bill.name ?? "-"}
                        </div>
                    </div>
                </div>
            </Modal>
            {
                !hideFilter && loading &&
                <img alt={""} className={"table-element-center"} src={LoadingSVG}/>
            }
            <div className={"grid-card-scrollable"}>
                {
                    !hideFilter &&
                    <div className={"filter-bar flex secondary-section"}><div className={"pageTitle"}><div className={"flex-row"}><img src={camion} className={"flex-row pageTitleImg"}/><h2>Liste des interventions</h2></div></div>
                        <div className={"dashboard-switcher"}>
                            <img src={switchToList} width={40} onClick={()=>setShowMode("list")}/>
                        </div>
                        <div className={"pagerCounts flex"}><p style={{margin: 3}}>
                            {rowCount} {text.RESULTS} | {text.PAGE} {pageNumber + 1} {text.OF} {Math.floor(rowCount / pageSize) + 1} | {text.RESULTS_PER_PAGE} :
                        </p></div>
                        <div className={"pager flex-row"}>
                            <IconButton onClick={firstPage}> <img alt={"img"} src={FirstPage}/> </IconButton>
                            <IconButton onClick={previousPage}> <img alt={"img"} src={PreviousPage}/> </IconButton>

                            <select id={"pageNumber"} value={pageSize} onChange={onHandleChangeRowsPerPage}>
                                <option value={10}>10</option>
                                <option value={20}>20</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                            </select>

                            <IconButton onClick={nextPage}> <img alt={"img"} src={NextPage}/> </IconButton>
                            <IconButton onClick={lastPage}> <img alt={"img"} src={LastPage}/> </IconButton>
                        </div>
                        <div className={`activeSearch br20 flex-row${activeFilter.length == 0 ? " hidden" : "" }`}>
                            {
                                activeFilter
                                    .map((col, id) => {
                                        return (
                                            <div className={"flex-row bord1 criteria"} title={col.label}>
                                                <input id={col.id} name={col.id} type={"text"} key={id} onChange={handleChange} onKeyDown={handleKeyDown} value={filter[col.id]}/>
                                                <div onClick={()=> handleChange({target:{value: "", name: col.id}})}>✕</div>
                                            </div>
                                        )
                                    })
                            }
                        </div>

                        {
                            !hideFilter &&
                            <div className={`bord1 popup-filter br05 criteria-list-dropdown ${visible ? " visible" : ""}`} >
                                <Tooltip title={text.CRITERIA_LIST}>
                                    <IconButton className={"criteria-button"} onClick={() => setVisible(prev => !prev)} >
                                        <img alt={"img"}  className={"criteria-icon"} src={filterCard} />
                                    </IconButton>
                                </Tooltip>
                                <ul className={"bord1 criteria-list br05"}>
                                    {
                                        columns.filter(col => col.isFiltrable).map((col, id) =>
                                            <div className={"flex-row bord1 criteria"} title={col.label}>
                                                <input id={col.id} name={col.id} type={"text"} key={id} onChange={handleChange} onKeyDown={handleKeyDown} value={filter[col.id]}/>
                                                <div onClick={()=> handleChange({target:{value: "", name: col.id}})}>✕</div>
                                            </div>
                                        )
                                    }
                                </ul>
                            </div>
                        }
                    </div>
                }
                {
                    !hideFilter && interventions && interventions?.length===0 && !loading &&
                    <center><div className={"elmt-link primary-section"}>{text.NO_RESULT}</div></center>
                }
                { !loading &&
                    <div className={"grid-card grid"}>
                        {
                            interventions?.map((intervention, index) => {
                                return (
                                    <Card key={index} intervention={intervention}
                                          handleClick={() => setSelectedIntervention(intervention)}
                                          getStatusIcon={getStatusIcon}
                                          getTypeIcon={getTypeIcon}
                                          getStatusClassName={getStatusClassName}
                                    />
                                )
                            })
                        }
                    </div>
                }
            </div>
            {
                children &&
                <aside className={`flex-column criteria-bar${developCriteriaBar ? " developped" : ""}`}>
                    <div className={`margin-content`}>
                        {children}
                        <div className={`bord1 only-for-small-screen${developCriteriaBar ? " developped" : ""}`} onClick={()=>setDevelopCriteriaBar(!developCriteriaBar)}>
                            >
                        </div>
                    </div>
                </aside>
            }

            <Modal open={modalAction} onClose={()=>setModalAction(null)}>
                {
                    modalAction &&
                    React.createElement(modalAction.modal, modalAction.props)
                }
            </Modal>
        </>
    )
}