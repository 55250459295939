import React, {useEffect, useState} from "react";
import constants, {text} from "../util/constants";
import {Tooltip} from "@mui/material";
import CSVDl from "../../images/csv-dl.png";
import Table from "../../table/Table";
import {useSnackbar} from "notistack";
import IbatApi from "../../utils/IbatApi";
import Ready from "../../images/ready.png"
import NotReady from "../../images/not-ready.png"
import DocImg from "../../images/file.png"
import {DocumentLoaderList} from "../util/DocumentLoader";
import {CSVLink} from "react-csv";

export default function Supply() {
  const {enqueueSnackbar} = useSnackbar()

  const columns = [
    {id: "dateReception", label: text.RECEPTION_DATE, isChecked: true, isFiltrable: true, type: "dateText"},
    {id: "devis", label: text.COMMAND_NUM, isChecked: true, isFiltrable: true, type: "objectLink"},
    {id: "client", label: text.CLIENT, isChecked: true, isFiltrable: true, type: "objectLink"},
    {id: "site", label: text.SITE, isChecked: true, isFiltrable: true, type: "objectLink"},
    {id: "supply", label: text.SUPPLY, isChecked: true, isFiltrable: true, type: "inputText"},
    {id: "qty", label: text.QUANTITY, isChecked: true, isFiltrable: false, type: "inputText"},
    {id: "document", label: text.DOCS, isChecked: true, isFiltrable: false, type: "popup", popup: DocumentLoaderList},
    {id: "supplier", label: text.SUPPLIER, isChecked: true, isFiltrable: true, type: "inputText"},
    {id: "isReady", label: text.STATUS, isChecked: true, isFiltrable: true, type: "selectable"},
    {id: "status", label: text.READY, isChecked: true, isFiltrable: false, type: "supply", icon: true},
    {id: "remove", label: text.REMOVE, isChecked: true, isFiltrable: false, type: "remove"},
  ]


  const [state, setState] = useState(
    {
      columns: columns,
      interventionList: [],
      count: 0,
      filters: {
        id: "",
        dateReception: "",
        devis: "",
        client: "",
        site: "",
      },
      internalFilters: {
        supply: "",
        supplier: "",
        isReady: ""
      }
    }
  )
  const [typing, setTyping] = useState(false)
  const [loading, setLoading] = useState(false)
  const [sort, setSort] = useState("dateReception")
  const api = new IbatApi()

  const headers = [
    {label:"Date de réception", key:"dateReception"},
    {label:"N° devis", key:"devis"},
    {label:"Client", key:"client"},
    {label:"Site", key:"site"},
    {label:"Fourniture", key:"supply"},
    {label:"Qté.", key:"qty"},
    {label:"Fournisseur", key:"supplier"},
    {label:"État", key:"isReady"},
    {label:"Type de donnée", key:"datatype"}
  ];
  const csvReport = {
    data: state.interventionList.map(x =>
      Object({
        dateReception: new Date(x["dateReception"]).toLocaleDateString('fr-FR').toString(),
        devis: x["devis"]["name"],
        client: x["client"]["name"],
        site: x["site"]["name"],
        supply: x["supply"]["name"]? x["supply"]["name"].replaceAll(',', "").replaceAll('"', ""): x["supply"].replaceAll(',', "").replaceAll('"', ""),
        qty: x["quantity"] ?? 0,
        supplier: x["supplier"] ?? "",
        isReady: x["isReady"],
        datatype: x["datatype"]
      })),
    headers: headers,
    filename: 'export_fourniture.csv'
  };

  function mapSupplyStatus(status) {
    switch (status) {
      case 0:
        return constants.NULL;
      case 1:
        return text.AVAILABLE_PRODUCT;
      case 2:
        return text.SUPPLY_ON_SITE;
      case 3:
        return text.SUPPLIER;
      default:
        return constants.NULL;
    }
  }

  function sortSupply() {
    if (sort === false) {
      return
    }
    state.interventionList.sort((a, b) => {
      if (sort !== 'dateReception') {
        let nameA = a[sort]
        let nameB = b[sort]
        if (a[sort]["name"] !== undefined) {
          nameA = a[sort]["name"]
        }
        if (b[sort]["name"] !== undefined) {
          nameB = b[sort]["name"]
        }
        return (nameA.toLowerCase() > nameB.toLowerCase()) ? 1 : -1
      } else {
        return (a[sort] > b[sort]) ? 1 : -1

      }
    })
  }
  function filterBySupply(list) {
    if (state.internalFilters.supply === '') {
      return list
    } else {
      return list.filter(s => {
        if (s.supply.name === undefined) {
          return (s.supply.toLowerCase().includes(state.internalFilters.supply.toLowerCase()))
        } else {
          return (s.supply.name.toLowerCase().includes(state.internalFilters.supply.toLowerCase()))
        }
      })
    }
  }
  function filterBySupplier(list) {
    if (state.internalFilters.supplier === '') {
      return list
    } else {
      return list.filter(s => {
        return (s.supplier.toLowerCase().includes(state.internalFilters.supplier.toLowerCase()))
      })
    }
  }
  function filterByStatus(list) {
    if (state.internalFilters.status === '') {
      return list
    } else {
      return list.filter(s => {
        return (s.isReady.toLowerCase().includes(state.internalFilters.isReady.toLowerCase()))
      })
    }
  }

  function mapSupply(devisList) {
    let globalList = []
    for (var i in devisList) {

      let devis = devisList[i]
      let elementList = devis.supply.elements.flatMap(x => x)
      elementList = elementList.map(x =>
        Object({
          id: x["id"],
          deletable: true,
          supplyId: devis.supply.id,
          dateReception: devis["dateReception"],
          devis: {
            name: devis["clientIdBCommande"],
            id: devis["id"]
          },
          client: devis["client"],
          site: devis["site"],
          supply: x["type"],
          qty: x["quantity"] ?? 0,
          document: {
            name: x["documents"] ?? [],
            icon: DocImg
          },
          supplier: x["supplier"] ?? "",
          isReady: mapSupplyStatus(x["isReady"]),
          status: x["isReady"] > 0 ? Ready : NotReady,
          datatype: x["dataType"]
        })
      )
      elementList.slice(0, 500).forEach(element => {
        if (!globalList.some(s => s.id === element.id)){
          globalList.push(element)
        }
      })
    }
    let filteredList = filterBySupply(globalList)
    filteredList = filterBySupplier(filteredList)
    filteredList = filterByStatus(filteredList)
    sortSupply(filteredList)
    return filteredList
  }

  function getSupply() {
    setLoading(true)
    api.getFilterSupply({
      ...state.filters
    }).then(res => {
      setLoading(false)
      let supplyList = mapSupply(res.interventions)
      setState(({...state, interventionList: supplyList, count: supplyList.length}))
    }).catch(e => {
      if (e.response?.status === 401) {
        enqueueSnackbar(text.PLEASE_LOGIN, {variant: "error"})
        // window.open("/login")
        return
      }
      enqueueSnackbar(text.SNACKBAR_ERROR, {variant: "error"})
    })
  }


  useEffect(() => {
    getSupply()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typing, state.pageNumber, state.pageSize])
  useEffect(() => {
    if (state.interventionList.length>0){
      sortSupply(state.interventionList)
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort])


  /*function isFilterEmpty() {
    // let reduce = Object.keys(state.filters).map((k)=> state.filters[k]==="").reduce((b1, b2) => b1 && b2);
    return false
  }*/

  const handleColumns = (event) => {
    let columns = state.columns
    let filter = {...state.filters}
    let find = columns.find(col => col.id === event.target.name);
    find.isChecked = event.target.checked
    if (find.isFiltrable) {
      filter[find.id] = ""
    }
    setState(({...state, columns: columns, filters: filter, mapping: false}))
  }
  const handleFilter = (e) => {
    let value = e.target.value
    let name = e.target.name
    if (name === 'supplier' || name === 'supply' || name === 'isReady') {
      setState(({...state, internalFilters: {...state.internalFilters, [name]: value}}))
    } else {
      setState(({...state, filters: {...state.filters, [name]: value, sort: name}}))
    }
  }
  const handleChangePage = (newPage) => {
    setState(({...state, pageNumber: newPage}))
  };
  const handleChangeRowsPerPage = (event) => {
    setLoading(true)
    setSort(event.target.value)
  };

  function clearFilter() {
    setState({
      ...state, filters: {
        id: "",
        status: "",
        type: "",
        site: "",
        client: "",
        supply: "",
        team: "",
        reception: "",
        intervention: "",
        devis: "",
        sort: "",
        maps: false
      }
    })
    setTyping(!typing)
  }



  return (
    <div className={"supplyTab"}>
      <Table onHandleChangeRowsPerPage={handleChangeRowsPerPage}
             loading={loading}
             onHandleChangePage={handleChangePage}
             onGetData={getSupply}
             onHandleColumns={handleColumns}
             onHandleFilter={handleFilter}
             rowCount={state.count}
             onEndTyping={setTyping}
             filter={state.filters}
             pageNumber={state.pageNumber}
             pageSize={state.pageSize}
             columns={state.columns}
             list={state.interventionList}
             onClearFilters={clearFilter}
             mapdisplay={true}
             tabName="Supply"
      >
        <Tooltip title={text.CSV}>
          <CSVLink {...csvReport}><img alt={"CSV"} src={CSVDl}/></CSVLink>
        </Tooltip>

      </Table>
    </div>
  )
}
