import React, {Fragment, useEffect, useState,} from "react";
import {IconButton, TextareaAutosize, Tooltip} from "@mui/material";
import constants, {text} from "../components/util/constants";
import IconSee from "../images/eye-see.png";
import IconNotSee from "../images/eye-no-see.png";
import IbatApi from "../utils/IbatApi";
import {useSnackbar} from "notistack";
import Modal from "react-responsive-modal";
import SearchSite from "../components/forms/SearchSite";
import SearchClient from "../components/forms/SearchClient";
import CustomImage from "../components/util/CustomImage";
import Select from "react-select";
import {getUID} from "../utils/Utils";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import IconMap from "../images/button-map.png";
import {v4} from "uuid";
import {useMediaQuery} from "react-responsive";


export default function QuotationForm({mQuotation, interventionId, refresh, devisId, site, supply, handleStatus, update, setPoints}){
    const [editMode, setEditMode] = useState(false)
    const [quotation, setQuotation] = useState(mQuotation)
    const [dest, setDest] = useState(false)
    const [updateMail, setUpdateMail] = useState(false)
    const [selectedDest, setSelectedDest] = useState([])
    const [addSection, setAddSection] = useState(false)
    const [operationType, setOperationType] = useState([])
    const [elementType, setElementType] = useState([])
    const [newSection, setNewSection] = useState({})
    const [families, setFamilies] = useState([])
    const [preciserFamilies, setPreciserFamilies] = useState([])
    const [productTypes, setProductTypes] = useState([])
    const [productList, setProductList] = useState()
    const [handleProduct, setHandleProduct] = useState(false)
    const [selectedProduct, setSelectedProduct] = useState({})
    const [catalogMode, setCatalogMode] = useState(false)
    const [carousel, setCarousel] = useState(false)
    const [previewPDF, setPreviewPDF] = useState(false)
    const [updating, setUpdating] = useState(false)
    const [rawMail, setRawMail] = useState("")
    const isMobile = useMediaQuery({ query: '(max-width: 915px)' })

    const nomenclatureTypeMapping = {
        install: "Pose",
        remove: "Dépose",
        substitution: "Remplacement",
        repair: "Réparation",
        paint: "Peinture",
        clean: "Nettoyage",
        check: "Contrôle",
        maintenance: "Maintenance",
        service: "Service administratif",
    }

    const gridColumn = [
        {name: "Article", classname: "product-name"},
        {name: "Prix d'achat HT"},
        {name: "Marge %"},
        {name: "Temps M.O (Heure)"},
        {name: "Prix de vente HT"},
        {name: "Quantité"},
        {name: "Unité"},
        {name: "Taux T.V.A"},
        {name:"Total H.T"}
    ]

    const tvaList = [
        {value: 0, label: "0%"},
        {value: 10, label: "10%"},
        {value: 20, label: "20%"}
    ]
    const mailTemplate = [
        {value: 16, label: "Particulier (assurances)"},
        {value: 17, label: "Particulier (sans assurances)"},
        {value: 18, label: "Grand compte"},
        {value: 19, label: "Bâchage/MHE avec nacelle"},
    ]
    const unitList = [
        {value: "m", label: "Mètre"},
        {value: "m²", label: "Mètre carré"},
        {value: "m3", label: "Mètre cube"},
        {value: "mL", label: "Mètre linéaire"},
        {value: "kg", label: "Kilogramme"},
        {value: "L", label: "Litre"},
        {value: "F", label: "Forfait"},
        {value: "H", label: "Heures"},
        {value: "J", label: "Jours"},
        {value: "U", label: "Unité"},
    ]


    const {enqueueSnackbar}=useSnackbar()
    const api = new IbatApi()
    useEffect(()=>{
        setQuotation(mQuotation)
        if (operationType.length === 0 && elementType.length === 0) {
            getNomenclatureData()
        }
        if (families.length === 0) {
            api.getCatalogFamilies()
                .then(r => setFamilies(r))
                .catch(e => console.log(e))
        }
        if (preciserFamilies.length === 0) {
            api.getCatalogPreciserFamilies()
                .then(r => setPreciserFamilies(r))
                .catch(e => console.log(e))
        }
        if (productTypes.length === 0) {
            api.getProductTypes()
                .then(r => setProductTypes(r))
                .catch(e => console.log(e))
        }
        if (previewPDF === 1) {
            setPreviewPDF(-1)
            api.previewQuotationPdf(mQuotation.odooId)
                .then(r => setPreviewPDF(r))
                .catch(()=>enqueueSnackbar("Une erreur est survenue lors de la prévisualisation du devis", {variant: "error"}))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mQuotation, selectedDest, supply])

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const items = reorder(
            quotation?.quotationGroup,
            result.source.index,
            result.destination.index
        ).map((qg, idx) => {
            return {...qg, position: idx}
        })
        setQuotation({...quotation, quotationGroup: items});
    }

    function mapReference(ref) {
        console.log(ref)
        let splittedRef = ref.match(/.{1,4}/g)
        let a = families.find(f => f.reference === splittedRef[0])?.name
        let b = preciserFamilies.find(f => f.reference === splittedRef[1])?.name
        let c = productTypes.find(f => f.reference === splittedRef[2])?.name

        return `${a ?? "?"} > ${b ?? "?"} > ${c ?? "?"}`
    }
    function handleInfoClients(e, qg_idx, lg_idx) {
        const qg = quotation.quotationGroup?.map((qg, idx) => { return {...qg, position: qg.position ?? idx}} ).sort((q1, q2) => q1.position > q2.position ? 1 : -1)
        let quotationGroup = [
            ...qg.slice(0, qg_idx),
            {
                ...qg[qg_idx],
                lineGroup: [
                    ...qg[qg_idx].lineGroup.slice(0, lg_idx),
                    {
                        ...qg[qg_idx].lineGroup[lg_idx],
                        clientInformation: e.target.value
                    },
                    ...qg[qg_idx].lineGroup.slice(lg_idx+1)
                ]
            },
            ...qg.slice(qg_idx+1)
        ]
        setQuotation({...quotation, quotationGroup: quotationGroup})
    }
    function handleClientTitle(e) {
        setQuotation({...quotation, clientTitle: e.target.value})
    }
    function removeQuotation(idx){
        let qg = quotation.quotationGroup?.map((qg, idx) => { return {...qg, position: qg.position ?? idx}} ).sort((q1, q2) => q1.position > q2.position ? 1 : -1)
        qg = [...qg.slice(0, idx), ...qg.slice(idx+1)]
        setQuotation({...quotation, quotationGroup: qg})
    }

    function removeLineGroup(qg_idx, idx) {
        const qg = quotation.quotationGroup?.map((qg, idx) => { return {...qg, position: qg.position ?? idx}} ).sort((q1, q2) => q1.position > q2.position ? 1 : -1)
        qg[qg_idx].lineGroup = [
            ...qg[qg_idx].lineGroup.slice(0, idx),
            ...qg[qg_idx].lineGroup.slice(idx+1)
        ]
        setQuotation({...quotation, quotationGroup: qg})
    }

    const saveNewPoint = (point) => {
        api.addPointToQuotationLine(point.lineGroupId, point)
            .then(() => {
                enqueueSnackbar("Le point a bien été ajouté", {variant: "success"})
                refresh()
            })
            .catch(() => enqueueSnackbar("Une erreur est survenue lors de l'ajout du point", {variant: "error"}))
        setPoints(false)
    }

    const saveQuotation = (editModeValue, forceRefresh, pdfPreview, q) => {
        update(quotation.quotationGroup, quotation.clientTitle)
        if (editModeValue){
            setEditMode(false)
        }
        if (updating) {
            return
        }
        setUpdating(true)
        api.updateQuotation(q ?? quotation, interventionId, devisId, mQuotation?.billEntity?.type === "COMPANY" ? 18 : 17)
            .then(() =>{
                if (pdfPreview) {
                    setPreviewPDF(1)
                }
                if (forceRefresh) {
                    refresh()
                }
                enqueueSnackbar("Sauvegardé avec succès", {variant: "success"})
                setUpdating(false)
            })
            .catch (e => {
                enqueueSnackbar('Erreur', {variant: "error"})
                setUpdating(false)
            })
    }
    const restartFromBegining = () => {
        enqueueSnackbar('Récupération des données en cours ...', {variant: "success"})
        api.getIntervention(interventionId)
            .then(r => {
                let q = []
                let points = r?.operations?.flatMap(op => {return {poi: op.metadata.points.filter(poi => poi.name === "Work").sort((a,b) => a.actionType > b.actionType ? 1 : -1), typeName: op.typeName, typeId: op.typeId}})
                points.forEach(point => {
                    let quotationLine = {}
                    quotationLine["typeName"] = point.typeName
                    quotationLine["typeId"] = point.typeId
                    quotationLine["lineGroup"] = []
                    point.poi.forEach(poi => {
                        let line = {}
                        line["actionType"] = poi.actionType
                        line["product"] = []
                        line["comment"] = poi.comment
                        poi.selectedProducts.forEach(sp => {
                            let p = {}
                            p["productName"] = sp.product.name
                            p["id"] = sp.product.id
                            p["productExternalId"] = sp.product.externalId
                            p["variantValue"] = sp.variantValue
                            p["ttva"] = 20
                            p["unit"] = "U"
                            p["qty"] = parseFloat(sp.qty ?? 0)
                            line["product"].push(p)
                        })
                        quotationLine.lineGroup.push(line)
                    })
                    q.push(quotationLine)
                })
                api.createQuotation({quotationGroup: q}, interventionId, devisId)
                    .then(r =>{
                        enqueueSnackbar('Brouillon créé', {variant: "success"})
                        window.open(`/quotation/${r}`, "_blank")
                    })
                    .catch (e => {
                        enqueueSnackbar('Erreur', {variant: "error"})
                    })
            })
            .catch (e => {
                enqueueSnackbar('Erreur', {variant: "error"})
            })
    }
    const converToDraft = () => {
        let newQ = quotation
        newQ.quotationGroup.forEach(qg => {
            delete qg.id
            qg.lineGroup.forEach(lg => {
                delete lg.id
            })
        })
        delete newQ.id
        delete newQ.odooId
        delete newQ.odooRef
        delete newQ.odooPdfId
        delete newQ.supply
        delete newQ.validityDate
        api.createQuotation(newQ, interventionId, devisId)
            .then(r =>{
                enqueueSnackbar('Brouillon créé', {variant: "success"})
                window.open(`/quotation/${r}`, "_blank")
            })
            .catch (e => {
                enqueueSnackbar('Erreur', {variant: "error"})
            })
    }

    const signQuotation = () => {
        let acompte = supply.elements.find(f =>  f.dataType === "AdminDocument" && f.type.name.includes("acompte"))
        let tvaAttest = supply.elements.find(f =>  f.dataType === "AdminDocument" && f.type.name.includes("Attestation TVA"))
        let order = supply.elements.find(f =>  f.dataType === "AdminDocument" && f.type.name.includes("Bon de commande client"))
        let alertMessage = "";
        let isBlockant = "";
        if (!acompte || !tvaAttest || !order){
            alertMessage += "Attention :\n"
            if (!acompte){
                alertMessage += "\tAucun acompte\n"
            }
            if (!tvaAttest){
                alertMessage += "\tAucune attestation de TVA\n"
            }
            if (!order){
                alertMessage += "\tLe devis signé n'est pas joint\n"
                isBlockant += "Vous devez joindre le devis signé.\n"
            }
        }
        if (
            (acompte && (!acompte.documents || acompte.documents.length === 0)) ||
            (tvaAttest && (!tvaAttest.documents || tvaAttest.documents.length === 0)) ||
            (order && (!order.documents || order.documents.length === 0))
        ){
            alertMessage += "Pièces jointes manquantes :\n"
            if (acompte && (!acompte.documents || acompte.documents.length === 0)){
                alertMessage += "\tAcompte\n"
                isBlockant += "Vous devez joindre l'acompte'.\n"
            }
            if (tvaAttest && (!tvaAttest.documents || tvaAttest.documents.length === 0)){
                alertMessage += "\tAttestation de TVA\n"
                isBlockant += "Vous devez joindre l'attestation de TVA'.\n"
            }
            if (order && (!order.documents || order.documents.length === 0)){
                alertMessage += "\tDevis signé\n"
                isBlockant += "Vous devez joindre le devis signé.\n"
            }
        }
        if (isBlockant) {
            alert(isBlockant)
            return;
        }
        if (alertMessage) {
            alertMessage += "Souhaitez-vous tout de même continuer ?"
            if(!window.confirm(alertMessage)) {
                return;
            }
        }
        api.signQuotation(quotation.id)
            .then(() => {
                enqueueSnackbar("Le devis a bien été envoyé", {variant: "success"})
                refresh()
            })
            .catch(() => enqueueSnackbar('Erreur', {variant: "error"}))
    }

    /*const handleStatus = (status) => {
        api.updateQuotationStatus(quotation.id, status)
            .then(r =>{
                enqueueSnackbar("Le status du devis a été mis à jour", {variant: "success"})
                refresh()
                setQuotation({...quotation, status: status})
                setEditMode(false)
            })
            .catch (e => {
                enqueueSnackbar('Erreur', {variant: "error"})
                console.log(e)
            })
    }*/
    const sendQuotation = (status) => {
        let sites = selectedDest.filter(sd => sd.target === "site").map(sd => {
            return {id: sd.id}
        })
        let clients = selectedDest.filter(sd => sd.target === "client").map(sd => {
            return {id: sd.id}
        })
        let rawEmail = selectedDest.filter(sd => sd.target === "raw").map(sd => sd.mail)
        let templateEmail = selectedDest.find(sd => sd.target === "emailType")
        let q = {...quotation, sites: sites,  clients: clients, rawEmailList: rawEmail, billEntity: {id: quotation.billEntity.id}}
        api.sendQuotation(q, interventionId, devisId, templateEmail.id)
            .then(() =>{
                enqueueSnackbar("Le devis a bien été envoyé", {variant: "success"})
                refresh()
                setDest(false)
                setQuotation({...quotation, status: status})
                setEditMode(false)
            })
            .catch (() => {
                enqueueSnackbar('Erreur', {variant: "error"})
            })
    }

    function updateProductList(productTypeName){
        api.getProductListByProductTypeName(productTypeName)
            .then(r => setProductList(r))
            .catch(e => console.log(e))
    }

    function removeSP(sp, qg_idx, lg_idx, row_idx) {
        let qg = quotation.quotationGroup
        if (sp.id) {
            qg[qg_idx].lineGroup[lg_idx].product = qg[qg_idx].lineGroup[lg_idx].product.filter(f => f.id !== sp.id)
        }
        else {
            qg[qg_idx].lineGroup[lg_idx].product = qg[qg_idx].lineGroup[lg_idx].product.filter(f => f.tmpId !== sp.tmpId)
        }
        setQuotation({...quotation, quotationGroup: qg})
        saveQuotation()
    }
    function updateSP(sp, key, value){
        let q = quotation
        q.quotationGroup.forEach(qg => {
            qg.lineGroup.forEach( lg => {
                lg.product.forEach(p => {
                    if ((sp.tmpId && p.tmpId === sp.tmpId) || (!sp.tmpId && p.id === sp.id && sp.productName === p.productName)) {
                        if (key === "hidden"){
                            p.hidden = p.hidden === undefined ? true : !p.hidden;
                        }
                        else {
                            if ((value !== p[key] && key !== "ttva") || (key==="ttva" && value !== "20") || key === "unit"){
                                p["m_"+key] = value;
                                if (key === "paht" && value) {
                                    if(!p.m_pvht || +p.m_pvht === 0){
                                        p.pvht = Number(value * (1 + .2)).toFixed(2)
                                        p.margin = 20
                                    }
                                    else if (+p.m_pvht > 0) {
                                        p.margin =
                                            Number(
                                                ((
                                                        Number((Number(sp.m_pvht) > 0) ? Number(sp.m_pvht) : (Number(sp.pvht) : 0))
                                                        /
                                                        Number((Number(sp.m_paht) > 0) ? Number(sp.m_paht) : (Number(sp.paht) : 1))
                                                    )
                                                    * 100)
                                                - 100
                                            ).toFixed(2) ??
                                            0
                                    }
                                }
                                if (key === "margin" && value) {
                                    p.pvht = Number((p.m_paht ?? p.paht) * (1 + (Number(value)/100))).toFixed(2)
                                    p.m_pvht = undefined
                                    p.margin = undefined
                                }
                                if (key === "pvht" && value) {
                                    p.m_margin = undefined
                                    p.margin =
                                            Number(
                                                ((
                                                            Number((Number(sp.m_pvht) > 0) ? Number(sp.m_pvht) : (Number(sp.pvht) : 0))
                                                            /
                                                            Number((Number(sp.m_paht) > 0) ? Number(sp.m_paht) : (Number(sp.paht) : 1))
                                                        )
                                                    * 100)
                                                - 100
                                            ).toFixed(2) ??
                                            0
                                }
                            }
                        }
                    }
                })
            })
        })
        setQuotation({...quotation, ...q})
        // saveQuotation(false, false, false, q)
    }

    function handleGlobaleVAT(vat) {
        let q = quotation
        q.quotationGroup.forEach(qg => {
            qg.lineGroup.forEach( lg => {
                lg.product.forEach(p => {
                    p["m_ttva"] = vat;
                })
            })
        })
        setQuotation({...quotation, ...q})
        saveQuotation(false)
    }


    function showLineGroup(lineGroup, qg_idx, idx) {
        return (
            <div className={"poi-chapter tertiary-section"} key={v4()}>

                {
                (quotation.status===constants.STATUS_DRAFT || editMode) &&
                <IconButton title={"Supprimer"} className={"action-btn cancel tr-corner delete-section delete-chapter"} onClick={()=> removeLineGroup(qg_idx, idx)}>
                    ✕
                </IconButton>
                }
            <div className={"grid except-addBtn bord1"}>

                {
                    (quotation.status===constants.STATUS_DRAFT || editMode) ?
                        <div className={"bord1 fs1 table-header"}>
                            <IconButton onClick={()=>setPoints({list: lineGroup?.point ? [lineGroup?.point] : [], addMode: !lineGroup?.point, onSave: saveNewPoint, lineGroupId: lineGroup.id})}>
                                <img alt={"img"} src={IconMap}/>
                            </IconButton>
                            Action
                        </div> :
                        <div className={"bord1 fs1 table-header"}>
                            <IconButton onClick={()=>setPoints({list: lineGroup?.point ? [lineGroup?.point] : [], addMode: false})}>
                                <img alt={"img"} src={IconMap}/>
                            </IconButton>
                        </div>
                }
                {
                    gridColumn.map((c, idx) =>
                        <div key={idx} className={`bord1 fs1 table-header flex-row ${c.classname ?? ""}`}>
                            {c.name}
                        </div>
                    )
                }

                {
                    lineGroup?.product?.map((sp,idxRow) =>
                    <Fragment key={idxRow}>
                        {
                            (quotation.status===constants.STATUS_DRAFT || editMode) ?
                                <div>
                                    <IconButton className={"action-btn cancel w2"} onClick={()=>{removeSP(sp, qg_idx, idx, idxRow)}}>
                                        ✕
                                    </IconButton>
                                    <IconButton onClick={()=>{updateSP(sp, "hidden")}}>
                                        <img alt={text.INVISIBLE} src={sp.hidden?IconNotSee:IconSee}/>
                                    </IconButton>
                                </div> :
                                <div></div>
                        }
                        <div className={"bord1 fs1 product-name flex-column"}>
                            {
                                sp?.productExternalId
                                    ?
                                    <div >{sp?.actionType ? `(${text[sp?.actionType]}) ` : ""} {sp?.productName} {sp.variantValue ?? ""}</div>
                                    :
                                    <div className={"as-link alert"} onClick={()=>{
                                        updateProductList(sp.productName)
                                        setHandleProduct({...sp, qg_idx: qg_idx, lg_idx: idx, row_idx: idxRow})
                                    }}>{sp?.productName}</div>
                            }
                        </div>
                        <div className={(sp.m_paht ? "manual-value " : "") + "discret"}>
                            <input
                                className={"large w100"}  type={"number"} defaultValue={sp.m_paht ?? (sp.paht ?? 0)}
                                    disabled={sp.hidden || (quotation.status!==constants.STATUS_DRAFT && !editMode)}
                                    placeholder={"Prix d'achat HT"}
                                    onBlur={(e) => {updateSP(sp, "paht", e.target.value)}}
                            />
                        </div>
                        <div className={(sp.m_paht ? "manual-value " : "") + "discret"}>
                            <input  type={"number"}
                                    className={"large w100"}
                                    value={sp.m_margin ?? (sp.margin ??
                                        ((typeof sp.m_paht === "number" && sp.m_paht > 0) || (typeof sp.paht === "number" && sp.paht > 0 )?
                                            Number(
                                                (
                                                    Number((typeof sp.m_pvht === "number" && sp.m_pvht > 0) ? sp.m_pvht : ((typeof sp.pvht === "number" && sp.pvht > 0) ? sp.pvht : 0))
                                                    /
                                                    Number((typeof sp.m_paht === "number" && sp.m_paht > 0) ? sp.m_paht : ((typeof sp.paht === "number" && sp.paht > 0) ? sp.paht : 0))
                                                    *
                                                    100
                                                )
                                                -
                                                100
                                            ).toFixed(2) :
                                            0))
                                    }
                                    onChange={e => updateSP(sp, "margin", e.target.value)}
                                    onBlur={() => saveQuotation()}
                                    disabled={!sp?.productName?.includes("Main d'oeuvre") || sp.hidden || (quotation.status!==constants.STATUS_DRAFT && !editMode)}/>
                        </div>
                        <div className={sp.m_pvht ? "manual-value" : ""}>
                            <input
                                className={"large w100"} type={"number"} defaultValue={sp.m_workforceTime ?? (sp.workforceTime ?? 0)}
                                   disabled={sp.hidden || (quotation.status!==constants.STATUS_DRAFT && !editMode)}
                                   placeholder={"Temps de main d'oeuvre"}
                                   onBlur={(e) => updateSP(sp, "workforceTime", e.target.value)}
                            />
                        </div>
                        <div className={sp.m_pvht ? "manual-value" : ""}>
                            <input
                                className={"large w100"} type={"number"} defaultValue={sp.m_pvht ?? (sp.pvht ?? 0)}
                                   disabled={sp.hidden || (quotation.status!==constants.STATUS_DRAFT && !editMode)}
                                   placeholder={"Prix de vente HT"}
                                   onBlur={(e) => updateSP(sp, "pvht", e.target.value)}
                            />
                        </div>
                        <div className={sp.m_qty ? "manual-value" : ""}>
                            <input
                                className={"large w100"} type={"number"}
                                   disabled={sp.hidden || (quotation.status!==constants.STATUS_DRAFT && !editMode)}
                                   defaultValue={sp.m_qty ?? (sp.qty ?? "")}
                                   placeholder={sp.qty}
                                   onBlur={(e) => updateSP(sp, "qty", e.target.value)}
                            />
                        </div>
                        <div className={sp.m_unit ? "manual-value" : ""}>
                            <Select
                                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                menuPortalTarget={document.body}
                                placeHolder = {"10%, 20%, ..."}
                                disabled={sp.hidden || (quotation.status!==constants.STATUS_DRAFT && !editMode)}
                                options={unitList}
                                defaultValue={unitList.filter(f => f.value === (sp.m_unit ?? (sp.unit ?? "U")))[0]}
                                onChange = {(e) => updateSP(sp, "unit", e.value)}
                                onBlur={() => saveQuotation()}
                                className={"selectable"}
                                classNames={{control:(base) => "h100"}}
                            />
                        </div>
                        <div className={sp.m_ttva ? "manual-value" : ""}>
                            <Select
                                placeHolder = {"10%, 20%, ..."}
                                disabled={sp.hidden || (quotation.status!==constants.STATUS_DRAFT && !editMode)}
                                options={tvaList}
                                value={tvaList.filter(f => f.value === (sp.m_ttva ?? (sp.ttva ?? 20)))[0]}
                                onChange = {(e) => updateSP(sp, "ttva", e.value)}
                                onBlur={() => saveQuotation()}
                                className={"selectable"}
                                classNames={{control:(base) => "h100"}}
                            />
                        </div>
                        <div className={"flex-column"}>
                            {Number(Number(sp.m_pvht ?? (sp.pvht ?? 0 )) * Number(sp.m_qty ?? (sp.qty ?? 0 ))).toFixed(2)}
                        </div>
                    </Fragment>
                    )
                }</div>
                {
                    (quotation?.status === constants.STATUS_DRAFT || editMode) &&
                    <div className={"w100 br05 bord1"}><div className={"elmt-link add-product bord1"} onClick={() => {
                        setCatalogMode({qg_idx: qg_idx, lg_idx: idx})
                    }}>+ Ajouter un produit</div></div>
                }
                <div className={"flex-column comments primary-section"}>
                    {
                        lineGroup.comment &&
                        <div className={"comments primary-section"}>
                            <b><u>Commentaire équipe:</u></b>
                            <div className={"comment"}>
                                {lineGroup.comment}
                            </div>
                        </div>
                    }
                    <div className={"flex-row"}>
                        <Tooltip title={"Informations client"}>
                            <b className="clientinfo-title">ℹ</b>
                        </Tooltip>
                        <TextareaAutosize className={"large w100 clientinfoTextArea-section"} defaultValue={lineGroup?.clientInformation ?? ""}
                                      onBlur={(e) => handleInfoClients(e, qg_idx, idx)}
                        />
                    </div>
                </div>
            </div>
        )
    }
    function showLineGroupForMobile(lineGroup, qg_idx, idx) {
        return (
            <div className={"poi-chapter bord1"} key={v4()}>
                {
                    (quotation.status===constants.STATUS_DRAFT || editMode) &&
                    <IconButton title={"Supprimer"} className={"action-btn cancel tr-corner delete-section delete-chapter"} onClick={()=> removeLineGroup(qg_idx, idx)}>
                        ✕
                    </IconButton>
                }

                <div className={"product-list"}>
                    {
                        lineGroup?.product?.map((sp,idxRow) =>
                        <div className={"product-line"} key={idxRow}>
                            <div className={"bord1 fs1 product-name flex-row"}>
                                {
                                    (quotation.status===constants.STATUS_DRAFT || editMode) ?
                                        <div className={"flex-row"}>
                                            <IconButton className={"action-btn cancel w2"} onClick={()=>{removeSP(sp, qg_idx, idx, idxRow)}}>
                                                ✕
                                            </IconButton>
                                            <IconButton onClick={()=>{updateSP(sp, "hidden")}}>
                                                <img alt={text.INVISIBLE} src={sp.hidden?IconNotSee:IconSee}/>
                                            </IconButton>
                                        </div> :
                                        <div></div>
                                }
                                {
                                    sp?.productExternalId
                                        ?
                                        <div className={"bord1"}>{sp?.actionType ? `(${text[sp?.actionType]}) ` : ""} {sp?.productName} {sp.variantValue ?? ""}</div>
                                        :
                                        <div className={"as-link alert"} onClick={()=>{
                                            updateProductList(sp.productName)
                                            setHandleProduct({...sp, qg_idx: qg_idx, lg_idx: idx, row_idx: idxRow})
                                        }}>{sp?.productName}</div>
                                }
                            </div>

                            <div className={(sp.m_paht ? "manual-value " : "") + "discret"} title={"Prix d'achat HT"}>
                                <input
                                    className={"large w100"}  type={"number"} defaultValue={sp.m_paht ?? (sp.paht ?? 0)}
                                        disabled={sp.hidden || (quotation.status!==constants.STATUS_DRAFT && !editMode)}
                                        placeholder={"Prix d'achat HT"}
                                        onBlur={(e) => {updateSP(sp, "paht", e.target.value)}}
                                />
                            </div>
                            <div className={(sp.m_paht ? "manual-value " : "") + "discret"} title={"Marge %"}>
                                <input  type={"number"}
                                        className={"large w100"}
                                        value={sp.m_margin ?? (sp.margin ??
                                            ((typeof sp.m_paht === "number" && sp.m_paht > 0) || (typeof sp.paht === "number" && sp.paht > 0 )?
                                                Number(
                                                    (
                                                        Number((typeof sp.m_pvht === "number" && sp.m_pvht > 0) ? sp.m_pvht : ((typeof sp.pvht === "number" && sp.pvht > 0) ? sp.pvht : 0))
                                                        /
                                                        Number((typeof sp.m_paht === "number" && sp.m_paht > 0) ? sp.m_paht : ((typeof sp.paht === "number" && sp.paht > 0) ? sp.paht : 0))
                                                        *
                                                        100
                                                    )
                                                    -
                                                    100
                                                ).toFixed(2) :
                                                0))
                                        }
                                        onChange={e => updateSP(sp, "margin", e.target.value)}
                                        onBlur={() => saveQuotation()}
                                        disabled={!sp?.productName?.includes("Main d'oeuvre") || sp.hidden || (quotation.status!==constants.STATUS_DRAFT && !editMode)}/>
                            </div>
                            <div className={sp.m_pvht ? "manual-value" : ""} title={"Temps M.O (Heure)"}>
                                <input
                                    className={"large w100"} type={"number"} defaultValue={sp.m_workforceTime ?? (sp.workforceTime ?? 0)}
                                       disabled={sp.hidden || (quotation.status!==constants.STATUS_DRAFT && !editMode)}
                                       placeholder={"Temps de main d'oeuvre"}
                                       onBlur={(e) => updateSP(sp, "workforceTime", e.target.value)}
                                />
                            </div>
                            <div className={sp.m_pvht ? "manual-value" : ""} title={"Prix de vente HT"}>
                                <input
                                    className={"large w100"} type={"number"} defaultValue={sp.m_pvht ?? (sp.pvht ?? 0)}
                                       disabled={sp.hidden || (quotation.status!==constants.STATUS_DRAFT && !editMode)}
                                       placeholder={"Prix de vente HT"}
                                       onBlur={(e) => updateSP(sp, "pvht", e.target.value)}
                                />
                            </div>
                            <div className={sp.m_qty ? "manual-value" : ""} title={"Quantité"}>
                                <input
                                    className={"large w100"} type={"number"}
                                       disabled={sp.hidden || (quotation.status!==constants.STATUS_DRAFT && !editMode)}
                                       defaultValue={sp.m_qty ?? (sp.qty ?? "")}
                                       placeholder={sp.qty}
                                       onBlur={(e) => updateSP(sp, "qty", e.target.value)}
                                />
                            </div>
                            <div className={sp.m_unit ? "manual-value" : ""} title={"Unité"}>
                                <Select
                                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                    menuPortalTarget={document.body}
                                    placeHolder = {"10%, 20%, ..."}
                                    disabled={sp.hidden || (quotation.status!==constants.STATUS_DRAFT && !editMode)}
                                    options={unitList}
                                    defaultValue={unitList.filter(f => f.value === (sp.m_unit ?? (sp.unit ?? "U")))[0]}
                                    onChange = {(e) => updateSP(sp, "unit", e.value)}
                                    onBlur={() => saveQuotation()}
                                    className={"selectable"}
                                    classNames={{control:(base) => "h100"}}
                                />
                            </div>
                            <div className={sp.m_ttva ? "manual-value" : ""} title={"Taux T.V.A"}>
                                <Select
                                    placeHolder = {"10%, 20%, ..."}
                                    disabled={sp.hidden || (quotation.status!==constants.STATUS_DRAFT && !editMode)}
                                    options={tvaList}
                                    value={tvaList.filter(f => f.value === (sp.m_ttva ?? (sp.ttva ?? 20)))[0]}
                                    onChange = {(e) => updateSP(sp, "ttva", e.value)}
                                    onBlur={() => saveQuotation()}
                                    className={"selectable"}
                                    classNames={{control:(base) => "h100"}}
                                />
                            </div>
                            <div title={"Total H.T"}>
                                <input
                                    className={"large w100"} type={"number"}
                                    disabled={true}
                                    defaultValue={Number(Number(sp.m_pvht ?? (sp.pvht ?? 0)) * Number(sp.m_qty ?? (sp.qty ?? 0))).toFixed(2)}
                                    placeholder={sp.qty}
                                    onBlur={(e) => updateSP(sp, "qty", e.target.value)}
                                />
                            </div>
                        </div>
                        )
                    }
                </div>
                {
                    (quotation?.status === constants.STATUS_DRAFT || editMode) &&
                    <div className={"elmt-link add-section bord1 flex-column"} onClick={() => {
                        setCatalogMode({qg_idx: qg_idx, lg_idx: idx})
                    }}>+ Ajouter un produit</div>
                }
                <div className={"flex-column comments primary-section"}>
                {
                        lineGroup.comment &&
                        <div className={"comments primary-section"}>
                            <b><u>Commentaire équipe:</u></b>
                            <div className={"comment"}>
                                {lineGroup.comment}
                            </div>
                        </div>
                    }
                    <div className={"flex-row"}>
                        <Tooltip title={"Informations client"}>
                            <b className="clientinfo-title">ℹ</b>
                        </Tooltip>
                        <TextareaAutosize className={"large w100 clientinfoTextArea-section"} defaultValue={lineGroup?.clientInformation ?? ""}
                                      onBlur={(e) => handleInfoClients(e, qg_idx, idx)}
                        />
                    </div>
                </div>
            </div>
        )
    }

    function showQuotationGroup(quotationSection, qg_idx){
        return (
            <Draggable key={quotationSection.id} draggableId={""+quotationSection.id} index={qg_idx}>
                {(provided, snapshot) => (
                    <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        className={"draggable-block"}
                    >
                        <div className={"flex-column"}>
                            <Tooltip title={"Déplacer la section dans le devis"}>
                                <div
                                className={"draggable-icon tl-corner"} {...provided.dragHandleProps}>
                                    <span className="anchor-icon">
                                    <span className="br05 as-link"/>
                                    <span className="br05 as-link"/>
                                </span>
                            </div></Tooltip>
                            <div key={qg_idx} className={"flex-column chapter secondary-section w100"}>
                                {
                                    (quotation.status === constants.STATUS_DRAFT || editMode) &&
                                    <IconButton title={"Supprimer section"}
                                                className={"action-btn cancel delete-chapter"}
                                                onClick={() => removeQuotation(qg_idx)}>
                                        ✕
                                    </IconButton>
                                }
                                <div className={"chapter-title flex-row auto-line secondary-section"}>
                                    <IconButton onClick={() => setPoints({
                                            list: [...quotationSection?.lineGroup?.flatMap(lg => lg.point)] ?? [],
                                            addMode: false
                                        })}>
                                        <img alt={"img"} src={IconMap}/>
                                    </IconButton>
                                    {quotationSection.typeName}
                                </div>
                                <ul>
                                    {
                                        quotationSection?.lineGroup?.map((p, idx) => isMobile ? showLineGroupForMobile(p, qg_idx, idx) : showLineGroup(p, qg_idx, idx))
                                    }
                                </ul>
                            </div>
                            {
                                (quotation?.status === constants.STATUS_DRAFT || editMode) &&
                                <div className={"elmt-link add-section bord1"} onClick={() => {
                                    setAddSection("intervention")
                                    setNewSection({...newSection, idx: qg_idx + 1})
                                }}>+ Nouvelle section</div>
                            }
                        </div>
                    </div>
                )}
            </Draggable>
        )
    }

    const addSelectedDest = (d, target) => {
        if (target !== "raw" && !selectedDest.find(sd => sd.id === d.id)) {
            setSelectedDest([...selectedDest, {...d, target: target}])
        }
        if (target === "raw") {
            let data = {mail: d, target: target}
            setSelectedDest([...selectedDest, data])
            setRawMail("")
        }
        if (target === "emailType"){
            setSelectedDest([...selectedDest.filter(f => f.target !== "emailType"), {...d, target: target}])
        }
    }
    const removeSelectedDest = (id, target) => {
        let d = []
        for (let sdN in selectedDest) {
            let sd = selectedDest[sdN]
            if ((sd.id === id || sd.mail === id) && sd.target === target) {
                continue;
            }
            d.push(sd)
        }
        setSelectedDest(d)
    }
    const updateMailSelectedDest = (updateMail) => {
        let d = []
        for (let sdN in selectedDest) {
            let sd = selectedDest[sdN]
            if (sd.id === updateMail.id && sd.target === updateMail.target) {
                sd.mail = updateMail.mail
            }
            d.push(sd)
        }
        setSelectedDest(d)
    }

    const handleUpdateMail = () => {

        api.updateSiteClientMail(updateMail.id, updateMail.mail, updateMail.target)
            .then(() => {
                enqueueSnackbar("@Mail mise à jour", {variant: "success"})
                updateMailSelectedDest(updateMail)
                setUpdateMail(false)
            })
            .catch (() => {
                enqueueSnackbar('Erreur', {variant: "error"})
            })
    }
    const handleNewSection = () => {
        let pList = newSection.ot.nomenclature?.mandatory ?? []
        newSection.ot.nomenclature?.optional && pList.push(...newSection.ot.nomenclature?.optional)
        // pList.push(...newSection.ot.nomenclature?.mandatory?.filter(f => f.includes(newSection.et.id)).map(ref => newSection.et.nomenclature).flatMap(ref => ref))
        // pList.push(...newSection.ot.nomenclature?.optional?.filter(f => f.includes(newSection.et.id)).map(ref => newSection.et.nomenclature).flatMap(ref => ref))
        console.log(pList)
        pList = pList.map(ref => mapReference(ref).split(" > ")[2]).filter(f => f!== "?").map(p => {return {
            m_paht: null, m_pvht: null, m_qty: null, m_ttva: null, paht: null, productExternalId: selectedProduct.id,
            productName: p, pvht: null, qty: 0, ttva: 20, unit: "U", variantValue: null
        }})
        let currentQG = quotation.quotationGroup.find(qg => qg.typeName === newSection.ot.name)
        let q
        if (currentQG){
            currentQG.lineGroup.push({product: pList})
            let qg = quotation.quotationGroup
                ?.map((qg, idx) => { return {...qg, position: qg.position ?? idx}} )
                .sort((q1, q2) => q1.position > q2.position ? 1 : -1)
                .filter(f => f.typeName !== newSection.ot.name)
            qg = [...qg.slice(0, newSection.idx), currentQG, ...qg.slice(newSection.idx)]
            q = {...quotation, quotationGroup: qg}
            setQuotation(q)
        }
        else {
            let qg = quotation.quotationGroup?.map((qg, idx) => { return {...qg, position: qg.position ?? idx}} ).sort((q1, q2) => q1.position > q2.position ? 1 : -1)
            qg = [...qg.slice(0, newSection.idx), {typeName: newSection.ot.name, typeId: newSection.ot.id, lineGroup: [{product: pList}]}, ...qg.slice(newSection.idx)]
            q = {...quotation, quotationGroup: qg}
            setQuotation(q)
        }
        setNewSection({})
        setAddSection(false)
        saveQuotation(false, true, false, q)
    }
    const handleNewAdminSection = () => {
        let p = {
            m_paht: null, m_pvht: null, m_qty: null, m_ttva: null, paht: null, productExternalId: newSection.product.id,
            productName: newSection.product.name, pvht: null, qty: 0, ttva: 20, unit: null, variantValue: null
        }
        let currentQG = quotation.quotationGroup.find(qg => qg.typeName === newSection.pt.name)
        if (currentQG){
            currentQG.lineGroup.push({actionType:"service", product: [p]})
            let qg = quotation.quotationGroup.filter(f => f.typeName !== newSection.pt.name)?.map((qg, idx) => { return {...qg, position: qg.position ?? idx}} ).sort((q1, q2) => q1.position > q2.position ? 1 : -1)
            qg = [...qg.slice(0, newSection.idx), currentQG, ...qg.slice(newSection.idx)]
            setQuotation({...quotation, quotationGroup: qg})
        }
        else {
            let qg = quotation.quotationGroup?.map((qg, idx) => { return {...qg, position: qg.position ?? idx}} ).sort((q1, q2) => q1.position > q2.position ? 1 : -1)
            qg = [...qg.slice(0, newSection.idx), {typeName: newSection.pt.name, typeId: newSection.pt.id, lineGroup: [{actionType:"service", product: [p]}]}, ...qg.slice(newSection.idx)]
            setQuotation({...quotation, quotationGroup: qg})
        }
        setNewSection({})
        setAddSection(false)
    }

    const handleNewProduct = () => {
        const qg = quotation.quotationGroup?.map((qg, idx) => { return {...qg, position: qg.position ?? idx}} ).sort((q1, q2) => q1.position > q2.position ? 1 : -1)
        let quotationGroup = [
                                ...qg.slice(0, handleProduct.qg_idx),
                                {
                                    ...qg[handleProduct.qg_idx],
                                    lineGroup: [
                                        ...qg[handleProduct.qg_idx].lineGroup.slice(0, handleProduct.lg_idx),
                                        {
                                            ...qg[handleProduct.qg_idx].lineGroup[handleProduct.lg_idx],
                                            product: [
                                                ...qg[handleProduct.qg_idx].lineGroup[handleProduct.lg_idx].product.slice(0, handleProduct.row_idx),
                                                {
                                                    ...qg[handleProduct.qg_idx].lineGroup[handleProduct.lg_idx].product[handleProduct.row_idx],
                                                    productExternalId: selectedProduct.id, variantValue: selectedProduct.variant.value
                                                },
                                                ...qg[handleProduct.qg_idx].lineGroup[handleProduct.lg_idx].product.slice(handleProduct.row_idx+1)
                                            ]
                                        },
                                        ...qg[handleProduct.qg_idx].lineGroup.slice(handleProduct.lg_idx+1)
                                    ]
                                },
                                ...qg.slice(handleProduct.qg_idx+1)
                            ]

        setQuotation({...quotation, quotationGroup: quotationGroup})
        setHandleProduct(false)
        setSelectedProduct({})

    }
    const addFreeProduct = () => {
        const qg = quotation.quotationGroup?.map((qg, idx) => { return {...qg, position: qg.position ?? idx}} ).sort((q1, q2) => q1.position > q2.position ? 1 : -1)
        let quotationGroup = [
                                ...qg.slice(0, catalogMode.qg_idx),
                                {
                                    ...qg[catalogMode.qg_idx],
                                    lineGroup: [
                                        ...qg[catalogMode.qg_idx].lineGroup.slice(0, catalogMode.lg_idx),
                                        {
                                            ...qg[catalogMode.qg_idx].lineGroup[catalogMode.lg_idx],
                                            product: [
                                                ...qg[catalogMode.qg_idx].lineGroup[catalogMode.lg_idx].product,
                                                {
                                                    tmpId: getUID(), m_paht: null, m_pvht: null, m_qty: null, m_ttva: null, paht: null, productExternalId: selectedProduct.id,
                                                    productName: selectedProduct.name, pvht: null, qty: 0, ttva: 20, unit: "U", variantValue: selectedProduct.variant.value,
                                                    m_workforceTime: 0, workforceTime: 0
                                                }
                                            ]
                                        },
                                        ...qg[catalogMode.qg_idx].lineGroup.slice(catalogMode.lg_idx+1)
                                    ]
                                },
                                ...qg.slice(catalogMode.qg_idx+1)
                            ]
        setQuotation({...quotation, quotationGroup: quotationGroup})
        setCatalogMode(false)
        setSelectedProduct(false)
    }

    function getNomenclatureData(){
        api.getCompany()
            .then(myCompany => {
                api.getElementType()
                    .then(r =>{
                        setElementType(
                            r
                            .filter(et => !myCompany.jobType || (et.new_jobtype && et.new_jobtype.some(jt => myCompany.jobType?.includes(jt))) || (et.jobtype && et.jobtype.some(jt => myCompany.jobType?.includes(jt))))
                            .sort((a, b) => a.name>b.name ? 1 : -1)
                        )
                    })
                    .catch(e => console.log(e))
                api.getOperationType()
                    .then(r =>{
                        setOperationType(
                            r
                            .filter(ot => !myCompany.jobType || (ot.new_jobtype && ot.new_jobtype.some(jt => myCompany.jobType?.includes(jt))) || (ot.jobtype && ot.jobtype.some(jt => myCompany.jobType?.includes(jt))))
                            .sort((a, b) => a.name>b.name ? 1 : -1)
                        )
                    })
                    .catch(e => console.log(e))
            })
            .catch(e => console.log(e))
    }
    const showProduct = (p, idx) => {
        return (
            <div key={idx} onClick={() => setSelectedProduct({...p, variant: selectedProduct?.id === p.id ? (selectedProduct?.variant ?? p.variants[0]) : p.variants[0]})} className={`primary-section${selectedProduct.id === p.id ? " selected" : ""}`}>
                {
                    (p.variants.pictures && p.variants.pictures?.length > 0) &&
                    <CustomImage name={selectedProduct?.id === p.id ? (selectedProduct?.variant.pictures[0] ?? p.variants[0].pictures[0]) : p.variants[0].pictures[0]} onClick={e=>{
                        e.stopPropagation()
                        setCarousel({current: 0, pictures: (selectedProduct?.id === p.id ? (selectedProduct?.variant.pictures ?? p.variants[0].pictures) : p.variants[0].pictures)})
                    }} />
                }
                <h1>{p.name} "{selectedProduct?.id === p.id ? (selectedProduct?.variant.value ?? p.variants[0].value) : p.variants[0].value}"</h1>
                <ul>
                    {
                        Object.keys(p.caracteristics).map(k =>
                            <li key={v4()}>
                                <h2>{k} : {Object.keys(p.caracteristics[k]).map(c => `${p.caracteristics[k][c]} ${c}`)}</h2>
                            </li>
                        )
                    }
                </ul>
                <h2>Variantes de ce produit :</h2>
                <ul>
                    {
                        p.variants
                        .filter(f => ((!selectedProduct?.variant || selectedProduct?.id !== p.id) && f.value !== p.variants[0].value) || ((selectedProduct?.id === p.id) && f.value !== selectedProduct.variant.value))
                        .map(v => <li key={v4()} onClick={(e) => {
                            e.stopPropagation()
                            setSelectedProduct({...p, variant:v})
                        }}><div className={"as-link"}>{v.name} : {v.value}</div></li>)
                    }
                </ul>
            </div>
        )
    }

    return (
        <div className={"quotation w100 flex-column primary-section"}>

            <div className={"client-title secondary-section flex-column w100"}>
                <h2>Titre du devis</h2>
                <input className={"large w100"} type={"text"} placeholder={"Devis suite intervention du ..."} value={quotation?.clientTitle ?? ""} onChange={e => handleClientTitle(e)} onBlur={saveQuotation}/>
                {
                    (quotation?.status===constants.STATUS_DRAFT || editMode) &&
                        <>
                            <div className={"primary-section"}><h2>Appliquer TVA à :</h2>
                            <div className={"flex-row auto-line"}>
                                <div className={"elmt-link bord1"} onClick={() => {
                                    handleGlobaleVAT(0.0)
                                }}>0%</div>
                                <div className={"elmt-link bord1"} onClick={() => {
                                    handleGlobaleVAT(10.0)
                                }}>10%</div>
                                <div className={"elmt-link bord1"} onClick={() => {
                                    handleGlobaleVAT(20.0)
                                }}>20%</div>
                            </div>
                            </div>
                        </>
                }
            </div>

            {
                (quotation?.status===constants.STATUS_DRAFT || editMode) &&
                <div className={"bord1 add-section elmt-link"} onClick={() => {                    setAddSection("intervention")
                    setNewSection({...newSection, idx: 0})
                }}>+ Nouvelle section</div>
            }
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId={"droppable"}>
                    {(provided, snapshot) => (
                    <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        className={"flex-column w100 bord1"}
                    >
                        {
                            quotation &&
                            quotation?.quotationGroup?.map((qg, idx) => { return {...qg, position: qg.position ?? idx}} ).sort((q1, q2) => q1.position > q2.position ? 1 : -1).map((p,idx) =>
                                showQuotationGroup(p,idx)
                            )
                        }
                        {provided.placeholder}
                    </div>
                    )}
                </Droppable>
            </DragDropContext>

        <div className={"button-bar flex-column"}>
            <div className={"flex-row auto-line"}>
                {
                    (quotation && (quotation.status===constants.STATUS_DRAFT || quotation.status===constants.STATUS_INTERNAL_VALIDATION || quotation.status===constants.STATUS_ACCOUNTANT_VALIDATION || quotation.status===constants.TO_SEND) && !editMode) &&
                    <IconButton disabled={previewPDF} className={"action-btn neutral"} onClick={() => {
                        saveQuotation(editMode, true, true)
                    }}>👁 {text.PREVIEW}</IconButton>
                }
                {
                    (quotation && (quotation.status===constants.STATUS_DRAFT || editMode)) &&
                    <IconButton className={"action-btn neutral"} onClick={() => saveQuotation(true)}>💾 {text.SAVE}</IconButton>
                }
                {
                    (quotation && (quotation.status===constants.STATUS_INTERNAL_VALIDATION || quotation.status===constants.STATUS_ACCOUNTANT_VALIDATION) && !editMode) &&
                    <IconButton className={"action-btn neutral"} onClick={() => setEditMode(true)}>✏ {text.EDIT}</IconButton>
                }
                <IconButton className={"action-btn neutral"} onClick={() => restartFromBegining(false)}>🔄 {text.RESTART_QUOTATION}</IconButton>
                <IconButton className={"action-btn neutral"} onClick={() => converToDraft(true)}>🔂 {text.DUPLICATE_IN_DRAFT}</IconButton>
            </div>
            <div className={"flex-row auto-line"}>
                {
                    (quotation && quotation.status===constants.STATUS_DRAFT) &&
                    <IconButton className={"action-btn validation"} onClick={() => handleStatus(constants.STATUS_INTERNAL_VALIDATION)}>☑ {text.SEND_TO_INTERNAL_VALIDATION}</IconButton>
                }
                {
                    (quotation && quotation.status===constants.STATUS_INTERNAL_VALIDATION) &&
                    <IconButton className={"action-btn validation"} onClick={() => handleStatus(constants.STATUS_ACCOUNTANT_VALIDATION)}>📤 {text.SEND_TO_ACCOUNTANT_VALIDATION}</IconButton>
                }
                {
                    (quotation && (quotation.status===constants.STATUS_DRAFT || quotation.status===constants.STATUS_INTERNAL_VALIDATION || quotation.status===constants.STATUS_ACCOUNTANT_VALIDATION)) &&
                    <IconButton className={"action-btn validation"} onClick={() => handleStatus(constants.TO_SEND)}>📤 {text.TO_SEND}</IconButton>
                }
                {
                    (quotation && (quotation.status===constants.STATUS_DRAFT || quotation.status===constants.STATUS_INTERNAL_VALIDATION || quotation.status===constants.STATUS_ACCOUNTANT_VALIDATION || quotation.status===constants.TO_SEND)) &&
                    <IconButton className={"action-btn validation"} onClick={() =>setDest(true)}>📤 {text.SEND_TO_CLIENT}</IconButton>
                }
                {
                    quotation && quotation.status!==constants.STATUS_SIGNED && quotation.status!==constants.STATUS_READY &&
                    <IconButton className={"action-btn validation"} onClick={() => {
                    signQuotation()
                    // handleStatus(constants.STATUS_SIGNED)
                }}>✒ {text.SIGNED_CLIENT}</IconButton>
                }
            </div>
        </div>


            <Modal open={dest} onClose={() => setDest(false)} >
                <div className={"quotation-form grid h70vh"}>
                    <h2>À qui souhaitez-vous envoyer le devis ?</h2>
                    <div className={"secondary-section flex-row"}>
                        Entités présentes sur le devis :
                        <div className={" bord1 of-auto w50 flex-row"}><div className={"flex-row bord1"}>
                            <input type={"checkbox"} onChange={(e) => {
                                if (e.target.checked) {
                                    addSelectedDest(mQuotation.billEntity, "client")
                                } else {
                                    removeSelectedDest(mQuotation.billEntity.id, "client")
                                }
                            }}/>
                            <label>Adresse de facturation <b>({mQuotation.billEntity.name})</b></label>
                        </div>
                        <div className={"flex-row bord1"}>
                            <input type={"checkbox"} onChange={(e) => {
                                if (e.target.checked) {
                                    addSelectedDest(site, "site")
                                } else {
                                    removeSelectedDest(site.id, "site")
                                }
                            }}/>
                            <label>Adresse de livraison <b>({mQuotation?.deliveryEntity?.name ??  (site?.name ?? "")})</b></label>
                        </div>
                        </div>
                    </div>
                    <div className={"secondary-section"}>
                        Envoyer à un ou plusieurs tier(s) :
                    <div className={"bord1 w100 flex-row"}>
                        <SearchSite selectMultiDest={true} onSave={(d)=>addSelectedDest(d, "site")}/>
                        <SearchClient selectMultiDest={true}  onSave={(d)=>addSelectedDest(d, "client")}/>
                    </div>
                    </div>
                    <div className={"secondary-section flex-row"}>
                        Ajouter une adresse mail :
                        <div className={"bord1 flex-row"}><input className={"w100 large"} value={rawMail} type={"text"} onChange={(e) => setRawMail(e.target.value)}/>
                        <IconButton className={"action-btn validation"} onClick={()=>{
                            addSelectedDest(rawMail, "raw")
                        }}>Ajouter</IconButton></div>
                        <div className={"bord1 flex-row"}>
                        Type d'adresse mail :
                        <Select
                            placeHolder = {"Grand compte"}
                            menuPortalTarget={document.body}
                            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999, color: '#000' }) }}
                            options={mailTemplate}
                            onChange = {(d)=>addSelectedDest({id: d.value}, "emailType")}
                            className={"selectable w100"}
                        />
                    </div>
                    </div>




                    {
                        selectedDest.filter(d => d.target === "site").length > 0 &&
                        <div className={"tertiary-section"}>
                            Sites :
                            <div className={"tertiary-section grid of-auto h10vh"}>
                                {
                                selectedDest.filter(d => d.target === "site").map(d => <div key={v4()} className={"justLeft flex-row bord1"}>
                                    <IconButton className={"action-btn cancel w2 remove-line"} onClick={()=>removeSelectedDest(d.id, "site")}>✕</IconButton>
                                    {
                                        d.contactMail ? `${d.name} (${d.contactMail})` : <div className={"quotation alert"} onClick={()=>setUpdateMail(d)}>{d.name} </div>
                                    }
                                </div>)
                            }
                            </div>
                        </div>
                    }
                    {
                        selectedDest.filter(d => d.target === "client").length > 0 &&
                        <div className={"tertiary-section"}>
                        Clients :
                        <div className={"tertiary-section bord1 of-auto grid h10vh"}> {
                            selectedDest.filter(d => d.target === "client").map(d => <div key={v4()} className={"justLeft flex-row bord1"}>
                                <IconButton className={"action-btn cancel w2 remove-line"} onClick={() => removeSelectedDest(d.id, "client")}>✕</IconButton>
                                {
                                    d.mail ? `${d.name} (${d.mail})` : <div className={"quotation alert"}
                                                           onClick={() => setUpdateMail(d)}>{d.name} </div>
                                }
                            </div>)
                        }</div>
                    </div>
                    }
                    <div className={"tertiary-section"}>Adresses mail ajoutées : {
                        selectedDest.filter(d => d.target === "raw").length > 0 &&
                        <div className={"tertiary-section grid of-auto h10vh"}>
                        {
                            selectedDest.filter(d => d.target === "raw").map(d => <div key={v4()} className={"justLeft bord1 flex-row"}>
                                <IconButton className={"action-btn cancel w2 remove-line"} onClick={() => removeSelectedDest(d.mail, "raw")}>✕</IconButton>
                                {d.mail}
                            </div>)
                        }
                    </div>

                    }</div>
                    <div className={"flex-row"}>
                        <IconButton className={"action-btn cancel"} onClick={() => {
                            setSelectedDest([])
                            setDest(false)
                        }}>{text.CANCEL}</IconButton>
                        <IconButton className={"action-btn validation"} onClick={() => {
                            sendQuotation(constants.SEND_TO_CLIENT)
                            // setDest(false)
                        }}>{text.SEND_TO_CLIENT}</IconButton>
                    </div>
                </div>
            </Modal>
            <Modal open={updateMail} onClose={()=>setUpdateMail(false)}>
                <div className={"tertiary-section flex-column"}>
                    Ce site ne contient aucune adresse mail. Merci d'en renseigner une. <br /><br />
                    {updateMail.name} : <br /><br />
                    <input placeholder={"adresse@mail.fr"} onChange={(e)=> setUpdateMail({...updateMail, mail: e.target.value})}/>
                    <div className={"flex-row"}>
                        <IconButton className={"action-btn cancel"} onClick={() => {
                            setSelectedDest([])
                            setDest(false)
                        }}>{text.CANCEL}</IconButton>
                        <IconButton className={"action-btn validation"} onClick={() => handleUpdateMail()}>{text.SAVE}</IconButton>
                    </div>
                </div>
            </Modal>

            <Modal classNames={{root: 'transparent'}} open={addSection} onClose={()=>setAddSection(false)}>
                <div className={"primary-section flex"}>
                    <h1>Ajout d'une section</h1>
                    <div className={"flex-row"}>
                        <div className={"bord1 as-link"} onClick={() => {
                            if (addSection !== "intervention"){
                                setNewSection({idx: newSection.idx})
                                setAddSection("intervention")
                            }
                        }}>Terrain</div>
                        <div className={"bord1 as-link"} onClick={() => {
                            if (addSection !== "desk"){
                                setNewSection({idx: newSection.idx})
                                setAddSection("desk")
                            }
                        }}>Administratif</div>
                    </div>
                    {
                        addSection === "intervention"
                            ?
                            <div className={"poi-chapter flex-row"}>
                                {
                                    !newSection.et &&
                                    <div className={"secondary-section h70vh"}>
                                        <h1>Support : </h1>
                                        <div className={"bord1"}><ul>
                                            {
                                                elementType?.map(et =>
                                                    <li key={v4()}>
                                                        <div className={"as-link bord1"} onClick={()=> setNewSection({...newSection, et: et})} style={{textDecoration: newSection?.et?.id=== et.id ? "underline .2em":""}}>{et.name} &nbsp;&nbsp;&#12297; </div>
                                                    </li>
                                                )
                                            }
                                        </ul></div>
                                    </div>
                                }
                                {
                                    (newSection.et) &&
                                    <div className={"secondary-section"}>
                                        <div className={"bord1"}><h1>Support :</h1>
                                            <div className={"as-link bord1"} onClick={()=> setNewSection({...newSection, et: false})}>{newSection.et.name} &nbsp;&nbsp;&#12297; </div>

                                        </div>
                                        <div className={"bord1"}>
                                        <h1>Élément concerné :</h1>
                                        <ul className={"of-auto h55vh bord1"}>
                                            {
                                                operationType?.map(ot =>
                                                    <li key={v4()}>
                                                        <div className={"as-link bord1"} onClick={()=> setNewSection({...newSection, ot: ot})} style={{textDecoration: newSection?.ot?.id=== ot.id ? "underline 0.15em":""}}> &nbsp;&nbsp;&#12297; {ot.name}</div>
                                                    </li>
                                                )
                                            }
                                        </ul></div>
                                    </div>
                                }
                                {
                                    /*(newSection.et && newSection.ot) &&
                                    <div className={"primary-section"}>
                                        <div>
                                            <a onClick={()=> setNewSection({...newSection, et: false})}>{newSection.et.name}</a>
                                            &nbsp;&nbsp;&#12297;
                                            <a onClick={()=> setNewSection({...newSection, ot: false})}>{newSection.ot.name}</a>
                                        </div>
                                        <h1>Travail à faire</h1>
                                        <ul className={"align-start"}>
                                            {
                                                newSection?.ot?.nomenclature &&
                                                Object.keys(newSection?.ot?.nomenclature)?.map(type =>
                                                    <li>
                                                        <a onClick={()=> setNewSection({...newSection, type: type})} style={{textDecoration: newSection?.type=== type ? "underline 0.15em":""}}>
                                                            {nomenclatureTypeMapping[type]}
                                                            <ul>
                                                                {
                                                                    newSection?.ot?.nomenclature[type]
                                                                        .filter(f => !f.startsWith("complements_"))
                                                                        .map(ref =>
                                                                            <li>
                                                                                {mapReference(ref)}
                                                                            </li>
                                                                        )
                                                                }
                                                                {
                                                                    newSection?.ot?.nomenclature[type].some(f => f.includes(newSection.et.id))
                                                                    &&
                                                                    newSection?.et?.nomenclature[type].map(ref =>
                                                                        <li>
                                                                            {`\t${mapReference(ref)}`}
                                                                        </li>
                                                                    )
                                                                }
                                                            </ul>
                                                        </a>
                                                    </li>
                                                )
                                            }
                                        </ul>
                                    </div>*/
                                }
                            </div>
                            :
                            <div className={"grid poi-chapter flex-row"}>
                                {
                                    !newSection.pf &&
                                    <div className={"secondary-section "}>
                                        <h1>Administratif :</h1>
                                        <div className={"bord1"}><ul>
                                            {
                                                preciserFamilies.filter(f => f.jobTypes.length === 0)?.map(pf =>
                                                    <li key={v4()}>
                                                        <div className={"as-link bord1"} onClick={()=> {
                                                            setNewSection({...newSection, pf: pf})
                                                        }} style={{textDecoration: newSection?.pf?.id=== pf.id ? "underline 0.2em":""}}>{pf.name} &nbsp;&nbsp;&#12297; </div>
                                                    </li>
                                                )
                                            }
                                        </ul></div>
                                    </div>

                                }
                                {
                                    (newSection.pf && !newSection.pt) &&
                                    <div className={"secondary-section large w100 h30vh"}>
                                        <div>
                                            <div className={"bord1 as-link"} onClick={()=> setNewSection({...newSection, pf: false})}>{newSection.pf.name} &nbsp;&nbsp;&#12297;</div>
                                        </div>
                                        <h1>Type de service :</h1>
                                        <ul className={"align-start bord1"}>
                                            {
                                                productTypes?.filter(f => f.preciserFamily === newSection.pf.id)?.map(pt =>
                                                    <li key={v4()}>
                                                        <div className={"as-link bord1"} onClick={()=> {
                                                            updateProductList(pt.name)
                                                            setNewSection({...newSection, pt: pt})
                                                        }} style={{textDecoration: newSection?.pt?.id === pt?.id ? "underline 0.15em":""}}>
                                                            {pt.name}
                                                            &nbsp;&nbsp;&#12297;
                                                        </div>
                                                    </li>
                                                )
                                            }
                                        </ul>
                                    </div>
                                }
                                {
                                    (newSection.pf && newSection.pt) &&
                                    <div className={"secondary-section h30vh"}>
                                        <div className={"flex-row"}>
                                            <div className={"as-link bord1"} onClick={()=> setNewSection({...newSection, pf: false, pt: false})}>{newSection.pf.name} &nbsp;&nbsp;&#12297;</div>
                                            <div className={"as-link bord1"} onClick={()=> setNewSection({...newSection, pt: false})}>{newSection.pt.name} &nbsp;&nbsp;&#12297;</div>

                                        </div>
                                        <h1>Sélection du service :</h1>
                                        <ul className={"align-start bord1"}>
                                            {
                                                productList?.map(p =>
                                                    <li key={v4()}>
                                                        <div className={"as-link bord1"} onClick={()=> setNewSection({...newSection, product: p})} style={{textDecoration: newSection?.product?.id === p?.id ? "underline .2em":""}}>
                                                            &nbsp;&nbsp;&#12297;
                                                            {p.name}
                                                        </div>
                                                    </li>
                                                )
                                            }
                                        </ul>
                                    </div>
                                }
                            </div>
                    }
                </div>
                <div className={"flex-row"}>
                    <IconButton className={"action-btn cancel"} onClick={() => {}}>{text.CANCEL}</IconButton>
                    {
                        (newSection.ot && newSection.et) &&
                        <IconButton className={"action-btn validation"} onClick={handleNewSection}>{text.SAVE}</IconButton>
                    }
                    {
                        (newSection.pf && newSection.pt && newSection.product) &&
                        <IconButton className={"action-btn validation"} onClick={handleNewAdminSection}>{text.SAVE}</IconButton>
                    }
                </div>
            </Modal>
            <Modal classNames={{root: 'transparent'}} open={handleProduct} onClose={()=>setHandleProduct(false)}>
                <div className={"primary-section"}>
                    <h1>Ajout d'un produit :</h1>
                    {
                        productList &&
                        <div>
                            {productList.map((p, idx) => showProduct(p, idx))}
                        </div>
                    }
                    {
                        selectedProduct &&
                        <IconButton className={"action-btn validation"} onClick={handleNewProduct}>{text.SAVE}</IconButton>
                    }
                </div>
            </Modal>

            <Modal classNames={{root: 'transparent'}} open={catalogMode} onClose={()=>setCatalogMode(false)}>
                <div className={"primary-section"}>
                    <h1>Ajout d'un produit</h1>
                    <div className={"secondary-section grid poi-chapter"}>
                        {
                            !catalogMode?.family &&
                            <Fragment>
                                <h2>Votre produit concerne :</h2>
                                <ul className={"align-start"}>
                                    {families?.sort((a, b) => a.name > b.name ? 1 : -1)?.map(f =>
                                        <li key={v4()} onClick={()=>setCatalogMode({...catalogMode, family: f})}><div className={"as-link"}>{f.name}</div></li>
                                    )}
                                </ul>
                            </Fragment>
                        }
                        {
                            (catalogMode?.family && !catalogMode?.preciserFamily) &&
                            <Fragment>
                                <div>
                                    <div className={"as-link bord1"} onClick={()=> setCatalogMode({...catalogMode, family: false})}>{catalogMode.family.name}</div>
                                </div>
                                <h2>Votre produit concerne plus particulièrement :</h2>
                                <ul className={"align-start bord1"}>
                                    {preciserFamilies?.filter(f => f.family === catalogMode?.family?.id)?.sort((a, b) => a.name > b.name ? 1 : -1)?.map(pf =>
                                        <li key={v4()} onClick={()=>setCatalogMode({...catalogMode, preciserFamily: pf})}><div className={"as-link"}>{pf.name}</div></li>
                                    )}
                                </ul>
                            </Fragment>
                        }
                        {
                            (catalogMode?.family && catalogMode?.preciserFamily && !catalogMode?.productType) &&
                            <Fragment>
                                <div className={"flex-row"}>
                                    <div className={"as-link"} onClick={()=> setCatalogMode({...catalogMode, family: false, preciserFamily: false})}>{catalogMode.family.name}</div>
                                    &nbsp;&nbsp;&#12297;
                                    <div className={"as-link"} onClick={()=> setCatalogMode({...catalogMode, preciserFamily: false})}>{catalogMode.preciserFamily.name}</div>
                                </div>
                                <h2>Voici le(s) type(s) de produit existant pour votre sélection :</h2>
                                <ul className={"align-start"}>
                                    {productTypes?.filter(f => f.preciserFamily === catalogMode?.preciserFamily?.id)?.sort((a, b) => a.name > b.name ? 1 : -1)?.map(pt =>
                                        <li key={v4()} onClick={()=>{
                                            setProductList(false)
                                            updateProductList(pt.name)
                                            setCatalogMode({...catalogMode, productType: pt})
                                        }}><div className={"as-link"}>{pt.name}</div></li>
                                    )}
                                </ul>
                            </Fragment>
                        }
                        {
                            (catalogMode?.family && catalogMode?.preciserFamily && catalogMode?.productType && !catalogMode.product && productList) &&
                            <div>
                                <div className={"flex-row"}>
                                    <div className={"as-link"} onClick={()=> setCatalogMode({...catalogMode, family: false, preciserFamily: false, productType: false})}>{catalogMode.family.name}</div>
                                    &nbsp;&nbsp;&#12297;
                                    <div className={"as-link"} onClick={()=> setCatalogMode({...catalogMode, preciserFamily: false, productType: false})}>{catalogMode.preciserFamily.name}</div>
                                    &nbsp;&nbsp;&#12297;
                                    <div className={"as-link"} onClick={()=> setCatalogMode({...catalogMode, productType: false})}>{catalogMode.productType.name}</div>
                                </div>
                                <div className={"auto-grid"}>
                                    {productList.map((p, idx) => showProduct(p, idx))}
                                </div>
                            </div>
                        }
                        {
                            selectedProduct &&
                            <IconButton className={"action-btn validation"} onClick={addFreeProduct}>+ Ajouter ce produit</IconButton>
                        }
                    </div>
                </div>
            </Modal>
            <Modal classNames={{root: 'transparent'}} open={carousel} onClose={()=>setCarousel(false)}>
                <div className={"caroussel-container primary-section"}>
                    <div className={"zoomHowto"}>{text.ZOOMHOWTO}</div>
                    <div className={"caroussel flex-row rounded"}>
                        <div className={"indexNumber rounded"}>{carousel.current + 1}</div>


                        {
                            carousel.current>0 &&
                            <IconButton onClick={()=>setCarousel({...carousel, current: carousel?.current-1})}>‹</IconButton>
                        }

                        <div className={"keep-space"}>
                            <CustomImage className={"rounded"} name={carousel?.pictures?.[carousel?.current]}/>
                        </div>
                        {
                            carousel?.current<carousel?.pictures?.length-1 &&
                            <IconButton onClick={()=>setCarousel({...carousel, current: carousel?.current+1})}>›</IconButton>
                        }

                    </div>

                </div>
            </Modal>

            <Modal open={previewPDF} onClose={() => setPreviewPDF(null)}>
                {
                    previewPDF === 1 || previewPDF === -1 ?
                        <div/> :
                        <embed className={"pdf-modal"} src={`data:application/pdf;base64,${previewPDF}`}/>
                }
            </Modal>

        </div>
    )
}