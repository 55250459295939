import genericLogo from "../../logo.svg";
import {Tooltip} from "@mui/material";
import {text} from "./constants";
import InterventionIcon from "../../images/banicon-interv.png";
import DevisIcon from "../../images/banicon-devis.png";
import SupplyPage from "../../images/supplyPage.png";
import ClientIcon from "../../images/banicon-clients.png";
import SiteIcon from "../../images/banicon-sites.png";
import FournisseurIcon from "../../images/banicon-fournisseur.png";
import ParamIcon from "../../images/banicon-param.png";
import NotifIcon from "../../images/banicon-notif.png";
import React from "react";
import IbatApi from "../../utils/IbatApi";
import {v4} from "uuid";

export default function Navbar({logo, handleDeconnexion, myUsername, notificationNumber}) {

    const api = new IbatApi()
    const [open, setOpen] = React.useState(false);
    const navItems = [
        {title: text.INTERVENTION_TABLE, link: "/", src: InterventionIcon},
        {title: text.DEVIS_TABLE, link: "/quotations", src: DevisIcon},
        // {title: text.SUPPLIES_PAGE, link: "/supplies", src: SupplyPage},
        {title: text.CLIENT_TABLE, link: "/clients", src: ClientIcon},
        {title: text.SITE_TABLE, link: "/sites", src: SiteIcon},
        {title: text.HOURS_PAGE, link: "/times", src: FournisseurIcon},
        {title: text.PARAMETERS, link: "/parameter", src: ParamIcon},
        // {title: text.NOTIFICATIONS, link: "/notifications", src: NotifIcon, children: <span style={{visibility: "hidden"}} className={`notificationNumber w2 flex-column`}>{notificationNumber}</span>},
    ]


    return <nav className={`grid navbar${open ? " opened" : ""}`}>
        <div className={"logo"}>
            <a href={"/"} className={"logoImg"}><img alt={"Logo entreprise"} src={logo?? genericLogo}/></a>
        </div>

        <div className={`burger-btn${open ? " opened" : ""}`} onClick={() => setOpen(!open)}>
                    <span className="burger-icon">
                      <span className={"row1"}></span>
                      <span className={"row2"}></span>
                      <span className={"row3"}></span>
                    </span>
        </div>
        <ul className={open ? "opened" : ""}>
            {
                navItems.map(item => <li key={v4()}>
                    <Tooltip title={item.title}>
                        <a href={item.link}>
                            <img alt={"intervention"} src={item.src}/>
                            {item.children ?? <></>}
                        </a>
                    </Tooltip>
                </li>)
            }
            {(api.getToken() !== "") &&
                <li>
                    <Tooltip title={text.CONNECT}>
                        <div className={"bord1 profile br20 flex-row"} onClick={handleDeconnexion}>{myUsername}</div>
                    </Tooltip>
                </li>
            }
        </ul>
        {/*<div className={"burgerBtn"}>
                    <Menu width={'80%'} noTransition noOverlay className={ "crossBtn" } styles={styles}>
                        <Tooltip title={text.INTERVENTION_TABLE}>
                            <a href={"/"}>
                                <img alt={"intervention"} src={InterventionIcon}/>
                            </a>
                        </Tooltip>
                        <Tooltip title={text.SUPPLIES_PAGE}>
                            <a href={"/supplies"}>
                                <img alt={"fournitures"} src={SupplyPage}/>
                            </a>
                        </Tooltip>
                        <Tooltip title={text.CLIENT_TABLE}>
                            <a href={"/clients"}>
                                <img alt={"clients"} src={ClientIcon}/>
                            </a>
                        </Tooltip>
                        <Tooltip title={text.SITE_TABLE}>
                            <a href={"/sites"}>
                                <img alt={"sites"} src={SiteIcon}/>
                            </a>
                        </Tooltip>

                        <Tooltip title={text.HOURS_PAGE}>
                            <a href={"/times"}>
                                <img alt={"Heures"} src={FournisseurIcon}/>
                            </a>
                        </Tooltip>
                        <Tooltip title={text.PARAMETERS}>
                            <a href={"/parameter"}>
                                <img alt={"parametres"} src={ParamIcon}/>
                            </a>
                        </Tooltip>
                        <Tooltip title={text.NOTIFICATIONS}>
                            <a href={"/notifications"}>
                                <img alt={"notifications"} src={NotifIcon}/>
                            </a>
                        </Tooltip>
                        {(api.getToken() !== "") &&
                            <Tooltip title={text.CONNECT}>
                                <div className={"profile"} onClick={handleDeconnexion}>{api.getTokenData()?.username}</div>
                            </Tooltip>
                        }

                    </Menu>
                </div>*/}
    </nav>
}