import React, {Fragment} from "react";
import IbatApi from "../../utils/IbatApi";
import constants from "../util/constants";
import {useSnackbar} from "notistack";
import { text } from "../util/constants";
import TimeSelector from "../util/TimeSelector";

export default function EditEstimateDuration({id,update}) {

    const { enqueueSnackbar} = useSnackbar();
    let api = new IbatApi();

    function handleSave(effTime) {
        api.setInterventionDuration(id,effTime/1000)
            .then(c=>update())
            .then(r => enqueueSnackbar(text.SNACKBAR_SAVED,{variant:"success"}))
            .catch(e=> enqueueSnackbar(text.SNACKBAR_ERROR,{variant:"error"}))
    }


    if(!api.hasRole(constants.PERMISSION_PM)){
        return <h3>{text.NO_RIGHTS_PROMPT}</h3>
    }
    return(
        <Fragment>
            <TimeSelector onSave={handleSave}/>
        </Fragment>


)
}